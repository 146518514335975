!(function (t, e, i, s) {
  function n(e, i) {
    (this.settings = null),
      (this.options = t.extend({}, n.Defaults, i)),
      (this.$element = t(e)),
      (this._handlers = {}),
      (this._plugins = {}),
      (this._supress = {}),
      (this._current = null),
      (this._speed = null),
      (this._coordinates = []),
      (this._breakpoint = null),
      (this._width = null),
      (this._items = []),
      (this._clones = []),
      (this._mergers = []),
      (this._widths = []),
      (this._invalidated = {}),
      (this._pipe = []),
      (this._drag = {
        time: null,
        target: null,
        pointer: null,
        stage: { start: null, current: null },
        direction: null,
      }),
      (this._states = {
        current: {},
        tags: {
          initializing: ['busy'],
          animating: ['busy'],
          dragging: ['interacting'],
        },
      }),
      t.each(
        ['onResize', 'onThrottledResize'],
        t.proxy(function (e, i) {
          this._handlers[i] = t.proxy(this[i], this);
        }, this)
      ),
      t.each(
        n.Plugins,
        t.proxy(function (t, e) {
          this._plugins[t.charAt(0).toLowerCase() + t.slice(1)] = new e(this);
        }, this)
      ),
      t.each(
        n.Workers,
        t.proxy(function (e, i) {
          this._pipe.push({ filter: i.filter, run: t.proxy(i.run, this) });
        }, this)
      ),
      this.setup(),
      this.initialize();
  }
  (n.Defaults = {
    items: 3,
    loop: !1,
    center: !1,
    rewind: !1,
    checkVisibility: !0,
    mouseDrag: !0,
    touchDrag: !0,
    pullDrag: !0,
    freeDrag: !1,
    margin: 0,
    stagePadding: 0,
    merge: !1,
    mergeFit: !0,
    autoWidth: !1,
    startPosition: 0,
    rtl: !1,
    smartSpeed: 250,
    fluidSpeed: !1,
    dragEndSpeed: !1,
    responsive: {},
    responsiveRefreshRate: 200,
    responsiveBaseElement: e,
    fallbackEasing: 'swing',
    slideTransition: '',
    info: !1,
    nestedItemSelector: !1,
    itemElement: 'div',
    stageElement: 'div',
    refreshClass: 'owl-refresh',
    loadedClass: 'owl-loaded',
    loadingClass: 'owl-loading',
    rtlClass: 'owl-rtl',
    responsiveClass: 'owl-responsive',
    dragClass: 'owl-drag',
    itemClass: 'owl-item',
    stageClass: 'owl-stage',
    stageOuterClass: 'owl-stage-outer',
    grabClass: 'owl-grab',
  }),
    (n.Width = { Default: 'default', Inner: 'inner', Outer: 'outer' }),
    (n.Type = { Event: 'event', State: 'state' }),
    (n.Plugins = {}),
    (n.Workers = [
      {
        filter: ['width', 'settings'],
        run: function () {
          this._width = this.$element.width();
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          t.current = this._items && this._items[this.relative(this._current)];
        },
      },
      {
        filter: ['items', 'settings'],
        run: function () {
          this.$stage.children('.cloned').remove();
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          var e = this.settings.margin || '',
            i = !this.settings.autoWidth,
            s = this.settings.rtl,
            n = {
              width: 'auto',
              'margin-left': s ? e : '',
              'margin-right': s ? '' : e,
            };
          !i && this.$stage.children().css(n), (t.css = n);
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          var e =
              (this.width() / this.settings.items).toFixed(3) -
              this.settings.margin,
            i = null,
            s = this._items.length,
            n = !this.settings.autoWidth,
            o = [];
          for (t.items = { merge: !1, width: e }; s--; )
            (i = this._mergers[s]),
              (i =
                (this.settings.mergeFit && Math.min(i, this.settings.items)) ||
                i),
              (t.items.merge = i > 1 || t.items.merge),
              (o[s] = n ? e * i : this._items[s].width());
          this._widths = o;
        },
      },
      {
        filter: ['items', 'settings'],
        run: function () {
          var e = [],
            i = this._items,
            s = this.settings,
            n = Math.max(2 * s.items, 4),
            o = 2 * Math.ceil(i.length / 2),
            r = s.loop && i.length ? (s.rewind ? n : Math.max(n, o)) : 0,
            a = '',
            h = '';
          for (r /= 2; r > 0; )
            e.push(this.normalize(e.length / 2, !0)),
              (a += i[e[e.length - 1]][0].outerHTML),
              e.push(this.normalize(i.length - 1 - (e.length - 1) / 2, !0)),
              (h = i[e[e.length - 1]][0].outerHTML + h),
              (r -= 1);
          (this._clones = e),
            t(a).addClass('cloned').appendTo(this.$stage),
            t(h).addClass('cloned').prependTo(this.$stage);
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function () {
          for (
            var t = this.settings.rtl ? 1 : -1,
              e = this._clones.length + this._items.length,
              i = -1,
              s = 0,
              n = 0,
              o = [];
            ++i < e;

          )
            (s = o[i - 1] || 0),
              (n = this._widths[this.relative(i)] + this.settings.margin),
              o.push(s + n * t);
          this._coordinates = o;
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function () {
          var t = this.settings.stagePadding,
            e = this._coordinates,
            i = {
              width: Math.ceil(Math.abs(e[e.length - 1])) + 2 * t,
              'padding-left': t || '',
              'padding-right': t || '',
            };
          this.$stage.css(i);
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          var e = this._coordinates.length,
            i = !this.settings.autoWidth,
            s = this.$stage.children();
          if (i && t.items.merge)
            for (; e--; )
              (t.css.width = this._widths[this.relative(e)]),
                s.eq(e).css(t.css);
          else i && ((t.css.width = t.items.width), s.css(t.css));
        },
      },
      {
        filter: ['items'],
        run: function () {
          this._coordinates.length < 1 && this.$stage.removeAttr('style');
        },
      },
      {
        filter: ['width', 'items', 'settings'],
        run: function (t) {
          (t.current = t.current ? this.$stage.children().index(t.current) : 0),
            (t.current = Math.max(
              this.minimum(),
              Math.min(this.maximum(), t.current)
            )),
            this.reset(t.current);
        },
      },
      {
        filter: ['position'],
        run: function () {
          this.animate(this.coordinates(this._current));
        },
      },
      {
        filter: ['width', 'position', 'items', 'settings'],
        run: function () {
          var t,
            e,
            i,
            s,
            n = this.settings.rtl ? 1 : -1,
            o = 2 * this.settings.stagePadding,
            r = this.coordinates(this.current()) + o,
            a = r + this.width() * n,
            h = [];
          for (i = 0, s = this._coordinates.length; i < s; i++)
            (t = this._coordinates[i - 1] || 0),
              (e = Math.abs(this._coordinates[i]) + o * n),
              ((this.op(t, '<=', r) && this.op(t, '>', a)) ||
                (this.op(e, '<', r) && this.op(e, '>', a))) &&
                h.push(i);
          this.$stage.children('.active').removeClass('active'),
            this.$stage
              .children(':eq(' + h.join('), :eq(') + ')')
              .addClass('active'),
            this.$stage.children('.center').removeClass('center'),
            this.settings.center &&
              this.$stage.children().eq(this.current()).addClass('center');
        },
      },
    ]),
    (n.prototype.initializeStage = function () {
      (this.$stage = this.$element.find('.' + this.settings.stageClass)),
        this.$stage.length ||
          (this.$element.addClass(this.options.loadingClass),
          (this.$stage = t('<' + this.settings.stageElement + '>', {
            class: this.settings.stageClass,
          }).wrap(t('<div/>', { class: this.settings.stageOuterClass }))),
          this.$element.append(this.$stage.parent()));
    }),
    (n.prototype.initializeItems = function () {
      var e = this.$element.find('.owl-item');
      return e.length
        ? ((this._items = e.get().map(function (e) {
            return t(e);
          })),
          (this._mergers = this._items.map(function () {
            return 1;
          })),
          void this.refresh())
        : (this.replace(this.$element.children().not(this.$stage.parent())),
          this.isVisible() ? this.refresh() : this.invalidate('width'),
          void this.$element
            .removeClass(this.options.loadingClass)
            .addClass(this.options.loadedClass));
    }),
    (n.prototype.initialize = function () {
      if (
        (this.enter('initializing'),
        this.trigger('initialize'),
        this.$element.toggleClass(this.settings.rtlClass, this.settings.rtl),
        this.settings.autoWidth && !this.is('pre-loading'))
      ) {
        var t, e, i;
        (t = this.$element.find('img')),
          (e = this.settings.nestedItemSelector
            ? '.' + this.settings.nestedItemSelector
            : s),
          (i = this.$element.children(e).width()),
          t.length && i <= 0 && this.preloadAutoWidthImages(t);
      }
      this.initializeStage(),
        this.initializeItems(),
        this.registerEventHandlers(),
        this.leave('initializing'),
        this.trigger('initialized');
    }),
    (n.prototype.isVisible = function () {
      return !this.settings.checkVisibility || this.$element.is(':visible');
    }),
    (n.prototype.setup = function () {
      var e = this.viewport(),
        i = this.options.responsive,
        s = -1,
        n = null;
      i
        ? (t.each(i, function (t) {
            t <= e && t > s && (s = Number(t));
          }),
          (n = t.extend({}, this.options, i[s])),
          'function' == typeof n.stagePadding &&
            (n.stagePadding = n.stagePadding()),
          delete n.responsive,
          n.responsiveClass &&
            this.$element.attr(
              'class',
              this.$element
                .attr('class')
                .replace(
                  new RegExp(
                    '(' + this.options.responsiveClass + '-)\\S+\\s',
                    'g'
                  ),
                  '$1' + s
                )
            ))
        : (n = t.extend({}, this.options)),
        this.trigger('change', { property: { name: 'settings', value: n } }),
        (this._breakpoint = s),
        (this.settings = n),
        this.invalidate('settings'),
        this.trigger('changed', {
          property: { name: 'settings', value: this.settings },
        });
    }),
    (n.prototype.optionsLogic = function () {
      this.settings.autoWidth &&
        ((this.settings.stagePadding = !1), (this.settings.merge = !1));
    }),
    (n.prototype.prepare = function (e) {
      var i = this.trigger('prepare', { content: e });
      return (
        i.data ||
          (i.data = t('<' + this.settings.itemElement + '/>')
            .addClass(this.options.itemClass)
            .append(e)),
        this.trigger('prepared', { content: i.data }),
        i.data
      );
    }),
    (n.prototype.update = function () {
      for (
        var e = 0,
          i = this._pipe.length,
          s = t.proxy(function (t) {
            return this[t];
          }, this._invalidated),
          n = {};
        e < i;

      )
        (this._invalidated.all || t.grep(this._pipe[e].filter, s).length > 0) &&
          this._pipe[e].run(n),
          e++;
      (this._invalidated = {}), !this.is('valid') && this.enter('valid');
    }),
    (n.prototype.width = function (t) {
      switch ((t = t || n.Width.Default)) {
        case n.Width.Inner:
        case n.Width.Outer:
          return this._width;
        default:
          return (
            this._width - 2 * this.settings.stagePadding + this.settings.margin
          );
      }
    }),
    (n.prototype.refresh = function () {
      this.enter('refreshing'),
        this.trigger('refresh'),
        this.setup(),
        this.optionsLogic(),
        this.$element.addClass(this.options.refreshClass),
        this.update(),
        this.$element.removeClass(this.options.refreshClass),
        this.leave('refreshing'),
        this.trigger('refreshed');
    }),
    (n.prototype.onThrottledResize = function () {
      e.clearTimeout(this.resizeTimer),
        (this.resizeTimer = e.setTimeout(
          this._handlers.onResize,
          this.settings.responsiveRefreshRate
        ));
    }),
    (n.prototype.onResize = function () {
      return (
        !!this._items.length &&
        this._width !== this.$element.width() &&
        !!this.isVisible() &&
        (this.enter('resizing'),
        this.trigger('resize').isDefaultPrevented()
          ? (this.leave('resizing'), !1)
          : (this.invalidate('width'),
            this.refresh(),
            this.leave('resizing'),
            void this.trigger('resized')))
      );
    }),
    (n.prototype.registerEventHandlers = function () {
      t.support.transition &&
        this.$stage.on(
          t.support.transition.end + '.owl.core',
          t.proxy(this.onTransitionEnd, this)
        ),
        this.settings.responsive !== !1 &&
          this.on(e, 'resize', this._handlers.onThrottledResize),
        this.settings.mouseDrag &&
          (this.$element.addClass(this.options.dragClass),
          this.$stage.on('mousedown.owl.core', t.proxy(this.onDragStart, this)),
          this.$stage.on(
            'dragstart.owl.core selectstart.owl.core',
            function () {
              return !1;
            }
          )),
        this.settings.touchDrag &&
          (this.$stage.on(
            'touchstart.owl.core',
            t.proxy(this.onDragStart, this)
          ),
          this.$stage.on(
            'touchcancel.owl.core',
            t.proxy(this.onDragEnd, this)
          ));
    }),
    (n.prototype.onDragStart = function (e) {
      var s = null;
      3 !== e.which &&
        (t.support.transform
          ? ((s = this.$stage
              .css('transform')
              .replace(/.*\(|\)| /g, '')
              .split(',')),
            (s = {
              x: s[16 === s.length ? 12 : 4],
              y: s[16 === s.length ? 13 : 5],
            }))
          : ((s = this.$stage.position()),
            (s = {
              x: this.settings.rtl
                ? s.left +
                  this.$stage.width() -
                  this.width() +
                  this.settings.margin
                : s.left,
              y: s.top,
            })),
        this.is('animating') &&
          (t.support.transform ? this.animate(s.x) : this.$stage.stop(),
          this.invalidate('position')),
        this.$element.toggleClass(
          this.options.grabClass,
          'mousedown' === e.type
        ),
        this.speed(0),
        (this._drag.time = new Date().getTime()),
        (this._drag.target = t(e.target)),
        (this._drag.stage.start = s),
        (this._drag.stage.current = s),
        (this._drag.pointer = this.pointer(e)),
        t(i).on(
          'mouseup.owl.core touchend.owl.core',
          t.proxy(this.onDragEnd, this)
        ),
        t(i).one(
          'mousemove.owl.core touchmove.owl.core',
          t.proxy(function (e) {
            var s = this.difference(this._drag.pointer, this.pointer(e));
            t(i).on(
              'mousemove.owl.core touchmove.owl.core',
              t.proxy(this.onDragMove, this)
            ),
              (Math.abs(s.x) < Math.abs(s.y) && this.is('valid')) ||
                (e.preventDefault(),
                this.enter('dragging'),
                this.trigger('drag'));
          }, this)
        ));
    }),
    (n.prototype.onDragMove = function (t) {
      var e = null,
        i = null,
        s = null,
        n = this.difference(this._drag.pointer, this.pointer(t)),
        o = this.difference(this._drag.stage.start, n);
      this.is('dragging') &&
        (t.preventDefault(),
        this.settings.loop
          ? ((e = this.coordinates(this.minimum())),
            (i = this.coordinates(this.maximum() + 1) - e),
            (o.x = ((((o.x - e) % i) + i) % i) + e))
          : ((e = this.settings.rtl
              ? this.coordinates(this.maximum())
              : this.coordinates(this.minimum())),
            (i = this.settings.rtl
              ? this.coordinates(this.minimum())
              : this.coordinates(this.maximum())),
            (s = this.settings.pullDrag ? (-1 * n.x) / 5 : 0),
            (o.x = Math.max(Math.min(o.x, e + s), i + s))),
        (this._drag.stage.current = o),
        this.animate(o.x));
    }),
    (n.prototype.onDragEnd = function (e) {
      var s = this.difference(this._drag.pointer, this.pointer(e)),
        n = this._drag.stage.current,
        o = (s.x > 0) ^ this.settings.rtl ? 'left' : 'right';
      t(i).off('.owl.core'),
        this.$element.removeClass(this.options.grabClass),
        ((0 !== s.x && this.is('dragging')) || !this.is('valid')) &&
          (this.speed(this.settings.dragEndSpeed || this.settings.smartSpeed),
          this.current(this.closest(n.x, 0 !== s.x ? o : this._drag.direction)),
          this.invalidate('position'),
          this.update(),
          (this._drag.direction = o),
          (Math.abs(s.x) > 3 || new Date().getTime() - this._drag.time > 300) &&
            this._drag.target.one('click.owl.core', function () {
              return !1;
            })),
        this.is('dragging') &&
          (this.leave('dragging'), this.trigger('dragged'));
    }),
    (n.prototype.closest = function (e, i) {
      var n = -1,
        o = 30,
        r = this.width(),
        a = this.coordinates();
      return (
        this.settings.freeDrag ||
          t.each(
            a,
            t.proxy(function (t, h) {
              return (
                'left' === i && e > h - o && e < h + o
                  ? (n = t)
                  : 'right' === i && e > h - r - o && e < h - r + o
                  ? (n = t + 1)
                  : this.op(e, '<', h) &&
                    this.op(e, '>', a[t + 1] !== s ? a[t + 1] : h - r) &&
                    (n = 'left' === i ? t + 1 : t),
                n === -1
              );
            }, this)
          ),
        this.settings.loop ||
          (this.op(e, '>', a[this.minimum()])
            ? (n = e = this.minimum())
            : this.op(e, '<', a[this.maximum()]) && (n = e = this.maximum())),
        n
      );
    }),
    (n.prototype.animate = function (e) {
      var i = this.speed() > 0;
      this.is('animating') && this.onTransitionEnd(),
        i && (this.enter('animating'), this.trigger('translate')),
        t.support.transform3d && t.support.transition
          ? this.$stage.css({
              transform: 'translate3d(' + e + 'px,0px,0px)',
              transition:
                this.speed() / 1e3 +
                's' +
                (this.settings.slideTransition
                  ? ' ' + this.settings.slideTransition
                  : ''),
            })
          : i
          ? this.$stage.animate(
              { left: e + 'px' },
              this.speed(),
              this.settings.fallbackEasing,
              t.proxy(this.onTransitionEnd, this)
            )
          : this.$stage.css({ left: e + 'px' });
    }),
    (n.prototype.is = function (t) {
      return this._states.current[t] && this._states.current[t] > 0;
    }),
    (n.prototype.current = function (t) {
      if (t === s) return this._current;
      if (0 === this._items.length) return s;
      if (((t = this.normalize(t)), this._current !== t)) {
        var e = this.trigger('change', {
          property: { name: 'position', value: t },
        });
        e.data !== s && (t = this.normalize(e.data)),
          (this._current = t),
          this.invalidate('position'),
          this.trigger('changed', {
            property: { name: 'position', value: this._current },
          });
      }
      return this._current;
    }),
    (n.prototype.invalidate = function (e) {
      return (
        'string' === t.type(e) &&
          ((this._invalidated[e] = !0),
          this.is('valid') && this.leave('valid')),
        t.map(this._invalidated, function (t, e) {
          return e;
        })
      );
    }),
    (n.prototype.reset = function (t) {
      (t = this.normalize(t)),
        t !== s &&
          ((this._speed = 0),
          (this._current = t),
          this.suppress(['translate', 'translated']),
          this.animate(this.coordinates(t)),
          this.release(['translate', 'translated']));
    }),
    (n.prototype.normalize = function (t, e) {
      var i = this._items.length,
        n = e ? 0 : this._clones.length;
      return (
        !this.isNumeric(t) || i < 1
          ? (t = s)
          : (t < 0 || t >= i + n) &&
            (t = ((((t - n / 2) % i) + i) % i) + n / 2),
        t
      );
    }),
    (n.prototype.relative = function (t) {
      return (t -= this._clones.length / 2), this.normalize(t, !0);
    }),
    (n.prototype.maximum = function (t) {
      var e,
        i,
        s,
        n = this.settings,
        o = this._coordinates.length;
      if (n.loop) o = this._clones.length / 2 + this._items.length - 1;
      else if (n.autoWidth || n.merge) {
        if ((e = this._items.length))
          for (
            i = this._items[--e].width(), s = this.$element.width();
            e-- &&
            ((i += this._items[e].width() + this.settings.margin), !(i > s));

          );
        o = e + 1;
      } else
        o = n.center ? this._items.length - 1 : this._items.length - n.items;
      return t && (o -= this._clones.length / 2), Math.max(o, 0);
    }),
    (n.prototype.minimum = function (t) {
      return t ? 0 : this._clones.length / 2;
    }),
    (n.prototype.items = function (t) {
      return t === s
        ? this._items.slice()
        : ((t = this.normalize(t, !0)), this._items[t]);
    }),
    (n.prototype.mergers = function (t) {
      return t === s
        ? this._mergers.slice()
        : ((t = this.normalize(t, !0)), this._mergers[t]);
    }),
    (n.prototype.clones = function (e) {
      var i = this._clones.length / 2,
        n = i + this._items.length,
        o = function (t) {
          return t % 2 === 0 ? n + t / 2 : i - (t + 1) / 2;
        };
      return e === s
        ? t.map(this._clones, function (t, e) {
            return o(e);
          })
        : t.map(this._clones, function (t, i) {
            return t === e ? o(i) : null;
          });
    }),
    (n.prototype.speed = function (t) {
      return t !== s && (this._speed = t), this._speed;
    }),
    (n.prototype.coordinates = function (e) {
      var i,
        n = 1,
        o = e - 1;
      return e === s
        ? t.map(
            this._coordinates,
            t.proxy(function (t, e) {
              return this.coordinates(e);
            }, this)
          )
        : (this.settings.center
            ? (this.settings.rtl && ((n = -1), (o = e + 1)),
              (i = this._coordinates[e]),
              (i += ((this.width() - i + (this._coordinates[o] || 0)) / 2) * n))
            : (i = this._coordinates[o] || 0),
          (i = Math.ceil(i)));
    }),
    (n.prototype.duration = function (t, e, i) {
      return 0 === i
        ? 0
        : Math.min(Math.max(Math.abs(e - t), 1), 6) *
            Math.abs(i || this.settings.smartSpeed);
    }),
    (n.prototype.to = function (t, e) {
      var i = this.current(),
        s = null,
        n = t - this.relative(i),
        o = (n > 0) - (n < 0),
        r = this._items.length,
        a = this.minimum(),
        h = this.maximum();
      this.settings.loop
        ? (!this.settings.rewind && Math.abs(n) > r / 2 && (n += o * -1 * r),
          (t = i + n),
          (s = ((((t - a) % r) + r) % r) + a),
          s !== t &&
            s - n <= h &&
            s - n > 0 &&
            ((i = s - n), (t = s), this.reset(i)))
        : this.settings.rewind
        ? ((h += 1), (t = ((t % h) + h) % h))
        : (t = Math.max(a, Math.min(h, t))),
        this.speed(this.duration(i, t, e)),
        this.current(t),
        this.isVisible() && this.update();
    }),
    (n.prototype.next = function (t) {
      (t = t || !1), this.to(this.relative(this.current()) + 1, t);
    }),
    (n.prototype.prev = function (t) {
      (t = t || !1), this.to(this.relative(this.current()) - 1, t);
    }),
    (n.prototype.onTransitionEnd = function (t) {
      return (
        (t === s ||
          (t.stopPropagation(),
          (t.target || t.srcElement || t.originalTarget) ===
            this.$stage.get(0))) &&
        (this.leave('animating'), void this.trigger('translated'))
      );
    }),
    (n.prototype.viewport = function () {
      var s;
      return (
        this.options.responsiveBaseElement !== e
          ? (s = t(this.options.responsiveBaseElement).width())
          : e.innerWidth
          ? (s = e.innerWidth)
          : i.documentElement && i.documentElement.clientWidth
          ? (s = i.documentElement.clientWidth)
          : console.warn('Can not detect viewport width.'),
        s
      );
    }),
    (n.prototype.replace = function (e) {
      this.$stage.empty(),
        (this._items = []),
        e && (e = e instanceof jQuery ? e : t(e)),
        this.settings.nestedItemSelector &&
          (e = e.find('.' + this.settings.nestedItemSelector)),
        e
          .filter(function () {
            return 1 === this.nodeType;
          })
          .each(
            t.proxy(function (t, e) {
              (e = this.prepare(e)),
                this.$stage.append(e),
                this._items.push(e),
                this._mergers.push(
                  1 *
                    e
                      .find('[data-merge]')
                      .addBack('[data-merge]')
                      .attr('data-merge') || 1
                );
            }, this)
          ),
        this.reset(
          this.isNumeric(this.settings.startPosition)
            ? this.settings.startPosition
            : 0
        ),
        this.invalidate('items');
    }),
    (n.prototype.add = function (e, i) {
      var n = this.relative(this._current);
      (i = i === s ? this._items.length : this.normalize(i, !0)),
        (e = e instanceof jQuery ? e : t(e)),
        this.trigger('add', { content: e, position: i }),
        (e = this.prepare(e)),
        0 === this._items.length || i === this._items.length
          ? (0 === this._items.length && this.$stage.append(e),
            0 !== this._items.length && this._items[i - 1].after(e),
            this._items.push(e),
            this._mergers.push(
              1 *
                e
                  .find('[data-merge]')
                  .addBack('[data-merge]')
                  .attr('data-merge') || 1
            ))
          : (this._items[i].before(e),
            this._items.splice(i, 0, e),
            this._mergers.splice(
              i,
              0,
              1 *
                e
                  .find('[data-merge]')
                  .addBack('[data-merge]')
                  .attr('data-merge') || 1
            )),
        this._items[n] && this.reset(this._items[n].index()),
        this.invalidate('items'),
        this.trigger('added', { content: e, position: i });
    }),
    (n.prototype.remove = function (t) {
      (t = this.normalize(t, !0)),
        t !== s &&
          (this.trigger('remove', { content: this._items[t], position: t }),
          this._items[t].remove(),
          this._items.splice(t, 1),
          this._mergers.splice(t, 1),
          this.invalidate('items'),
          this.trigger('removed', { content: null, position: t }));
    }),
    (n.prototype.preloadAutoWidthImages = function (e) {
      e.each(
        t.proxy(function (e, i) {
          this.enter('pre-loading'),
            (i = t(i)),
            t(new Image())
              .one(
                'load',
                t.proxy(function (t) {
                  i.attr('src', t.target.src),
                    i.css('opacity', 1),
                    this.leave('pre-loading'),
                    !this.is('pre-loading') &&
                      !this.is('initializing') &&
                      this.refresh();
                }, this)
              )
              .attr(
                'src',
                i.attr('src') || i.attr('data-src') || i.attr('data-src-retina')
              );
        }, this)
      );
    }),
    (n.prototype.destroy = function () {
      this.$element.off('.owl.core'),
        this.$stage.off('.owl.core'),
        t(i).off('.owl.core'),
        this.settings.responsive !== !1 &&
          (e.clearTimeout(this.resizeTimer),
          this.off(e, 'resize', this._handlers.onThrottledResize));
      for (var s in this._plugins) this._plugins[s].destroy();
      this.$stage.children('.cloned').remove(),
        this.$stage.unwrap(),
        this.$stage.children().contents().unwrap(),
        this.$stage.children().unwrap(),
        this.$stage.remove(),
        this.$element
          .removeClass(this.options.refreshClass)
          .removeClass(this.options.loadingClass)
          .removeClass(this.options.loadedClass)
          .removeClass(this.options.rtlClass)
          .removeClass(this.options.dragClass)
          .removeClass(this.options.grabClass)
          .attr(
            'class',
            this.$element
              .attr('class')
              .replace(
                new RegExp(this.options.responsiveClass + '-\\S+\\s', 'g'),
                ''
              )
          )
          .removeData('owl.carousel');
    }),
    (n.prototype.op = function (t, e, i) {
      var s = this.settings.rtl;
      switch (e) {
        case '<':
          return s ? t > i : t < i;
        case '>':
          return s ? t < i : t > i;
        case '>=':
          return s ? t <= i : t >= i;
        case '<=':
          return s ? t >= i : t <= i;
      }
    }),
    (n.prototype.on = function (t, e, i, s) {
      t.addEventListener
        ? t.addEventListener(e, i, s)
        : t.attachEvent && t.attachEvent('on' + e, i);
    }),
    (n.prototype.off = function (t, e, i, s) {
      t.removeEventListener
        ? t.removeEventListener(e, i, s)
        : t.detachEvent && t.detachEvent('on' + e, i);
    }),
    (n.prototype.trigger = function (e, i, s, o, r) {
      var a = { item: { count: this._items.length, index: this.current() } },
        h = t.camelCase(
          t
            .grep(['on', e, s], function (t) {
              return t;
            })
            .join('-')
            .toLowerCase()
        ),
        l = t.Event(
          [e, 'owl', s || 'carousel'].join('.').toLowerCase(),
          t.extend({ relatedTarget: this }, a, i)
        );
      return (
        this._supress[e] ||
          (t.each(this._plugins, function (t, e) {
            e.onTrigger && e.onTrigger(l);
          }),
          this.register({ type: n.Type.Event, name: e }),
          this.$element.trigger(l),
          this.settings &&
            'function' == typeof this.settings[h] &&
            this.settings[h].call(this, l)),
        l
      );
    }),
    (n.prototype.enter = function (e) {
      t.each(
        [e].concat(this._states.tags[e] || []),
        t.proxy(function (t, e) {
          this._states.current[e] === s && (this._states.current[e] = 0),
            this._states.current[e]++;
        }, this)
      );
    }),
    (n.prototype.leave = function (e) {
      t.each(
        [e].concat(this._states.tags[e] || []),
        t.proxy(function (t, e) {
          this._states.current[e]--;
        }, this)
      );
    }),
    (n.prototype.register = function (e) {
      if (e.type === n.Type.Event) {
        if (
          (t.event.special[e.name] || (t.event.special[e.name] = {}),
          !t.event.special[e.name].owl)
        ) {
          var i = t.event.special[e.name]._default;
          (t.event.special[e.name]._default = function (t) {
            return !i ||
              !i.apply ||
              (t.namespace && t.namespace.indexOf('owl') !== -1)
              ? t.namespace && t.namespace.indexOf('owl') > -1
              : i.apply(this, arguments);
          }),
            (t.event.special[e.name].owl = !0);
        }
      } else
        e.type === n.Type.State &&
          (this._states.tags[e.name]
            ? (this._states.tags[e.name] = this._states.tags[e.name].concat(
                e.tags
              ))
            : (this._states.tags[e.name] = e.tags),
          (this._states.tags[e.name] = t.grep(
            this._states.tags[e.name],
            t.proxy(function (i, s) {
              return t.inArray(i, this._states.tags[e.name]) === s;
            }, this)
          )));
    }),
    (n.prototype.suppress = function (e) {
      t.each(
        e,
        t.proxy(function (t, e) {
          this._supress[e] = !0;
        }, this)
      );
    }),
    (n.prototype.release = function (e) {
      t.each(
        e,
        t.proxy(function (t, e) {
          delete this._supress[e];
        }, this)
      );
    }),
    (n.prototype.pointer = function (t) {
      var i = { x: null, y: null };
      return (
        (t = t.originalEvent || t || e.event),
        (t =
          t.touches && t.touches.length
            ? t.touches[0]
            : t.changedTouches && t.changedTouches.length
            ? t.changedTouches[0]
            : t),
        t.pageX
          ? ((i.x = t.pageX), (i.y = t.pageY))
          : ((i.x = t.clientX), (i.y = t.clientY)),
        i
      );
    }),
    (n.prototype.isNumeric = function (t) {
      return !isNaN(parseFloat(t));
    }),
    (n.prototype.difference = function (t, e) {
      return { x: t.x - e.x, y: t.y - e.y };
    }),
    (t.fn.owlCarousel = function (e) {
      var i = Array.prototype.slice.call(arguments, 1);
      return this.each(function () {
        var s = t(this),
          o = s.data('owl.carousel');
        o ||
          ((o = new n(this, 'object' == typeof e && e)),
          s.data('owl.carousel', o),
          t.each(
            [
              'next',
              'prev',
              'to',
              'destroy',
              'refresh',
              'replace',
              'add',
              'remove',
            ],
            function (e, i) {
              o.register({ type: n.Type.Event, name: i }),
                o.$element.on(
                  i + '.owl.carousel.core',
                  t.proxy(function (t) {
                    t.namespace &&
                      t.relatedTarget !== this &&
                      (this.suppress([i]),
                      o[i].apply(this, [].slice.call(arguments, 1)),
                      this.release([i]));
                  }, o)
                );
            }
          )),
          'string' == typeof e && '_' !== e.charAt(0) && o[e].apply(o, i);
      });
    }),
    (t.fn.owlCarousel.Constructor = n);
})(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._interval = null),
        (this._visible = null),
        (this._handlers = {
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace && this._core.settings.autoRefresh && this.watch();
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers);
    };
    (n.Defaults = { autoRefresh: !0, autoRefreshInterval: 500 }),
      (n.prototype.watch = function () {
        this._interval ||
          ((this._visible = this._core.isVisible()),
          (this._interval = e.setInterval(
            t.proxy(this.refresh, this),
            this._core.settings.autoRefreshInterval
          )));
      }),
      (n.prototype.refresh = function () {
        this._core.isVisible() !== this._visible &&
          ((this._visible = !this._visible),
          this._core.$element.toggleClass('owl-hidden', !this._visible),
          this._visible &&
            this._core.invalidate('width') &&
            this._core.refresh());
      }),
      (n.prototype.destroy = function () {
        var t, i;
        e.clearInterval(this._interval);
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (i in Object.getOwnPropertyNames(this))
          'function' != typeof this[i] && (this[i] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.AutoRefresh = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._loaded = []),
        (this._handlers = {
          'initialized.owl.carousel change.owl.carousel resized.owl.carousel':
            t.proxy(function (e) {
              if (
                e.namespace &&
                this._core.settings &&
                this._core.settings.lazyLoad &&
                ((e.property && 'position' == e.property.name) ||
                  'initialized' == e.type)
              ) {
                var i = this._core.settings,
                  n = (i.center && Math.ceil(i.items / 2)) || i.items,
                  o = (i.center && n * -1) || 0,
                  r =
                    (e.property && e.property.value !== s
                      ? e.property.value
                      : this._core.current()) + o,
                  a = this._core.clones().length,
                  h = t.proxy(function (t, e) {
                    this.load(e);
                  }, this);
                for (
                  i.lazyLoadEager > 0 &&
                  ((n += i.lazyLoadEager),
                  i.loop && ((r -= i.lazyLoadEager), n++));
                  o++ < n;

                )
                  this.load(a / 2 + this._core.relative(r)),
                    a && t.each(this._core.clones(this._core.relative(r)), h),
                    r++;
              }
            }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers);
    };
    (n.Defaults = { lazyLoad: !1, lazyLoadEager: 0 }),
      (n.prototype.load = function (i) {
        var s = this._core.$stage.children().eq(i),
          n = s && s.find('.owl-lazy');
        !n ||
          t.inArray(s.get(0), this._loaded) > -1 ||
          (n.each(
            t.proxy(function (i, s) {
              var n,
                o = t(s),
                r =
                  (e.devicePixelRatio > 1 && o.attr('data-src-retina')) ||
                  o.attr('data-src') ||
                  o.attr('data-srcset');
              this._core.trigger('load', { element: o, url: r }, 'lazy'),
                o.is('img')
                  ? o
                      .one(
                        'load.owl.lazy',
                        t.proxy(function () {
                          o.css('opacity', 1),
                            this._core.trigger(
                              'loaded',
                              { element: o, url: r },
                              'lazy'
                            );
                        }, this)
                      )
                      .attr('src', r)
                  : o.is('source')
                  ? o
                      .one(
                        'load.owl.lazy',
                        t.proxy(function () {
                          this._core.trigger(
                            'loaded',
                            { element: o, url: r },
                            'lazy'
                          );
                        }, this)
                      )
                      .attr('srcset', r)
                  : ((n = new Image()),
                    (n.onload = t.proxy(function () {
                      o.css({
                        'background-image': 'url("' + r + '")',
                        opacity: '1',
                      }),
                        this._core.trigger(
                          'loaded',
                          { element: o, url: r },
                          'lazy'
                        );
                    }, this)),
                    (n.src = r));
            }, this)
          ),
          this._loaded.push(s.get(0)));
      }),
      (n.prototype.destroy = function () {
        var t, e;
        for (t in this.handlers) this._core.$element.off(t, this.handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Lazy = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (i) {
      (this._core = i),
        (this._previousHeight = null),
        (this._handlers = {
          'initialized.owl.carousel refreshed.owl.carousel': t.proxy(function (
            t
          ) {
            t.namespace && this._core.settings.autoHeight && this.update();
          },
          this),
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.autoHeight &&
              'position' === t.property.name &&
              this.update();
          }, this),
          'loaded.owl.lazy': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.autoHeight &&
              t.element.closest('.' + this._core.settings.itemClass).index() ===
                this._core.current() &&
              this.update();
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers),
        (this._intervalId = null);
      var s = this;
      t(e).on('load', function () {
        s._core.settings.autoHeight && s.update();
      }),
        t(e).resize(function () {
          s._core.settings.autoHeight &&
            (null != s._intervalId && clearTimeout(s._intervalId),
            (s._intervalId = setTimeout(function () {
              s.update();
            }, 250)));
        });
    };
    (n.Defaults = { autoHeight: !1, autoHeightClass: 'owl-height' }),
      (n.prototype.update = function () {
        var e = this._core._current,
          i = e + this._core.settings.items,
          s = this._core.settings.lazyLoad,
          n = this._core.$stage.children().toArray().slice(e, i),
          o = [],
          r = 0;
        t.each(n, function (e, i) {
          o.push(t(i).height());
        }),
          (r = Math.max.apply(null, o)),
          r <= 1 && s && this._previousHeight && (r = this._previousHeight),
          (this._previousHeight = r),
          this._core.$stage
            .parent()
            .height(r)
            .addClass(this._core.settings.autoHeightClass);
      }),
      (n.prototype.destroy = function () {
        var t, e;
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.AutoHeight = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._videos = {}),
        (this._playing = null),
        (this._handlers = {
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.register({
                type: 'state',
                name: 'playing',
                tags: ['interacting'],
              });
          }, this),
          'resize.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.video &&
              this.isInFullScreen() &&
              t.preventDefault();
          }, this),
          'refreshed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.is('resizing') &&
              this._core.$stage.find('.cloned .owl-video-frame').remove();
          }, this),
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              'position' === t.property.name &&
              this._playing &&
              this.stop();
          }, this),
          'prepared.owl.carousel': t.proxy(function (e) {
            if (e.namespace) {
              var i = t(e.content).find('.owl-video');
              i.length &&
                (i.css('display', 'none'), this.fetch(i, t(e.content)));
            }
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this._core.$element.on(this._handlers),
        this._core.$element.on(
          'click.owl.video',
          '.owl-video-play-icon',
          t.proxy(function (t) {
            this.play(t);
          }, this)
        );
    };
    (n.Defaults = { video: !1, videoHeight: !1, videoWidth: !1 }),
      (n.prototype.fetch = function (t, e) {
        var i = (function () {
            return t.attr('data-vimeo-id')
              ? 'vimeo'
              : t.attr('data-vzaar-id')
              ? 'vzaar'
              : 'youtube';
          })(),
          s =
            t.attr('data-vimeo-id') ||
            t.attr('data-youtube-id') ||
            t.attr('data-vzaar-id'),
          n = t.attr('data-width') || this._core.settings.videoWidth,
          o = t.attr('data-height') || this._core.settings.videoHeight,
          r = t.attr('href');
        if (!r) throw new Error('Missing video URL.');
        if (
          ((s = r.match(
            /(http:|https:|)\/\/(player.|www.|app.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com|be\-nocookie\.com)|vzaar\.com)\/(video\/|videos\/|embed\/|channels\/.+\/|groups\/.+\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/
          )),
          s[3].indexOf('youtu') > -1)
        )
          i = 'youtube';
        else if (s[3].indexOf('vimeo') > -1) i = 'vimeo';
        else {
          if (!(s[3].indexOf('vzaar') > -1))
            throw new Error('Video URL not supported.');
          i = 'vzaar';
        }
        (s = s[6]),
          (this._videos[r] = { type: i, id: s, width: n, height: o }),
          e.attr('data-video', r),
          this.thumbnail(t, this._videos[r]);
      }),
      (n.prototype.thumbnail = function (e, i) {
        var s,
          n,
          o,
          r =
            i.width && i.height
              ? 'width:' + i.width + 'px;height:' + i.height + 'px;'
              : '',
          a = e.find('img'),
          h = 'src',
          l = '',
          c = this._core.settings,
          p = function (i) {
            (n = '<div class="owl-video-play-icon"></div>'),
              (s = c.lazyLoad
                ? t('<div/>', { class: 'owl-video-tn ' + l, srcType: i })
                : t('<div/>', {
                    class: 'owl-video-tn',
                    style: 'opacity:1;background-image:url(' + i + ')',
                  })),
              e.after(s),
              e.after(n);
          };
        return (
          e.wrap(t('<div/>', { class: 'owl-video-wrapper', style: r })),
          this._core.settings.lazyLoad && ((h = 'data-src'), (l = 'owl-lazy')),
          a.length
            ? (p(a.attr(h)), a.remove(), !1)
            : void ('youtube' === i.type
                ? ((o = '//img.youtube.com/vi/' + i.id + '/hqdefault.jpg'),
                  p(o))
                : 'vimeo' === i.type
                ? t.ajax({
                    type: 'GET',
                    url: '//vimeo.com/api/v2/video/' + i.id + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function (t) {
                      (o = t[0].thumbnail_large), p(o);
                    },
                  })
                : 'vzaar' === i.type &&
                  t.ajax({
                    type: 'GET',
                    url: '//vzaar.com/api/videos/' + i.id + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function (t) {
                      (o = t.framegrab_url), p(o);
                    },
                  }))
        );
      }),
      (n.prototype.stop = function () {
        this._core.trigger('stop', null, 'video'),
          this._playing.find('.owl-video-frame').remove(),
          this._playing.removeClass('owl-video-playing'),
          (this._playing = null),
          this._core.leave('playing'),
          this._core.trigger('stopped', null, 'video');
      }),
      (n.prototype.play = function (e) {
        var i,
          s,
          n = t(e.target),
          o = n.closest('.' + this._core.settings.itemClass),
          r = this._videos[o.attr('data-video')],
          a = r.width || '100%',
          h = r.height || this._core.$stage.height();
        this._playing ||
          (this._core.enter('playing'),
          this._core.trigger('play', null, 'video'),
          (o = this._core.items(this._core.relative(o.index()))),
          this._core.reset(o.index()),
          (i = t(
            '<iframe frameborder="0" allowfullscreen mozallowfullscreen webkitAllowFullScreen ></iframe>'
          )),
          i.attr('height', h),
          i.attr('width', a),
          'youtube' === r.type
            ? i.attr(
                'src',
                '//www.youtube.com/embed/' +
                  r.id +
                  '?autoplay=1&rel=0&v=' +
                  r.id
              )
            : 'vimeo' === r.type
            ? i.attr('src', '//player.vimeo.com/video/' + r.id + '?autoplay=1')
            : 'vzaar' === r.type &&
              i.attr(
                'src',
                '//view.vzaar.com/' + r.id + '/player?autoplay=true'
              ),
          (s = t(i)
            .wrap('<div class="owl-video-frame" />')
            .insertAfter(o.find('.owl-video'))),
          (this._playing = o.addClass('owl-video-playing')));
      }),
      (n.prototype.isInFullScreen = function () {
        var e =
          i.fullscreenElement ||
          i.mozFullScreenElement ||
          i.webkitFullscreenElement;
        return e && t(e).parent().hasClass('owl-video-frame');
      }),
      (n.prototype.destroy = function () {
        var t, e;
        this._core.$element.off('click.owl.video');
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Video = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this.core = e),
        (this.core.options = t.extend({}, n.Defaults, this.core.options)),
        (this.swapping = !0),
        (this.previous = s),
        (this.next = s),
        (this.handlers = {
          'change.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              'position' == t.property.name &&
              ((this.previous = this.core.current()),
              (this.next = t.property.value));
          }, this),
          'drag.owl.carousel dragged.owl.carousel translated.owl.carousel':
            t.proxy(function (t) {
              t.namespace && (this.swapping = 'translated' == t.type);
            }, this),
          'translate.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this.swapping &&
              (this.core.options.animateOut || this.core.options.animateIn) &&
              this.swap();
          }, this),
        }),
        this.core.$element.on(this.handlers);
    };
    (n.Defaults = { animateOut: !1, animateIn: !1 }),
      (n.prototype.swap = function () {
        if (
          1 === this.core.settings.items &&
          t.support.animation &&
          t.support.transition
        ) {
          this.core.speed(0);
          var e,
            i = t.proxy(this.clear, this),
            s = this.core.$stage.children().eq(this.previous),
            n = this.core.$stage.children().eq(this.next),
            o = this.core.settings.animateIn,
            r = this.core.settings.animateOut;
          this.core.current() !== this.previous &&
            (r &&
              ((e =
                this.core.coordinates(this.previous) -
                this.core.coordinates(this.next)),
              s
                .one(t.support.animation.end, i)
                .css({ left: e + 'px' })
                .addClass('animated owl-animated-out')
                .addClass(r)),
            o &&
              n
                .one(t.support.animation.end, i)
                .addClass('animated owl-animated-in')
                .addClass(o));
        }
      }),
      (n.prototype.clear = function (e) {
        t(e.target)
          .css({ left: '' })
          .removeClass('animated owl-animated-out owl-animated-in')
          .removeClass(this.core.settings.animateIn)
          .removeClass(this.core.settings.animateOut),
          this.core.onTransitionEnd();
      }),
      (n.prototype.destroy = function () {
        var t, e;
        for (t in this.handlers) this.core.$element.off(t, this.handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Animate = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    var n = function (e) {
      (this._core = e),
        (this._call = null),
        (this._time = 0),
        (this._timeout = 0),
        (this._paused = !0),
        (this._handlers = {
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace && 'settings' === t.property.name
              ? this._core.settings.autoplay
                ? this.play()
                : this.stop()
              : t.namespace &&
                'position' === t.property.name &&
                this._paused &&
                (this._time = 0);
          }, this),
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace && this._core.settings.autoplay && this.play();
          }, this),
          'play.owl.autoplay': t.proxy(function (t, e, i) {
            t.namespace && this.play(e, i);
          }, this),
          'stop.owl.autoplay': t.proxy(function (t) {
            t.namespace && this.stop();
          }, this),
          'mouseover.owl.autoplay': t.proxy(function () {
            this._core.settings.autoplayHoverPause &&
              this._core.is('rotating') &&
              this.pause();
          }, this),
          'mouseleave.owl.autoplay': t.proxy(function () {
            this._core.settings.autoplayHoverPause &&
              this._core.is('rotating') &&
              this.play();
          }, this),
          'touchstart.owl.core': t.proxy(function () {
            this._core.settings.autoplayHoverPause &&
              this._core.is('rotating') &&
              this.pause();
          }, this),
          'touchend.owl.core': t.proxy(function () {
            this._core.settings.autoplayHoverPause && this.play();
          }, this),
        }),
        this._core.$element.on(this._handlers),
        (this._core.options = t.extend({}, n.Defaults, this._core.options));
    };
    (n.Defaults = {
      autoplay: !1,
      autoplayTimeout: 5e3,
      autoplayHoverPause: !1,
      autoplaySpeed: !1,
    }),
      (n.prototype._next = function (s) {
        (this._call = e.setTimeout(
          t.proxy(this._next, this, s),
          this._timeout * (Math.round(this.read() / this._timeout) + 1) -
            this.read()
        )),
          this._core.is('interacting') ||
            i.hidden ||
            this._core.next(s || this._core.settings.autoplaySpeed);
      }),
      (n.prototype.read = function () {
        return new Date().getTime() - this._time;
      }),
      (n.prototype.play = function (i, s) {
        var n;
        this._core.is('rotating') || this._core.enter('rotating'),
          (i = i || this._core.settings.autoplayTimeout),
          (n = Math.min(this._time % (this._timeout || i), i)),
          this._paused
            ? ((this._time = this.read()), (this._paused = !1))
            : e.clearTimeout(this._call),
          (this._time += (this.read() % i) - n),
          (this._timeout = i),
          (this._call = e.setTimeout(t.proxy(this._next, this, s), i - n));
      }),
      (n.prototype.stop = function () {
        this._core.is('rotating') &&
          ((this._time = 0),
          (this._paused = !0),
          e.clearTimeout(this._call),
          this._core.leave('rotating'));
      }),
      (n.prototype.pause = function () {
        this._core.is('rotating') &&
          !this._paused &&
          ((this._time = this.read()),
          (this._paused = !0),
          e.clearTimeout(this._call));
      }),
      (n.prototype.destroy = function () {
        var t, e;
        this.stop();
        for (t in this._handlers) this._core.$element.off(t, this._handlers[t]);
        for (e in Object.getOwnPropertyNames(this))
          'function' != typeof this[e] && (this[e] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.autoplay = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    'use strict';
    var n = function (e) {
      (this._core = e),
        (this._initialized = !1),
        (this._pages = []),
        (this._controls = {}),
        (this._templates = []),
        (this.$element = this._core.$element),
        (this._overrides = {
          next: this._core.next,
          prev: this._core.prev,
          to: this._core.to,
        }),
        (this._handlers = {
          'prepared.owl.carousel': t.proxy(function (e) {
            e.namespace &&
              this._core.settings.dotsData &&
              this._templates.push(
                '<div class="' +
                  this._core.settings.dotClass +
                  '">' +
                  t(e.content)
                    .find('[data-dot]')
                    .addBack('[data-dot]')
                    .attr('data-dot') +
                  '</div>'
              );
          }, this),
          'added.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.dotsData &&
              this._templates.splice(t.position, 0, this._templates.pop());
          }, this),
          'remove.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._core.settings.dotsData &&
              this._templates.splice(t.position, 1);
          }, this),
          'changed.owl.carousel': t.proxy(function (t) {
            t.namespace && 'position' == t.property.name && this.draw();
          }, this),
          'initialized.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              !this._initialized &&
              (this._core.trigger('initialize', null, 'navigation'),
              this.initialize(),
              this.update(),
              this.draw(),
              (this._initialized = !0),
              this._core.trigger('initialized', null, 'navigation'));
          }, this),
          'refreshed.owl.carousel': t.proxy(function (t) {
            t.namespace &&
              this._initialized &&
              (this._core.trigger('refresh', null, 'navigation'),
              this.update(),
              this.draw(),
              this._core.trigger('refreshed', null, 'navigation'));
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this.$element.on(this._handlers);
    };
    (n.Defaults = {
      nav: !1,
      navText: [
        '<span aria-label="Previous">&#x2039;</span>',
        '<span aria-label="Next">&#x203a;</span>',
      ],
      navSpeed: !1,
      navElement: 'button type="button" role="presentation"',
      navContainer: !1,
      navContainerClass: 'owl-nav',
      navClass: ['owl-prev', 'owl-next'],
      slideBy: 1,
      dotClass: 'owl-dot',
      dotsClass: 'owl-dots',
      dots: !0,
      dotsEach: !1,
      dotsData: !1,
      dotsSpeed: !1,
      dotsContainer: !1,
    }),
      (n.prototype.initialize = function () {
        var e,
          i = this._core.settings;
        (this._controls.$relative = (
          i.navContainer
            ? t(i.navContainer)
            : t('<div>').addClass(i.navContainerClass).appendTo(this.$element)
        ).addClass('disabled')),
          (this._controls.$previous = t('<' + i.navElement + '>')
            .addClass(i.navClass[0])
            .html(i.navText[0])
            .prependTo(this._controls.$relative)
            .on(
              'click',
              t.proxy(function (t) {
                this.prev(i.navSpeed);
              }, this)
            )),
          (this._controls.$next = t('<' + i.navElement + '>')
            .addClass(i.navClass[1])
            .html(i.navText[1])
            .appendTo(this._controls.$relative)
            .on(
              'click',
              t.proxy(function (t) {
                this.next(i.navSpeed);
              }, this)
            )),
          i.dotsData ||
            (this._templates = [
              t('<button role="button">')
                .addClass(i.dotClass)
                .append(t('<span>'))
                .prop('outerHTML'),
            ]),
          (this._controls.$absolute = (
            i.dotsContainer
              ? t(i.dotsContainer)
              : t('<div>').addClass(i.dotsClass).appendTo(this.$element)
          ).addClass('disabled')),
          this._controls.$absolute.on(
            'click',
            'button',
            t.proxy(function (e) {
              var s = t(e.target).parent().is(this._controls.$absolute)
                ? t(e.target).index()
                : t(e.target).parent().index();
              e.preventDefault(), this.to(s, i.dotsSpeed);
            }, this)
          );
        for (e in this._overrides) this._core[e] = t.proxy(this[e], this);
      }),
      (n.prototype.destroy = function () {
        var t, e, i, s, n;
        n = this._core.settings;
        for (t in this._handlers) this.$element.off(t, this._handlers[t]);
        for (e in this._controls)
          '$relative' === e && n.navContainer
            ? this._controls[e].html('')
            : this._controls[e].remove();
        for (s in this.overides) this._core[s] = this._overrides[s];
        for (i in Object.getOwnPropertyNames(this))
          'function' != typeof this[i] && (this[i] = null);
      }),
      (n.prototype.update = function () {
        var t,
          e,
          i,
          s = this._core.clones().length / 2,
          n = s + this._core.items().length,
          o = this._core.maximum(!0),
          r = this._core.settings,
          a = r.center || r.autoWidth || r.dotsData ? 1 : r.dotsEach || r.items;
        if (
          ('page' !== r.slideBy && (r.slideBy = Math.min(r.slideBy, r.items)),
          r.dots || 'page' == r.slideBy)
        )
          for (this._pages = [], t = s, e = 0, i = 0; t < n; t++) {
            if (e >= a || 0 === e) {
              if (
                (this._pages.push({
                  start: Math.min(o, t - s),
                  end: t - s + a - 1,
                }),
                Math.min(o, t - s) === o)
              )
                break;
              (e = 0), ++i;
            }
            e += this._core.mergers(this._core.relative(t));
          }
      }),
      (n.prototype.draw = function () {
        var e,
          i = this._core.settings,
          s = this._core.items().length <= i.items,
          n = this._core.relative(this._core.current()),
          o = i.loop || i.rewind;
        this._controls.$relative.toggleClass('disabled', !i.nav || s),
          i.nav &&
            (this._controls.$previous.toggleClass(
              'disabled',
              !o && n <= this._core.minimum(!0)
            ),
            this._controls.$next.toggleClass(
              'disabled',
              !o && n >= this._core.maximum(!0)
            )),
          this._controls.$absolute.toggleClass('disabled', !i.dots || s),
          i.dots &&
            ((e =
              this._pages.length - this._controls.$absolute.children().length),
            i.dotsData && 0 !== e
              ? this._controls.$absolute.html(this._templates.join(''))
              : e > 0
              ? this._controls.$absolute.append(
                  new Array(e + 1).join(this._templates[0])
                )
              : e < 0 && this._controls.$absolute.children().slice(e).remove(),
            this._controls.$absolute.find('.active').removeClass('active'),
            this._controls.$absolute
              .children()
              .eq(t.inArray(this.current(), this._pages))
              .addClass('active'));
      }),
      (n.prototype.onTrigger = function (e) {
        var i = this._core.settings;
        e.page = {
          index: t.inArray(this.current(), this._pages),
          count: this._pages.length,
          size:
            i &&
            (i.center || i.autoWidth || i.dotsData ? 1 : i.dotsEach || i.items),
        };
      }),
      (n.prototype.current = function () {
        var e = this._core.relative(this._core.current());
        return t
          .grep(
            this._pages,
            t.proxy(function (t, i) {
              return t.start <= e && t.end >= e;
            }, this)
          )
          .pop();
      }),
      (n.prototype.getPosition = function (e) {
        var i,
          s,
          n = this._core.settings;
        return (
          'page' == n.slideBy
            ? ((i = t.inArray(this.current(), this._pages)),
              (s = this._pages.length),
              e ? ++i : --i,
              (i = this._pages[((i % s) + s) % s].start))
            : ((i = this._core.relative(this._core.current())),
              (s = this._core.items().length),
              e ? (i += n.slideBy) : (i -= n.slideBy)),
          i
        );
      }),
      (n.prototype.next = function (e) {
        t.proxy(this._overrides.to, this._core)(this.getPosition(!0), e);
      }),
      (n.prototype.prev = function (e) {
        t.proxy(this._overrides.to, this._core)(this.getPosition(!1), e);
      }),
      (n.prototype.to = function (e, i, s) {
        var n;
        !s && this._pages.length
          ? ((n = this._pages.length),
            t.proxy(this._overrides.to, this._core)(
              this._pages[((e % n) + n) % n].start,
              i
            ))
          : t.proxy(this._overrides.to, this._core)(e, i);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Navigation = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    'use strict';
    var n = function (i) {
      (this._core = i),
        (this._hashes = {}),
        (this.$element = this._core.$element),
        (this._handlers = {
          'initialized.owl.carousel': t.proxy(function (i) {
            i.namespace &&
              'URLHash' === this._core.settings.startPosition &&
              t(e).trigger('hashchange.owl.navigation');
          }, this),
          'prepared.owl.carousel': t.proxy(function (e) {
            if (e.namespace) {
              var i = t(e.content)
                .find('[data-hash]')
                .addBack('[data-hash]')
                .attr('data-hash');
              if (!i) return;
              this._hashes[i] = e.content;
            }
          }, this),
          'changed.owl.carousel': t.proxy(function (i) {
            if (i.namespace && 'position' === i.property.name) {
              var s = this._core.items(
                  this._core.relative(this._core.current())
                ),
                n = t
                  .map(this._hashes, function (t, e) {
                    return t === s ? e : null;
                  })
                  .join();
              if (!n || e.location.hash.slice(1) === n) return;
              e.location.hash = n;
            }
          }, this),
        }),
        (this._core.options = t.extend({}, n.Defaults, this._core.options)),
        this.$element.on(this._handlers),
        t(e).on(
          'hashchange.owl.navigation',
          t.proxy(function (t) {
            var i = e.location.hash.substring(1),
              n = this._core.$stage.children(),
              o = this._hashes[i] && n.index(this._hashes[i]);
            o !== s &&
              o !== this._core.current() &&
              this._core.to(this._core.relative(o), !1, !0);
          }, this)
        );
    };
    (n.Defaults = { URLhashListener: !1 }),
      (n.prototype.destroy = function () {
        var i, s;
        t(e).off('hashchange.owl.navigation');
        for (i in this._handlers) this._core.$element.off(i, this._handlers[i]);
        for (s in Object.getOwnPropertyNames(this))
          'function' != typeof this[s] && (this[s] = null);
      }),
      (t.fn.owlCarousel.Constructor.Plugins.Hash = n);
  })(window.Zepto || window.jQuery, window, document),
  (function (t, e, i, s) {
    function n(e, i) {
      var n = !1,
        o = e.charAt(0).toUpperCase() + e.slice(1);
      return (
        t.each((e + ' ' + a.join(o + ' ') + o).split(' '), function (t, e) {
          if (r[e] !== s) return (n = !i || e), !1;
        }),
        n
      );
    }
    function o(t) {
      return n(t, !0);
    }
    var r = t('<support>').get(0).style,
      a = 'Webkit Moz O ms'.split(' '),
      h = {
        transition: {
          end: {
            WebkitTransition: 'webkitTransitionEnd',
            MozTransition: 'transitionend',
            OTransition: 'oTransitionEnd',
            transition: 'transitionend',
          },
        },
        animation: {
          end: {
            WebkitAnimation: 'webkitAnimationEnd',
            MozAnimation: 'animationend',
            OAnimation: 'oAnimationEnd',
            animation: 'animationend',
          },
        },
      },
      l = {
        csstransforms: function () {
          return !!n('transform');
        },
        csstransforms3d: function () {
          return !!n('perspective');
        },
        csstransitions: function () {
          return !!n('transition');
        },
        cssanimations: function () {
          return !!n('animation');
        },
      };
    l.csstransitions() &&
      ((t.support.transition = new String(o('transition'))),
      (t.support.transition.end = h.transition.end[t.support.transition])),
      l.cssanimations() &&
        ((t.support.animation = new String(o('animation'))),
        (t.support.animation.end = h.animation.end[t.support.animation])),
      l.csstransforms() &&
        ((t.support.transform = new String(o('transform'))),
        (t.support.transform3d = l.csstransforms3d()));
  })(window.Zepto || window.jQuery, window, document);
!(function t(e, n, r) {
  function o(i, s) {
    if (!n[i]) {
      if (!e[i]) {
        var a = 'function' == typeof require && require;
        if (!s && a) return a(i, !0);
        if (l) return l(i, !0);
        var c = new Error("Cannot find module '" + i + "'");
        throw ((c.code = 'MODULE_NOT_FOUND'), c);
      }
      var u = (n[i] = { exports: {} });
      e[i][0].call(
        u.exports,
        function (t) {
          var n = e[i][1][t];
          return o(n ? n : t);
        },
        u,
        u.exports,
        t,
        e,
        n,
        r
      );
    }
    return n[i].exports;
  }
  for (
    var l = 'function' == typeof require && require, i = 0;
    i < r.length;
    i++
  )
    o(r[i]);
  return o;
})(
  {
    1: [
      function (t, e, n) {
        'use strict';
        function r(t) {
          t.fn.perfectScrollbar = function (t) {
            return this.each(function () {
              if ('object' == typeof t || 'undefined' == typeof t) {
                var e = t;
                l.get(this) || o.initialize(this, e);
              } else {
                var n = t;
                'update' === n
                  ? o.update(this)
                  : 'destroy' === n && o.destroy(this);
              }
            });
          };
        }
        var o = t('../main'),
          l = t('../plugin/instances');
        if ('function' == typeof define && define.amd) define(['jquery'], r);
        else {
          var i = window.jQuery ? window.jQuery : window.$;
          'undefined' != typeof i && r(i);
        }
        e.exports = r;
      },
      { '../main': 6, '../plugin/instances': 17 },
    ],
    2: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          return window.getComputedStyle(t)[e];
        }
        function o(t, e, n) {
          return (
            'number' == typeof n && (n = n.toString() + 'px'),
            (t.style[e] = n),
            t
          );
        }
        function l(t, e) {
          for (var n in e) {
            var r = e[n];
            'number' == typeof r && (r = r.toString() + 'px'), (t.style[n] = r);
          }
          return t;
        }
        var i = {};
        (i.create = function (t, e) {
          var n = document.createElement(t);
          return (n.className = e), n;
        }),
          (i.appendTo = function (t, e) {
            return e.appendChild(t), t;
          }),
          (i.css = function (t, e, n) {
            return 'object' == typeof e
              ? l(t, e)
              : 'undefined' == typeof n
              ? r(t, e)
              : o(t, e, n);
          }),
          (i.matches = function (t, e) {
            return 'undefined' != typeof t.matches
              ? t.matches(e)
              : t.msMatchesSelector(e);
          }),
          (i.remove = function (t) {
            'undefined' != typeof t.remove
              ? t.remove()
              : t.parentNode && t.parentNode.removeChild(t);
          }),
          (i.queryChildren = function (t, e) {
            return Array.prototype.filter.call(t.childNodes, function (t) {
              return i.matches(t, e);
            });
          }),
          (e.exports = i);
      },
      {},
    ],
    3: [
      function (t, e, n) {
        'use strict';
        var r = function (t) {
          (this.element = t), (this.events = {});
        };
        (r.prototype.bind = function (t, e) {
          'undefined' == typeof this.events[t] && (this.events[t] = []),
            this.events[t].push(e),
            this.element.addEventListener(t, e, !1);
        }),
          (r.prototype.unbind = function (t, e) {
            var n = 'undefined' != typeof e;
            this.events[t] = this.events[t].filter(function (r) {
              return (
                !(!n || r === e) ||
                (this.element.removeEventListener(t, r, !1), !1)
              );
            }, this);
          }),
          (r.prototype.unbindAll = function () {
            for (var t in this.events) this.unbind(t);
          });
        var o = function () {
          this.eventElements = [];
        };
        (o.prototype.eventElement = function (t) {
          var e = this.eventElements.filter(function (e) {
            return e.element === t;
          })[0];
          return (
            'undefined' == typeof e &&
              ((e = new r(t)), this.eventElements.push(e)),
            e
          );
        }),
          (o.prototype.bind = function (t, e, n) {
            this.eventElement(t).bind(e, n);
          }),
          (o.prototype.unbind = function (t, e, n) {
            this.eventElement(t).unbind(e, n);
          }),
          (o.prototype.unbindAll = function () {
            for (var t = 0; t < this.eventElements.length; t++)
              this.eventElements[t].unbindAll();
          }),
          (o.prototype.once = function (t, e, n) {
            var r = this.eventElement(t),
              o = function (t) {
                r.unbind(e, o), n(t);
              };
            r.bind(e, o);
          }),
          (e.exports = o);
      },
      {},
    ],
    4: [
      function (t, e, n) {
        'use strict';
        e.exports = (function () {
          function t() {
            return Math.floor(65536 * (1 + Math.random()))
              .toString(16)
              .substring(1);
          }
          return function () {
            return (
              t() +
              t() +
              '-' +
              t() +
              '-' +
              t() +
              '-' +
              t() +
              '-' +
              t() +
              t() +
              t()
            );
          };
        })();
      },
      {},
    ],
    5: [
      function (t, e, n) {
        'use strict';
        function r(t) {
          var e,
            n = ['ps--in-scrolling'];
          return (
            (e = 'undefined' == typeof t ? ['ps--x', 'ps--y'] : ['ps--' + t]),
            n.concat(e)
          );
        }
        var o = t('./dom'),
          l = (n.toInt = function (t) {
            return parseInt(t, 10) || 0;
          });
        (n.isEditable = function (t) {
          return (
            o.matches(t, 'input,[contenteditable]') ||
            o.matches(t, 'select,[contenteditable]') ||
            o.matches(t, 'textarea,[contenteditable]') ||
            o.matches(t, 'button,[contenteditable]')
          );
        }),
          (n.removePsClasses = function (t) {
            for (var e = 0; e < t.classList.length; e++) {
              var n = t.classList[e];
              0 === n.indexOf('ps-') && t.classList.remove(n);
            }
          }),
          (n.outerWidth = function (t) {
            return (
              l(o.css(t, 'width')) +
              l(o.css(t, 'paddingLeft')) +
              l(o.css(t, 'paddingRight')) +
              l(o.css(t, 'borderLeftWidth')) +
              l(o.css(t, 'borderRightWidth'))
            );
          }),
          (n.startScrolling = function (t, e) {
            for (var n = r(e), o = 0; o < n.length; o++) t.classList.add(n[o]);
          }),
          (n.stopScrolling = function (t, e) {
            for (var n = r(e), o = 0; o < n.length; o++)
              t.classList.remove(n[o]);
          }),
          (n.env = {
            isWebKit:
              'undefined' != typeof document &&
              'WebkitAppearance' in document.documentElement.style,
            supportsTouch:
              'undefined' != typeof window &&
              ('ontouchstart' in window ||
                (window.DocumentTouch &&
                  document instanceof window.DocumentTouch)),
            supportsIePointer:
              'undefined' != typeof window &&
              null !== window.navigator.msMaxTouchPoints,
          });
      },
      { './dom': 2 },
    ],
    6: [
      function (t, e, n) {
        'use strict';
        var r = t('./plugin/destroy'),
          o = t('./plugin/initialize'),
          l = t('./plugin/update');
        e.exports = { initialize: o, update: l, destroy: r };
      },
      {
        './plugin/destroy': 8,
        './plugin/initialize': 16,
        './plugin/update': 20,
      },
    ],
    7: [
      function (t, e, n) {
        'use strict';
        e.exports = function () {
          return {
            handlers: [
              'click-rail',
              'drag-scrollbar',
              'keyboard',
              'wheel',
              'touch',
            ],
            maxScrollbarLength: null,
            minScrollbarLength: null,
            scrollXMarginOffset: 0,
            scrollYMarginOffset: 0,
            suppressScrollX: !1,
            suppressScrollY: !1,
            swipePropagation: !0,
            swipeEasing: !0,
            useBothWheelAxes: !1,
            wheelPropagation: !1,
            wheelSpeed: 1,
            theme: 'default',
          };
        };
      },
      {},
    ],
    8: [
      function (t, e, n) {
        'use strict';
        var r = t('../lib/helper'),
          o = t('../lib/dom'),
          l = t('./instances');
        e.exports = function (t) {
          var e = l.get(t);
          e &&
            (e.event.unbindAll(),
            o.remove(e.scrollbarX),
            o.remove(e.scrollbarY),
            o.remove(e.scrollbarXRail),
            o.remove(e.scrollbarYRail),
            r.removePsClasses(t),
            l.remove(t));
        };
      },
      { '../lib/dom': 2, '../lib/helper': 5, './instances': 17 },
    ],
    9: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(t) {
            return t.getBoundingClientRect();
          }
          var r = function (t) {
            t.stopPropagation();
          };
          e.event.bind(e.scrollbarY, 'click', r),
            e.event.bind(e.scrollbarYRail, 'click', function (r) {
              var o = r.pageY - window.pageYOffset - n(e.scrollbarYRail).top,
                s = o > e.scrollbarYTop ? 1 : -1;
              i(t, 'top', t.scrollTop + s * e.containerHeight),
                l(t),
                r.stopPropagation();
            }),
            e.event.bind(e.scrollbarX, 'click', r),
            e.event.bind(e.scrollbarXRail, 'click', function (r) {
              var o = r.pageX - window.pageXOffset - n(e.scrollbarXRail).left,
                s = o > e.scrollbarXLeft ? 1 : -1;
              i(t, 'left', t.scrollLeft + s * e.containerWidth),
                l(t),
                r.stopPropagation();
            });
        }
        var o = t('../instances'),
          l = t('../update-geometry'),
          i = t('../update-scroll');
        e.exports = function (t) {
          var e = o.get(t);
          r(t, e);
        };
      },
      { '../instances': 17, '../update-geometry': 18, '../update-scroll': 19 },
    ],
    10: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(n) {
            var o = r + n * e.railXRatio,
              i =
                Math.max(0, e.scrollbarXRail.getBoundingClientRect().left) +
                e.railXRatio * (e.railXWidth - e.scrollbarXWidth);
            o < 0
              ? (e.scrollbarXLeft = 0)
              : o > i
              ? (e.scrollbarXLeft = i)
              : (e.scrollbarXLeft = o);
            var s =
              l.toInt(
                (e.scrollbarXLeft * (e.contentWidth - e.containerWidth)) /
                  (e.containerWidth - e.railXRatio * e.scrollbarXWidth)
              ) - e.negativeScrollAdjustment;
            c(t, 'left', s);
          }
          var r = null,
            o = null,
            s = function (e) {
              n(e.pageX - o), a(t), e.stopPropagation(), e.preventDefault();
            },
            u = function () {
              l.stopScrolling(t, 'x'),
                e.event.unbind(e.ownerDocument, 'mousemove', s);
            };
          e.event.bind(e.scrollbarX, 'mousedown', function (n) {
            (o = n.pageX),
              (r = l.toInt(i.css(e.scrollbarX, 'left')) * e.railXRatio),
              l.startScrolling(t, 'x'),
              e.event.bind(e.ownerDocument, 'mousemove', s),
              e.event.once(e.ownerDocument, 'mouseup', u),
              n.stopPropagation(),
              n.preventDefault();
          });
        }
        function o(t, e) {
          function n(n) {
            var o = r + n * e.railYRatio,
              i =
                Math.max(0, e.scrollbarYRail.getBoundingClientRect().top) +
                e.railYRatio * (e.railYHeight - e.scrollbarYHeight);
            o < 0
              ? (e.scrollbarYTop = 0)
              : o > i
              ? (e.scrollbarYTop = i)
              : (e.scrollbarYTop = o);
            var s = l.toInt(
              (e.scrollbarYTop * (e.contentHeight - e.containerHeight)) /
                (e.containerHeight - e.railYRatio * e.scrollbarYHeight)
            );
            c(t, 'top', s);
          }
          var r = null,
            o = null,
            s = function (e) {
              n(e.pageY - o), a(t), e.stopPropagation(), e.preventDefault();
            },
            u = function () {
              l.stopScrolling(t, 'y'),
                e.event.unbind(e.ownerDocument, 'mousemove', s);
            };
          e.event.bind(e.scrollbarY, 'mousedown', function (n) {
            (o = n.pageY),
              (r = l.toInt(i.css(e.scrollbarY, 'top')) * e.railYRatio),
              l.startScrolling(t, 'y'),
              e.event.bind(e.ownerDocument, 'mousemove', s),
              e.event.once(e.ownerDocument, 'mouseup', u),
              n.stopPropagation(),
              n.preventDefault();
          });
        }
        var l = t('../../lib/helper'),
          i = t('../../lib/dom'),
          s = t('../instances'),
          a = t('../update-geometry'),
          c = t('../update-scroll');
        e.exports = function (t) {
          var e = s.get(t);
          r(t, e), o(t, e);
        };
      },
      {
        '../../lib/dom': 2,
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    11: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(n, r) {
            var o = t.scrollTop;
            if (0 === n) {
              if (!e.scrollbarYActive) return !1;
              if (
                (0 === o && r > 0) ||
                (o >= e.contentHeight - e.containerHeight && r < 0)
              )
                return !e.settings.wheelPropagation;
            }
            var l = t.scrollLeft;
            if (0 === r) {
              if (!e.scrollbarXActive) return !1;
              if (
                (0 === l && n < 0) ||
                (l >= e.contentWidth - e.containerWidth && n > 0)
              )
                return !e.settings.wheelPropagation;
            }
            return !0;
          }
          var r = !1;
          e.event.bind(t, 'mouseenter', function () {
            r = !0;
          }),
            e.event.bind(t, 'mouseleave', function () {
              r = !1;
            });
          var i = !1;
          e.event.bind(e.ownerDocument, 'keydown', function (c) {
            if (
              !(
                (c.isDefaultPrevented && c.isDefaultPrevented()) ||
                c.defaultPrevented
              )
            ) {
              var u =
                l.matches(e.scrollbarX, ':focus') ||
                l.matches(e.scrollbarY, ':focus');
              if (r || u) {
                var d = document.activeElement
                  ? document.activeElement
                  : e.ownerDocument.activeElement;
                if (d) {
                  if ('IFRAME' === d.tagName)
                    d = d.contentDocument.activeElement;
                  else for (; d.shadowRoot; ) d = d.shadowRoot.activeElement;
                  if (o.isEditable(d)) return;
                }
                var p = 0,
                  f = 0;
                switch (c.which) {
                  case 37:
                    p = c.metaKey
                      ? -e.contentWidth
                      : c.altKey
                      ? -e.containerWidth
                      : -30;
                    break;
                  case 38:
                    f = c.metaKey
                      ? e.contentHeight
                      : c.altKey
                      ? e.containerHeight
                      : 30;
                    break;
                  case 39:
                    p = c.metaKey
                      ? e.contentWidth
                      : c.altKey
                      ? e.containerWidth
                      : 30;
                    break;
                  case 40:
                    f = c.metaKey
                      ? -e.contentHeight
                      : c.altKey
                      ? -e.containerHeight
                      : -30;
                    break;
                  case 33:
                    f = 90;
                    break;
                  case 32:
                    f = c.shiftKey ? 90 : -90;
                    break;
                  case 34:
                    f = -90;
                    break;
                  case 35:
                    f = c.ctrlKey ? -e.contentHeight : -e.containerHeight;
                    break;
                  case 36:
                    f = c.ctrlKey ? t.scrollTop : e.containerHeight;
                    break;
                  default:
                    return;
                }
                a(t, 'top', t.scrollTop - f),
                  a(t, 'left', t.scrollLeft + p),
                  s(t),
                  (i = n(p, f)),
                  i && c.preventDefault();
              }
            }
          });
        }
        var o = t('../../lib/helper'),
          l = t('../../lib/dom'),
          i = t('../instances'),
          s = t('../update-geometry'),
          a = t('../update-scroll');
        e.exports = function (t) {
          var e = i.get(t);
          r(t, e);
        };
      },
      {
        '../../lib/dom': 2,
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    12: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n(n, r) {
            var o = t.scrollTop;
            if (0 === n) {
              if (!e.scrollbarYActive) return !1;
              if (
                (0 === o && r > 0) ||
                (o >= e.contentHeight - e.containerHeight && r < 0)
              )
                return !e.settings.wheelPropagation;
            }
            var l = t.scrollLeft;
            if (0 === r) {
              if (!e.scrollbarXActive) return !1;
              if (
                (0 === l && n < 0) ||
                (l >= e.contentWidth - e.containerWidth && n > 0)
              )
                return !e.settings.wheelPropagation;
            }
            return !0;
          }
          function r(t) {
            var e = t.deltaX,
              n = -1 * t.deltaY;
            return (
              ('undefined' != typeof e && 'undefined' != typeof n) ||
                ((e = (-1 * t.wheelDeltaX) / 6), (n = t.wheelDeltaY / 6)),
              t.deltaMode && 1 === t.deltaMode && ((e *= 10), (n *= 10)),
              e !== e && n !== n && ((e = 0), (n = t.wheelDelta)),
              t.shiftKey ? [-n, -e] : [e, n]
            );
          }
          function o(e, n) {
            var r = t.querySelector(
              'textarea:hover, select[multiple]:hover, .ps-child:hover'
            );
            if (r) {
              var o = window.getComputedStyle(r),
                l = [o.overflow, o.overflowX, o.overflowY].join('');
              if (!l.match(/(scroll|auto)/)) return !1;
              var i = r.scrollHeight - r.clientHeight;
              if (
                i > 0 &&
                !((0 === r.scrollTop && n > 0) || (r.scrollTop === i && n < 0))
              )
                return !0;
              var s = r.scrollLeft - r.clientWidth;
              if (
                s > 0 &&
                !(
                  (0 === r.scrollLeft && e < 0) ||
                  (r.scrollLeft === s && e > 0)
                )
              )
                return !0;
            }
            return !1;
          }
          function s(s) {
            var c = r(s),
              u = c[0],
              d = c[1];
            o(u, d) ||
              ((a = !1),
              e.settings.useBothWheelAxes
                ? e.scrollbarYActive && !e.scrollbarXActive
                  ? (d
                      ? i(t, 'top', t.scrollTop - d * e.settings.wheelSpeed)
                      : i(t, 'top', t.scrollTop + u * e.settings.wheelSpeed),
                    (a = !0))
                  : e.scrollbarXActive &&
                    !e.scrollbarYActive &&
                    (u
                      ? i(t, 'left', t.scrollLeft + u * e.settings.wheelSpeed)
                      : i(t, 'left', t.scrollLeft - d * e.settings.wheelSpeed),
                    (a = !0))
                : (i(t, 'top', t.scrollTop - d * e.settings.wheelSpeed),
                  i(t, 'left', t.scrollLeft + u * e.settings.wheelSpeed)),
              l(t),
              (a = a || n(u, d)),
              a && (s.stopPropagation(), s.preventDefault()));
          }
          var a = !1;
          'undefined' != typeof window.onwheel
            ? e.event.bind(t, 'wheel', s)
            : 'undefined' != typeof window.onmousewheel &&
              e.event.bind(t, 'mousewheel', s);
        }
        var o = t('../instances'),
          l = t('../update-geometry'),
          i = t('../update-scroll');
        e.exports = function (t) {
          var e = o.get(t);
          r(t, e);
        };
      },
      { '../instances': 17, '../update-geometry': 18, '../update-scroll': 19 },
    ],
    13: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          e.event.bind(t, 'scroll', function () {
            l(t);
          });
        }
        var o = t('../instances'),
          l = t('../update-geometry');
        e.exports = function (t) {
          var e = o.get(t);
          r(t, e);
        };
      },
      { '../instances': 17, '../update-geometry': 18 },
    ],
    14: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n() {
            var t = window.getSelection
              ? window.getSelection()
              : document.getSelection
              ? document.getSelection()
              : '';
            return 0 === t.toString().length
              ? null
              : t.getRangeAt(0).commonAncestorContainer;
          }
          function r() {
            c ||
              (c = setInterval(function () {
                return l.get(t)
                  ? (s(t, 'top', t.scrollTop + u.top),
                    s(t, 'left', t.scrollLeft + u.left),
                    void i(t))
                  : void clearInterval(c);
              }, 50));
          }
          function a() {
            c && (clearInterval(c), (c = null)), o.stopScrolling(t);
          }
          var c = null,
            u = { top: 0, left: 0 },
            d = !1;
          e.event.bind(e.ownerDocument, 'selectionchange', function () {
            t.contains(n()) ? (d = !0) : ((d = !1), a());
          }),
            e.event.bind(window, 'mouseup', function () {
              d && ((d = !1), a());
            }),
            e.event.bind(window, 'keyup', function () {
              d && ((d = !1), a());
            }),
            e.event.bind(window, 'mousemove', function (e) {
              if (d) {
                var n = { x: e.pageX, y: e.pageY },
                  l = {
                    left: t.offsetLeft,
                    right: t.offsetLeft + t.offsetWidth,
                    top: t.offsetTop,
                    bottom: t.offsetTop + t.offsetHeight,
                  };
                n.x < l.left + 3
                  ? ((u.left = -5), o.startScrolling(t, 'x'))
                  : n.x > l.right - 3
                  ? ((u.left = 5), o.startScrolling(t, 'x'))
                  : (u.left = 0),
                  n.y < l.top + 3
                    ? (l.top + 3 - n.y < 5 ? (u.top = -5) : (u.top = -20),
                      o.startScrolling(t, 'y'))
                    : n.y > l.bottom - 3
                    ? (n.y - l.bottom + 3 < 5 ? (u.top = 5) : (u.top = 20),
                      o.startScrolling(t, 'y'))
                    : (u.top = 0),
                  0 === u.top && 0 === u.left ? a() : r();
              }
            });
        }
        var o = t('../../lib/helper'),
          l = t('../instances'),
          i = t('../update-geometry'),
          s = t('../update-scroll');
        e.exports = function (t) {
          var e = l.get(t);
          r(t, e);
        };
      },
      {
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    15: [
      function (t, e, n) {
        'use strict';
        function r(t, e, n, r) {
          function o(n, r) {
            var o = t.scrollTop,
              l = t.scrollLeft,
              i = Math.abs(n),
              s = Math.abs(r);
            if (s > i) {
              if (
                (r < 0 && o === e.contentHeight - e.containerHeight) ||
                (r > 0 && 0 === o)
              )
                return !e.settings.swipePropagation;
            } else if (
              i > s &&
              ((n < 0 && l === e.contentWidth - e.containerWidth) ||
                (n > 0 && 0 === l))
            )
              return !e.settings.swipePropagation;
            return !0;
          }
          function a(e, n) {
            s(t, 'top', t.scrollTop - n), s(t, 'left', t.scrollLeft - e), i(t);
          }
          function c() {
            w = !0;
          }
          function u() {
            w = !1;
          }
          function d(t) {
            return t.targetTouches ? t.targetTouches[0] : t;
          }
          function p(t) {
            return !(
              (t.pointerType && 'pen' === t.pointerType && 0 === t.buttons) ||
              ((!t.targetTouches || 1 !== t.targetTouches.length) &&
                (!t.pointerType ||
                  'mouse' === t.pointerType ||
                  t.pointerType === t.MSPOINTER_TYPE_MOUSE))
            );
          }
          function f(t) {
            if (p(t)) {
              Y = !0;
              var e = d(t);
              (g.pageX = e.pageX),
                (g.pageY = e.pageY),
                (v = new Date().getTime()),
                null !== y && clearInterval(y),
                t.stopPropagation();
            }
          }
          function h(t) {
            if ((!Y && e.settings.swipePropagation && f(t), !w && Y && p(t))) {
              var n = d(t),
                r = { pageX: n.pageX, pageY: n.pageY },
                l = r.pageX - g.pageX,
                i = r.pageY - g.pageY;
              a(l, i), (g = r);
              var s = new Date().getTime(),
                c = s - v;
              c > 0 && ((m.x = l / c), (m.y = i / c), (v = s)),
                o(l, i) && (t.stopPropagation(), t.preventDefault());
            }
          }
          function b() {
            !w &&
              Y &&
              ((Y = !1),
              e.settings.swipeEasing &&
                (clearInterval(y),
                (y = setInterval(function () {
                  return l.get(t) && (m.x || m.y)
                    ? Math.abs(m.x) < 0.01 && Math.abs(m.y) < 0.01
                      ? void clearInterval(y)
                      : (a(30 * m.x, 30 * m.y), (m.x *= 0.8), void (m.y *= 0.8))
                    : void clearInterval(y);
                }, 10))));
          }
          var g = {},
            v = 0,
            m = {},
            y = null,
            w = !1,
            Y = !1;
          n
            ? (e.event.bind(window, 'touchstart', c),
              e.event.bind(window, 'touchend', u),
              e.event.bind(t, 'touchstart', f),
              e.event.bind(t, 'touchmove', h),
              e.event.bind(t, 'touchend', b))
            : r &&
              (window.PointerEvent
                ? (e.event.bind(window, 'pointerdown', c),
                  e.event.bind(window, 'pointerup', u),
                  e.event.bind(t, 'pointerdown', f),
                  e.event.bind(t, 'pointermove', h),
                  e.event.bind(t, 'pointerup', b))
                : window.MSPointerEvent &&
                  (e.event.bind(window, 'MSPointerDown', c),
                  e.event.bind(window, 'MSPointerUp', u),
                  e.event.bind(t, 'MSPointerDown', f),
                  e.event.bind(t, 'MSPointerMove', h),
                  e.event.bind(t, 'MSPointerUp', b)));
        }
        var o = t('../../lib/helper'),
          l = t('../instances'),
          i = t('../update-geometry'),
          s = t('../update-scroll');
        e.exports = function (t) {
          if (o.env.supportsTouch || o.env.supportsIePointer) {
            var e = l.get(t);
            r(t, e, o.env.supportsTouch, o.env.supportsIePointer);
          }
        };
      },
      {
        '../../lib/helper': 5,
        '../instances': 17,
        '../update-geometry': 18,
        '../update-scroll': 19,
      },
    ],
    16: [
      function (t, e, n) {
        'use strict';
        var r = t('./instances'),
          o = t('./update-geometry'),
          l = {
            'click-rail': t('./handler/click-rail'),
            'drag-scrollbar': t('./handler/drag-scrollbar'),
            keyboard: t('./handler/keyboard'),
            wheel: t('./handler/mouse-wheel'),
            touch: t('./handler/touch'),
            selection: t('./handler/selection'),
          },
          i = t('./handler/native-scroll');
        e.exports = function (t, e) {
          t.classList.add('ps');
          var n = r.add(t, 'object' == typeof e ? e : {});
          t.classList.add('ps--theme_' + n.settings.theme),
            n.settings.handlers.forEach(function (e) {
              l[e](t);
            }),
            i(t),
            o(t);
        };
      },
      {
        './handler/click-rail': 9,
        './handler/drag-scrollbar': 10,
        './handler/keyboard': 11,
        './handler/mouse-wheel': 12,
        './handler/native-scroll': 13,
        './handler/selection': 14,
        './handler/touch': 15,
        './instances': 17,
        './update-geometry': 18,
      },
    ],
    17: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          function n() {
            t.classList.add('ps--focus');
          }
          function r() {
            t.classList.remove('ps--focus');
          }
          var o = this;
          o.settings = a();
          for (var l in e) o.settings[l] = e[l];
          (o.containerWidth = null),
            (o.containerHeight = null),
            (o.contentWidth = null),
            (o.contentHeight = null),
            (o.isRtl = 'rtl' === c.css(t, 'direction')),
            (o.isNegativeScroll = (function () {
              var e = t.scrollLeft,
                n = null;
              return (
                (t.scrollLeft = -1),
                (n = t.scrollLeft < 0),
                (t.scrollLeft = e),
                n
              );
            })()),
            (o.negativeScrollAdjustment = o.isNegativeScroll
              ? t.scrollWidth - t.clientWidth
              : 0),
            (o.event = new u()),
            (o.ownerDocument = t.ownerDocument || document),
            (o.scrollbarXRail = c.appendTo(
              c.create('div', 'ps__scrollbar-x-rail'),
              t
            )),
            (o.scrollbarX = c.appendTo(
              c.create('div', 'ps__scrollbar-x'),
              o.scrollbarXRail
            )),
            o.scrollbarX.setAttribute('tabindex', 0),
            o.event.bind(o.scrollbarX, 'focus', n),
            o.event.bind(o.scrollbarX, 'blur', r),
            (o.scrollbarXActive = null),
            (o.scrollbarXWidth = null),
            (o.scrollbarXLeft = null),
            (o.scrollbarXBottom = s.toInt(c.css(o.scrollbarXRail, 'bottom'))),
            (o.isScrollbarXUsingBottom =
              o.scrollbarXBottom === o.scrollbarXBottom),
            (o.scrollbarXTop = o.isScrollbarXUsingBottom
              ? null
              : s.toInt(c.css(o.scrollbarXRail, 'top'))),
            (o.railBorderXWidth =
              s.toInt(c.css(o.scrollbarXRail, 'borderLeftWidth')) +
              s.toInt(c.css(o.scrollbarXRail, 'borderRightWidth'))),
            c.css(o.scrollbarXRail, 'display', 'block'),
            (o.railXMarginWidth =
              s.toInt(c.css(o.scrollbarXRail, 'marginLeft')) +
              s.toInt(c.css(o.scrollbarXRail, 'marginRight'))),
            c.css(o.scrollbarXRail, 'display', ''),
            (o.railXWidth = null),
            (o.railXRatio = null),
            (o.scrollbarYRail = c.appendTo(
              c.create('div', 'ps__scrollbar-y-rail'),
              t
            )),
            (o.scrollbarY = c.appendTo(
              c.create('div', 'ps__scrollbar-y'),
              o.scrollbarYRail
            )),
            o.scrollbarY.setAttribute('tabindex', 0),
            o.event.bind(o.scrollbarY, 'focus', n),
            o.event.bind(o.scrollbarY, 'blur', r),
            (o.scrollbarYActive = null),
            (o.scrollbarYHeight = null),
            (o.scrollbarYTop = null),
            (o.scrollbarYRight = s.toInt(c.css(o.scrollbarYRail, 'right'))),
            (o.isScrollbarYUsingRight =
              o.scrollbarYRight === o.scrollbarYRight),
            (o.scrollbarYLeft = o.isScrollbarYUsingRight
              ? null
              : s.toInt(c.css(o.scrollbarYRail, 'left'))),
            (o.scrollbarYOuterWidth = o.isRtl
              ? s.outerWidth(o.scrollbarY)
              : null),
            (o.railBorderYWidth =
              s.toInt(c.css(o.scrollbarYRail, 'borderTopWidth')) +
              s.toInt(c.css(o.scrollbarYRail, 'borderBottomWidth'))),
            c.css(o.scrollbarYRail, 'display', 'block'),
            (o.railYMarginHeight =
              s.toInt(c.css(o.scrollbarYRail, 'marginTop')) +
              s.toInt(c.css(o.scrollbarYRail, 'marginBottom'))),
            c.css(o.scrollbarYRail, 'display', ''),
            (o.railYHeight = null),
            (o.railYRatio = null);
        }
        function o(t) {
          return t.getAttribute('data-ps-id');
        }
        function l(t, e) {
          t.setAttribute('data-ps-id', e);
        }
        function i(t) {
          t.removeAttribute('data-ps-id');
        }
        var s = t('../lib/helper'),
          a = t('./default-setting'),
          c = t('../lib/dom'),
          u = t('../lib/event-manager'),
          d = t('../lib/guid'),
          p = {};
        (n.add = function (t, e) {
          var n = d();
          return l(t, n), (p[n] = new r(t, e)), p[n];
        }),
          (n.remove = function (t) {
            delete p[o(t)], i(t);
          }),
          (n.get = function (t) {
            return p[o(t)];
          });
      },
      {
        '../lib/dom': 2,
        '../lib/event-manager': 3,
        '../lib/guid': 4,
        '../lib/helper': 5,
        './default-setting': 7,
      },
    ],
    18: [
      function (t, e, n) {
        'use strict';
        function r(t, e) {
          return (
            t.settings.minScrollbarLength &&
              (e = Math.max(e, t.settings.minScrollbarLength)),
            t.settings.maxScrollbarLength &&
              (e = Math.min(e, t.settings.maxScrollbarLength)),
            e
          );
        }
        function o(t, e) {
          var n = { width: e.railXWidth };
          e.isRtl
            ? (n.left =
                e.negativeScrollAdjustment +
                t.scrollLeft +
                e.containerWidth -
                e.contentWidth)
            : (n.left = t.scrollLeft),
            e.isScrollbarXUsingBottom
              ? (n.bottom = e.scrollbarXBottom - t.scrollTop)
              : (n.top = e.scrollbarXTop + t.scrollTop),
            i.css(e.scrollbarXRail, n);
          var r = { top: t.scrollTop, height: e.railYHeight };
          e.isScrollbarYUsingRight
            ? e.isRtl
              ? (r.right =
                  e.contentWidth -
                  (e.negativeScrollAdjustment + t.scrollLeft) -
                  e.scrollbarYRight -
                  e.scrollbarYOuterWidth)
              : (r.right = e.scrollbarYRight - t.scrollLeft)
            : e.isRtl
            ? (r.left =
                e.negativeScrollAdjustment +
                t.scrollLeft +
                2 * e.containerWidth -
                e.contentWidth -
                e.scrollbarYLeft -
                e.scrollbarYOuterWidth)
            : (r.left = e.scrollbarYLeft + t.scrollLeft),
            i.css(e.scrollbarYRail, r),
            i.css(e.scrollbarX, {
              left: e.scrollbarXLeft,
              width: e.scrollbarXWidth - e.railBorderXWidth,
            }),
            i.css(e.scrollbarY, {
              top: e.scrollbarYTop,
              height: e.scrollbarYHeight - e.railBorderYWidth,
            });
        }
        var l = t('../lib/helper'),
          i = t('../lib/dom'),
          s = t('./instances'),
          a = t('./update-scroll');
        e.exports = function (t) {
          var e = s.get(t);
          (e.containerWidth = t.clientWidth),
            (e.containerHeight = t.clientHeight),
            (e.contentWidth = t.scrollWidth),
            (e.contentHeight = t.scrollHeight);
          var n;
          t.contains(e.scrollbarXRail) ||
            ((n = i.queryChildren(t, '.ps__scrollbar-x-rail')),
            n.length > 0 &&
              n.forEach(function (t) {
                i.remove(t);
              }),
            i.appendTo(e.scrollbarXRail, t)),
            t.contains(e.scrollbarYRail) ||
              ((n = i.queryChildren(t, '.ps__scrollbar-y-rail')),
              n.length > 0 &&
                n.forEach(function (t) {
                  i.remove(t);
                }),
              i.appendTo(e.scrollbarYRail, t)),
            !e.settings.suppressScrollX &&
            e.containerWidth + e.settings.scrollXMarginOffset < e.contentWidth
              ? ((e.scrollbarXActive = !0),
                (e.railXWidth = e.containerWidth - e.railXMarginWidth),
                (e.railXRatio = e.containerWidth / e.railXWidth),
                (e.scrollbarXWidth = r(
                  e,
                  l.toInt((e.railXWidth * e.containerWidth) / e.contentWidth)
                )),
                (e.scrollbarXLeft = l.toInt(
                  ((e.negativeScrollAdjustment + t.scrollLeft) *
                    (e.railXWidth - e.scrollbarXWidth)) /
                    (e.contentWidth - e.containerWidth)
                )))
              : (e.scrollbarXActive = !1),
            !e.settings.suppressScrollY &&
            e.containerHeight + e.settings.scrollYMarginOffset < e.contentHeight
              ? ((e.scrollbarYActive = !0),
                (e.railYHeight = e.containerHeight - e.railYMarginHeight),
                (e.railYRatio = e.containerHeight / e.railYHeight),
                (e.scrollbarYHeight = r(
                  e,
                  l.toInt((e.railYHeight * e.containerHeight) / e.contentHeight)
                )),
                (e.scrollbarYTop = l.toInt(
                  (t.scrollTop * (e.railYHeight - e.scrollbarYHeight)) /
                    (e.contentHeight - e.containerHeight)
                )))
              : (e.scrollbarYActive = !1),
            e.scrollbarXLeft >= e.railXWidth - e.scrollbarXWidth &&
              (e.scrollbarXLeft = e.railXWidth - e.scrollbarXWidth),
            e.scrollbarYTop >= e.railYHeight - e.scrollbarYHeight &&
              (e.scrollbarYTop = e.railYHeight - e.scrollbarYHeight),
            o(t, e),
            e.scrollbarXActive
              ? t.classList.add('ps--active-x')
              : (t.classList.remove('ps--active-x'),
                (e.scrollbarXWidth = 0),
                (e.scrollbarXLeft = 0),
                a(t, 'left', 0)),
            e.scrollbarYActive
              ? t.classList.add('ps--active-y')
              : (t.classList.remove('ps--active-y'),
                (e.scrollbarYHeight = 0),
                (e.scrollbarYTop = 0),
                a(t, 'top', 0));
        };
      },
      {
        '../lib/dom': 2,
        '../lib/helper': 5,
        './instances': 17,
        './update-scroll': 19,
      },
    ],
    19: [
      function (t, e, n) {
        'use strict';
        var r = t('./instances'),
          o = function (t) {
            var e = document.createEvent('Event');
            return e.initEvent(t, !0, !0), e;
          };
        e.exports = function (t, e, n) {
          if ('undefined' == typeof t)
            throw 'You must provide an element to the update-scroll function';
          if ('undefined' == typeof e)
            throw 'You must provide an axis to the update-scroll function';
          if ('undefined' == typeof n)
            throw 'You must provide a value to the update-scroll function';
          'top' === e &&
            n <= 0 &&
            ((t.scrollTop = n = 0), t.dispatchEvent(o('ps-y-reach-start'))),
            'left' === e &&
              n <= 0 &&
              ((t.scrollLeft = n = 0), t.dispatchEvent(o('ps-x-reach-start')));
          var l = r.get(t);
          'top' === e &&
            n >= l.contentHeight - l.containerHeight &&
            ((n = l.contentHeight - l.containerHeight),
            n - t.scrollTop <= 2 ? (n = t.scrollTop) : (t.scrollTop = n),
            t.dispatchEvent(o('ps-y-reach-end'))),
            'left' === e &&
              n >= l.contentWidth - l.containerWidth &&
              ((n = l.contentWidth - l.containerWidth),
              n - t.scrollLeft <= 2 ? (n = t.scrollLeft) : (t.scrollLeft = n),
              t.dispatchEvent(o('ps-x-reach-end'))),
            void 0 === l.lastTop && (l.lastTop = t.scrollTop),
            void 0 === l.lastLeft && (l.lastLeft = t.scrollLeft),
            'top' === e && n < l.lastTop && t.dispatchEvent(o('ps-scroll-up')),
            'top' === e &&
              n > l.lastTop &&
              t.dispatchEvent(o('ps-scroll-down')),
            'left' === e &&
              n < l.lastLeft &&
              t.dispatchEvent(o('ps-scroll-left')),
            'left' === e &&
              n > l.lastLeft &&
              t.dispatchEvent(o('ps-scroll-right')),
            'top' === e &&
              n !== l.lastTop &&
              ((t.scrollTop = l.lastTop = n),
              t.dispatchEvent(o('ps-scroll-y'))),
            'left' === e &&
              n !== l.lastLeft &&
              ((t.scrollLeft = l.lastLeft = n),
              t.dispatchEvent(o('ps-scroll-x')));
        };
      },
      { './instances': 17 },
    ],
    20: [
      function (t, e, n) {
        'use strict';
        var r = t('../lib/helper'),
          o = t('../lib/dom'),
          l = t('./instances'),
          i = t('./update-geometry'),
          s = t('./update-scroll');
        e.exports = function (t) {
          var e = l.get(t);
          e &&
            ((e.negativeScrollAdjustment = e.isNegativeScroll
              ? t.scrollWidth - t.clientWidth
              : 0),
            o.css(e.scrollbarXRail, 'display', 'block'),
            o.css(e.scrollbarYRail, 'display', 'block'),
            (e.railXMarginWidth =
              r.toInt(o.css(e.scrollbarXRail, 'marginLeft')) +
              r.toInt(o.css(e.scrollbarXRail, 'marginRight'))),
            (e.railYMarginHeight =
              r.toInt(o.css(e.scrollbarYRail, 'marginTop')) +
              r.toInt(o.css(e.scrollbarYRail, 'marginBottom'))),
            o.css(e.scrollbarXRail, 'display', 'none'),
            o.css(e.scrollbarYRail, 'display', 'none'),
            i(t),
            s(t, 'top', t.scrollTop),
            s(t, 'left', t.scrollLeft),
            o.css(e.scrollbarXRail, 'display', ''),
            o.css(e.scrollbarYRail, 'display', ''));
        };
      },
      {
        '../lib/dom': 2,
        '../lib/helper': 5,
        './instances': 17,
        './update-geometry': 18,
        './update-scroll': 19,
      },
    ],
  },
  {},
  [1]
);
!(function (t, e) {
  'function' == typeof define && define.amd
    ? define('jquery-bridget/jquery-bridget', ['jquery'], function (i) {
        return e(t, i);
      })
    : 'object' == typeof module && module.exports
    ? (module.exports = e(t, require('jquery')))
    : (t.jQueryBridget = e(t, t.jQuery));
})(window, function (t, e) {
  'use strict';
  function i(i, s, a) {
    function u(t, e, o) {
      var n,
        s = '$().' + i + '("' + e + '")';
      return (
        t.each(function (t, u) {
          var h = a.data(u, i);
          if (!h)
            return void r(
              i + ' not initialized. Cannot call methods, i.e. ' + s
            );
          var d = h[e];
          if (!d || '_' == e.charAt(0))
            return void r(s + ' is not a valid method');
          var l = d.apply(h, o);
          n = void 0 === n ? l : n;
        }),
        void 0 !== n ? n : t
      );
    }
    function h(t, e) {
      t.each(function (t, o) {
        var n = a.data(o, i);
        n ? (n.option(e), n._init()) : ((n = new s(o, e)), a.data(o, i, n));
      });
    }
    (a = a || e || t.jQuery),
      a &&
        (s.prototype.option ||
          (s.prototype.option = function (t) {
            a.isPlainObject(t) &&
              (this.options = a.extend(!0, this.options, t));
          }),
        (a.fn[i] = function (t) {
          if ('string' == typeof t) {
            var e = n.call(arguments, 1);
            return u(this, t, e);
          }
          return h(this, t), this;
        }),
        o(a));
  }
  function o(t) {
    !t || (t && t.bridget) || (t.bridget = i);
  }
  var n = Array.prototype.slice,
    s = t.console,
    r =
      'undefined' == typeof s
        ? function () {}
        : function (t) {
            s.error(t);
          };
  return o(e || t.jQuery), i;
}),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('ev-emitter/ev-emitter', e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e())
      : (t.EvEmitter = e());
  })('undefined' != typeof window ? window : this, function () {
    function t() {}
    var e = t.prototype;
    return (
      (e.on = function (t, e) {
        if (t && e) {
          var i = (this._events = this._events || {}),
            o = (i[t] = i[t] || []);
          return o.indexOf(e) == -1 && o.push(e), this;
        }
      }),
      (e.once = function (t, e) {
        if (t && e) {
          this.on(t, e);
          var i = (this._onceEvents = this._onceEvents || {}),
            o = (i[t] = i[t] || {});
          return (o[e] = !0), this;
        }
      }),
      (e.off = function (t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
          var o = i.indexOf(e);
          return o != -1 && i.splice(o, 1), this;
        }
      }),
      (e.emitEvent = function (t, e) {
        var i = this._events && this._events[t];
        if (i && i.length) {
          (i = i.slice(0)), (e = e || []);
          for (
            var o = this._onceEvents && this._onceEvents[t], n = 0;
            n < i.length;
            n++
          ) {
            var s = i[n],
              r = o && o[s];
            r && (this.off(t, s), delete o[s]), s.apply(this, e);
          }
          return this;
        }
      }),
      (e.allOff = function () {
        delete this._events, delete this._onceEvents;
      }),
      t
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('get-size/get-size', e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e())
      : (t.getSize = e());
  })(window, function () {
    'use strict';
    function t(t) {
      var e = parseFloat(t),
        i = t.indexOf('%') == -1 && !isNaN(e);
      return i && e;
    }
    function e() {}
    function i() {
      for (
        var t = {
            width: 0,
            height: 0,
            innerWidth: 0,
            innerHeight: 0,
            outerWidth: 0,
            outerHeight: 0,
          },
          e = 0;
        e < h;
        e++
      ) {
        var i = u[e];
        t[i] = 0;
      }
      return t;
    }
    function o(t) {
      var e = getComputedStyle(t);
      return (
        e ||
          a(
            'Style returned ' +
              e +
              '. Are you running this code in a hidden iframe on Firefox? See https://bit.ly/getsizebug1'
          ),
        e
      );
    }
    function n() {
      if (!d) {
        d = !0;
        var e = document.createElement('div');
        (e.style.width = '200px'),
          (e.style.padding = '1px 2px 3px 4px'),
          (e.style.borderStyle = 'solid'),
          (e.style.borderWidth = '1px 2px 3px 4px'),
          (e.style.boxSizing = 'border-box');
        var i = document.body || document.documentElement;
        i.appendChild(e);
        var n = o(e);
        (r = 200 == Math.round(t(n.width))),
          (s.isBoxSizeOuter = r),
          i.removeChild(e);
      }
    }
    function s(e) {
      if (
        (n(),
        'string' == typeof e && (e = document.querySelector(e)),
        e && 'object' == typeof e && e.nodeType)
      ) {
        var s = o(e);
        if ('none' == s.display) return i();
        var a = {};
        (a.width = e.offsetWidth), (a.height = e.offsetHeight);
        for (
          var d = (a.isBorderBox = 'border-box' == s.boxSizing), l = 0;
          l < h;
          l++
        ) {
          var f = u[l],
            c = s[f],
            m = parseFloat(c);
          a[f] = isNaN(m) ? 0 : m;
        }
        var p = a.paddingLeft + a.paddingRight,
          y = a.paddingTop + a.paddingBottom,
          g = a.marginLeft + a.marginRight,
          v = a.marginTop + a.marginBottom,
          _ = a.borderLeftWidth + a.borderRightWidth,
          z = a.borderTopWidth + a.borderBottomWidth,
          I = d && r,
          x = t(s.width);
        x !== !1 && (a.width = x + (I ? 0 : p + _));
        var S = t(s.height);
        return (
          S !== !1 && (a.height = S + (I ? 0 : y + z)),
          (a.innerWidth = a.width - (p + _)),
          (a.innerHeight = a.height - (y + z)),
          (a.outerWidth = a.width + g),
          (a.outerHeight = a.height + v),
          a
        );
      }
    }
    var r,
      a =
        'undefined' == typeof console
          ? e
          : function (t) {
              console.error(t);
            },
      u = [
        'paddingLeft',
        'paddingRight',
        'paddingTop',
        'paddingBottom',
        'marginLeft',
        'marginRight',
        'marginTop',
        'marginBottom',
        'borderLeftWidth',
        'borderRightWidth',
        'borderTopWidth',
        'borderBottomWidth',
      ],
      h = u.length,
      d = !1;
    return s;
  }),
  (function (t, e) {
    'use strict';
    'function' == typeof define && define.amd
      ? define('desandro-matches-selector/matches-selector', e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e())
      : (t.matchesSelector = e());
  })(window, function () {
    'use strict';
    var t = (function () {
      var t = window.Element.prototype;
      if (t.matches) return 'matches';
      if (t.matchesSelector) return 'matchesSelector';
      for (var e = ['webkit', 'moz', 'ms', 'o'], i = 0; i < e.length; i++) {
        var o = e[i],
          n = o + 'MatchesSelector';
        if (t[n]) return n;
      }
    })();
    return function (e, i) {
      return e[t](i);
    };
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'fizzy-ui-utils/utils',
          ['desandro-matches-selector/matches-selector'],
          function (i) {
            return e(t, i);
          }
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(t, require('desandro-matches-selector')))
      : (t.fizzyUIUtils = e(t, t.matchesSelector));
  })(window, function (t, e) {
    var i = {};
    (i.extend = function (t, e) {
      for (var i in e) t[i] = e[i];
      return t;
    }),
      (i.modulo = function (t, e) {
        return ((t % e) + e) % e;
      });
    var o = Array.prototype.slice;
    (i.makeArray = function (t) {
      if (Array.isArray(t)) return t;
      if (null === t || void 0 === t) return [];
      var e = 'object' == typeof t && 'number' == typeof t.length;
      return e ? o.call(t) : [t];
    }),
      (i.removeFrom = function (t, e) {
        var i = t.indexOf(e);
        i != -1 && t.splice(i, 1);
      }),
      (i.getParent = function (t, i) {
        for (; t.parentNode && t != document.body; )
          if (((t = t.parentNode), e(t, i))) return t;
      }),
      (i.getQueryElement = function (t) {
        return 'string' == typeof t ? document.querySelector(t) : t;
      }),
      (i.handleEvent = function (t) {
        var e = 'on' + t.type;
        this[e] && this[e](t);
      }),
      (i.filterFindElements = function (t, o) {
        t = i.makeArray(t);
        var n = [];
        return (
          t.forEach(function (t) {
            if (t instanceof HTMLElement) {
              if (!o) return void n.push(t);
              e(t, o) && n.push(t);
              for (var i = t.querySelectorAll(o), s = 0; s < i.length; s++)
                n.push(i[s]);
            }
          }),
          n
        );
      }),
      (i.debounceMethod = function (t, e, i) {
        i = i || 100;
        var o = t.prototype[e],
          n = e + 'Timeout';
        t.prototype[e] = function () {
          var t = this[n];
          clearTimeout(t);
          var e = arguments,
            s = this;
          this[n] = setTimeout(function () {
            o.apply(s, e), delete s[n];
          }, i);
        };
      }),
      (i.docReady = function (t) {
        var e = document.readyState;
        'complete' == e || 'interactive' == e
          ? setTimeout(t)
          : document.addEventListener('DOMContentLoaded', t);
      }),
      (i.toDashed = function (t) {
        return t
          .replace(/(.)([A-Z])/g, function (t, e, i) {
            return e + '-' + i;
          })
          .toLowerCase();
      });
    var n = t.console;
    return (
      (i.htmlInit = function (e, o) {
        i.docReady(function () {
          var s = i.toDashed(o),
            r = 'data-' + s,
            a = document.querySelectorAll('[' + r + ']'),
            u = document.querySelectorAll('.js-' + s),
            h = i.makeArray(a).concat(i.makeArray(u)),
            d = r + '-options',
            l = t.jQuery;
          h.forEach(function (t) {
            var i,
              s = t.getAttribute(r) || t.getAttribute(d);
            try {
              i = s && JSON.parse(s);
            } catch (a) {
              return void (
                n &&
                n.error('Error parsing ' + r + ' on ' + t.className + ': ' + a)
              );
            }
            var u = new e(t, i);
            l && l.data(t, o, u);
          });
        });
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'outlayer/item',
          ['ev-emitter/ev-emitter', 'get-size/get-size'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('ev-emitter'), require('get-size')))
      : ((t.Outlayer = {}), (t.Outlayer.Item = e(t.EvEmitter, t.getSize)));
  })(window, function (t, e) {
    'use strict';
    function i(t) {
      for (var e in t) return !1;
      return (e = null), !0;
    }
    function o(t, e) {
      t &&
        ((this.element = t),
        (this.layout = e),
        (this.position = { x: 0, y: 0 }),
        this._create());
    }
    function n(t) {
      return t.replace(/([A-Z])/g, function (t) {
        return '-' + t.toLowerCase();
      });
    }
    var s = document.documentElement.style,
      r = 'string' == typeof s.transition ? 'transition' : 'WebkitTransition',
      a = 'string' == typeof s.transform ? 'transform' : 'WebkitTransform',
      u = {
        WebkitTransition: 'webkitTransitionEnd',
        transition: 'transitionend',
      }[r],
      h = {
        transform: a,
        transition: r,
        transitionDuration: r + 'Duration',
        transitionProperty: r + 'Property',
        transitionDelay: r + 'Delay',
      },
      d = (o.prototype = Object.create(t.prototype));
    (d.constructor = o),
      (d._create = function () {
        (this._transn = { ingProperties: {}, clean: {}, onEnd: {} }),
          this.css({ position: 'absolute' });
      }),
      (d.handleEvent = function (t) {
        var e = 'on' + t.type;
        this[e] && this[e](t);
      }),
      (d.getSize = function () {
        this.size = e(this.element);
      }),
      (d.css = function (t) {
        var e = this.element.style;
        for (var i in t) {
          var o = h[i] || i;
          e[o] = t[i];
        }
      }),
      (d.getPosition = function () {
        var t = getComputedStyle(this.element),
          e = this.layout._getOption('originLeft'),
          i = this.layout._getOption('originTop'),
          o = t[e ? 'left' : 'right'],
          n = t[i ? 'top' : 'bottom'],
          s = parseFloat(o),
          r = parseFloat(n),
          a = this.layout.size;
        o.indexOf('%') != -1 && (s = (s / 100) * a.width),
          n.indexOf('%') != -1 && (r = (r / 100) * a.height),
          (s = isNaN(s) ? 0 : s),
          (r = isNaN(r) ? 0 : r),
          (s -= e ? a.paddingLeft : a.paddingRight),
          (r -= i ? a.paddingTop : a.paddingBottom),
          (this.position.x = s),
          (this.position.y = r);
      }),
      (d.layoutPosition = function () {
        var t = this.layout.size,
          e = {},
          i = this.layout._getOption('originLeft'),
          o = this.layout._getOption('originTop'),
          n = i ? 'paddingLeft' : 'paddingRight',
          s = i ? 'left' : 'right',
          r = i ? 'right' : 'left',
          a = this.position.x + t[n];
        (e[s] = this.getXValue(a)), (e[r] = '');
        var u = o ? 'paddingTop' : 'paddingBottom',
          h = o ? 'top' : 'bottom',
          d = o ? 'bottom' : 'top',
          l = this.position.y + t[u];
        (e[h] = this.getYValue(l)),
          (e[d] = ''),
          this.css(e),
          this.emitEvent('layout', [this]);
      }),
      (d.getXValue = function (t) {
        var e = this.layout._getOption('horizontal');
        return this.layout.options.percentPosition && !e
          ? (t / this.layout.size.width) * 100 + '%'
          : t + 'px';
      }),
      (d.getYValue = function (t) {
        var e = this.layout._getOption('horizontal');
        return this.layout.options.percentPosition && e
          ? (t / this.layout.size.height) * 100 + '%'
          : t + 'px';
      }),
      (d._transitionTo = function (t, e) {
        this.getPosition();
        var i = this.position.x,
          o = this.position.y,
          n = t == this.position.x && e == this.position.y;
        if ((this.setPosition(t, e), n && !this.isTransitioning))
          return void this.layoutPosition();
        var s = t - i,
          r = e - o,
          a = {};
        (a.transform = this.getTranslate(s, r)),
          this.transition({
            to: a,
            onTransitionEnd: { transform: this.layoutPosition },
            isCleaning: !0,
          });
      }),
      (d.getTranslate = function (t, e) {
        var i = this.layout._getOption('originLeft'),
          o = this.layout._getOption('originTop');
        return (
          (t = i ? t : -t),
          (e = o ? e : -e),
          'translate3d(' + t + 'px, ' + e + 'px, 0)'
        );
      }),
      (d.goTo = function (t, e) {
        this.setPosition(t, e), this.layoutPosition();
      }),
      (d.moveTo = d._transitionTo),
      (d.setPosition = function (t, e) {
        (this.position.x = parseFloat(t)), (this.position.y = parseFloat(e));
      }),
      (d._nonTransition = function (t) {
        this.css(t.to), t.isCleaning && this._removeStyles(t.to);
        for (var e in t.onTransitionEnd) t.onTransitionEnd[e].call(this);
      }),
      (d.transition = function (t) {
        if (!parseFloat(this.layout.options.transitionDuration))
          return void this._nonTransition(t);
        var e = this._transn;
        for (var i in t.onTransitionEnd) e.onEnd[i] = t.onTransitionEnd[i];
        for (i in t.to)
          (e.ingProperties[i] = !0), t.isCleaning && (e.clean[i] = !0);
        if (t.from) {
          this.css(t.from);
          var o = this.element.offsetHeight;
          o = null;
        }
        this.enableTransition(t.to),
          this.css(t.to),
          (this.isTransitioning = !0);
      });
    var l = 'opacity,' + n(a);
    (d.enableTransition = function () {
      if (!this.isTransitioning) {
        var t = this.layout.options.transitionDuration;
        (t = 'number' == typeof t ? t + 'ms' : t),
          this.css({
            transitionProperty: l,
            transitionDuration: t,
            transitionDelay: this.staggerDelay || 0,
          }),
          this.element.addEventListener(u, this, !1);
      }
    }),
      (d.onwebkitTransitionEnd = function (t) {
        this.ontransitionend(t);
      }),
      (d.onotransitionend = function (t) {
        this.ontransitionend(t);
      });
    var f = { '-webkit-transform': 'transform' };
    (d.ontransitionend = function (t) {
      if (t.target === this.element) {
        var e = this._transn,
          o = f[t.propertyName] || t.propertyName;
        if (
          (delete e.ingProperties[o],
          i(e.ingProperties) && this.disableTransition(),
          o in e.clean &&
            ((this.element.style[t.propertyName] = ''), delete e.clean[o]),
          o in e.onEnd)
        ) {
          var n = e.onEnd[o];
          n.call(this), delete e.onEnd[o];
        }
        this.emitEvent('transitionEnd', [this]);
      }
    }),
      (d.disableTransition = function () {
        this.removeTransitionStyles(),
          this.element.removeEventListener(u, this, !1),
          (this.isTransitioning = !1);
      }),
      (d._removeStyles = function (t) {
        var e = {};
        for (var i in t) e[i] = '';
        this.css(e);
      });
    var c = {
      transitionProperty: '',
      transitionDuration: '',
      transitionDelay: '',
    };
    return (
      (d.removeTransitionStyles = function () {
        this.css(c);
      }),
      (d.stagger = function (t) {
        (t = isNaN(t) ? 0 : t), (this.staggerDelay = t + 'ms');
      }),
      (d.removeElem = function () {
        this.element.parentNode.removeChild(this.element),
          this.css({ display: '' }),
          this.emitEvent('remove', [this]);
      }),
      (d.remove = function () {
        return r && parseFloat(this.layout.options.transitionDuration)
          ? (this.once('transitionEnd', function () {
              this.removeElem();
            }),
            void this.hide())
          : void this.removeElem();
      }),
      (d.reveal = function () {
        delete this.isHidden, this.css({ display: '' });
        var t = this.layout.options,
          e = {},
          i = this.getHideRevealTransitionEndProperty('visibleStyle');
        (e[i] = this.onRevealTransitionEnd),
          this.transition({
            from: t.hiddenStyle,
            to: t.visibleStyle,
            isCleaning: !0,
            onTransitionEnd: e,
          });
      }),
      (d.onRevealTransitionEnd = function () {
        this.isHidden || this.emitEvent('reveal');
      }),
      (d.getHideRevealTransitionEndProperty = function (t) {
        var e = this.layout.options[t];
        if (e.opacity) return 'opacity';
        for (var i in e) return i;
      }),
      (d.hide = function () {
        (this.isHidden = !0), this.css({ display: '' });
        var t = this.layout.options,
          e = {},
          i = this.getHideRevealTransitionEndProperty('hiddenStyle');
        (e[i] = this.onHideTransitionEnd),
          this.transition({
            from: t.visibleStyle,
            to: t.hiddenStyle,
            isCleaning: !0,
            onTransitionEnd: e,
          });
      }),
      (d.onHideTransitionEnd = function () {
        this.isHidden &&
          (this.css({ display: 'none' }), this.emitEvent('hide'));
      }),
      (d.destroy = function () {
        this.css({
          position: '',
          left: '',
          right: '',
          top: '',
          bottom: '',
          transition: '',
          transform: '',
        });
      }),
      o
    );
  }),
  (function (t, e) {
    'use strict';
    'function' == typeof define && define.amd
      ? define(
          'outlayer/outlayer',
          [
            'ev-emitter/ev-emitter',
            'get-size/get-size',
            'fizzy-ui-utils/utils',
            './item',
          ],
          function (i, o, n, s) {
            return e(t, i, o, n, s);
          }
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          t,
          require('ev-emitter'),
          require('get-size'),
          require('fizzy-ui-utils'),
          require('./item')
        ))
      : (t.Outlayer = e(
          t,
          t.EvEmitter,
          t.getSize,
          t.fizzyUIUtils,
          t.Outlayer.Item
        ));
  })(window, function (t, e, i, o, n) {
    'use strict';
    function s(t, e) {
      var i = o.getQueryElement(t);
      if (!i)
        return void (
          u &&
          u.error(
            'Bad element for ' + this.constructor.namespace + ': ' + (i || t)
          )
        );
      (this.element = i),
        h && (this.$element = h(this.element)),
        (this.options = o.extend({}, this.constructor.defaults)),
        this.option(e);
      var n = ++l;
      (this.element.outlayerGUID = n), (f[n] = this), this._create();
      var s = this._getOption('initLayout');
      s && this.layout();
    }
    function r(t) {
      function e() {
        t.apply(this, arguments);
      }
      return (
        (e.prototype = Object.create(t.prototype)),
        (e.prototype.constructor = e),
        e
      );
    }
    function a(t) {
      if ('number' == typeof t) return t;
      var e = t.match(/(^\d*\.?\d*)(\w*)/),
        i = e && e[1],
        o = e && e[2];
      if (!i.length) return 0;
      i = parseFloat(i);
      var n = m[o] || 1;
      return i * n;
    }
    var u = t.console,
      h = t.jQuery,
      d = function () {},
      l = 0,
      f = {};
    (s.namespace = 'outlayer'),
      (s.Item = n),
      (s.defaults = {
        containerStyle: { position: 'relative' },
        initLayout: !0,
        originLeft: !0,
        originTop: !0,
        resize: !0,
        resizeContainer: !0,
        transitionDuration: '0.4s',
        hiddenStyle: { opacity: 0, transform: 'scale(0.001)' },
        visibleStyle: { opacity: 1, transform: 'scale(1)' },
      });
    var c = s.prototype;
    o.extend(c, e.prototype),
      (c.option = function (t) {
        o.extend(this.options, t);
      }),
      (c._getOption = function (t) {
        var e = this.constructor.compatOptions[t];
        return e && void 0 !== this.options[e]
          ? this.options[e]
          : this.options[t];
      }),
      (s.compatOptions = {
        initLayout: 'isInitLayout',
        horizontal: 'isHorizontal',
        layoutInstant: 'isLayoutInstant',
        originLeft: 'isOriginLeft',
        originTop: 'isOriginTop',
        resize: 'isResizeBound',
        resizeContainer: 'isResizingContainer',
      }),
      (c._create = function () {
        this.reloadItems(),
          (this.stamps = []),
          this.stamp(this.options.stamp),
          o.extend(this.element.style, this.options.containerStyle);
        var t = this._getOption('resize');
        t && this.bindResize();
      }),
      (c.reloadItems = function () {
        this.items = this._itemize(this.element.children);
      }),
      (c._itemize = function (t) {
        for (
          var e = this._filterFindItemElements(t),
            i = this.constructor.Item,
            o = [],
            n = 0;
          n < e.length;
          n++
        ) {
          var s = e[n],
            r = new i(s, this);
          o.push(r);
        }
        return o;
      }),
      (c._filterFindItemElements = function (t) {
        return o.filterFindElements(t, this.options.itemSelector);
      }),
      (c.getItemElements = function () {
        return this.items.map(function (t) {
          return t.element;
        });
      }),
      (c.layout = function () {
        this._resetLayout(), this._manageStamps();
        var t = this._getOption('layoutInstant'),
          e = void 0 !== t ? t : !this._isLayoutInited;
        this.layoutItems(this.items, e), (this._isLayoutInited = !0);
      }),
      (c._init = c.layout),
      (c._resetLayout = function () {
        this.getSize();
      }),
      (c.getSize = function () {
        this.size = i(this.element);
      }),
      (c._getMeasurement = function (t, e) {
        var o,
          n = this.options[t];
        n
          ? ('string' == typeof n
              ? (o = this.element.querySelector(n))
              : n instanceof HTMLElement && (o = n),
            (this[t] = o ? i(o)[e] : n))
          : (this[t] = 0);
      }),
      (c.layoutItems = function (t, e) {
        (t = this._getItemsForLayout(t)),
          this._layoutItems(t, e),
          this._postLayout();
      }),
      (c._getItemsForLayout = function (t) {
        return t.filter(function (t) {
          return !t.isIgnored;
        });
      }),
      (c._layoutItems = function (t, e) {
        if ((this._emitCompleteOnItems('layout', t), t && t.length)) {
          var i = [];
          t.forEach(function (t) {
            var o = this._getItemLayoutPosition(t);
            (o.item = t), (o.isInstant = e || t.isLayoutInstant), i.push(o);
          }, this),
            this._processLayoutQueue(i);
        }
      }),
      (c._getItemLayoutPosition = function () {
        return { x: 0, y: 0 };
      }),
      (c._processLayoutQueue = function (t) {
        this.updateStagger(),
          t.forEach(function (t, e) {
            this._positionItem(t.item, t.x, t.y, t.isInstant, e);
          }, this);
      }),
      (c.updateStagger = function () {
        var t = this.options.stagger;
        return null === t || void 0 === t
          ? void (this.stagger = 0)
          : ((this.stagger = a(t)), this.stagger);
      }),
      (c._positionItem = function (t, e, i, o, n) {
        o ? t.goTo(e, i) : (t.stagger(n * this.stagger), t.moveTo(e, i));
      }),
      (c._postLayout = function () {
        this.resizeContainer();
      }),
      (c.resizeContainer = function () {
        var t = this._getOption('resizeContainer');
        if (t) {
          var e = this._getContainerSize();
          e &&
            (this._setContainerMeasure(e.width, !0),
            this._setContainerMeasure(e.height, !1));
        }
      }),
      (c._getContainerSize = d),
      (c._setContainerMeasure = function (t, e) {
        if (void 0 !== t) {
          var i = this.size;
          i.isBorderBox &&
            (t += e
              ? i.paddingLeft +
                i.paddingRight +
                i.borderLeftWidth +
                i.borderRightWidth
              : i.paddingBottom +
                i.paddingTop +
                i.borderTopWidth +
                i.borderBottomWidth),
            (t = Math.max(t, 0)),
            (this.element.style[e ? 'width' : 'height'] = t + 'px');
        }
      }),
      (c._emitCompleteOnItems = function (t, e) {
        function i() {
          n.dispatchEvent(t + 'Complete', null, [e]);
        }
        function o() {
          r++, r == s && i();
        }
        var n = this,
          s = e.length;
        if (!e || !s) return void i();
        var r = 0;
        e.forEach(function (e) {
          e.once(t, o);
        });
      }),
      (c.dispatchEvent = function (t, e, i) {
        var o = e ? [e].concat(i) : i;
        if ((this.emitEvent(t, o), h))
          if (((this.$element = this.$element || h(this.element)), e)) {
            var n = h.Event(e);
            (n.type = t), this.$element.trigger(n, i);
          } else this.$element.trigger(t, i);
      }),
      (c.ignore = function (t) {
        var e = this.getItem(t);
        e && (e.isIgnored = !0);
      }),
      (c.unignore = function (t) {
        var e = this.getItem(t);
        e && delete e.isIgnored;
      }),
      (c.stamp = function (t) {
        (t = this._find(t)),
          t &&
            ((this.stamps = this.stamps.concat(t)),
            t.forEach(this.ignore, this));
      }),
      (c.unstamp = function (t) {
        (t = this._find(t)),
          t &&
            t.forEach(function (t) {
              o.removeFrom(this.stamps, t), this.unignore(t);
            }, this);
      }),
      (c._find = function (t) {
        if (t)
          return (
            'string' == typeof t && (t = this.element.querySelectorAll(t)),
            (t = o.makeArray(t))
          );
      }),
      (c._manageStamps = function () {
        this.stamps &&
          this.stamps.length &&
          (this._getBoundingRect(),
          this.stamps.forEach(this._manageStamp, this));
      }),
      (c._getBoundingRect = function () {
        var t = this.element.getBoundingClientRect(),
          e = this.size;
        this._boundingRect = {
          left: t.left + e.paddingLeft + e.borderLeftWidth,
          top: t.top + e.paddingTop + e.borderTopWidth,
          right: t.right - (e.paddingRight + e.borderRightWidth),
          bottom: t.bottom - (e.paddingBottom + e.borderBottomWidth),
        };
      }),
      (c._manageStamp = d),
      (c._getElementOffset = function (t) {
        var e = t.getBoundingClientRect(),
          o = this._boundingRect,
          n = i(t),
          s = {
            left: e.left - o.left - n.marginLeft,
            top: e.top - o.top - n.marginTop,
            right: o.right - e.right - n.marginRight,
            bottom: o.bottom - e.bottom - n.marginBottom,
          };
        return s;
      }),
      (c.handleEvent = o.handleEvent),
      (c.bindResize = function () {
        t.addEventListener('resize', this), (this.isResizeBound = !0);
      }),
      (c.unbindResize = function () {
        t.removeEventListener('resize', this), (this.isResizeBound = !1);
      }),
      (c.onresize = function () {
        this.resize();
      }),
      o.debounceMethod(s, 'onresize', 100),
      (c.resize = function () {
        this.isResizeBound && this.needsResizeLayout() && this.layout();
      }),
      (c.needsResizeLayout = function () {
        var t = i(this.element),
          e = this.size && t;
        return e && t.innerWidth !== this.size.innerWidth;
      }),
      (c.addItems = function (t) {
        var e = this._itemize(t);
        return e.length && (this.items = this.items.concat(e)), e;
      }),
      (c.appended = function (t) {
        var e = this.addItems(t);
        e.length && (this.layoutItems(e, !0), this.reveal(e));
      }),
      (c.prepended = function (t) {
        var e = this._itemize(t);
        if (e.length) {
          var i = this.items.slice(0);
          (this.items = e.concat(i)),
            this._resetLayout(),
            this._manageStamps(),
            this.layoutItems(e, !0),
            this.reveal(e),
            this.layoutItems(i);
        }
      }),
      (c.reveal = function (t) {
        if ((this._emitCompleteOnItems('reveal', t), t && t.length)) {
          var e = this.updateStagger();
          t.forEach(function (t, i) {
            t.stagger(i * e), t.reveal();
          });
        }
      }),
      (c.hide = function (t) {
        if ((this._emitCompleteOnItems('hide', t), t && t.length)) {
          var e = this.updateStagger();
          t.forEach(function (t, i) {
            t.stagger(i * e), t.hide();
          });
        }
      }),
      (c.revealItemElements = function (t) {
        var e = this.getItems(t);
        this.reveal(e);
      }),
      (c.hideItemElements = function (t) {
        var e = this.getItems(t);
        this.hide(e);
      }),
      (c.getItem = function (t) {
        for (var e = 0; e < this.items.length; e++) {
          var i = this.items[e];
          if (i.element == t) return i;
        }
      }),
      (c.getItems = function (t) {
        t = o.makeArray(t);
        var e = [];
        return (
          t.forEach(function (t) {
            var i = this.getItem(t);
            i && e.push(i);
          }, this),
          e
        );
      }),
      (c.remove = function (t) {
        var e = this.getItems(t);
        this._emitCompleteOnItems('remove', e),
          e &&
            e.length &&
            e.forEach(function (t) {
              t.remove(), o.removeFrom(this.items, t);
            }, this);
      }),
      (c.destroy = function () {
        var t = this.element.style;
        (t.height = ''),
          (t.position = ''),
          (t.width = ''),
          this.items.forEach(function (t) {
            t.destroy();
          }),
          this.unbindResize();
        var e = this.element.outlayerGUID;
        delete f[e],
          delete this.element.outlayerGUID,
          h && h.removeData(this.element, this.constructor.namespace);
      }),
      (s.data = function (t) {
        t = o.getQueryElement(t);
        var e = t && t.outlayerGUID;
        return e && f[e];
      }),
      (s.create = function (t, e) {
        var i = r(s);
        return (
          (i.defaults = o.extend({}, s.defaults)),
          o.extend(i.defaults, e),
          (i.compatOptions = o.extend({}, s.compatOptions)),
          (i.namespace = t),
          (i.data = s.data),
          (i.Item = r(n)),
          o.htmlInit(i, t),
          h && h.bridget && h.bridget(t, i),
          i
        );
      });
    var m = { ms: 1, s: 1e3 };
    return (s.Item = n), s;
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('isotope-layout/js/item', ['outlayer/outlayer'], e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('outlayer')))
      : ((t.Isotope = t.Isotope || {}), (t.Isotope.Item = e(t.Outlayer)));
  })(window, function (t) {
    'use strict';
    function e() {
      t.Item.apply(this, arguments);
    }
    var i = (e.prototype = Object.create(t.Item.prototype)),
      o = i._create;
    (i._create = function () {
      (this.id = this.layout.itemGUID++), o.call(this), (this.sortData = {});
    }),
      (i.updateSortData = function () {
        if (!this.isIgnored) {
          (this.sortData.id = this.id),
            (this.sortData['original-order'] = this.id),
            (this.sortData.random = Math.random());
          var t = this.layout.options.getSortData,
            e = this.layout._sorters;
          for (var i in t) {
            var o = e[i];
            this.sortData[i] = o(this.element, this);
          }
        }
      });
    var n = i.destroy;
    return (
      (i.destroy = function () {
        n.apply(this, arguments), this.css({ display: '' });
      }),
      e
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'isotope-layout/js/layout-mode',
          ['get-size/get-size', 'outlayer/outlayer'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('get-size'), require('outlayer')))
      : ((t.Isotope = t.Isotope || {}),
        (t.Isotope.LayoutMode = e(t.getSize, t.Outlayer)));
  })(window, function (t, e) {
    'use strict';
    function i(t) {
      (this.isotope = t),
        t &&
          ((this.options = t.options[this.namespace]),
          (this.element = t.element),
          (this.items = t.filteredItems),
          (this.size = t.size));
    }
    var o = i.prototype,
      n = [
        '_resetLayout',
        '_getItemLayoutPosition',
        '_manageStamp',
        '_getContainerSize',
        '_getElementOffset',
        'needsResizeLayout',
        '_getOption',
      ];
    return (
      n.forEach(function (t) {
        o[t] = function () {
          return e.prototype[t].apply(this.isotope, arguments);
        };
      }),
      (o.needsVerticalResizeLayout = function () {
        var e = t(this.isotope.element),
          i = this.isotope.size && e;
        return i && e.innerHeight != this.isotope.size.innerHeight;
      }),
      (o._getMeasurement = function () {
        this.isotope._getMeasurement.apply(this, arguments);
      }),
      (o.getColumnWidth = function () {
        this.getSegmentSize('column', 'Width');
      }),
      (o.getRowHeight = function () {
        this.getSegmentSize('row', 'Height');
      }),
      (o.getSegmentSize = function (t, e) {
        var i = t + e,
          o = 'outer' + e;
        if ((this._getMeasurement(i, o), !this[i])) {
          var n = this.getFirstItemSize();
          this[i] = (n && n[o]) || this.isotope.size['inner' + e];
        }
      }),
      (o.getFirstItemSize = function () {
        var e = this.isotope.filteredItems[0];
        return e && e.element && t(e.element);
      }),
      (o.layout = function () {
        this.isotope.layout.apply(this.isotope, arguments);
      }),
      (o.getSize = function () {
        this.isotope.getSize(), (this.size = this.isotope.size);
      }),
      (i.modes = {}),
      (i.create = function (t, e) {
        function n() {
          i.apply(this, arguments);
        }
        return (
          (n.prototype = Object.create(o)),
          (n.prototype.constructor = n),
          e && (n.options = e),
          (n.prototype.namespace = t),
          (i.modes[t] = n),
          n
        );
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'masonry-layout/masonry',
          ['outlayer/outlayer', 'get-size/get-size'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('outlayer'), require('get-size')))
      : (t.Masonry = e(t.Outlayer, t.getSize));
  })(window, function (t, e) {
    var i = t.create('masonry');
    i.compatOptions.fitWidth = 'isFitWidth';
    var o = i.prototype;
    return (
      (o._resetLayout = function () {
        this.getSize(),
          this._getMeasurement('columnWidth', 'outerWidth'),
          this._getMeasurement('gutter', 'outerWidth'),
          this.measureColumns(),
          (this.colYs = []);
        for (var t = 0; t < this.cols; t++) this.colYs.push(0);
        (this.maxY = 0), (this.horizontalColIndex = 0);
      }),
      (o.measureColumns = function () {
        if ((this.getContainerWidth(), !this.columnWidth)) {
          var t = this.items[0],
            i = t && t.element;
          this.columnWidth = (i && e(i).outerWidth) || this.containerWidth;
        }
        var o = (this.columnWidth += this.gutter),
          n = this.containerWidth + this.gutter,
          s = n / o,
          r = o - (n % o),
          a = r && r < 1 ? 'round' : 'floor';
        (s = Math[a](s)), (this.cols = Math.max(s, 1));
      }),
      (o.getContainerWidth = function () {
        var t = this._getOption('fitWidth'),
          i = t ? this.element.parentNode : this.element,
          o = e(i);
        this.containerWidth = o && o.innerWidth;
      }),
      (o._getItemLayoutPosition = function (t) {
        t.getSize();
        var e = t.size.outerWidth % this.columnWidth,
          i = e && e < 1 ? 'round' : 'ceil',
          o = Math[i](t.size.outerWidth / this.columnWidth);
        o = Math.min(o, this.cols);
        for (
          var n = this.options.horizontalOrder
              ? '_getHorizontalColPosition'
              : '_getTopColPosition',
            s = this[n](o, t),
            r = { x: this.columnWidth * s.col, y: s.y },
            a = s.y + t.size.outerHeight,
            u = o + s.col,
            h = s.col;
          h < u;
          h++
        )
          this.colYs[h] = a;
        return r;
      }),
      (o._getTopColPosition = function (t) {
        var e = this._getTopColGroup(t),
          i = Math.min.apply(Math, e);
        return { col: e.indexOf(i), y: i };
      }),
      (o._getTopColGroup = function (t) {
        if (t < 2) return this.colYs;
        for (var e = [], i = this.cols + 1 - t, o = 0; o < i; o++)
          e[o] = this._getColGroupY(o, t);
        return e;
      }),
      (o._getColGroupY = function (t, e) {
        if (e < 2) return this.colYs[t];
        var i = this.colYs.slice(t, t + e);
        return Math.max.apply(Math, i);
      }),
      (o._getHorizontalColPosition = function (t, e) {
        var i = this.horizontalColIndex % this.cols,
          o = t > 1 && i + t > this.cols;
        i = o ? 0 : i;
        var n = e.size.outerWidth && e.size.outerHeight;
        return (
          (this.horizontalColIndex = n ? i + t : this.horizontalColIndex),
          { col: i, y: this._getColGroupY(i, t) }
        );
      }),
      (o._manageStamp = function (t) {
        var i = e(t),
          o = this._getElementOffset(t),
          n = this._getOption('originLeft'),
          s = n ? o.left : o.right,
          r = s + i.outerWidth,
          a = Math.floor(s / this.columnWidth);
        a = Math.max(0, a);
        var u = Math.floor(r / this.columnWidth);
        (u -= r % this.columnWidth ? 0 : 1), (u = Math.min(this.cols - 1, u));
        for (
          var h = this._getOption('originTop'),
            d = (h ? o.top : o.bottom) + i.outerHeight,
            l = a;
          l <= u;
          l++
        )
          this.colYs[l] = Math.max(d, this.colYs[l]);
      }),
      (o._getContainerSize = function () {
        this.maxY = Math.max.apply(Math, this.colYs);
        var t = { height: this.maxY };
        return (
          this._getOption('fitWidth') &&
            (t.width = this._getContainerFitWidth()),
          t
        );
      }),
      (o._getContainerFitWidth = function () {
        for (var t = 0, e = this.cols; --e && 0 === this.colYs[e]; ) t++;
        return (this.cols - t) * this.columnWidth - this.gutter;
      }),
      (o.needsResizeLayout = function () {
        var t = this.containerWidth;
        return this.getContainerWidth(), t != this.containerWidth;
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'isotope-layout/js/layout-modes/masonry',
          ['../layout-mode', 'masonry-layout/masonry'],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          require('../layout-mode'),
          require('masonry-layout')
        ))
      : e(t.Isotope.LayoutMode, t.Masonry);
  })(window, function (t, e) {
    'use strict';
    var i = t.create('masonry'),
      o = i.prototype,
      n = { _getElementOffset: !0, layout: !0, _getMeasurement: !0 };
    for (var s in e.prototype) n[s] || (o[s] = e.prototype[s]);
    var r = o.measureColumns;
    o.measureColumns = function () {
      (this.items = this.isotope.filteredItems), r.call(this);
    };
    var a = o._getOption;
    return (
      (o._getOption = function (t) {
        return 'fitWidth' == t
          ? void 0 !== this.options.isFitWidth
            ? this.options.isFitWidth
            : this.options.fitWidth
          : a.apply(this.isotope, arguments);
      }),
      i
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('isotope-layout/js/layout-modes/fit-rows', ['../layout-mode'], e)
      : 'object' == typeof exports
      ? (module.exports = e(require('../layout-mode')))
      : e(t.Isotope.LayoutMode);
  })(window, function (t) {
    'use strict';
    var e = t.create('fitRows'),
      i = e.prototype;
    return (
      (i._resetLayout = function () {
        (this.x = 0),
          (this.y = 0),
          (this.maxY = 0),
          this._getMeasurement('gutter', 'outerWidth');
      }),
      (i._getItemLayoutPosition = function (t) {
        t.getSize();
        var e = t.size.outerWidth + this.gutter,
          i = this.isotope.size.innerWidth + this.gutter;
        0 !== this.x && e + this.x > i && ((this.x = 0), (this.y = this.maxY));
        var o = { x: this.x, y: this.y };
        return (
          (this.maxY = Math.max(this.maxY, this.y + t.size.outerHeight)),
          (this.x += e),
          o
        );
      }),
      (i._getContainerSize = function () {
        return { height: this.maxY };
      }),
      e
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('isotope-layout/js/layout-modes/vertical', ['../layout-mode'], e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('../layout-mode')))
      : e(t.Isotope.LayoutMode);
  })(window, function (t) {
    'use strict';
    var e = t.create('vertical', { horizontalAlignment: 0 }),
      i = e.prototype;
    return (
      (i._resetLayout = function () {
        this.y = 0;
      }),
      (i._getItemLayoutPosition = function (t) {
        t.getSize();
        var e =
            (this.isotope.size.innerWidth - t.size.outerWidth) *
            this.options.horizontalAlignment,
          i = this.y;
        return (this.y += t.size.outerHeight), { x: e, y: i };
      }),
      (i._getContainerSize = function () {
        return { height: this.y };
      }),
      e
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          [
            'outlayer/outlayer',
            'get-size/get-size',
            'desandro-matches-selector/matches-selector',
            'fizzy-ui-utils/utils',
            'isotope-layout/js/item',
            'isotope-layout/js/layout-mode',
            'isotope-layout/js/layout-modes/masonry',
            'isotope-layout/js/layout-modes/fit-rows',
            'isotope-layout/js/layout-modes/vertical',
          ],
          function (i, o, n, s, r, a) {
            return e(t, i, o, n, s, r, a);
          }
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          t,
          require('outlayer'),
          require('get-size'),
          require('desandro-matches-selector'),
          require('fizzy-ui-utils'),
          require('isotope-layout/js/item'),
          require('isotope-layout/js/layout-mode'),
          require('isotope-layout/js/layout-modes/masonry'),
          require('isotope-layout/js/layout-modes/fit-rows'),
          require('isotope-layout/js/layout-modes/vertical')
        ))
      : (t.Isotope = e(
          t,
          t.Outlayer,
          t.getSize,
          t.matchesSelector,
          t.fizzyUIUtils,
          t.Isotope.Item,
          t.Isotope.LayoutMode
        ));
  })(window, function (t, e, i, o, n, s, r) {
    function a(t, e) {
      return function (i, o) {
        for (var n = 0; n < t.length; n++) {
          var s = t[n],
            r = i.sortData[s],
            a = o.sortData[s];
          if (r > a || r < a) {
            var u = void 0 !== e[s] ? e[s] : e,
              h = u ? 1 : -1;
            return (r > a ? 1 : -1) * h;
          }
        }
        return 0;
      };
    }
    var u = t.jQuery,
      h = String.prototype.trim
        ? function (t) {
            return t.trim();
          }
        : function (t) {
            return t.replace(/^\s+|\s+$/g, '');
          },
      d = e.create('isotope', {
        layoutMode: 'masonry',
        isJQueryFiltering: !0,
        sortAscending: !0,
      });
    (d.Item = s), (d.LayoutMode = r);
    var l = d.prototype;
    (l._create = function () {
      (this.itemGUID = 0),
        (this._sorters = {}),
        this._getSorters(),
        e.prototype._create.call(this),
        (this.modes = {}),
        (this.filteredItems = this.items),
        (this.sortHistory = ['original-order']);
      for (var t in r.modes) this._initLayoutMode(t);
    }),
      (l.reloadItems = function () {
        (this.itemGUID = 0), e.prototype.reloadItems.call(this);
      }),
      (l._itemize = function () {
        for (
          var t = e.prototype._itemize.apply(this, arguments), i = 0;
          i < t.length;
          i++
        ) {
          var o = t[i];
          o.id = this.itemGUID++;
        }
        return this._updateItemsSortData(t), t;
      }),
      (l._initLayoutMode = function (t) {
        var e = r.modes[t],
          i = this.options[t] || {};
        (this.options[t] = e.options ? n.extend(e.options, i) : i),
          (this.modes[t] = new e(this));
      }),
      (l.layout = function () {
        return !this._isLayoutInited && this._getOption('initLayout')
          ? void this.arrange()
          : void this._layout();
      }),
      (l._layout = function () {
        var t = this._getIsInstant();
        this._resetLayout(),
          this._manageStamps(),
          this.layoutItems(this.filteredItems, t),
          (this._isLayoutInited = !0);
      }),
      (l.arrange = function (t) {
        this.option(t), this._getIsInstant();
        var e = this._filter(this.items);
        (this.filteredItems = e.matches),
          this._bindArrangeComplete(),
          this._isInstant
            ? this._noTransition(this._hideReveal, [e])
            : this._hideReveal(e),
          this._sort(),
          this._layout();
      }),
      (l._init = l.arrange),
      (l._hideReveal = function (t) {
        this.reveal(t.needReveal), this.hide(t.needHide);
      }),
      (l._getIsInstant = function () {
        var t = this._getOption('layoutInstant'),
          e = void 0 !== t ? t : !this._isLayoutInited;
        return (this._isInstant = e), e;
      }),
      (l._bindArrangeComplete = function () {
        function t() {
          e &&
            i &&
            o &&
            n.dispatchEvent('arrangeComplete', null, [n.filteredItems]);
        }
        var e,
          i,
          o,
          n = this;
        this.once('layoutComplete', function () {
          (e = !0), t();
        }),
          this.once('hideComplete', function () {
            (i = !0), t();
          }),
          this.once('revealComplete', function () {
            (o = !0), t();
          });
      }),
      (l._filter = function (t) {
        var e = this.options.filter;
        e = e || '*';
        for (
          var i = [], o = [], n = [], s = this._getFilterTest(e), r = 0;
          r < t.length;
          r++
        ) {
          var a = t[r];
          if (!a.isIgnored) {
            var u = s(a);
            u && i.push(a),
              u && a.isHidden ? o.push(a) : u || a.isHidden || n.push(a);
          }
        }
        return { matches: i, needReveal: o, needHide: n };
      }),
      (l._getFilterTest = function (t) {
        return u && this.options.isJQueryFiltering
          ? function (e) {
              return u(e.element).is(t);
            }
          : 'function' == typeof t
          ? function (e) {
              return t(e.element);
            }
          : function (e) {
              return o(e.element, t);
            };
      }),
      (l.updateSortData = function (t) {
        var e;
        t ? ((t = n.makeArray(t)), (e = this.getItems(t))) : (e = this.items),
          this._getSorters(),
          this._updateItemsSortData(e);
      }),
      (l._getSorters = function () {
        var t = this.options.getSortData;
        for (var e in t) {
          var i = t[e];
          this._sorters[e] = f(i);
        }
      }),
      (l._updateItemsSortData = function (t) {
        for (var e = t && t.length, i = 0; e && i < e; i++) {
          var o = t[i];
          o.updateSortData();
        }
      });
    var f = (function () {
      function t(t) {
        if ('string' != typeof t) return t;
        var i = h(t).split(' '),
          o = i[0],
          n = o.match(/^\[(.+)\]$/),
          s = n && n[1],
          r = e(s, o),
          a = d.sortDataParsers[i[1]];
        return (t = a
          ? function (t) {
              return t && a(r(t));
            }
          : function (t) {
              return t && r(t);
            });
      }
      function e(t, e) {
        return t
          ? function (e) {
              return e.getAttribute(t);
            }
          : function (t) {
              var i = t.querySelector(e);
              return i && i.textContent;
            };
      }
      return t;
    })();
    (d.sortDataParsers = {
      parseInt: function (t) {
        return parseInt(t, 10);
      },
      parseFloat: function (t) {
        return parseFloat(t);
      },
    }),
      (l._sort = function () {
        if (this.options.sortBy) {
          var t = n.makeArray(this.options.sortBy);
          this._getIsSameSortBy(t) ||
            (this.sortHistory = t.concat(this.sortHistory));
          var e = a(this.sortHistory, this.options.sortAscending);
          this.filteredItems.sort(e);
        }
      }),
      (l._getIsSameSortBy = function (t) {
        for (var e = 0; e < t.length; e++)
          if (t[e] != this.sortHistory[e]) return !1;
        return !0;
      }),
      (l._mode = function () {
        var t = this.options.layoutMode,
          e = this.modes[t];
        if (!e) throw new Error('No layout mode: ' + t);
        return (e.options = this.options[t]), e;
      }),
      (l._resetLayout = function () {
        e.prototype._resetLayout.call(this), this._mode()._resetLayout();
      }),
      (l._getItemLayoutPosition = function (t) {
        return this._mode()._getItemLayoutPosition(t);
      }),
      (l._manageStamp = function (t) {
        this._mode()._manageStamp(t);
      }),
      (l._getContainerSize = function () {
        return this._mode()._getContainerSize();
      }),
      (l.needsResizeLayout = function () {
        return this._mode().needsResizeLayout();
      }),
      (l.appended = function (t) {
        var e = this.addItems(t);
        if (e.length) {
          var i = this._filterRevealAdded(e);
          this.filteredItems = this.filteredItems.concat(i);
        }
      }),
      (l.prepended = function (t) {
        var e = this._itemize(t);
        if (e.length) {
          this._resetLayout(), this._manageStamps();
          var i = this._filterRevealAdded(e);
          this.layoutItems(this.filteredItems),
            (this.filteredItems = i.concat(this.filteredItems)),
            (this.items = e.concat(this.items));
        }
      }),
      (l._filterRevealAdded = function (t) {
        var e = this._filter(t);
        return (
          this.hide(e.needHide),
          this.reveal(e.matches),
          this.layoutItems(e.matches, !0),
          e.matches
        );
      }),
      (l.insert = function (t) {
        var e = this.addItems(t);
        if (e.length) {
          var i,
            o,
            n = e.length;
          for (i = 0; i < n; i++)
            (o = e[i]), this.element.appendChild(o.element);
          var s = this._filter(e).matches;
          for (i = 0; i < n; i++) e[i].isLayoutInstant = !0;
          for (this.arrange(), i = 0; i < n; i++) delete e[i].isLayoutInstant;
          this.reveal(s);
        }
      });
    var c = l.remove;
    return (
      (l.remove = function (t) {
        t = n.makeArray(t);
        var e = this.getItems(t);
        c.call(this, t);
        for (var i = e && e.length, o = 0; i && o < i; o++) {
          var s = e[o];
          n.removeFrom(this.filteredItems, s);
        }
      }),
      (l.shuffle = function () {
        for (var t = 0; t < this.items.length; t++) {
          var e = this.items[t];
          e.sortData.random = Math.random();
        }
        (this.options.sortBy = 'random'), this._sort(), this._layout();
      }),
      (l._noTransition = function (t, e) {
        var i = this.options.transitionDuration;
        this.options.transitionDuration = 0;
        var o = t.apply(this, e);
        return (this.options.transitionDuration = i), o;
      }),
      (l.getFilteredItemElements = function () {
        return this.filteredItems.map(function (t) {
          return t.element;
        });
      }),
      d
    );
  });
!(function (t, e) {
  'function' == typeof define && define.amd
    ? define('packery/js/rect', e)
    : 'object' == typeof module && module.exports
    ? (module.exports = e())
    : ((t.Packery = t.Packery || {}), (t.Packery.Rect = e()));
})(window, function () {
  function t(e) {
    for (var i in t.defaults) this[i] = t.defaults[i];
    for (i in e) this[i] = e[i];
  }
  t.defaults = { x: 0, y: 0, width: 0, height: 0 };
  var e = t.prototype;
  return (
    (e.contains = function (t) {
      var e = t.width || 0,
        i = t.height || 0;
      return (
        this.x <= t.x &&
        this.y <= t.y &&
        this.x + this.width >= t.x + e &&
        this.y + this.height >= t.y + i
      );
    }),
    (e.overlaps = function (t) {
      var e = this.x + this.width,
        i = this.y + this.height,
        s = t.x + t.width,
        r = t.y + t.height;
      return this.x < s && e > t.x && this.y < r && i > t.y;
    }),
    (e.getMaximalFreeRects = function (e) {
      if (!this.overlaps(e)) return !1;
      var i,
        s = [],
        r = this.x + this.width,
        h = this.y + this.height,
        n = e.x + e.width,
        o = e.y + e.height;
      return (
        this.y < e.y &&
          ((i = new t({
            x: this.x,
            y: this.y,
            width: this.width,
            height: e.y - this.y,
          })),
          s.push(i)),
        r > n &&
          ((i = new t({ x: n, y: this.y, width: r - n, height: this.height })),
          s.push(i)),
        h > o &&
          ((i = new t({ x: this.x, y: o, width: this.width, height: h - o })),
          s.push(i)),
        this.x < e.x &&
          ((i = new t({
            x: this.x,
            y: this.y,
            width: e.x - this.x,
            height: this.height,
          })),
          s.push(i)),
        s
      );
    }),
    (e.canFit = function (t) {
      return this.width >= t.width && this.height >= t.height;
    }),
    t
  );
}),
  (function (t, e) {
    if ('function' == typeof define && define.amd)
      define('packery/js/packer', ['./rect'], e);
    else if ('object' == typeof module && module.exports)
      module.exports = e(require('./rect'));
    else {
      var i = (t.Packery = t.Packery || {});
      i.Packer = e(i.Rect);
    }
  })(window, function (t) {
    function e(t, e, i) {
      (this.width = t || 0),
        (this.height = e || 0),
        (this.sortDirection = i || 'downwardLeftToRight'),
        this.reset();
    }
    var i = e.prototype;
    (i.reset = function () {
      this.spaces = [];
      var e = new t({ x: 0, y: 0, width: this.width, height: this.height });
      this.spaces.push(e),
        (this.sorter = s[this.sortDirection] || s.downwardLeftToRight);
    }),
      (i.pack = function (t) {
        for (var e = 0; e < this.spaces.length; e++) {
          var i = this.spaces[e];
          if (i.canFit(t)) {
            this.placeInSpace(t, i);
            break;
          }
        }
      }),
      (i.columnPack = function (t) {
        for (var e = 0; e < this.spaces.length; e++) {
          var i = this.spaces[e],
            s =
              i.x <= t.x &&
              i.x + i.width >= t.x + t.width &&
              i.height >= t.height - 0.01;
          if (s) {
            (t.y = i.y), this.placed(t);
            break;
          }
        }
      }),
      (i.rowPack = function (t) {
        for (var e = 0; e < this.spaces.length; e++) {
          var i = this.spaces[e],
            s =
              i.y <= t.y &&
              i.y + i.height >= t.y + t.height &&
              i.width >= t.width - 0.01;
          if (s) {
            (t.x = i.x), this.placed(t);
            break;
          }
        }
      }),
      (i.placeInSpace = function (t, e) {
        (t.x = e.x), (t.y = e.y), this.placed(t);
      }),
      (i.placed = function (t) {
        for (var e = [], i = 0; i < this.spaces.length; i++) {
          var s = this.spaces[i],
            r = s.getMaximalFreeRects(t);
          r ? e.push.apply(e, r) : e.push(s);
        }
        (this.spaces = e), this.mergeSortSpaces();
      }),
      (i.mergeSortSpaces = function () {
        e.mergeRects(this.spaces), this.spaces.sort(this.sorter);
      }),
      (i.addSpace = function (t) {
        this.spaces.push(t), this.mergeSortSpaces();
      }),
      (e.mergeRects = function (t) {
        var e = 0,
          i = t[e];
        t: for (; i; ) {
          for (var s = 0, r = t[e + s]; r; ) {
            if (r == i) s++;
            else {
              if (r.contains(i)) {
                t.splice(e, 1), (i = t[e]);
                continue t;
              }
              i.contains(r) ? t.splice(e + s, 1) : s++;
            }
            r = t[e + s];
          }
          e++, (i = t[e]);
        }
        return t;
      });
    var s = {
      downwardLeftToRight: function (t, e) {
        return t.y - e.y || t.x - e.x;
      },
      rightwardTopToBottom: function (t, e) {
        return t.x - e.x || t.y - e.y;
      },
    };
    return e;
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define('packery/js/item', ['outlayer/outlayer', './rect'], e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e(require('outlayer'), require('./rect')))
      : (t.Packery.Item = e(t.Outlayer, t.Packery.Rect));
  })(window, function (t, e) {
    var i = document.documentElement.style,
      s = 'string' == typeof i.transform ? 'transform' : 'WebkitTransform',
      r = function () {
        t.Item.apply(this, arguments);
      },
      h = (r.prototype = Object.create(t.Item.prototype)),
      n = h._create;
    h._create = function () {
      n.call(this), (this.rect = new e());
    };
    var o = h.moveTo;
    return (
      (h.moveTo = function (t, e) {
        var i = Math.abs(this.position.x - t),
          s = Math.abs(this.position.y - e),
          r =
            this.layout.dragItemCount &&
            !this.isPlacing &&
            !this.isTransitioning &&
            i < 1 &&
            s < 1;
        return r ? void this.goTo(t, e) : void o.apply(this, arguments);
      }),
      (h.enablePlacing = function () {
        this.removeTransitionStyles(),
          this.isTransitioning && s && (this.element.style[s] = 'none'),
          (this.isTransitioning = !1),
          this.getSize(),
          this.layout._setRectSize(this.element, this.rect),
          (this.isPlacing = !0);
      }),
      (h.disablePlacing = function () {
        this.isPlacing = !1;
      }),
      (h.removeElem = function () {
        this.element.parentNode.removeChild(this.element),
          this.layout.packer.addSpace(this.rect),
          this.emitEvent('remove', [this]);
      }),
      (h.showDropPlaceholder = function () {
        var t = this.dropPlaceholder;
        t ||
          ((t = this.dropPlaceholder = document.createElement('div')),
          (t.className = 'packery-drop-placeholder'),
          (t.style.position = 'absolute')),
          (t.style.width = this.size.width + 'px'),
          (t.style.height = this.size.height + 'px'),
          this.positionDropPlaceholder(),
          this.layout.element.appendChild(t);
      }),
      (h.positionDropPlaceholder = function () {
        this.dropPlaceholder.style[s] =
          'translate(' + this.rect.x + 'px, ' + this.rect.y + 'px)';
      }),
      (h.hideDropPlaceholder = function () {
        this.layout.element.removeChild(this.dropPlaceholder);
      }),
      r
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(
          'packery/js/packery',
          [
            'get-size/get-size',
            'outlayer/outlayer',
            './rect',
            './packer',
            './item',
          ],
          e
        )
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          require('get-size'),
          require('outlayer'),
          require('./rect'),
          require('./packer'),
          require('./item')
        ))
      : (t.Packery = e(
          t.getSize,
          t.Outlayer,
          t.Packery.Rect,
          t.Packery.Packer,
          t.Packery.Item
        ));
  })(window, function (t, e, i, s, r) {
    function h(t, e) {
      return t.position.y - e.position.y || t.position.x - e.position.x;
    }
    function n(t, e) {
      return t.position.x - e.position.x || t.position.y - e.position.y;
    }
    function o(t, e) {
      var i = e.x - t.x,
        s = e.y - t.y;
      return Math.sqrt(i * i + s * s);
    }
    i.prototype.canFit = function (t) {
      return this.width >= t.width - 1 && this.height >= t.height - 1;
    };
    var a = e.create('packery');
    a.Item = r;
    var c = a.prototype;
    (c._create = function () {
      e.prototype._create.call(this),
        (this.packer = new s()),
        (this.shiftPacker = new s()),
        (this.isEnabled = !0),
        (this.dragItemCount = 0);
      var t = this;
      (this.handleDraggabilly = {
        dragStart: function () {
          t.itemDragStart(this.element);
        },
        dragMove: function () {
          t.itemDragMove(this.element, this.position.x, this.position.y);
        },
        dragEnd: function () {
          t.itemDragEnd(this.element);
        },
      }),
        (this.handleUIDraggable = {
          start: function (e, i) {
            i && t.itemDragStart(e.currentTarget);
          },
          drag: function (e, i) {
            i &&
              t.itemDragMove(e.currentTarget, i.position.left, i.position.top);
          },
          stop: function (e, i) {
            i && t.itemDragEnd(e.currentTarget);
          },
        });
    }),
      (c._resetLayout = function () {
        this.getSize(), this._getMeasurements();
        var t, e, i;
        this._getOption('horizontal')
          ? ((t = 1 / 0),
            (e = this.size.innerHeight + this.gutter),
            (i = 'rightwardTopToBottom'))
          : ((t = this.size.innerWidth + this.gutter),
            (e = 1 / 0),
            (i = 'downwardLeftToRight')),
          (this.packer.width = this.shiftPacker.width = t),
          (this.packer.height = this.shiftPacker.height = e),
          (this.packer.sortDirection = this.shiftPacker.sortDirection = i),
          this.packer.reset(),
          (this.maxY = 0),
          (this.maxX = 0);
      }),
      (c._getMeasurements = function () {
        this._getMeasurement('columnWidth', 'width'),
          this._getMeasurement('rowHeight', 'height'),
          this._getMeasurement('gutter', 'width');
      }),
      (c._getItemLayoutPosition = function (t) {
        if (
          (this._setRectSize(t.element, t.rect),
          this.isShifting || this.dragItemCount > 0)
        ) {
          var e = this._getPackMethod();
          this.packer[e](t.rect);
        } else this.packer.pack(t.rect);
        return this._setMaxXY(t.rect), t.rect;
      }),
      (c.shiftLayout = function () {
        (this.isShifting = !0), this.layout(), delete this.isShifting;
      }),
      (c._getPackMethod = function () {
        return this._getOption('horizontal') ? 'rowPack' : 'columnPack';
      }),
      (c._setMaxXY = function (t) {
        (this.maxX = Math.max(t.x + t.width, this.maxX)),
          (this.maxY = Math.max(t.y + t.height, this.maxY));
      }),
      (c._setRectSize = function (e, i) {
        var s = t(e),
          r = s.outerWidth,
          h = s.outerHeight;
        (r || h) &&
          ((r = this._applyGridGutter(r, this.columnWidth)),
          (h = this._applyGridGutter(h, this.rowHeight))),
          (i.width = Math.min(r, this.packer.width)),
          (i.height = Math.min(h, this.packer.height));
      }),
      (c._applyGridGutter = function (t, e) {
        if (!e) return t + this.gutter;
        e += this.gutter;
        var i = t % e,
          s = i && i < 1 ? 'round' : 'ceil';
        return (t = Math[s](t / e) * e);
      }),
      (c._getContainerSize = function () {
        return this._getOption('horizontal')
          ? { width: this.maxX - this.gutter }
          : { height: this.maxY - this.gutter };
      }),
      (c._manageStamp = function (t) {
        var e,
          s = this.getItem(t);
        if (s && s.isPlacing) e = s.rect;
        else {
          var r = this._getElementOffset(t);
          e = new i({
            x: this._getOption('originLeft') ? r.left : r.right,
            y: this._getOption('originTop') ? r.top : r.bottom,
          });
        }
        this._setRectSize(t, e), this.packer.placed(e), this._setMaxXY(e);
      }),
      (c.sortItemsByPosition = function () {
        var t = this._getOption('horizontal') ? n : h;
        this.items.sort(t);
      }),
      (c.fit = function (t, e, i) {
        var s = this.getItem(t);
        s &&
          (this.stamp(s.element),
          s.enablePlacing(),
          this.updateShiftTargets(s),
          (e = void 0 === e ? s.rect.x : e),
          (i = void 0 === i ? s.rect.y : i),
          this.shift(s, e, i),
          this._bindFitEvents(s),
          s.moveTo(s.rect.x, s.rect.y),
          this.shiftLayout(),
          this.unstamp(s.element),
          this.sortItemsByPosition(),
          s.disablePlacing());
      }),
      (c._bindFitEvents = function (t) {
        function e() {
          s++, 2 == s && i.dispatchEvent('fitComplete', null, [t]);
        }
        var i = this,
          s = 0;
        t.once('layout', e), this.once('layoutComplete', e);
      }),
      (c.resize = function () {
        this.isResizeBound &&
          this.needsResizeLayout() &&
          (this.options.shiftPercentResize
            ? this.resizeShiftPercentLayout()
            : this.layout());
      }),
      (c.needsResizeLayout = function () {
        var e = t(this.element),
          i = this._getOption('horizontal') ? 'innerHeight' : 'innerWidth';
        return e[i] != this.size[i];
      }),
      (c.resizeShiftPercentLayout = function () {
        var e = this._getItemsForLayout(this.items),
          i = this._getOption('horizontal'),
          s = i ? 'y' : 'x',
          r = i ? 'height' : 'width',
          h = i ? 'rowHeight' : 'columnWidth',
          n = i ? 'innerHeight' : 'innerWidth',
          o = this[h];
        if ((o = o && o + this.gutter)) {
          this._getMeasurements();
          var a = this[h] + this.gutter;
          e.forEach(function (t) {
            var e = Math.round(t.rect[s] / o);
            t.rect[s] = e * a;
          });
        } else {
          var c = t(this.element)[n] + this.gutter,
            u = this.packer[r];
          e.forEach(function (t) {
            t.rect[s] = (t.rect[s] / u) * c;
          });
        }
        this.shiftLayout();
      }),
      (c.itemDragStart = function (t) {
        if (this.isEnabled) {
          this.stamp(t);
          var e = this.getItem(t);
          e &&
            (e.enablePlacing(),
            e.showDropPlaceholder(),
            this.dragItemCount++,
            this.updateShiftTargets(e));
        }
      }),
      (c.updateShiftTargets = function (t) {
        this.shiftPacker.reset(), this._getBoundingRect();
        var e = this._getOption('originLeft'),
          s = this._getOption('originTop');
        this.stamps.forEach(function (t) {
          var r = this.getItem(t);
          if (!r || !r.isPlacing) {
            var h = this._getElementOffset(t),
              n = new i({ x: e ? h.left : h.right, y: s ? h.top : h.bottom });
            this._setRectSize(t, n), this.shiftPacker.placed(n);
          }
        }, this);
        var r = this._getOption('horizontal'),
          h = r ? 'rowHeight' : 'columnWidth',
          n = r ? 'height' : 'width';
        (this.shiftTargetKeys = []), (this.shiftTargets = []);
        var o,
          a = this[h];
        if ((a = a && a + this.gutter)) {
          var c = Math.ceil(t.rect[n] / a),
            u = Math.floor((this.shiftPacker[n] + this.gutter) / a);
          o = (u - c) * a;
          for (var d = 0; d < u; d++) this._addShiftTarget(d * a, 0, o);
        } else
          (o = this.shiftPacker[n] + this.gutter - t.rect[n]),
            this._addShiftTarget(0, 0, o);
        var g = this._getItemsForLayout(this.items),
          l = this._getPackMethod();
        g.forEach(function (t) {
          var e = t.rect;
          this._setRectSize(t.element, e),
            this.shiftPacker[l](e),
            this._addShiftTarget(e.x, e.y, o);
          var i = r ? e.x + e.width : e.x,
            s = r ? e.y : e.y + e.height;
          if ((this._addShiftTarget(i, s, o), a))
            for (var h = Math.round(e[n] / a), c = 1; c < h; c++) {
              var u = r ? i : e.x + a * c,
                d = r ? e.y + a * c : s;
              this._addShiftTarget(u, d, o);
            }
        }, this);
      }),
      (c._addShiftTarget = function (t, e, i) {
        var s = this._getOption('horizontal') ? e : t;
        if (!(0 !== s && s > i)) {
          var r = t + ',' + e,
            h = this.shiftTargetKeys.indexOf(r) != -1;
          h ||
            (this.shiftTargetKeys.push(r),
            this.shiftTargets.push({ x: t, y: e }));
        }
      }),
      (c.shift = function (t, e, i) {
        var s,
          r = 1 / 0,
          h = { x: e, y: i };
        this.shiftTargets.forEach(function (t) {
          var e = o(t, h);
          e < r && ((s = t), (r = e));
        }),
          (t.rect.x = s.x),
          (t.rect.y = s.y);
      });
    var u = 120;
    (c.itemDragMove = function (t, e, i) {
      function s() {
        h.shift(r, e, i), r.positionDropPlaceholder(), h.layout();
      }
      var r = this.isEnabled && this.getItem(t);
      if (r) {
        (e -= this.size.paddingLeft), (i -= this.size.paddingTop);
        var h = this,
          n = new Date();
        this._itemDragTime && n - this._itemDragTime < u
          ? (clearTimeout(this.dragTimeout),
            (this.dragTimeout = setTimeout(s, u)))
          : (s(), (this._itemDragTime = n));
      }
    }),
      (c.itemDragEnd = function (t) {
        function e() {
          s++,
            2 == s &&
              (i.element.classList.remove('is-positioning-post-drag'),
              i.hideDropPlaceholder(),
              r.dispatchEvent('dragItemPositioned', null, [i]));
        }
        var i = this.isEnabled && this.getItem(t);
        if (i) {
          clearTimeout(this.dragTimeout),
            i.element.classList.add('is-positioning-post-drag');
          var s = 0,
            r = this;
          i.once('layout', e),
            this.once('layoutComplete', e),
            i.moveTo(i.rect.x, i.rect.y),
            this.layout(),
            (this.dragItemCount = Math.max(0, this.dragItemCount - 1)),
            this.sortItemsByPosition(),
            i.disablePlacing(),
            this.unstamp(i.element);
        }
      }),
      (c.bindDraggabillyEvents = function (t) {
        this._bindDraggabillyEvents(t, 'on');
      }),
      (c.unbindDraggabillyEvents = function (t) {
        this._bindDraggabillyEvents(t, 'off');
      }),
      (c._bindDraggabillyEvents = function (t, e) {
        var i = this.handleDraggabilly;
        t[e]('dragStart', i.dragStart),
          t[e]('dragMove', i.dragMove),
          t[e]('dragEnd', i.dragEnd);
      }),
      (c.bindUIDraggableEvents = function (t) {
        this._bindUIDraggableEvents(t, 'on');
      }),
      (c.unbindUIDraggableEvents = function (t) {
        this._bindUIDraggableEvents(t, 'off');
      }),
      (c._bindUIDraggableEvents = function (t, e) {
        var i = this.handleUIDraggable;
        t[e]('dragstart', i.start)[e]('drag', i.drag)[e]('dragstop', i.stop);
      });
    var d = c.destroy;
    return (
      (c.destroy = function () {
        d.apply(this, arguments), (this.isEnabled = !1);
      }),
      (a.Rect = i),
      (a.Packer = s),
      a
    );
  }),
  (function (t, e) {
    'function' == typeof define && define.amd
      ? define(['isotope-layout/js/layout-mode', 'packery/js/packery'], e)
      : 'object' == typeof module && module.exports
      ? (module.exports = e(
          require('isotope-layout/js/layout-mode'),
          require('packery')
        ))
      : e(t.Isotope.LayoutMode, t.Packery);
  })(window, function (t, e) {
    var i = t.create('packery'),
      s = i.prototype,
      r = { _getElementOffset: !0, _getMeasurement: !0 };
    for (var h in e.prototype) r[h] || (s[h] = e.prototype[h]);
    var n = s._resetLayout;
    s._resetLayout = function () {
      (this.packer = this.packer || new e.Packer()),
        (this.shiftPacker = this.shiftPacker || new e.Packer()),
        n.apply(this, arguments);
    };
    var o = s._getItemLayoutPosition;
    s._getItemLayoutPosition = function (t) {
      return (t.rect = t.rect || new e.Rect()), o.call(this, t);
    };
    var a = s.needsResizeLayout;
    s.needsResizeLayout = function () {
      return this._getOption('horizontal')
        ? this.needsVerticalResizeLayout()
        : a.call(this);
    };
    var c = s._getOption;
    return (
      (s._getOption = function (t) {
        return 'horizontal' == t
          ? void 0 !== this.options.isHorizontal
            ? this.options.isHorizontal
            : this.options.horizontal
          : c.apply(this.isotope, arguments);
      }),
      i
    );
  });
!(function (t) {
  'use strict';
  'function' == typeof define && define.amd
    ? define(['jquery'], t)
    : 'undefined' != typeof module && module.exports
    ? (module.exports = t(require('jquery')))
    : t(jQuery);
})(function (t) {
  var e = -1,
    o = -1,
    a = function (t) {
      return parseFloat(t) || 0;
    },
    n = function (e) {
      var o = 1,
        n = t(e),
        i = null,
        r = [];
      return (
        n.each(function () {
          var e = t(this),
            n = e.offset().top - a(e.css('margin-top')),
            s = r.length > 0 ? r[r.length - 1] : null;
          null === s
            ? r.push(e)
            : Math.floor(Math.abs(i - n)) <= o
            ? (r[r.length - 1] = s.add(e))
            : r.push(e),
            (i = n);
        }),
        r
      );
    },
    i = function (e) {
      var o = { byRow: !0, property: 'height', target: null, remove: !1 };
      return 'object' == typeof e
        ? t.extend(o, e)
        : ('boolean' == typeof e
            ? (o.byRow = e)
            : 'remove' === e && (o.remove = !0),
          o);
    },
    r = (t.fn.matchHeight = function (e) {
      var o = i(e);
      if (o.remove) {
        var a = this;
        return (
          this.css(o.property, ''),
          t.each(r._groups, function (t, e) {
            e.elements = e.elements.not(a);
          }),
          this
        );
      }
      return this.length <= 1 && !o.target
        ? this
        : (r._groups.push({ elements: this, options: o }),
          r._apply(this, o),
          this);
    });
  (r.version = 'master'),
    (r._groups = []),
    (r._throttle = 80),
    (r._maintainScroll = !1),
    (r._beforeUpdate = null),
    (r._afterUpdate = null),
    (r._rows = n),
    (r._parse = a),
    (r._parseOptions = i),
    (r._apply = function (e, o) {
      var s = i(o),
        h = t(e),
        l = [h],
        c = t(window).scrollTop(),
        p = t('html').outerHeight(!0),
        u = h.parents().filter(':hidden');
      return (
        u.each(function () {
          var e = t(this);
          e.data('style-cache', e.attr('style'));
        }),
        u.css('display', 'block'),
        s.byRow &&
          !s.target &&
          (h.each(function () {
            var e = t(this),
              o = e.css('display');
            'inline-block' !== o &&
              'flex' !== o &&
              'inline-flex' !== o &&
              (o = 'block'),
              e.data('style-cache', e.attr('style')),
              e.css({
                display: o,
                'padding-top': '0',
                'padding-bottom': '0',
                'margin-top': '0',
                'margin-bottom': '0',
                'border-top-width': '0',
                'border-bottom-width': '0',
                height: '100px',
                overflow: 'hidden',
              });
          }),
          (l = n(h)),
          h.each(function () {
            var e = t(this);
            e.attr('style', e.data('style-cache') || '');
          })),
        t.each(l, function (e, o) {
          var n = t(o),
            i = 0;
          if (s.target) i = s.target.outerHeight(!1);
          else {
            if (s.byRow && n.length <= 1) return void n.css(s.property, '');
            n.each(function () {
              var e = t(this),
                o = e.attr('style'),
                a = e.css('display');
              'inline-block' !== a &&
                'flex' !== a &&
                'inline-flex' !== a &&
                (a = 'block');
              var n = { display: a };
              (n[s.property] = ''),
                e.css(n),
                e.outerHeight(!1) > i && (i = e.outerHeight(!1)),
                o ? e.attr('style', o) : e.css('display', '');
            });
          }
          n.each(function () {
            var e = t(this),
              o = 0;
            (s.target && e.is(s.target)) ||
              ('border-box' !== e.css('box-sizing') &&
                ((o +=
                  a(e.css('border-top-width')) +
                  a(e.css('border-bottom-width'))),
                (o += a(e.css('padding-top')) + a(e.css('padding-bottom')))),
              e.css(s.property, i - o + 'px'));
          });
        }),
        u.each(function () {
          var e = t(this);
          e.attr('style', e.data('style-cache') || null);
        }),
        r._maintainScroll &&
          t(window).scrollTop((c / p) * t('html').outerHeight(!0)),
        this
      );
    }),
    (r._applyDataApi = function () {
      var e = {};
      t('[data-match-height], [data-mh]').each(function () {
        var o = t(this),
          a = o.attr('data-mh') || o.attr('data-match-height');
        a in e ? (e[a] = e[a].add(o)) : (e[a] = o);
      }),
        t.each(e, function () {
          this.matchHeight(!0);
        });
    });
  var s = function (e) {
    r._beforeUpdate && r._beforeUpdate(e, r._groups),
      t.each(r._groups, function () {
        r._apply(this.elements, this.options);
      }),
      r._afterUpdate && r._afterUpdate(e, r._groups);
  };
  (r._update = function (a, n) {
    if (n && 'resize' === n.type) {
      var i = t(window).width();
      if (i === e) return;
      e = i;
    }
    a
      ? o === -1 &&
        (o = setTimeout(function () {
          s(n), (o = -1);
        }, r._throttle))
      : s(n);
  }),
    t(r._applyDataApi);
  var h = t.fn.on ? 'on' : 'bind';
  t(window)[h]('load', function (t) {
    r._update(!1, t);
  }),
    t(window)[h]('resize orientationchange', function (t) {
      r._update(!0, t);
    });
});
!(function (e) {
  'function' == typeof define && define.amd
    ? define(['jquery'], e)
    : e(
        'object' == typeof exports
          ? require('jquery')
          : window.jQuery || window.Zepto
      );
})(function (e) {
  var t,
    n,
    i,
    o,
    r,
    a,
    s = 'Close',
    l = 'BeforeClose',
    c = 'AfterClose',
    d = 'BeforeAppend',
    u = 'MarkupParse',
    p = 'Open',
    f = 'Change',
    m = 'mfp',
    g = '.' + m,
    v = 'mfp-ready',
    h = 'mfp-removing',
    y = 'mfp-prevent-close',
    C = function () {},
    w = !!window.jQuery,
    b = e(window),
    I = function (e, n) {
      t.ev.on(m + e + g, n);
    },
    x = function (t, n, i, o) {
      var r = document.createElement('div');
      return (
        (r.className = 'mfp-' + t),
        i && (r.innerHTML = i),
        o ? n && n.appendChild(r) : ((r = e(r)), n && r.appendTo(n)),
        r
      );
    },
    k = function (n, i) {
      t.ev.triggerHandler(m + n, i),
        t.st.callbacks &&
          ((n = n.charAt(0).toLowerCase() + n.slice(1)),
          t.st.callbacks[n] &&
            t.st.callbacks[n].apply(t, e.isArray(i) ? i : [i]));
    },
    T = function (n) {
      return (
        (n === a && t.currTemplate.closeBtn) ||
          ((t.currTemplate.closeBtn = e(
            t.st.closeMarkup.replace('%title%', t.st.tClose)
          )),
          (a = n)),
        t.currTemplate.closeBtn
      );
    },
    _ = function () {
      e.magnificPopup.instance ||
        ((t = new C()), t.init(), (e.magnificPopup.instance = t));
    },
    P = function () {
      var e = document.createElement('p').style,
        t = ['ms', 'O', 'Moz', 'Webkit'];
      if (void 0 !== e.transition) return !0;
      for (; t.length; ) if (t.pop() + 'Transition' in e) return !0;
      return !1;
    };
  (C.prototype = {
    constructor: C,
    init: function () {
      var n = navigator.appVersion;
      (t.isLowIE = t.isIE8 = document.all && !document.addEventListener),
        (t.isAndroid = /android/gi.test(n)),
        (t.isIOS = /iphone|ipad|ipod/gi.test(n)),
        (t.supportsTransition = P()),
        (t.probablyMobile =
          t.isAndroid ||
          t.isIOS ||
          /(Opera Mini)|Kindle|webOS|BlackBerry|(Opera Mobi)|(Windows Phone)|IEMobile/i.test(
            navigator.userAgent
          )),
        (i = e(document)),
        (t.popupsCache = {});
    },
    open: function (n) {
      var o;
      if (n.isObj === !1) {
        (t.items = n.items.toArray()), (t.index = 0);
        var a,
          s = n.items;
        for (o = 0; o < s.length; o++)
          if (((a = s[o]), a.parsed && (a = a.el[0]), a === n.el[0])) {
            t.index = o;
            break;
          }
      } else
        (t.items = e.isArray(n.items) ? n.items : [n.items]),
          (t.index = n.index || 0);
      if (t.isOpen) return void t.updateItemHTML();
      (t.types = []),
        (r = ''),
        n.mainEl && n.mainEl.length ? (t.ev = n.mainEl.eq(0)) : (t.ev = i),
        n.key
          ? (t.popupsCache[n.key] || (t.popupsCache[n.key] = {}),
            (t.currTemplate = t.popupsCache[n.key]))
          : (t.currTemplate = {}),
        (t.st = e.extend(!0, {}, e.magnificPopup.defaults, n)),
        (t.fixedContentPos =
          'auto' === t.st.fixedContentPos
            ? !t.probablyMobile
            : t.st.fixedContentPos),
        t.st.modal &&
          ((t.st.closeOnContentClick = !1),
          (t.st.closeOnBgClick = !1),
          (t.st.showCloseBtn = !1),
          (t.st.enableEscapeKey = !1)),
        t.bgOverlay ||
          ((t.bgOverlay = x('bg').on('click' + g, function () {
            t.close();
          })),
          (t.wrap = x('wrap')
            .attr('tabindex', -1)
            .on('click' + g, function (e) {
              t._checkIfClose(e.target) && t.close();
            })),
          (t.container = x('container', t.wrap))),
        (t.contentContainer = x('content')),
        t.st.preloader &&
          (t.preloader = x('preloader', t.container, t.st.tLoading));
      var l = e.magnificPopup.modules;
      for (o = 0; o < l.length; o++) {
        var c = l[o];
        (c = c.charAt(0).toUpperCase() + c.slice(1)), t['init' + c].call(t);
      }
      k('BeforeOpen'),
        t.st.showCloseBtn &&
          (t.st.closeBtnInside
            ? (I(u, function (e, t, n, i) {
                n.close_replaceWith = T(i.type);
              }),
              (r += ' mfp-close-btn-in'))
            : t.wrap.append(T())),
        t.st.alignTop && (r += ' mfp-align-top'),
        t.fixedContentPos
          ? t.wrap.css({
              overflow: t.st.overflowY,
              overflowX: 'hidden',
              overflowY: t.st.overflowY,
            })
          : t.wrap.css({ top: b.scrollTop(), position: 'absolute' }),
        (t.st.fixedBgPos === !1 ||
          ('auto' === t.st.fixedBgPos && !t.fixedContentPos)) &&
          t.bgOverlay.css({ height: i.height(), position: 'absolute' }),
        t.st.enableEscapeKey &&
          i.on('keyup' + g, function (e) {
            27 === e.keyCode && t.close();
          }),
        b.on('resize' + g, function () {
          t.updateSize();
        }),
        t.st.closeOnContentClick || (r += ' mfp-auto-cursor'),
        r && t.wrap.addClass(r);
      var d = (t.wH = b.height()),
        f = {};
      if (t.fixedContentPos && t._hasScrollBar(d)) {
        var m = t._getScrollbarSize();
        m && (f.marginRight = m);
      }
      t.fixedContentPos &&
        (t.isIE7
          ? e('body, html').css('overflow', 'hidden')
          : (f.overflow = 'hidden'));
      var h = t.st.mainClass;
      return (
        t.isIE7 && (h += ' mfp-ie7'),
        h && t._addClassToMFP(h),
        t.updateItemHTML(),
        k('BuildControls'),
        e('html').css(f),
        t.bgOverlay.add(t.wrap).prependTo(t.st.prependTo || e(document.body)),
        (t._lastFocusedEl = document.activeElement),
        setTimeout(function () {
          t.content
            ? (t._addClassToMFP(v), t._setFocus())
            : t.bgOverlay.addClass(v),
            i.on('focusin' + g, t._onFocusIn);
        }, 16),
        (t.isOpen = !0),
        t.updateSize(d),
        k(p),
        n
      );
    },
    close: function () {
      t.isOpen &&
        (k(l),
        (t.isOpen = !1),
        t.st.removalDelay && !t.isLowIE && t.supportsTransition
          ? (t._addClassToMFP(h),
            setTimeout(function () {
              t._close();
            }, t.st.removalDelay))
          : t._close());
    },
    _close: function () {
      k(s);
      var n = h + ' ' + v + ' ';
      if (
        (t.bgOverlay.detach(),
        t.wrap.detach(),
        t.container.empty(),
        t.st.mainClass && (n += t.st.mainClass + ' '),
        t._removeClassFromMFP(n),
        t.fixedContentPos)
      ) {
        var o = { marginRight: '' };
        t.isIE7 ? e('body, html').css('overflow', '') : (o.overflow = ''),
          e('html').css(o);
      }
      i.off('keyup' + g + ' focusin' + g),
        t.ev.off(g),
        t.wrap.attr('class', 'mfp-wrap').removeAttr('style'),
        t.bgOverlay.attr('class', 'mfp-bg'),
        t.container.attr('class', 'mfp-container'),
        !t.st.showCloseBtn ||
          (t.st.closeBtnInside && t.currTemplate[t.currItem.type] !== !0) ||
          (t.currTemplate.closeBtn && t.currTemplate.closeBtn.detach()),
        t.st.autoFocusLast && t._lastFocusedEl && e(t._lastFocusedEl).focus(),
        (t.currItem = null),
        (t.content = null),
        (t.currTemplate = null),
        (t.prevHeight = 0),
        k(c);
    },
    updateSize: function (e) {
      if (t.isIOS) {
        var n = document.documentElement.clientWidth / window.innerWidth,
          i = window.innerHeight * n;
        t.wrap.css('height', i), (t.wH = i);
      } else t.wH = e || b.height();
      t.fixedContentPos || t.wrap.css('height', t.wH), k('Resize');
    },
    updateItemHTML: function () {
      var n = t.items[t.index];
      t.contentContainer.detach(),
        t.content && t.content.detach(),
        n.parsed || (n = t.parseEl(t.index));
      var i = n.type;
      if (
        (k('BeforeChange', [t.currItem ? t.currItem.type : '', i]),
        (t.currItem = n),
        !t.currTemplate[i])
      ) {
        var r = !!t.st[i] && t.st[i].markup;
        k('FirstMarkupParse', r),
          r ? (t.currTemplate[i] = e(r)) : (t.currTemplate[i] = !0);
      }
      o && o !== n.type && t.container.removeClass('mfp-' + o + '-holder');
      var a = t['get' + i.charAt(0).toUpperCase() + i.slice(1)](
        n,
        t.currTemplate[i]
      );
      t.appendContent(a, i),
        (n.preloaded = !0),
        k(f, n),
        (o = n.type),
        t.container.prepend(t.contentContainer),
        k('AfterChange');
    },
    appendContent: function (e, n) {
      (t.content = e),
        e
          ? t.st.showCloseBtn && t.st.closeBtnInside && t.currTemplate[n] === !0
            ? t.content.find('.mfp-close').length || t.content.append(T())
            : (t.content = e)
          : (t.content = ''),
        k(d),
        t.container.addClass('mfp-' + n + '-holder'),
        t.contentContainer.append(t.content);
    },
    parseEl: function (n) {
      var i,
        o = t.items[n];
      if (
        (o.tagName
          ? (o = { el: e(o) })
          : ((i = o.type), (o = { data: o, src: o.src })),
        o.el)
      ) {
        for (var r = t.types, a = 0; a < r.length; a++)
          if (o.el.hasClass('mfp-' + r[a])) {
            i = r[a];
            break;
          }
        (o.src = o.el.attr('data-mfp-src')),
          o.src || (o.src = o.el.attr('href'));
      }
      return (
        (o.type = i || t.st.type || 'inline'),
        (o.index = n),
        (o.parsed = !0),
        (t.items[n] = o),
        k('ElementParse', o),
        t.items[n]
      );
    },
    addGroup: function (e, n) {
      var i = function (i) {
        (i.mfpEl = this), t._openClick(i, e, n);
      };
      n || (n = {});
      var o = 'click.magnificPopup';
      (n.mainEl = e),
        n.items
          ? ((n.isObj = !0), e.off(o).on(o, i))
          : ((n.isObj = !1),
            n.delegate
              ? e.off(o).on(o, n.delegate, i)
              : ((n.items = e), e.off(o).on(o, i)));
    },
    _openClick: function (n, i, o) {
      var r =
        void 0 !== o.midClick ? o.midClick : e.magnificPopup.defaults.midClick;
      if (
        r ||
        !(2 === n.which || n.ctrlKey || n.metaKey || n.altKey || n.shiftKey)
      ) {
        var a =
          void 0 !== o.disableOn
            ? o.disableOn
            : e.magnificPopup.defaults.disableOn;
        if (a)
          if (e.isFunction(a)) {
            if (!a.call(t)) return !0;
          } else if (b.width() < a) return !0;
        n.type && (n.preventDefault(), t.isOpen && n.stopPropagation()),
          (o.el = e(n.mfpEl)),
          o.delegate && (o.items = i.find(o.delegate)),
          t.open(o);
      }
    },
    updateStatus: function (e, i) {
      if (t.preloader) {
        n !== e && t.container.removeClass('mfp-s-' + n),
          i || 'loading' !== e || (i = t.st.tLoading);
        var o = { status: e, text: i };
        k('UpdateStatus', o),
          (e = o.status),
          (i = o.text),
          t.preloader.html(i),
          t.preloader.find('a').on('click', function (e) {
            e.stopImmediatePropagation();
          }),
          t.container.addClass('mfp-s-' + e),
          (n = e);
      }
    },
    _checkIfClose: function (n) {
      if (!e(n).hasClass(y)) {
        var i = t.st.closeOnContentClick,
          o = t.st.closeOnBgClick;
        if (i && o) return !0;
        if (
          !t.content ||
          e(n).hasClass('mfp-close') ||
          (t.preloader && n === t.preloader[0])
        )
          return !0;
        if (n === t.content[0] || e.contains(t.content[0], n)) {
          if (i) return !0;
        } else if (o && e.contains(document, n)) return !0;
        return !1;
      }
    },
    _addClassToMFP: function (e) {
      t.bgOverlay.addClass(e), t.wrap.addClass(e);
    },
    _removeClassFromMFP: function (e) {
      this.bgOverlay.removeClass(e), t.wrap.removeClass(e);
    },
    _hasScrollBar: function (e) {
      return (
        (t.isIE7 ? i.height() : document.body.scrollHeight) > (e || b.height())
      );
    },
    _setFocus: function () {
      (t.st.focus ? t.content.find(t.st.focus).eq(0) : t.wrap).focus();
    },
    _onFocusIn: function (n) {
      if (n.target !== t.wrap[0] && !e.contains(t.wrap[0], n.target))
        return t._setFocus(), !1;
    },
    _parseMarkup: function (t, n, i) {
      var o;
      i.data && (n = e.extend(i.data, n)),
        k(u, [t, n, i]),
        e.each(n, function (n, i) {
          if (void 0 === i || i === !1) return !0;
          if (((o = n.split('_')), o.length > 1)) {
            var r = t.find(g + '-' + o[0]);
            if (r.length > 0) {
              var a = o[1];
              'replaceWith' === a
                ? r[0] !== i[0] && r.replaceWith(i)
                : 'img' === a
                ? r.is('img')
                  ? r.attr('src', i)
                  : r.replaceWith(
                      e('<img>').attr('src', i).attr('class', r.attr('class'))
                    )
                : r.attr(o[1], i);
            }
          } else t.find(g + '-' + n).html(i);
        });
    },
    _getScrollbarSize: function () {
      if (void 0 === t.scrollbarSize) {
        var e = document.createElement('div');
        (e.style.cssText =
          'width: 99px; height: 99px; overflow: scroll; position: absolute; top: -9999px;'),
          document.body.appendChild(e),
          (t.scrollbarSize = e.offsetWidth - e.clientWidth),
          document.body.removeChild(e);
      }
      return t.scrollbarSize;
    },
  }),
    (e.magnificPopup = {
      instance: null,
      proto: C.prototype,
      modules: [],
      open: function (t, n) {
        return (
          _(),
          (t = t ? e.extend(!0, {}, t) : {}),
          (t.isObj = !0),
          (t.index = n || 0),
          this.instance.open(t)
        );
      },
      close: function () {
        return e.magnificPopup.instance && e.magnificPopup.instance.close();
      },
      registerModule: function (t, n) {
        n.options && (e.magnificPopup.defaults[t] = n.options),
          e.extend(this.proto, n.proto),
          this.modules.push(t);
      },
      defaults: {
        disableOn: 0,
        key: null,
        midClick: !1,
        mainClass: '',
        preloader: !0,
        focus: '',
        closeOnContentClick: !1,
        closeOnBgClick: !0,
        closeBtnInside: !0,
        showCloseBtn: !0,
        enableEscapeKey: !0,
        modal: !1,
        alignTop: !1,
        removalDelay: 0,
        prependTo: null,
        fixedContentPos: 'auto',
        fixedBgPos: 'auto',
        overflowY: 'auto',
        closeMarkup:
          '<button title="%title%" type="button" class="mfp-close">&#215;</button>',
        tClose: 'Close (Esc)',
        tLoading: 'Loading...',
        autoFocusLast: !0,
      },
    }),
    (e.fn.magnificPopup = function (n) {
      _();
      var i = e(this);
      if ('string' == typeof n)
        if ('open' === n) {
          var o,
            r = w ? i.data('magnificPopup') : i[0].magnificPopup,
            a = parseInt(arguments[1], 10) || 0;
          r.items
            ? (o = r.items[a])
            : ((o = i), r.delegate && (o = o.find(r.delegate)), (o = o.eq(a))),
            t._openClick({ mfpEl: o }, i, r);
        } else
          t.isOpen && t[n].apply(t, Array.prototype.slice.call(arguments, 1));
      else
        (n = e.extend(!0, {}, n)),
          w ? i.data('magnificPopup', n) : (i[0].magnificPopup = n),
          t.addGroup(i, n);
      return i;
    });
  var S,
    E,
    z,
    O = 'inline',
    M = function () {
      z && (E.after(z.addClass(S)).detach(), (z = null));
    };
  e.magnificPopup.registerModule(O, {
    options: {
      hiddenClass: 'hide',
      markup: '',
      tNotFound: 'Content not found',
    },
    proto: {
      initInline: function () {
        t.types.push(O),
          I(s + '.' + O, function () {
            M();
          });
      },
      getInline: function (n, i) {
        if ((M(), n.src)) {
          var o = t.st.inline,
            r = e(n.src);
          if (r.length) {
            var a = r[0].parentNode;
            a &&
              a.tagName &&
              (E || ((S = o.hiddenClass), (E = x(S)), (S = 'mfp-' + S)),
              (z = r.after(E).detach().removeClass(S))),
              t.updateStatus('ready');
          } else t.updateStatus('error', o.tNotFound), (r = e('<div>'));
          return (n.inlineElement = r), r;
        }
        return t.updateStatus('ready'), t._parseMarkup(i, {}, n), i;
      },
    },
  });
  var B,
    L = 'ajax',
    H = function () {
      B && e(document.body).removeClass(B);
    },
    A = function () {
      H(), t.req && t.req.abort();
    };
  e.magnificPopup.registerModule(L, {
    options: {
      settings: null,
      cursor: 'mfp-ajax-cur',
      tError: '<a href="%url%">The content</a> could not be loaded.',
    },
    proto: {
      initAjax: function () {
        t.types.push(L),
          (B = t.st.ajax.cursor),
          I(s + '.' + L, A),
          I('BeforeChange.' + L, A);
      },
      getAjax: function (n) {
        B && e(document.body).addClass(B), t.updateStatus('loading');
        var i = e.extend(
          {
            url: n.src,
            success: function (i, o, r) {
              var a = { data: i, xhr: r };
              k('ParseAjax', a),
                t.appendContent(e(a.data), L),
                (n.finished = !0),
                H(),
                t._setFocus(),
                setTimeout(function () {
                  t.wrap.addClass(v);
                }, 16),
                t.updateStatus('ready'),
                k('AjaxContentAdded');
            },
            error: function () {
              H(),
                (n.finished = n.loadError = !0),
                t.updateStatus(
                  'error',
                  t.st.ajax.tError.replace('%url%', n.src)
                );
            },
          },
          t.st.ajax.settings
        );
        return (t.req = e.ajax(i)), '';
      },
    },
  });
  var F,
    j = function (n) {
      if (n.data && void 0 !== n.data.title) return n.data.title;
      var i = t.st.image.titleSrc;
      if (i) {
        if (e.isFunction(i)) return i.call(t, n);
        if (n.el) return n.el.attr(i) || '';
      }
      return '';
    };
  e.magnificPopup.registerModule('image', {
    options: {
      markup:
        '<div class="mfp-figure"><div class="mfp-close"></div><figure><div class="mfp-img"></div><figcaption><div class="mfp-bottom-bar"><div class="mfp-title"></div><div class="mfp-counter"></div></div></figcaption></figure></div>',
      cursor: 'mfp-zoom-out-cur',
      titleSrc: 'title',
      verticalFit: !0,
      tError: '<a href="%url%">The image</a> could not be loaded.',
    },
    proto: {
      initImage: function () {
        var n = t.st.image,
          i = '.image';
        t.types.push('image'),
          I(p + i, function () {
            'image' === t.currItem.type &&
              n.cursor &&
              e(document.body).addClass(n.cursor);
          }),
          I(s + i, function () {
            n.cursor && e(document.body).removeClass(n.cursor),
              b.off('resize' + g);
          }),
          I('Resize' + i, t.resizeImage),
          t.isLowIE && I('AfterChange', t.resizeImage);
      },
      resizeImage: function () {
        var e = t.currItem;
        if (e && e.img && t.st.image.verticalFit) {
          var n = 0;
          t.isLowIE &&
            (n =
              parseInt(e.img.css('padding-top'), 10) +
              parseInt(e.img.css('padding-bottom'), 10)),
            e.img.css('max-height', t.wH - n);
        }
      },
      _onImageHasSize: function (e) {
        e.img &&
          ((e.hasSize = !0),
          F && clearInterval(F),
          (e.isCheckingImgSize = !1),
          k('ImageHasSize', e),
          e.imgHidden &&
            (t.content && t.content.removeClass('mfp-loading'),
            (e.imgHidden = !1)));
      },
      findImageSize: function (e) {
        var n = 0,
          i = e.img[0],
          o = function (r) {
            F && clearInterval(F),
              (F = setInterval(function () {
                return i.naturalWidth > 0
                  ? void t._onImageHasSize(e)
                  : (n > 200 && clearInterval(F),
                    n++,
                    void (3 === n
                      ? o(10)
                      : 40 === n
                      ? o(50)
                      : 100 === n && o(500)));
              }, r));
          };
        o(1);
      },
      getImage: function (n, i) {
        var o = 0,
          r = function () {
            n &&
              (n.img[0].complete
                ? (n.img.off('.mfploader'),
                  n === t.currItem &&
                    (t._onImageHasSize(n), t.updateStatus('ready')),
                  (n.hasSize = !0),
                  (n.loaded = !0),
                  k('ImageLoadComplete'))
                : (o++, o < 200 ? setTimeout(r, 100) : a()));
          },
          a = function () {
            n &&
              (n.img.off('.mfploader'),
              n === t.currItem &&
                (t._onImageHasSize(n),
                t.updateStatus('error', s.tError.replace('%url%', n.src))),
              (n.hasSize = !0),
              (n.loaded = !0),
              (n.loadError = !0));
          },
          s = t.st.image,
          l = i.find('.mfp-img');
        if (l.length) {
          var c = document.createElement('img');
          (c.className = 'mfp-img'),
            n.el &&
              n.el.find('img').length &&
              (c.alt = n.el.find('img').attr('alt')),
            (n.img = e(c).on('load.mfploader', r).on('error.mfploader', a)),
            (c.src = n.src),
            l.is('img') && (n.img = n.img.clone()),
            (c = n.img[0]),
            c.naturalWidth > 0 ? (n.hasSize = !0) : c.width || (n.hasSize = !1);
        }
        return (
          t._parseMarkup(i, { title: j(n), img_replaceWith: n.img }, n),
          t.resizeImage(),
          n.hasSize
            ? (F && clearInterval(F),
              n.loadError
                ? (i.addClass('mfp-loading'),
                  t.updateStatus('error', s.tError.replace('%url%', n.src)))
                : (i.removeClass('mfp-loading'), t.updateStatus('ready')),
              i)
            : (t.updateStatus('loading'),
              (n.loading = !0),
              n.hasSize ||
                ((n.imgHidden = !0),
                i.addClass('mfp-loading'),
                t.findImageSize(n)),
              i)
        );
      },
    },
  });
  var N,
    W = function () {
      return (
        void 0 === N &&
          (N = void 0 !== document.createElement('p').style.MozTransform),
        N
      );
    };
  e.magnificPopup.registerModule('zoom', {
    options: {
      enabled: !1,
      easing: 'ease-in-out',
      duration: 300,
      opener: function (e) {
        return e.is('img') ? e : e.find('img');
      },
    },
    proto: {
      initZoom: function () {
        var e,
          n = t.st.zoom,
          i = '.zoom';
        if (n.enabled && t.supportsTransition) {
          var o,
            r,
            a = n.duration,
            c = function (e) {
              var t = e
                  .clone()
                  .removeAttr('style')
                  .removeAttr('class')
                  .addClass('mfp-animated-image'),
                i = 'all ' + n.duration / 1e3 + 's ' + n.easing,
                o = {
                  position: 'fixed',
                  zIndex: 9999,
                  left: 0,
                  top: 0,
                  '-webkit-backface-visibility': 'hidden',
                },
                r = 'transition';
              return (
                (o['-webkit-' + r] = o['-moz-' + r] = o['-o-' + r] = o[r] = i),
                t.css(o),
                t
              );
            },
            d = function () {
              t.content.css('visibility', 'visible');
            };
          I('BuildControls' + i, function () {
            if (t._allowZoom()) {
              if (
                (clearTimeout(o),
                t.content.css('visibility', 'hidden'),
                (e = t._getItemToZoom()),
                !e)
              )
                return void d();
              (r = c(e)),
                r.css(t._getOffset()),
                t.wrap.append(r),
                (o = setTimeout(function () {
                  r.css(t._getOffset(!0)),
                    (o = setTimeout(function () {
                      d(),
                        setTimeout(function () {
                          r.remove(), (e = r = null), k('ZoomAnimationEnded');
                        }, 16);
                    }, a));
                }, 16));
            }
          }),
            I(l + i, function () {
              if (t._allowZoom()) {
                if ((clearTimeout(o), (t.st.removalDelay = a), !e)) {
                  if (((e = t._getItemToZoom()), !e)) return;
                  r = c(e);
                }
                r.css(t._getOffset(!0)),
                  t.wrap.append(r),
                  t.content.css('visibility', 'hidden'),
                  setTimeout(function () {
                    r.css(t._getOffset());
                  }, 16);
              }
            }),
            I(s + i, function () {
              t._allowZoom() && (d(), r && r.remove(), (e = null));
            });
        }
      },
      _allowZoom: function () {
        return 'image' === t.currItem.type;
      },
      _getItemToZoom: function () {
        return !!t.currItem.hasSize && t.currItem.img;
      },
      _getOffset: function (n) {
        var i;
        i = n ? t.currItem.img : t.st.zoom.opener(t.currItem.el || t.currItem);
        var o = i.offset(),
          r = parseInt(i.css('padding-top'), 10),
          a = parseInt(i.css('padding-bottom'), 10);
        o.top -= e(window).scrollTop() - r;
        var s = {
          width: i.width(),
          height: (w ? i.innerHeight() : i[0].offsetHeight) - a - r,
        };
        return (
          W()
            ? (s['-moz-transform'] = s.transform =
                'translate(' + o.left + 'px,' + o.top + 'px)')
            : ((s.left = o.left), (s.top = o.top)),
          s
        );
      },
    },
  });
  var Z = 'iframe',
    q = '//about:blank',
    R = function (e) {
      if (t.currTemplate[Z]) {
        var n = t.currTemplate[Z].find('iframe');
        n.length &&
          (e || (n[0].src = q),
          t.isIE8 && n.css('display', e ? 'block' : 'none'));
      }
    };
  e.magnificPopup.registerModule(Z, {
    options: {
      markup:
        '<div class="mfp-iframe-scaler"><div class="mfp-close"></div><iframe class="mfp-iframe" src="//about:blank" frameborder="0" allowfullscreen></iframe></div>',
      srcAction: 'iframe_src',
      patterns: {
        youtube: {
          index: 'youtube.com',
          id: 'v=',
          src: '//www.youtube.com/embed/%id%?autoplay=1',
        },
        vimeo: {
          index: 'vimeo.com/',
          id: '/',
          src: '//player.vimeo.com/video/%id%?autoplay=1',
        },
        gmaps: { index: '//maps.google.', src: '%id%&output=embed' },
      },
    },
    proto: {
      initIframe: function () {
        t.types.push(Z),
          I('BeforeChange', function (e, t, n) {
            t !== n && (t === Z ? R() : n === Z && R(!0));
          }),
          I(s + '.' + Z, function () {
            R();
          });
      },
      getIframe: function (n, i) {
        var o = n.src,
          r = t.st.iframe;
        e.each(r.patterns, function () {
          if (o.indexOf(this.index) > -1)
            return (
              this.id &&
                (o =
                  'string' == typeof this.id
                    ? o.substr(
                        o.lastIndexOf(this.id) + this.id.length,
                        o.length
                      )
                    : this.id.call(this, o)),
              (o = this.src.replace('%id%', o)),
              !1
            );
        });
        var a = {};
        return (
          r.srcAction && (a[r.srcAction] = o),
          t._parseMarkup(i, a, n),
          t.updateStatus('ready'),
          i
        );
      },
    },
  });
  var K = function (e) {
      var n = t.items.length;
      return e > n - 1 ? e - n : e < 0 ? n + e : e;
    },
    D = function (e, t, n) {
      return e.replace(/%curr%/gi, t + 1).replace(/%total%/gi, n);
    };
  e.magnificPopup.registerModule('gallery', {
    options: {
      enabled: !1,
      arrowMarkup:
        '<button title="%title%" type="button" class="mfp-arrow mfp-arrow-%dir%"></button>',
      preload: [0, 2],
      navigateByImgClick: !0,
      arrows: !0,
      tPrev: 'Previous (Left arrow key)',
      tNext: 'Next (Right arrow key)',
      tCounter: '%curr% of %total%',
    },
    proto: {
      initGallery: function () {
        var n = t.st.gallery,
          o = '.mfp-gallery';
        return (
          (t.direction = !0),
          !(!n || !n.enabled) &&
            ((r += ' mfp-gallery'),
            I(p + o, function () {
              n.navigateByImgClick &&
                t.wrap.on('click' + o, '.mfp-img', function () {
                  if (t.items.length > 1) return t.next(), !1;
                }),
                i.on('keydown' + o, function (e) {
                  37 === e.keyCode ? t.prev() : 39 === e.keyCode && t.next();
                });
            }),
            I('UpdateStatus' + o, function (e, n) {
              n.text && (n.text = D(n.text, t.currItem.index, t.items.length));
            }),
            I(u + o, function (e, i, o, r) {
              var a = t.items.length;
              o.counter = a > 1 ? D(n.tCounter, r.index, a) : '';
            }),
            I('BuildControls' + o, function () {
              if (t.items.length > 1 && n.arrows && !t.arrowLeft) {
                var i = n.arrowMarkup,
                  o = (t.arrowLeft = e(
                    i.replace(/%title%/gi, n.tPrev).replace(/%dir%/gi, 'left')
                  ).addClass(y)),
                  r = (t.arrowRight = e(
                    i.replace(/%title%/gi, n.tNext).replace(/%dir%/gi, 'right')
                  ).addClass(y));
                o.click(function () {
                  t.prev();
                }),
                  r.click(function () {
                    t.next();
                  }),
                  t.container.append(o.add(r));
              }
            }),
            I(f + o, function () {
              t._preloadTimeout && clearTimeout(t._preloadTimeout),
                (t._preloadTimeout = setTimeout(function () {
                  t.preloadNearbyImages(), (t._preloadTimeout = null);
                }, 16));
            }),
            void I(s + o, function () {
              i.off(o),
                t.wrap.off('click' + o),
                (t.arrowRight = t.arrowLeft = null);
            }))
        );
      },
      next: function () {
        (t.direction = !0), (t.index = K(t.index + 1)), t.updateItemHTML();
      },
      prev: function () {
        (t.direction = !1), (t.index = K(t.index - 1)), t.updateItemHTML();
      },
      goTo: function (e) {
        (t.direction = e >= t.index), (t.index = e), t.updateItemHTML();
      },
      preloadNearbyImages: function () {
        var e,
          n = t.st.gallery.preload,
          i = Math.min(n[0], t.items.length),
          o = Math.min(n[1], t.items.length);
        for (e = 1; e <= (t.direction ? o : i); e++)
          t._preloadItem(t.index + e);
        for (e = 1; e <= (t.direction ? i : o); e++)
          t._preloadItem(t.index - e);
      },
      _preloadItem: function (n) {
        if (((n = K(n)), !t.items[n].preloaded)) {
          var i = t.items[n];
          i.parsed || (i = t.parseEl(n)),
            k('LazyLoad', i),
            'image' === i.type &&
              (i.img = e('<img class="mfp-img" />')
                .on('load.mfploader', function () {
                  i.hasSize = !0;
                })
                .on('error.mfploader', function () {
                  (i.hasSize = !0), (i.loadError = !0), k('LazyLoadError', i);
                })
                .attr('src', i.src)),
            (i.preloaded = !0);
        }
      },
    },
  });
  var U = 'retina';
  e.magnificPopup.registerModule(U, {
    options: {
      replaceSrc: function (e) {
        return e.src.replace(/\.\w+$/, function (e) {
          return '@2x' + e;
        });
      },
      ratio: 1,
    },
    proto: {
      initRetina: function () {
        if (window.devicePixelRatio > 1) {
          var e = t.st.retina,
            n = e.ratio;
          (n = isNaN(n) ? n() : n),
            n > 1 &&
              (I('ImageHasSize.' + U, function (e, t) {
                t.img.css({
                  'max-width': t.img[0].naturalWidth / n,
                  width: '100%',
                });
              }),
              I('ElementParse.' + U, function (t, i) {
                i.src = e.replaceSrc(i, n);
              }));
        }
      },
    },
  }),
    _();
});
!(function (e, t) {
  'object' == typeof exports && 'object' == typeof module
    ? (module.exports = t())
    : 'function' == typeof define && define.amd
    ? define([], t)
    : 'object' == typeof exports
    ? (exports.AOS = t())
    : (e.AOS = t());
})(this, function () {
  return (function (e) {
    function t(o) {
      if (n[o]) return n[o].exports;
      var i = (n[o] = { exports: {}, id: o, loaded: !1 });
      return e[o].call(i.exports, i, i.exports, t), (i.loaded = !0), i.exports;
    }
    var n = {};
    return (t.m = e), (t.c = n), (t.p = 'dist/'), t(0);
  })([
    function (e, t, n) {
      'use strict';
      function o(e) {
        return e && e.__esModule ? e : { default: e };
      }
      var i =
          Object.assign ||
          function (e) {
            for (var t = 1; t < arguments.length; t++) {
              var n = arguments[t];
              for (var o in n)
                Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
            }
            return e;
          },
        r = n(1),
        a = (o(r), n(6)),
        u = o(a),
        c = n(7),
        s = o(c),
        f = n(8),
        d = o(f),
        l = n(9),
        p = o(l),
        m = n(10),
        b = o(m),
        v = n(11),
        y = o(v),
        g = n(14),
        h = o(g),
        w = [],
        k = !1,
        x = {
          offset: 120,
          delay: 0,
          easing: 'ease',
          duration: 400,
          disable: !1,
          once: !1,
          startEvent: 'DOMContentLoaded',
          throttleDelay: 99,
          debounceDelay: 50,
          disableMutationObserver: !1,
        },
        j = function () {
          var e =
            arguments.length > 0 && void 0 !== arguments[0] && arguments[0];
          if ((e && (k = !0), k))
            return (
              (w = (0, y['default'])(w, x)), (0, b['default'])(w, x.once), w
            );
        },
        O = function () {
          (w = (0, h['default'])()), j();
        },
        M = function () {
          w.forEach(function (e, t) {
            e.node.removeAttribute('data-aos'),
              e.node.removeAttribute('data-aos-easing'),
              e.node.removeAttribute('data-aos-duration'),
              e.node.removeAttribute('data-aos-delay');
          });
        },
        S = function (e) {
          return (
            e === !0 ||
            ('mobile' === e && p['default'].mobile()) ||
            ('phone' === e && p['default'].phone()) ||
            ('tablet' === e && p['default'].tablet()) ||
            ('function' == typeof e && e() === !0)
          );
        },
        _ = function (e) {
          (x = i(x, e)), (w = (0, h['default'])());
          var t = document.all && !window.atob;
          return S(x.disable) || t
            ? M()
            : (x.disableMutationObserver ||
                d['default'].isSupported() ||
                (console.info(
                  '\n      aos: MutationObserver is not supported on this browser,\n      code mutations observing has been disabled.\n      You may have to call "refreshHard()" by yourself.\n    '
                ),
                (x.disableMutationObserver = !0)),
              document
                .querySelector('body')
                .setAttribute('data-aos-easing', x.easing),
              document
                .querySelector('body')
                .setAttribute('data-aos-duration', x.duration),
              document
                .querySelector('body')
                .setAttribute('data-aos-delay', x.delay),
              'DOMContentLoaded' === x.startEvent &&
              ['complete', 'interactive'].indexOf(document.readyState) > -1
                ? j(!0)
                : 'load' === x.startEvent
                ? window.addEventListener(x.startEvent, function () {
                    j(!0);
                  })
                : document.addEventListener(x.startEvent, function () {
                    j(!0);
                  }),
              window.addEventListener(
                'resize',
                (0, s['default'])(j, x.debounceDelay, !0)
              ),
              window.addEventListener(
                'orientationchange',
                (0, s['default'])(j, x.debounceDelay, !0)
              ),
              window.addEventListener(
                'scroll',
                (0, u['default'])(function () {
                  (0, b['default'])(w, x.once);
                }, x.throttleDelay)
              ),
              x.disableMutationObserver || d['default'].ready('[data-aos]', O),
              w);
        };
      e.exports = { init: _, refresh: j, refreshHard: O };
    },
    function (e, t) {},
    ,
    ,
    ,
    ,
    function (e, t) {
      (function (t) {
        'use strict';
        function n(e, t, n) {
          function o(t) {
            var n = b,
              o = v;
            return (b = v = void 0), (k = t), (g = e.apply(o, n));
          }
          function r(e) {
            return (k = e), (h = setTimeout(f, t)), M ? o(e) : g;
          }
          function a(e) {
            var n = e - w,
              o = e - k,
              i = t - n;
            return S ? j(i, y - o) : i;
          }
          function c(e) {
            var n = e - w,
              o = e - k;
            return void 0 === w || n >= t || n < 0 || (S && o >= y);
          }
          function f() {
            var e = O();
            return c(e) ? d(e) : void (h = setTimeout(f, a(e)));
          }
          function d(e) {
            return (h = void 0), _ && b ? o(e) : ((b = v = void 0), g);
          }
          function l() {
            void 0 !== h && clearTimeout(h), (k = 0), (b = w = v = h = void 0);
          }
          function p() {
            return void 0 === h ? g : d(O());
          }
          function m() {
            var e = O(),
              n = c(e);
            if (((b = arguments), (v = this), (w = e), n)) {
              if (void 0 === h) return r(w);
              if (S) return (h = setTimeout(f, t)), o(w);
            }
            return void 0 === h && (h = setTimeout(f, t)), g;
          }
          var b,
            v,
            y,
            g,
            h,
            w,
            k = 0,
            M = !1,
            S = !1,
            _ = !0;
          if ('function' != typeof e) throw new TypeError(s);
          return (
            (t = u(t) || 0),
            i(n) &&
              ((M = !!n.leading),
              (S = 'maxWait' in n),
              (y = S ? x(u(n.maxWait) || 0, t) : y),
              (_ = 'trailing' in n ? !!n.trailing : _)),
            (m.cancel = l),
            (m.flush = p),
            m
          );
        }
        function o(e, t, o) {
          var r = !0,
            a = !0;
          if ('function' != typeof e) throw new TypeError(s);
          return (
            i(o) &&
              ((r = 'leading' in o ? !!o.leading : r),
              (a = 'trailing' in o ? !!o.trailing : a)),
            n(e, t, { leading: r, maxWait: t, trailing: a })
          );
        }
        function i(e) {
          var t = 'undefined' == typeof e ? 'undefined' : c(e);
          return !!e && ('object' == t || 'function' == t);
        }
        function r(e) {
          return (
            !!e && 'object' == ('undefined' == typeof e ? 'undefined' : c(e))
          );
        }
        function a(e) {
          return (
            'symbol' == ('undefined' == typeof e ? 'undefined' : c(e)) ||
            (r(e) && k.call(e) == d)
          );
        }
        function u(e) {
          if ('number' == typeof e) return e;
          if (a(e)) return f;
          if (i(e)) {
            var t = 'function' == typeof e.valueOf ? e.valueOf() : e;
            e = i(t) ? t + '' : t;
          }
          if ('string' != typeof e) return 0 === e ? e : +e;
          e = e.replace(l, '');
          var n = m.test(e);
          return n || b.test(e) ? v(e.slice(2), n ? 2 : 8) : p.test(e) ? f : +e;
        }
        var c =
            'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
              ? function (e) {
                  return typeof e;
                }
              : function (e) {
                  return e &&
                    'function' == typeof Symbol &&
                    e.constructor === Symbol &&
                    e !== Symbol.prototype
                    ? 'symbol'
                    : typeof e;
                },
          s = 'Expected a function',
          f = NaN,
          d = '[object Symbol]',
          l = /^\s+|\s+$/g,
          p = /^[-+]0x[0-9a-f]+$/i,
          m = /^0b[01]+$/i,
          b = /^0o[0-7]+$/i,
          v = parseInt,
          y =
            'object' == ('undefined' == typeof t ? 'undefined' : c(t)) &&
            t &&
            t.Object === Object &&
            t,
          g =
            'object' == ('undefined' == typeof self ? 'undefined' : c(self)) &&
            self &&
            self.Object === Object &&
            self,
          h = y || g || Function('return this')(),
          w = Object.prototype,
          k = w.toString,
          x = Math.max,
          j = Math.min,
          O = function () {
            return h.Date.now();
          };
        e.exports = o;
      }).call(
        t,
        (function () {
          return this;
        })()
      );
    },
    function (e, t) {
      (function (t) {
        'use strict';
        function n(e, t, n) {
          function i(t) {
            var n = b,
              o = v;
            return (b = v = void 0), (O = t), (g = e.apply(o, n));
          }
          function r(e) {
            return (O = e), (h = setTimeout(f, t)), M ? i(e) : g;
          }
          function u(e) {
            var n = e - w,
              o = e - O,
              i = t - n;
            return S ? x(i, y - o) : i;
          }
          function s(e) {
            var n = e - w,
              o = e - O;
            return void 0 === w || n >= t || n < 0 || (S && o >= y);
          }
          function f() {
            var e = j();
            return s(e) ? d(e) : void (h = setTimeout(f, u(e)));
          }
          function d(e) {
            return (h = void 0), _ && b ? i(e) : ((b = v = void 0), g);
          }
          function l() {
            void 0 !== h && clearTimeout(h), (O = 0), (b = w = v = h = void 0);
          }
          function p() {
            return void 0 === h ? g : d(j());
          }
          function m() {
            var e = j(),
              n = s(e);
            if (((b = arguments), (v = this), (w = e), n)) {
              if (void 0 === h) return r(w);
              if (S) return (h = setTimeout(f, t)), i(w);
            }
            return void 0 === h && (h = setTimeout(f, t)), g;
          }
          var b,
            v,
            y,
            g,
            h,
            w,
            O = 0,
            M = !1,
            S = !1,
            _ = !0;
          if ('function' != typeof e) throw new TypeError(c);
          return (
            (t = a(t) || 0),
            o(n) &&
              ((M = !!n.leading),
              (S = 'maxWait' in n),
              (y = S ? k(a(n.maxWait) || 0, t) : y),
              (_ = 'trailing' in n ? !!n.trailing : _)),
            (m.cancel = l),
            (m.flush = p),
            m
          );
        }
        function o(e) {
          var t = 'undefined' == typeof e ? 'undefined' : u(e);
          return !!e && ('object' == t || 'function' == t);
        }
        function i(e) {
          return (
            !!e && 'object' == ('undefined' == typeof e ? 'undefined' : u(e))
          );
        }
        function r(e) {
          return (
            'symbol' == ('undefined' == typeof e ? 'undefined' : u(e)) ||
            (i(e) && w.call(e) == f)
          );
        }
        function a(e) {
          if ('number' == typeof e) return e;
          if (r(e)) return s;
          if (o(e)) {
            var t = 'function' == typeof e.valueOf ? e.valueOf() : e;
            e = o(t) ? t + '' : t;
          }
          if ('string' != typeof e) return 0 === e ? e : +e;
          e = e.replace(d, '');
          var n = p.test(e);
          return n || m.test(e) ? b(e.slice(2), n ? 2 : 8) : l.test(e) ? s : +e;
        }
        var u =
            'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
              ? function (e) {
                  return typeof e;
                }
              : function (e) {
                  return e &&
                    'function' == typeof Symbol &&
                    e.constructor === Symbol &&
                    e !== Symbol.prototype
                    ? 'symbol'
                    : typeof e;
                },
          c = 'Expected a function',
          s = NaN,
          f = '[object Symbol]',
          d = /^\s+|\s+$/g,
          l = /^[-+]0x[0-9a-f]+$/i,
          p = /^0b[01]+$/i,
          m = /^0o[0-7]+$/i,
          b = parseInt,
          v =
            'object' == ('undefined' == typeof t ? 'undefined' : u(t)) &&
            t &&
            t.Object === Object &&
            t,
          y =
            'object' == ('undefined' == typeof self ? 'undefined' : u(self)) &&
            self &&
            self.Object === Object &&
            self,
          g = v || y || Function('return this')(),
          h = Object.prototype,
          w = h.toString,
          k = Math.max,
          x = Math.min,
          j = function () {
            return g.Date.now();
          };
        e.exports = n;
      }).call(
        t,
        (function () {
          return this;
        })()
      );
    },
    function (e, t) {
      'use strict';
      function n(e) {
        var t = void 0,
          o = void 0,
          i = void 0;
        for (t = 0; t < e.length; t += 1) {
          if (((o = e[t]), o.dataset && o.dataset.aos)) return !0;
          if ((i = o.children && n(o.children))) return !0;
        }
        return !1;
      }
      function o() {
        return (
          window.MutationObserver ||
          window.WebKitMutationObserver ||
          window.MozMutationObserver
        );
      }
      function i() {
        return !!o();
      }
      function r(e, t) {
        var n = window.document,
          i = o(),
          r = new i(a);
        (u = t),
          r.observe(n.documentElement, {
            childList: !0,
            subtree: !0,
            removedNodes: !0,
          });
      }
      function a(e) {
        e &&
          e.forEach(function (e) {
            var t = Array.prototype.slice.call(e.addedNodes),
              o = Array.prototype.slice.call(e.removedNodes),
              i = t.concat(o);
            if (n(i)) return u();
          });
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var u = function () {};
      t['default'] = { isSupported: i, ready: r };
    },
    function (e, t) {
      'use strict';
      function n(e, t) {
        if (!(e instanceof t))
          throw new TypeError('Cannot call a class as a function');
      }
      function o() {
        return navigator.userAgent || navigator.vendor || window.opera || '';
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var i = (function () {
          function e(e, t) {
            for (var n = 0; n < t.length; n++) {
              var o = t[n];
              (o.enumerable = o.enumerable || !1),
                (o.configurable = !0),
                'value' in o && (o.writable = !0),
                Object.defineProperty(e, o.key, o);
            }
          }
          return function (t, n, o) {
            return n && e(t.prototype, n), o && e(t, o), t;
          };
        })(),
        r =
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i,
        a =
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
        u =
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i,
        c =
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i,
        s = (function () {
          function e() {
            n(this, e);
          }
          return (
            i(e, [
              {
                key: 'phone',
                value: function () {
                  var e = o();
                  return !(!r.test(e) && !a.test(e.substr(0, 4)));
                },
              },
              {
                key: 'mobile',
                value: function () {
                  var e = o();
                  return !(!u.test(e) && !c.test(e.substr(0, 4)));
                },
              },
              {
                key: 'tablet',
                value: function () {
                  return this.mobile() && !this.phone();
                },
              },
            ]),
            e
          );
        })();
      t['default'] = new s();
    },
    function (e, t) {
      'use strict';
      Object.defineProperty(t, '__esModule', { value: !0 });
      var n = function (e, t, n) {
          var o = e.node.getAttribute('data-aos-once');
          t > e.position
            ? e.node.classList.add('aos-animate')
            : 'undefined' != typeof o &&
              ('false' === o || (!n && 'true' !== o)) &&
              e.node.classList.remove('aos-animate');
        },
        o = function (e, t) {
          var o = window.pageYOffset,
            i = window.innerHeight;
          e.forEach(function (e, r) {
            n(e, i + o, t);
          });
        };
      t['default'] = o;
    },
    function (e, t, n) {
      'use strict';
      function o(e) {
        return e && e.__esModule ? e : { default: e };
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var i = n(12),
        r = o(i),
        a = function (e, t) {
          return (
            e.forEach(function (e, n) {
              e.node.classList.add('aos-init'),
                (e.position = (0, r['default'])(e.node, t.offset));
            }),
            e
          );
        };
      t['default'] = a;
    },
    function (e, t, n) {
      'use strict';
      function o(e) {
        return e && e.__esModule ? e : { default: e };
      }
      Object.defineProperty(t, '__esModule', { value: !0 });
      var i = n(13),
        r = o(i),
        a = function (e, t) {
          var n = 0,
            o = 0,
            i = window.innerHeight,
            a = {
              offset: e.getAttribute('data-aos-offset'),
              anchor: e.getAttribute('data-aos-anchor'),
              anchorPlacement: e.getAttribute('data-aos-anchor-placement'),
            };
          switch (
            (a.offset && !isNaN(a.offset) && (o = parseInt(a.offset)),
            a.anchor &&
              document.querySelectorAll(a.anchor) &&
              (e = document.querySelectorAll(a.anchor)[0]),
            (n = (0, r['default'])(e).top),
            a.anchorPlacement)
          ) {
            case 'top-bottom':
              break;
            case 'center-bottom':
              n += e.offsetHeight / 2;
              break;
            case 'bottom-bottom':
              n += e.offsetHeight;
              break;
            case 'top-center':
              n += i / 2;
              break;
            case 'bottom-center':
              n += i / 2 + e.offsetHeight;
              break;
            case 'center-center':
              n += i / 2 + e.offsetHeight / 2;
              break;
            case 'top-top':
              n += i;
              break;
            case 'bottom-top':
              n += e.offsetHeight + i;
              break;
            case 'center-top':
              n += e.offsetHeight / 2 + i;
          }
          return a.anchorPlacement || a.offset || isNaN(t) || (o = t), n + o;
        };
      t['default'] = a;
    },
    function (e, t) {
      'use strict';
      Object.defineProperty(t, '__esModule', { value: !0 });
      var n = function (e) {
        for (
          var t = 0, n = 0;
          e && !isNaN(e.offsetLeft) && !isNaN(e.offsetTop);

        )
          (t += e.offsetLeft - ('BODY' != e.tagName ? e.scrollLeft : 0)),
            (n += e.offsetTop - ('BODY' != e.tagName ? e.scrollTop : 0)),
            (e = e.offsetParent);
        return { top: n, left: t };
      };
      t['default'] = n;
    },
    function (e, t) {
      'use strict';
      Object.defineProperty(t, '__esModule', { value: !0 });
      var n = function (e) {
        return (
          (e = e || document.querySelectorAll('[data-aos]')),
          Array.prototype.map.call(e, function (e) {
            return { node: e };
          })
        );
      };
      t['default'] = n;
    },
  ]);
});
!(function (e) {
  'function' == typeof define && define.amd
    ? define(['jquery'], e)
    : 'object' == typeof module && module.exports
    ? (module.exports = function (t, n) {
        return (
          void 0 === n &&
            (n =
              'undefined' != typeof window
                ? require('jquery')
                : require('jquery')(t)),
          e(n),
          n
        );
      })
    : e(jQuery);
})(function (e) {
  var t = (function () {
      if (e && e.fn && e.fn.select2 && e.fn.select2.amd)
        var t = e.fn.select2.amd;
      var t;
      return (
        (function () {
          if (!t || !t.requirejs) {
            t ? (n = t) : (t = {});
            var e, n, r;
            !(function (t) {
              function i(e, t) {
                return w.call(e, t);
              }
              function o(e, t) {
                var n,
                  r,
                  i,
                  o,
                  s,
                  a,
                  l,
                  c,
                  u,
                  d,
                  p,
                  h,
                  f = t && t.split('/'),
                  g = _.map,
                  m = (g && g['*']) || {};
                if (e) {
                  for (
                    e = e.split('/'),
                      s = e.length - 1,
                      _.nodeIdCompat &&
                        A.test(e[s]) &&
                        (e[s] = e[s].replace(A, '')),
                      '.' === e[0].charAt(0) &&
                        f &&
                        ((h = f.slice(0, f.length - 1)), (e = h.concat(e))),
                      u = 0;
                    u < e.length;
                    u++
                  )
                    if (((p = e[u]), '.' === p)) e.splice(u, 1), (u -= 1);
                    else if ('..' === p) {
                      if (
                        0 === u ||
                        (1 === u && '..' === e[2]) ||
                        '..' === e[u - 1]
                      )
                        continue;
                      u > 0 && (e.splice(u - 1, 2), (u -= 2));
                    }
                  e = e.join('/');
                }
                if ((f || m) && g) {
                  for (n = e.split('/'), u = n.length; u > 0; u -= 1) {
                    if (((r = n.slice(0, u).join('/')), f))
                      for (d = f.length; d > 0; d -= 1)
                        if (
                          ((i = g[f.slice(0, d).join('/')]), i && (i = i[r]))
                        ) {
                          (o = i), (a = u);
                          break;
                        }
                    if (o) break;
                    !l && m && m[r] && ((l = m[r]), (c = u));
                  }
                  !o && l && ((o = l), (a = c)),
                    o && (n.splice(0, a, o), (e = n.join('/')));
                }
                return e;
              }
              function s(e, n) {
                return function () {
                  var r = b.call(arguments, 0);
                  return (
                    'string' != typeof r[0] && 1 === r.length && r.push(null),
                    f.apply(t, r.concat([e, n]))
                  );
                };
              }
              function a(e) {
                return function (t) {
                  return o(t, e);
                };
              }
              function l(e) {
                return function (t) {
                  v[e] = t;
                };
              }
              function c(e) {
                if (i(y, e)) {
                  var n = y[e];
                  delete y[e], ($[e] = !0), h.apply(t, n);
                }
                if (!i(v, e) && !i($, e)) throw new Error('No ' + e);
                return v[e];
              }
              function u(e) {
                var t,
                  n = e ? e.indexOf('!') : -1;
                return (
                  n > -1 &&
                    ((t = e.substring(0, n)),
                    (e = e.substring(n + 1, e.length))),
                  [t, e]
                );
              }
              function d(e) {
                return e ? u(e) : [];
              }
              function p(e) {
                return function () {
                  return (_ && _.config && _.config[e]) || {};
                };
              }
              var h,
                f,
                g,
                m,
                v = {},
                y = {},
                _ = {},
                $ = {},
                w = Object.prototype.hasOwnProperty,
                b = [].slice,
                A = /\.js$/;
              (g = function (e, t) {
                var n,
                  r = u(e),
                  i = r[0],
                  s = t[1];
                return (
                  (e = r[1]),
                  i && ((i = o(i, s)), (n = c(i))),
                  i
                    ? (e = n && n.normalize ? n.normalize(e, a(s)) : o(e, s))
                    : ((e = o(e, s)),
                      (r = u(e)),
                      (i = r[0]),
                      (e = r[1]),
                      i && (n = c(i))),
                  { f: i ? i + '!' + e : e, n: e, pr: i, p: n }
                );
              }),
                (m = {
                  require: function (e) {
                    return s(e);
                  },
                  exports: function (e) {
                    var t = v[e];
                    return 'undefined' != typeof t ? t : (v[e] = {});
                  },
                  module: function (e) {
                    return { id: e, uri: '', exports: v[e], config: p(e) };
                  },
                }),
                (h = function (e, n, r, o) {
                  var a,
                    u,
                    p,
                    h,
                    f,
                    _,
                    w,
                    b = [],
                    A = typeof r;
                  if (
                    ((o = o || e),
                    (_ = d(o)),
                    'undefined' === A || 'function' === A)
                  ) {
                    for (
                      n =
                        !n.length && r.length
                          ? ['require', 'exports', 'module']
                          : n,
                        f = 0;
                      f < n.length;
                      f += 1
                    )
                      if (((h = g(n[f], _)), (u = h.f), 'require' === u))
                        b[f] = m.require(e);
                      else if ('exports' === u) (b[f] = m.exports(e)), (w = !0);
                      else if ('module' === u) a = b[f] = m.module(e);
                      else if (i(v, u) || i(y, u) || i($, u)) b[f] = c(u);
                      else {
                        if (!h.p) throw new Error(e + ' missing ' + u);
                        h.p.load(h.n, s(o, !0), l(u), {}), (b[f] = v[u]);
                      }
                    (p = r ? r.apply(v[e], b) : void 0),
                      e &&
                        (a && a.exports !== t && a.exports !== v[e]
                          ? (v[e] = a.exports)
                          : (p === t && w) || (v[e] = p));
                  } else e && (v[e] = r);
                }),
                (e =
                  n =
                  f =
                    function (e, n, r, i, o) {
                      if ('string' == typeof e)
                        return m[e] ? m[e](n) : c(g(e, d(n)).f);
                      if (!e.splice) {
                        if (((_ = e), _.deps && f(_.deps, _.callback), !n))
                          return;
                        n.splice ? ((e = n), (n = r), (r = null)) : (e = t);
                      }
                      return (
                        (n = n || function () {}),
                        'function' == typeof r && ((r = i), (i = o)),
                        i
                          ? h(t, e, n, r)
                          : setTimeout(function () {
                              h(t, e, n, r);
                            }, 4),
                        f
                      );
                    }),
                (f.config = function (e) {
                  return f(e);
                }),
                (e._defined = v),
                (r = function (e, t, n) {
                  if ('string' != typeof e)
                    throw new Error(
                      'See almond README: incorrect module build, no module name'
                    );
                  t.splice || ((n = t), (t = [])),
                    i(v, e) || i(y, e) || (y[e] = [e, t, n]);
                }),
                (r.amd = { jQuery: !0 });
            })(),
              (t.requirejs = e),
              (t.require = n),
              (t.define = r);
          }
        })(),
        t.define('almond', function () {}),
        t.define('jquery', [], function () {
          var t = e || $;
          return (
            null == t &&
              console &&
              console.error &&
              console.error(
                'Select2: An instance of jQuery or a jQuery-compatible library was not found. Make sure that you are including jQuery before Select2 on your web page.'
              ),
            t
          );
        }),
        t.define('select2/utils', ['jquery'], function (e) {
          function t(e) {
            var t = e.prototype,
              n = [];
            for (var r in t) {
              var i = t[r];
              'function' == typeof i && 'constructor' !== r && n.push(r);
            }
            return n;
          }
          var n = {};
          (n.Extend = function (e, t) {
            function n() {
              this.constructor = e;
            }
            var r = {}.hasOwnProperty;
            for (var i in t) r.call(t, i) && (e[i] = t[i]);
            return (
              (n.prototype = t.prototype),
              (e.prototype = new n()),
              (e.__super__ = t.prototype),
              e
            );
          }),
            (n.Decorate = function (e, n) {
              function r() {
                var t = Array.prototype.unshift,
                  r = n.prototype.constructor.length,
                  i = e.prototype.constructor;
                r > 0 &&
                  (t.call(arguments, e.prototype.constructor),
                  (i = n.prototype.constructor)),
                  i.apply(this, arguments);
              }
              function i() {
                this.constructor = r;
              }
              var o = t(n),
                s = t(e);
              (n.displayName = e.displayName), (r.prototype = new i());
              for (var a = 0; a < s.length; a++) {
                var l = s[a];
                r.prototype[l] = e.prototype[l];
              }
              for (
                var c = function (e) {
                    var t = function () {};
                    (e in r.prototype) && (t = r.prototype[e]);
                    var i = n.prototype[e];
                    return function () {
                      var e = Array.prototype.unshift;
                      return e.call(arguments, t), i.apply(this, arguments);
                    };
                  },
                  u = 0;
                u < o.length;
                u++
              ) {
                var d = o[u];
                r.prototype[d] = c(d);
              }
              return r;
            });
          var r = function () {
            this.listeners = {};
          };
          (r.prototype.on = function (e, t) {
            (this.listeners = this.listeners || {}),
              e in this.listeners
                ? this.listeners[e].push(t)
                : (this.listeners[e] = [t]);
          }),
            (r.prototype.trigger = function (e) {
              var t = Array.prototype.slice,
                n = t.call(arguments, 1);
              (this.listeners = this.listeners || {}),
                null == n && (n = []),
                0 === n.length && n.push({}),
                (n[0]._type = e),
                e in this.listeners &&
                  this.invoke(this.listeners[e], t.call(arguments, 1)),
                '*' in this.listeners &&
                  this.invoke(this.listeners['*'], arguments);
            }),
            (r.prototype.invoke = function (e, t) {
              for (var n = 0, r = e.length; n < r; n++) e[n].apply(this, t);
            }),
            (n.Observable = r),
            (n.generateChars = function (e) {
              for (var t = '', n = 0; n < e; n++) {
                var r = Math.floor(36 * Math.random());
                t += r.toString(36);
              }
              return t;
            }),
            (n.bind = function (e, t) {
              return function () {
                e.apply(t, arguments);
              };
            }),
            (n._convertData = function (e) {
              for (var t in e) {
                var n = t.split('-'),
                  r = e;
                if (1 !== n.length) {
                  for (var i = 0; i < n.length; i++) {
                    var o = n[i];
                    (o = o.substring(0, 1).toLowerCase() + o.substring(1)),
                      o in r || (r[o] = {}),
                      i == n.length - 1 && (r[o] = e[t]),
                      (r = r[o]);
                  }
                  delete e[t];
                }
              }
              return e;
            }),
            (n.hasScroll = function (t, n) {
              var r = e(n),
                i = n.style.overflowX,
                o = n.style.overflowY;
              return (
                (i !== o || ('hidden' !== o && 'visible' !== o)) &&
                ('scroll' === i ||
                  'scroll' === o ||
                  r.innerHeight() < n.scrollHeight ||
                  r.innerWidth() < n.scrollWidth)
              );
            }),
            (n.escapeMarkup = function (e) {
              var t = {
                '\\': '&#92;',
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                '"': '&quot;',
                "'": '&#39;',
                '/': '&#47;',
              };
              return 'string' != typeof e
                ? e
                : String(e).replace(/[&<>"'\/\\]/g, function (e) {
                    return t[e];
                  });
            }),
            (n.appendMany = function (t, n) {
              if ('1.7' === e.fn.jquery.substr(0, 3)) {
                var r = e();
                e.map(n, function (e) {
                  r = r.add(e);
                }),
                  (n = r);
              }
              t.append(n);
            }),
            (n.__cache = {});
          var i = 0;
          return (
            (n.GetUniqueElementId = function (e) {
              var t = e.getAttribute('data-select2-id');
              return (
                null == t &&
                  (e.id
                    ? ((t = e.id), e.setAttribute('data-select2-id', t))
                    : (e.setAttribute('data-select2-id', ++i),
                      (t = i.toString()))),
                t
              );
            }),
            (n.StoreData = function (e, t, r) {
              var i = n.GetUniqueElementId(e);
              n.__cache[i] || (n.__cache[i] = {}), (n.__cache[i][t] = r);
            }),
            (n.GetData = function (t, r) {
              var i = n.GetUniqueElementId(t);
              return r
                ? n.__cache[i] && null != n.__cache[i][r]
                  ? n.__cache[i][r]
                  : e(t).data(r)
                : n.__cache[i];
            }),
            (n.RemoveData = function (e) {
              var t = n.GetUniqueElementId(e);
              null != n.__cache[t] && delete n.__cache[t],
                e.removeAttribute('data-select2-id');
            }),
            n
          );
        }),
        t.define('select2/results', ['jquery', './utils'], function (e, t) {
          function n(e, t, r) {
            (this.$element = e),
              (this.data = r),
              (this.options = t),
              n.__super__.constructor.call(this);
          }
          return (
            t.Extend(n, t.Observable),
            (n.prototype.render = function () {
              var t = e(
                '<ul class="select2-results__options" role="listbox"></ul>'
              );
              return (
                this.options.get('multiple') &&
                  t.attr('aria-multiselectable', 'true'),
                (this.$results = t),
                t
              );
            }),
            (n.prototype.clear = function () {
              this.$results.empty();
            }),
            (n.prototype.displayMessage = function (t) {
              var n = this.options.get('escapeMarkup');
              this.clear(), this.hideLoading();
              var r = e(
                  '<li role="alert" aria-live="assertive" class="select2-results__option"></li>'
                ),
                i = this.options.get('translations').get(t.message);
              r.append(n(i(t.args))),
                (r[0].className += ' select2-results__message'),
                this.$results.append(r);
            }),
            (n.prototype.hideMessages = function () {
              this.$results.find('.select2-results__message').remove();
            }),
            (n.prototype.append = function (e) {
              this.hideLoading();
              var t = [];
              if (null == e.results || 0 === e.results.length)
                return void (
                  0 === this.$results.children().length &&
                  this.trigger('results:message', { message: 'noResults' })
                );
              e.results = this.sort(e.results);
              for (var n = 0; n < e.results.length; n++) {
                var r = e.results[n],
                  i = this.option(r);
                t.push(i);
              }
              this.$results.append(t);
            }),
            (n.prototype.position = function (e, t) {
              var n = t.find('.select2-results');
              n.append(e);
            }),
            (n.prototype.sort = function (e) {
              var t = this.options.get('sorter');
              return t(e);
            }),
            (n.prototype.highlightFirstItem = function () {
              var e = this.$results.find(
                  '.select2-results__option[aria-selected]'
                ),
                t = e.filter('[aria-selected=true]');
              t.length > 0
                ? t.first().trigger('mouseenter')
                : e.first().trigger('mouseenter'),
                this.ensureHighlightVisible();
            }),
            (n.prototype.setClasses = function () {
              var n = this;
              this.data.current(function (r) {
                var i = e.map(r, function (e) {
                    return e.id.toString();
                  }),
                  o = n.$results.find(
                    '.select2-results__option[aria-selected]'
                  );
                o.each(function () {
                  var n = e(this),
                    r = t.GetData(this, 'data'),
                    o = '' + r.id;
                  (null != r.element && r.element.selected) ||
                  (null == r.element && e.inArray(o, i) > -1)
                    ? n.attr('aria-selected', 'true')
                    : n.attr('aria-selected', 'false');
                });
              });
            }),
            (n.prototype.showLoading = function (e) {
              this.hideLoading();
              var t = this.options.get('translations').get('searching'),
                n = { disabled: !0, loading: !0, text: t(e) },
                r = this.option(n);
              (r.className += ' loading-results'), this.$results.prepend(r);
            }),
            (n.prototype.hideLoading = function () {
              this.$results.find('.loading-results').remove();
            }),
            (n.prototype.option = function (n) {
              var r = document.createElement('li');
              r.className = 'select2-results__option';
              var i = { role: 'option', 'aria-selected': 'false' },
                o =
                  window.Element.prototype.matches ||
                  window.Element.prototype.msMatchesSelector ||
                  window.Element.prototype.webkitMatchesSelector;
              ((null != n.element && o.call(n.element, ':disabled')) ||
                (null == n.element && n.disabled)) &&
                (delete i['aria-selected'], (i['aria-disabled'] = 'true')),
                null == n.id && delete i['aria-selected'],
                null != n._resultId && (r.id = n._resultId),
                n.title && (r.title = n.title),
                n.children &&
                  ((i.role = 'group'),
                  (i['aria-label'] = n.text),
                  delete i['aria-selected']);
              for (var s in i) {
                var a = i[s];
                r.setAttribute(s, a);
              }
              if (n.children) {
                var l = e(r),
                  c = document.createElement('strong');
                c.className = 'select2-results__group';
                e(c);
                this.template(n, c);
                for (var u = [], d = 0; d < n.children.length; d++) {
                  var p = n.children[d],
                    h = this.option(p);
                  u.push(h);
                }
                var f = e('<ul></ul>', {
                  class:
                    'select2-results__options select2-results__options--nested',
                });
                f.append(u), l.append(c), l.append(f);
              } else this.template(n, r);
              return t.StoreData(r, 'data', n), r;
            }),
            (n.prototype.bind = function (n, r) {
              var i = this,
                o = n.id + '-results';
              this.$results.attr('id', o),
                n.on('results:all', function (e) {
                  i.clear(),
                    i.append(e.data),
                    n.isOpen() && (i.setClasses(), i.highlightFirstItem());
                }),
                n.on('results:append', function (e) {
                  i.append(e.data), n.isOpen() && i.setClasses();
                }),
                n.on('query', function (e) {
                  i.hideMessages(), i.showLoading(e);
                }),
                n.on('select', function () {
                  n.isOpen() &&
                    (i.setClasses(),
                    i.options.get('scrollAfterSelect') &&
                      i.highlightFirstItem());
                }),
                n.on('unselect', function () {
                  n.isOpen() &&
                    (i.setClasses(),
                    i.options.get('scrollAfterSelect') &&
                      i.highlightFirstItem());
                }),
                n.on('open', function () {
                  i.$results.attr('aria-expanded', 'true'),
                    i.$results.attr('aria-hidden', 'false'),
                    i.setClasses(),
                    i.ensureHighlightVisible();
                }),
                n.on('close', function () {
                  i.$results.attr('aria-expanded', 'false'),
                    i.$results.attr('aria-hidden', 'true'),
                    i.$results.removeAttr('aria-activedescendant');
                }),
                n.on('results:toggle', function () {
                  var e = i.getHighlightedResults();
                  0 !== e.length && e.trigger('mouseup');
                }),
                n.on('results:select', function () {
                  var e = i.getHighlightedResults();
                  if (0 !== e.length) {
                    var n = t.GetData(e[0], 'data');
                    'true' == e.attr('aria-selected')
                      ? i.trigger('close', {})
                      : i.trigger('select', { data: n });
                  }
                }),
                n.on('results:previous', function () {
                  var e = i.getHighlightedResults(),
                    t = i.$results.find('[aria-selected]'),
                    n = t.index(e);
                  if (!(n <= 0)) {
                    var r = n - 1;
                    0 === e.length && (r = 0);
                    var o = t.eq(r);
                    o.trigger('mouseenter');
                    var s = i.$results.offset().top,
                      a = o.offset().top,
                      l = i.$results.scrollTop() + (a - s);
                    0 === r
                      ? i.$results.scrollTop(0)
                      : a - s < 0 && i.$results.scrollTop(l);
                  }
                }),
                n.on('results:next', function () {
                  var e = i.getHighlightedResults(),
                    t = i.$results.find('[aria-selected]'),
                    n = t.index(e),
                    r = n + 1;
                  if (!(r >= t.length)) {
                    var o = t.eq(r);
                    o.trigger('mouseenter');
                    var s =
                        i.$results.offset().top + i.$results.outerHeight(!1),
                      a = o.offset().top + o.outerHeight(!1),
                      l = i.$results.scrollTop() + a - s;
                    0 === r
                      ? i.$results.scrollTop(0)
                      : a > s && i.$results.scrollTop(l);
                  }
                }),
                n.on('results:focus', function (e) {
                  e.element.addClass('select2-results__option--highlighted');
                }),
                n.on('results:message', function (e) {
                  i.displayMessage(e);
                }),
                e.fn.mousewheel &&
                  this.$results.on('mousewheel', function (e) {
                    var t = i.$results.scrollTop(),
                      n = i.$results.get(0).scrollHeight - t + e.deltaY,
                      r = e.deltaY > 0 && t - e.deltaY <= 0,
                      o = e.deltaY < 0 && n <= i.$results.height();
                    r
                      ? (i.$results.scrollTop(0),
                        e.preventDefault(),
                        e.stopPropagation())
                      : o &&
                        (i.$results.scrollTop(
                          i.$results.get(0).scrollHeight - i.$results.height()
                        ),
                        e.preventDefault(),
                        e.stopPropagation());
                  }),
                this.$results.on(
                  'mouseup',
                  '.select2-results__option[aria-selected]',
                  function (n) {
                    var r = e(this),
                      o = t.GetData(this, 'data');
                    return 'true' === r.attr('aria-selected')
                      ? void (i.options.get('multiple')
                          ? i.trigger('unselect', { originalEvent: n, data: o })
                          : i.trigger('close', {}))
                      : void i.trigger('select', { originalEvent: n, data: o });
                  }
                ),
                this.$results.on(
                  'mouseenter',
                  '.select2-results__option[aria-selected]',
                  function (n) {
                    var r = t.GetData(this, 'data');
                    i
                      .getHighlightedResults()
                      .removeClass('select2-results__option--highlighted'),
                      i.trigger('results:focus', { data: r, element: e(this) });
                  }
                );
            }),
            (n.prototype.getHighlightedResults = function () {
              var e = this.$results.find(
                '.select2-results__option--highlighted'
              );
              return e;
            }),
            (n.prototype.destroy = function () {
              this.$results.remove();
            }),
            (n.prototype.ensureHighlightVisible = function () {
              var e = this.getHighlightedResults();
              if (0 !== e.length) {
                var t = this.$results.find('[aria-selected]'),
                  n = t.index(e),
                  r = this.$results.offset().top,
                  i = e.offset().top,
                  o = this.$results.scrollTop() + (i - r),
                  s = i - r;
                (o -= 2 * e.outerHeight(!1)),
                  n <= 2
                    ? this.$results.scrollTop(0)
                    : (s > this.$results.outerHeight() || s < 0) &&
                      this.$results.scrollTop(o);
              }
            }),
            (n.prototype.template = function (t, n) {
              var r = this.options.get('templateResult'),
                i = this.options.get('escapeMarkup'),
                o = r(t, n);
              null == o
                ? (n.style.display = 'none')
                : 'string' == typeof o
                ? (n.innerHTML = i(o))
                : e(n).append(o);
            }),
            n
          );
        }),
        t.define('select2/keys', [], function () {
          var e = {
            BACKSPACE: 8,
            TAB: 9,
            ENTER: 13,
            SHIFT: 16,
            CTRL: 17,
            ALT: 18,
            ESC: 27,
            SPACE: 32,
            PAGE_UP: 33,
            PAGE_DOWN: 34,
            END: 35,
            HOME: 36,
            LEFT: 37,
            UP: 38,
            RIGHT: 39,
            DOWN: 40,
            DELETE: 46,
          };
          return e;
        }),
        t.define(
          'select2/selection/base',
          ['jquery', '../utils', '../keys'],
          function (e, t, n) {
            function r(e, t) {
              (this.$element = e),
                (this.options = t),
                r.__super__.constructor.call(this);
            }
            return (
              t.Extend(r, t.Observable),
              (r.prototype.render = function () {
                var n = e(
                  '<span class="select2-selection" role="combobox"  aria-haspopup="true" aria-expanded="false"></span>'
                );
                return (
                  (this._tabindex = 0),
                  null != t.GetData(this.$element[0], 'old-tabindex')
                    ? (this._tabindex = t.GetData(
                        this.$element[0],
                        'old-tabindex'
                      ))
                    : null != this.$element.attr('tabindex') &&
                      (this._tabindex = this.$element.attr('tabindex')),
                  n.attr('title', this.$element.attr('title')),
                  n.attr('tabindex', this._tabindex),
                  n.attr('aria-disabled', 'false'),
                  (this.$selection = n),
                  n
                );
              }),
              (r.prototype.bind = function (e, t) {
                var r = this,
                  i = e.id + '-results';
                (this.container = e),
                  this.$selection.on('focus', function (e) {
                    r.trigger('focus', e);
                  }),
                  this.$selection.on('blur', function (e) {
                    r._handleBlur(e);
                  }),
                  this.$selection.on('keydown', function (e) {
                    r.trigger('keypress', e),
                      e.which === n.SPACE && e.preventDefault();
                  }),
                  e.on('results:focus', function (e) {
                    r.$selection.attr(
                      'aria-activedescendant',
                      e.data._resultId
                    );
                  }),
                  e.on('selection:update', function (e) {
                    r.update(e.data);
                  }),
                  e.on('open', function () {
                    r.$selection.attr('aria-expanded', 'true'),
                      r.$selection.attr('aria-owns', i),
                      r._attachCloseHandler(e);
                  }),
                  e.on('close', function () {
                    r.$selection.attr('aria-expanded', 'false'),
                      r.$selection.removeAttr('aria-activedescendant'),
                      r.$selection.removeAttr('aria-owns'),
                      r.$selection.trigger('focus'),
                      r._detachCloseHandler(e);
                  }),
                  e.on('enable', function () {
                    r.$selection.attr('tabindex', r._tabindex),
                      r.$selection.attr('aria-disabled', 'false');
                  }),
                  e.on('disable', function () {
                    r.$selection.attr('tabindex', '-1'),
                      r.$selection.attr('aria-disabled', 'true');
                  });
              }),
              (r.prototype._handleBlur = function (t) {
                var n = this;
                window.setTimeout(function () {
                  document.activeElement == n.$selection[0] ||
                    e.contains(n.$selection[0], document.activeElement) ||
                    n.trigger('blur', t);
                }, 1);
              }),
              (r.prototype._attachCloseHandler = function (n) {
                e(document.body).on('mousedown.select2.' + n.id, function (n) {
                  var r = e(n.target),
                    i = r.closest('.select2'),
                    o = e('.select2.select2-container--open');
                  o.each(function () {
                    if (this != i[0]) {
                      var e = t.GetData(this, 'element');
                      e.select2('close');
                    }
                  });
                });
              }),
              (r.prototype._detachCloseHandler = function (t) {
                e(document.body).off('mousedown.select2.' + t.id);
              }),
              (r.prototype.position = function (e, t) {
                var n = t.find('.selection');
                n.append(e);
              }),
              (r.prototype.destroy = function () {
                this._detachCloseHandler(this.container);
              }),
              (r.prototype.update = function (e) {
                throw new Error(
                  'The `update` method must be defined in child classes.'
                );
              }),
              r
            );
          }
        ),
        t.define(
          'select2/selection/single',
          ['jquery', './base', '../utils', '../keys'],
          function (e, t, n, r) {
            function i() {
              i.__super__.constructor.apply(this, arguments);
            }
            return (
              n.Extend(i, t),
              (i.prototype.render = function () {
                var e = i.__super__.render.call(this);
                return (
                  e.addClass('select2-selection--single'),
                  e.html(
                    '<span class="select2-selection__rendered"></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span>'
                  ),
                  e
                );
              }),
              (i.prototype.bind = function (e, t) {
                var n = this;
                i.__super__.bind.apply(this, arguments);
                var r = e.id + '-container';
                this.$selection
                  .find('.select2-selection__rendered')
                  .attr('id', r)
                  .attr('role', 'textbox')
                  .attr('aria-readonly', 'true'),
                  this.$selection.attr('aria-labelledby', r),
                  this.$selection.on('mousedown', function (e) {
                    1 === e.which && n.trigger('toggle', { originalEvent: e });
                  }),
                  this.$selection.on('focus', function (e) {}),
                  this.$selection.on('blur', function (e) {}),
                  e.on('focus', function (t) {
                    e.isOpen() || n.$selection.trigger('focus');
                  });
              }),
              (i.prototype.clear = function () {
                var e = this.$selection.find('.select2-selection__rendered');
                e.empty(), e.removeAttr('title');
              }),
              (i.prototype.display = function (e, t) {
                var n = this.options.get('templateSelection'),
                  r = this.options.get('escapeMarkup');
                return r(n(e, t));
              }),
              (i.prototype.selectionContainer = function () {
                return e('<span></span>');
              }),
              (i.prototype.update = function (e) {
                if (0 === e.length) return void this.clear();
                var t = e[0],
                  n = this.$selection.find('.select2-selection__rendered'),
                  r = this.display(t, n);
                n.empty().append(r);
                var i = t.title || t.text;
                i ? n.attr('title', i) : n.removeAttr('title');
              }),
              i
            );
          }
        ),
        t.define(
          'select2/selection/multiple',
          ['jquery', './base', '../utils'],
          function (e, t, n) {
            function r(e, t) {
              r.__super__.constructor.apply(this, arguments);
            }
            return (
              n.Extend(r, t),
              (r.prototype.render = function () {
                var e = r.__super__.render.call(this);
                return (
                  e.addClass('select2-selection--multiple'),
                  e.html('<ul class="select2-selection__rendered"></ul>'),
                  e
                );
              }),
              (r.prototype.bind = function (t, i) {
                var o = this;
                r.__super__.bind.apply(this, arguments),
                  this.$selection.on('click', function (e) {
                    o.trigger('toggle', { originalEvent: e });
                  }),
                  this.$selection.on(
                    'click',
                    '.select2-selection__choice__remove',
                    function (t) {
                      if (!o.options.get('disabled')) {
                        var r = e(this),
                          i = r.parent(),
                          s = n.GetData(i[0], 'data');
                        o.trigger('unselect', { originalEvent: t, data: s });
                      }
                    }
                  );
              }),
              (r.prototype.clear = function () {
                var e = this.$selection.find('.select2-selection__rendered');
                e.empty(), e.removeAttr('title');
              }),
              (r.prototype.display = function (e, t) {
                var n = this.options.get('templateSelection'),
                  r = this.options.get('escapeMarkup');
                return r(n(e, t));
              }),
              (r.prototype.selectionContainer = function () {
                var t = e(
                  '<li class="select2-selection__choice"><span class="select2-selection__choice__remove" role="presentation">&times;</span></li>'
                );
                return t;
              }),
              (r.prototype.update = function (e) {
                if ((this.clear(), 0 !== e.length)) {
                  for (var t = [], r = 0; r < e.length; r++) {
                    var i = e[r],
                      o = this.selectionContainer(),
                      s = this.display(i, o);
                    o.append(s);
                    var a = i.title || i.text;
                    a && o.attr('title', a),
                      n.StoreData(o[0], 'data', i),
                      t.push(o);
                  }
                  var l = this.$selection.find('.select2-selection__rendered');
                  n.appendMany(l, t);
                }
              }),
              r
            );
          }
        ),
        t.define('select2/selection/placeholder', ['../utils'], function (e) {
          function t(e, t, n) {
            (this.placeholder = this.normalizePlaceholder(
              n.get('placeholder')
            )),
              e.call(this, t, n);
          }
          return (
            (t.prototype.normalizePlaceholder = function (e, t) {
              return 'string' == typeof t && (t = { id: '', text: t }), t;
            }),
            (t.prototype.createPlaceholder = function (e, t) {
              var n = this.selectionContainer();
              return (
                n.html(this.display(t)),
                n
                  .addClass('select2-selection__placeholder')
                  .removeClass('select2-selection__choice'),
                n
              );
            }),
            (t.prototype.update = function (e, t) {
              var n = 1 == t.length && t[0].id != this.placeholder.id,
                r = t.length > 1;
              if (r || n) return e.call(this, t);
              this.clear();
              var i = this.createPlaceholder(this.placeholder);
              this.$selection.find('.select2-selection__rendered').append(i);
            }),
            t
          );
        }),
        t.define(
          'select2/selection/allowClear',
          ['jquery', '../keys', '../utils'],
          function (e, t, n) {
            function r() {}
            return (
              (r.prototype.bind = function (e, t, n) {
                var r = this;
                e.call(this, t, n),
                  null == this.placeholder &&
                    this.options.get('debug') &&
                    window.console &&
                    console.error &&
                    console.error(
                      'Select2: The `allowClear` option should be used in combination with the `placeholder` option.'
                    ),
                  this.$selection.on(
                    'mousedown',
                    '.select2-selection__clear',
                    function (e) {
                      r._handleClear(e);
                    }
                  ),
                  t.on('keypress', function (e) {
                    r._handleKeyboardClear(e, t);
                  });
              }),
              (r.prototype._handleClear = function (e, t) {
                if (!this.options.get('disabled')) {
                  var r = this.$selection.find('.select2-selection__clear');
                  if (0 !== r.length) {
                    t.stopPropagation();
                    var i = n.GetData(r[0], 'data'),
                      o = this.$element.val();
                    this.$element.val(this.placeholder.id);
                    var s = { data: i };
                    if ((this.trigger('clear', s), s.prevented))
                      return void this.$element.val(o);
                    for (var a = 0; a < i.length; a++)
                      if (
                        ((s = { data: i[a] }),
                        this.trigger('unselect', s),
                        s.prevented)
                      )
                        return void this.$element.val(o);
                    this.$element.trigger('change'), this.trigger('toggle', {});
                  }
                }
              }),
              (r.prototype._handleKeyboardClear = function (e, n, r) {
                r.isOpen() ||
                  (n.which != t.DELETE && n.which != t.BACKSPACE) ||
                  this._handleClear(n);
              }),
              (r.prototype.update = function (t, r) {
                if (
                  (t.call(this, r),
                  !(
                    this.$selection.find('.select2-selection__placeholder')
                      .length > 0 || 0 === r.length
                  ))
                ) {
                  var i = this.options
                      .get('translations')
                      .get('removeAllItems'),
                    o = e(
                      '<span class="select2-selection__clear" title="' +
                        i() +
                        '">&times;</span>'
                    );
                  n.StoreData(o[0], 'data', r),
                    this.$selection
                      .find('.select2-selection__rendered')
                      .prepend(o);
                }
              }),
              r
            );
          }
        ),
        t.define(
          'select2/selection/search',
          ['jquery', '../utils', '../keys'],
          function (e, t, n) {
            function r(e, t, n) {
              e.call(this, t, n);
            }
            return (
              (r.prototype.render = function (t) {
                var n = e(
                  '<li class="select2-search select2-search--inline"><input class="select2-search__field" type="search" tabindex="-1" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" /></li>'
                );
                (this.$searchContainer = n), (this.$search = n.find('input'));
                var r = t.call(this);
                return this._transferTabIndex(), r;
              }),
              (r.prototype.bind = function (e, r, i) {
                var o = this,
                  s = r.id + '-results';
                e.call(this, r, i),
                  r.on('open', function () {
                    o.$search.attr('aria-controls', s),
                      o.$search.trigger('focus');
                  }),
                  r.on('close', function () {
                    o.$search.val(''),
                      o.$search.removeAttr('aria-controls'),
                      o.$search.removeAttr('aria-activedescendant'),
                      o.$search.trigger('focus');
                  }),
                  r.on('enable', function () {
                    o.$search.prop('disabled', !1), o._transferTabIndex();
                  }),
                  r.on('disable', function () {
                    o.$search.prop('disabled', !0);
                  }),
                  r.on('focus', function (e) {
                    o.$search.trigger('focus');
                  }),
                  r.on('results:focus', function (e) {
                    e.data._resultId
                      ? o.$search.attr(
                          'aria-activedescendant',
                          e.data._resultId
                        )
                      : o.$search.removeAttr('aria-activedescendant');
                  }),
                  this.$selection.on(
                    'focusin',
                    '.select2-search--inline',
                    function (e) {
                      o.trigger('focus', e);
                    }
                  ),
                  this.$selection.on(
                    'focusout',
                    '.select2-search--inline',
                    function (e) {
                      o._handleBlur(e);
                    }
                  ),
                  this.$selection.on(
                    'keydown',
                    '.select2-search--inline',
                    function (e) {
                      e.stopPropagation(),
                        o.trigger('keypress', e),
                        (o._keyUpPrevented = e.isDefaultPrevented());
                      var r = e.which;
                      if (r === n.BACKSPACE && '' === o.$search.val()) {
                        var i = o.$searchContainer.prev(
                          '.select2-selection__choice'
                        );
                        if (i.length > 0) {
                          var s = t.GetData(i[0], 'data');
                          o.searchRemoveChoice(s), e.preventDefault();
                        }
                      }
                    }
                  ),
                  this.$selection.on(
                    'click',
                    '.select2-search--inline',
                    function (e) {
                      o.$search.val() && e.stopPropagation();
                    }
                  );
                var a = document.documentMode,
                  l = a && a <= 11;
                this.$selection.on(
                  'input.searchcheck',
                  '.select2-search--inline',
                  function (e) {
                    return l
                      ? void o.$selection.off('input.search input.searchcheck')
                      : void o.$selection.off('keyup.search');
                  }
                ),
                  this.$selection.on(
                    'keyup.search input.search',
                    '.select2-search--inline',
                    function (e) {
                      if (l && 'input' === e.type)
                        return void o.$selection.off(
                          'input.search input.searchcheck'
                        );
                      var t = e.which;
                      t != n.SHIFT &&
                        t != n.CTRL &&
                        t != n.ALT &&
                        t != n.TAB &&
                        o.handleSearch(e);
                    }
                  );
              }),
              (r.prototype._transferTabIndex = function (e) {
                this.$search.attr('tabindex', this.$selection.attr('tabindex')),
                  this.$selection.attr('tabindex', '-1');
              }),
              (r.prototype.createPlaceholder = function (e, t) {
                this.$search.attr('placeholder', t.text);
              }),
              (r.prototype.update = function (e, t) {
                var n = this.$search[0] == document.activeElement;
                this.$search.attr('placeholder', ''),
                  e.call(this, t),
                  this.$selection
                    .find('.select2-selection__rendered')
                    .append(this.$searchContainer),
                  this.resizeSearch(),
                  n && this.$search.trigger('focus');
              }),
              (r.prototype.handleSearch = function () {
                if ((this.resizeSearch(), !this._keyUpPrevented)) {
                  var e = this.$search.val();
                  this.trigger('query', { term: e });
                }
                this._keyUpPrevented = !1;
              }),
              (r.prototype.searchRemoveChoice = function (e, t) {
                this.trigger('unselect', { data: t }),
                  this.$search.val(t.text),
                  this.handleSearch();
              }),
              (r.prototype.resizeSearch = function () {
                this.$search.css('width', '25px');
                var e = '';
                if ('' !== this.$search.attr('placeholder'))
                  e = this.$selection
                    .find('.select2-selection__rendered')
                    .width();
                else {
                  var t = this.$search.val().length + 1;
                  e = 0.75 * t + 'em';
                }
                this.$search.css('width', e);
              }),
              r
            );
          }
        ),
        t.define('select2/selection/eventRelay', ['jquery'], function (e) {
          function t() {}
          return (
            (t.prototype.bind = function (t, n, r) {
              var i = this,
                o = [
                  'open',
                  'opening',
                  'close',
                  'closing',
                  'select',
                  'selecting',
                  'unselect',
                  'unselecting',
                  'clear',
                  'clearing',
                ],
                s = [
                  'opening',
                  'closing',
                  'selecting',
                  'unselecting',
                  'clearing',
                ];
              t.call(this, n, r),
                n.on('*', function (t, n) {
                  if (e.inArray(t, o) !== -1) {
                    n = n || {};
                    var r = e.Event('select2:' + t, { params: n });
                    i.$element.trigger(r),
                      e.inArray(t, s) !== -1 &&
                        (n.prevented = r.isDefaultPrevented());
                  }
                });
            }),
            t
          );
        }),
        t.define('select2/translation', ['jquery', 'require'], function (e, t) {
          function n(e) {
            this.dict = e || {};
          }
          return (
            (n.prototype.all = function () {
              return this.dict;
            }),
            (n.prototype.get = function (e) {
              return this.dict[e];
            }),
            (n.prototype.extend = function (t) {
              this.dict = e.extend({}, t.all(), this.dict);
            }),
            (n._cache = {}),
            (n.loadPath = function (e) {
              if (!(e in n._cache)) {
                var r = t(e);
                n._cache[e] = r;
              }
              return new n(n._cache[e]);
            }),
            n
          );
        }),
        t.define('select2/diacritics', [], function () {
          var e = {
            'Ⓐ': 'A',
            Ａ: 'A',
            À: 'A',
            Á: 'A',
            Â: 'A',
            Ầ: 'A',
            Ấ: 'A',
            Ẫ: 'A',
            Ẩ: 'A',
            Ã: 'A',
            Ā: 'A',
            Ă: 'A',
            Ằ: 'A',
            Ắ: 'A',
            Ẵ: 'A',
            Ẳ: 'A',
            Ȧ: 'A',
            Ǡ: 'A',
            Ä: 'A',
            Ǟ: 'A',
            Ả: 'A',
            Å: 'A',
            Ǻ: 'A',
            Ǎ: 'A',
            Ȁ: 'A',
            Ȃ: 'A',
            Ạ: 'A',
            Ậ: 'A',
            Ặ: 'A',
            Ḁ: 'A',
            Ą: 'A',
            Ⱥ: 'A',
            Ɐ: 'A',
            Ꜳ: 'AA',
            Æ: 'AE',
            Ǽ: 'AE',
            Ǣ: 'AE',
            Ꜵ: 'AO',
            Ꜷ: 'AU',
            Ꜹ: 'AV',
            Ꜻ: 'AV',
            Ꜽ: 'AY',
            'Ⓑ': 'B',
            Ｂ: 'B',
            Ḃ: 'B',
            Ḅ: 'B',
            Ḇ: 'B',
            Ƀ: 'B',
            Ƃ: 'B',
            Ɓ: 'B',
            'Ⓒ': 'C',
            Ｃ: 'C',
            Ć: 'C',
            Ĉ: 'C',
            Ċ: 'C',
            Č: 'C',
            Ç: 'C',
            Ḉ: 'C',
            Ƈ: 'C',
            Ȼ: 'C',
            Ꜿ: 'C',
            'Ⓓ': 'D',
            Ｄ: 'D',
            Ḋ: 'D',
            Ď: 'D',
            Ḍ: 'D',
            Ḑ: 'D',
            Ḓ: 'D',
            Ḏ: 'D',
            Đ: 'D',
            Ƌ: 'D',
            Ɗ: 'D',
            Ɖ: 'D',
            Ꝺ: 'D',
            Ǳ: 'DZ',
            Ǆ: 'DZ',
            ǲ: 'Dz',
            ǅ: 'Dz',
            'Ⓔ': 'E',
            Ｅ: 'E',
            È: 'E',
            É: 'E',
            Ê: 'E',
            Ề: 'E',
            Ế: 'E',
            Ễ: 'E',
            Ể: 'E',
            Ẽ: 'E',
            Ē: 'E',
            Ḕ: 'E',
            Ḗ: 'E',
            Ĕ: 'E',
            Ė: 'E',
            Ë: 'E',
            Ẻ: 'E',
            Ě: 'E',
            Ȅ: 'E',
            Ȇ: 'E',
            Ẹ: 'E',
            Ệ: 'E',
            Ȩ: 'E',
            Ḝ: 'E',
            Ę: 'E',
            Ḙ: 'E',
            Ḛ: 'E',
            Ɛ: 'E',
            Ǝ: 'E',
            'Ⓕ': 'F',
            Ｆ: 'F',
            Ḟ: 'F',
            Ƒ: 'F',
            Ꝼ: 'F',
            'Ⓖ': 'G',
            Ｇ: 'G',
            Ǵ: 'G',
            Ĝ: 'G',
            Ḡ: 'G',
            Ğ: 'G',
            Ġ: 'G',
            Ǧ: 'G',
            Ģ: 'G',
            Ǥ: 'G',
            Ɠ: 'G',
            Ꞡ: 'G',
            Ᵹ: 'G',
            Ꝿ: 'G',
            'Ⓗ': 'H',
            Ｈ: 'H',
            Ĥ: 'H',
            Ḣ: 'H',
            Ḧ: 'H',
            Ȟ: 'H',
            Ḥ: 'H',
            Ḩ: 'H',
            Ḫ: 'H',
            Ħ: 'H',
            Ⱨ: 'H',
            Ⱶ: 'H',
            Ɥ: 'H',
            'Ⓘ': 'I',
            Ｉ: 'I',
            Ì: 'I',
            Í: 'I',
            Î: 'I',
            Ĩ: 'I',
            Ī: 'I',
            Ĭ: 'I',
            İ: 'I',
            Ï: 'I',
            Ḯ: 'I',
            Ỉ: 'I',
            Ǐ: 'I',
            Ȉ: 'I',
            Ȋ: 'I',
            Ị: 'I',
            Į: 'I',
            Ḭ: 'I',
            Ɨ: 'I',
            'Ⓙ': 'J',
            Ｊ: 'J',
            Ĵ: 'J',
            Ɉ: 'J',
            'Ⓚ': 'K',
            Ｋ: 'K',
            Ḱ: 'K',
            Ǩ: 'K',
            Ḳ: 'K',
            Ķ: 'K',
            Ḵ: 'K',
            Ƙ: 'K',
            Ⱪ: 'K',
            Ꝁ: 'K',
            Ꝃ: 'K',
            Ꝅ: 'K',
            Ꞣ: 'K',
            'Ⓛ': 'L',
            Ｌ: 'L',
            Ŀ: 'L',
            Ĺ: 'L',
            Ľ: 'L',
            Ḷ: 'L',
            Ḹ: 'L',
            Ļ: 'L',
            Ḽ: 'L',
            Ḻ: 'L',
            Ł: 'L',
            Ƚ: 'L',
            Ɫ: 'L',
            Ⱡ: 'L',
            Ꝉ: 'L',
            Ꝇ: 'L',
            Ꞁ: 'L',
            Ǉ: 'LJ',
            ǈ: 'Lj',
            'Ⓜ': 'M',
            Ｍ: 'M',
            Ḿ: 'M',
            Ṁ: 'M',
            Ṃ: 'M',
            Ɱ: 'M',
            Ɯ: 'M',
            'Ⓝ': 'N',
            Ｎ: 'N',
            Ǹ: 'N',
            Ń: 'N',
            Ñ: 'N',
            Ṅ: 'N',
            Ň: 'N',
            Ṇ: 'N',
            Ņ: 'N',
            Ṋ: 'N',
            Ṉ: 'N',
            Ƞ: 'N',
            Ɲ: 'N',
            Ꞑ: 'N',
            Ꞥ: 'N',
            Ǌ: 'NJ',
            ǋ: 'Nj',
            'Ⓞ': 'O',
            Ｏ: 'O',
            Ò: 'O',
            Ó: 'O',
            Ô: 'O',
            Ồ: 'O',
            Ố: 'O',
            Ỗ: 'O',
            Ổ: 'O',
            Õ: 'O',
            Ṍ: 'O',
            Ȭ: 'O',
            Ṏ: 'O',
            Ō: 'O',
            Ṑ: 'O',
            Ṓ: 'O',
            Ŏ: 'O',
            Ȯ: 'O',
            Ȱ: 'O',
            Ö: 'O',
            Ȫ: 'O',
            Ỏ: 'O',
            Ő: 'O',
            Ǒ: 'O',
            Ȍ: 'O',
            Ȏ: 'O',
            Ơ: 'O',
            Ờ: 'O',
            Ớ: 'O',
            Ỡ: 'O',
            Ở: 'O',
            Ợ: 'O',
            Ọ: 'O',
            Ộ: 'O',
            Ǫ: 'O',
            Ǭ: 'O',
            Ø: 'O',
            Ǿ: 'O',
            Ɔ: 'O',
            Ɵ: 'O',
            Ꝋ: 'O',
            Ꝍ: 'O',
            Œ: 'OE',
            Ƣ: 'OI',
            Ꝏ: 'OO',
            Ȣ: 'OU',
            'Ⓟ': 'P',
            Ｐ: 'P',
            Ṕ: 'P',
            Ṗ: 'P',
            Ƥ: 'P',
            Ᵽ: 'P',
            Ꝑ: 'P',
            Ꝓ: 'P',
            Ꝕ: 'P',
            'Ⓠ': 'Q',
            Ｑ: 'Q',
            Ꝗ: 'Q',
            Ꝙ: 'Q',
            Ɋ: 'Q',
            'Ⓡ': 'R',
            Ｒ: 'R',
            Ŕ: 'R',
            Ṙ: 'R',
            Ř: 'R',
            Ȑ: 'R',
            Ȓ: 'R',
            Ṛ: 'R',
            Ṝ: 'R',
            Ŗ: 'R',
            Ṟ: 'R',
            Ɍ: 'R',
            Ɽ: 'R',
            Ꝛ: 'R',
            Ꞧ: 'R',
            Ꞃ: 'R',
            'Ⓢ': 'S',
            Ｓ: 'S',
            ẞ: 'S',
            Ś: 'S',
            Ṥ: 'S',
            Ŝ: 'S',
            Ṡ: 'S',
            Š: 'S',
            Ṧ: 'S',
            Ṣ: 'S',
            Ṩ: 'S',
            Ș: 'S',
            Ş: 'S',
            Ȿ: 'S',
            Ꞩ: 'S',
            Ꞅ: 'S',
            'Ⓣ': 'T',
            Ｔ: 'T',
            Ṫ: 'T',
            Ť: 'T',
            Ṭ: 'T',
            Ț: 'T',
            Ţ: 'T',
            Ṱ: 'T',
            Ṯ: 'T',
            Ŧ: 'T',
            Ƭ: 'T',
            Ʈ: 'T',
            Ⱦ: 'T',
            Ꞇ: 'T',
            Ꜩ: 'TZ',
            'Ⓤ': 'U',
            Ｕ: 'U',
            Ù: 'U',
            Ú: 'U',
            Û: 'U',
            Ũ: 'U',
            Ṹ: 'U',
            Ū: 'U',
            Ṻ: 'U',
            Ŭ: 'U',
            Ü: 'U',
            Ǜ: 'U',
            Ǘ: 'U',
            Ǖ: 'U',
            Ǚ: 'U',
            Ủ: 'U',
            Ů: 'U',
            Ű: 'U',
            Ǔ: 'U',
            Ȕ: 'U',
            Ȗ: 'U',
            Ư: 'U',
            Ừ: 'U',
            Ứ: 'U',
            Ữ: 'U',
            Ử: 'U',
            Ự: 'U',
            Ụ: 'U',
            Ṳ: 'U',
            Ų: 'U',
            Ṷ: 'U',
            Ṵ: 'U',
            Ʉ: 'U',
            'Ⓥ': 'V',
            Ｖ: 'V',
            Ṽ: 'V',
            Ṿ: 'V',
            Ʋ: 'V',
            Ꝟ: 'V',
            Ʌ: 'V',
            Ꝡ: 'VY',
            'Ⓦ': 'W',
            Ｗ: 'W',
            Ẁ: 'W',
            Ẃ: 'W',
            Ŵ: 'W',
            Ẇ: 'W',
            Ẅ: 'W',
            Ẉ: 'W',
            Ⱳ: 'W',
            'Ⓧ': 'X',
            Ｘ: 'X',
            Ẋ: 'X',
            Ẍ: 'X',
            'Ⓨ': 'Y',
            Ｙ: 'Y',
            Ỳ: 'Y',
            Ý: 'Y',
            Ŷ: 'Y',
            Ỹ: 'Y',
            Ȳ: 'Y',
            Ẏ: 'Y',
            Ÿ: 'Y',
            Ỷ: 'Y',
            Ỵ: 'Y',
            Ƴ: 'Y',
            Ɏ: 'Y',
            Ỿ: 'Y',
            'Ⓩ': 'Z',
            Ｚ: 'Z',
            Ź: 'Z',
            Ẑ: 'Z',
            Ż: 'Z',
            Ž: 'Z',
            Ẓ: 'Z',
            Ẕ: 'Z',
            Ƶ: 'Z',
            Ȥ: 'Z',
            Ɀ: 'Z',
            Ⱬ: 'Z',
            Ꝣ: 'Z',
            'ⓐ': 'a',
            ａ: 'a',
            ẚ: 'a',
            à: 'a',
            á: 'a',
            â: 'a',
            ầ: 'a',
            ấ: 'a',
            ẫ: 'a',
            ẩ: 'a',
            ã: 'a',
            ā: 'a',
            ă: 'a',
            ằ: 'a',
            ắ: 'a',
            ẵ: 'a',
            ẳ: 'a',
            ȧ: 'a',
            ǡ: 'a',
            ä: 'a',
            ǟ: 'a',
            ả: 'a',
            å: 'a',
            ǻ: 'a',
            ǎ: 'a',
            ȁ: 'a',
            ȃ: 'a',
            ạ: 'a',
            ậ: 'a',
            ặ: 'a',
            ḁ: 'a',
            ą: 'a',
            ⱥ: 'a',
            ɐ: 'a',
            ꜳ: 'aa',
            æ: 'ae',
            ǽ: 'ae',
            ǣ: 'ae',
            ꜵ: 'ao',
            ꜷ: 'au',
            ꜹ: 'av',
            ꜻ: 'av',
            ꜽ: 'ay',
            'ⓑ': 'b',
            ｂ: 'b',
            ḃ: 'b',
            ḅ: 'b',
            ḇ: 'b',
            ƀ: 'b',
            ƃ: 'b',
            ɓ: 'b',
            'ⓒ': 'c',
            ｃ: 'c',
            ć: 'c',
            ĉ: 'c',
            ċ: 'c',
            č: 'c',
            ç: 'c',
            ḉ: 'c',
            ƈ: 'c',
            ȼ: 'c',
            ꜿ: 'c',
            ↄ: 'c',
            'ⓓ': 'd',
            ｄ: 'd',
            ḋ: 'd',
            ď: 'd',
            ḍ: 'd',
            ḑ: 'd',
            ḓ: 'd',
            ḏ: 'd',
            đ: 'd',
            ƌ: 'd',
            ɖ: 'd',
            ɗ: 'd',
            ꝺ: 'd',
            ǳ: 'dz',
            ǆ: 'dz',
            'ⓔ': 'e',
            ｅ: 'e',
            è: 'e',
            é: 'e',
            ê: 'e',
            ề: 'e',
            ế: 'e',
            ễ: 'e',
            ể: 'e',
            ẽ: 'e',
            ē: 'e',
            ḕ: 'e',
            ḗ: 'e',
            ĕ: 'e',
            ė: 'e',
            ë: 'e',
            ẻ: 'e',
            ě: 'e',
            ȅ: 'e',
            ȇ: 'e',
            ẹ: 'e',
            ệ: 'e',
            ȩ: 'e',
            ḝ: 'e',
            ę: 'e',
            ḙ: 'e',
            ḛ: 'e',
            ɇ: 'e',
            ɛ: 'e',
            ǝ: 'e',
            'ⓕ': 'f',
            ｆ: 'f',
            ḟ: 'f',
            ƒ: 'f',
            ꝼ: 'f',
            'ⓖ': 'g',
            ｇ: 'g',
            ǵ: 'g',
            ĝ: 'g',
            ḡ: 'g',
            ğ: 'g',
            ġ: 'g',
            ǧ: 'g',
            ģ: 'g',
            ǥ: 'g',
            ɠ: 'g',
            ꞡ: 'g',
            ᵹ: 'g',
            ꝿ: 'g',
            'ⓗ': 'h',
            ｈ: 'h',
            ĥ: 'h',
            ḣ: 'h',
            ḧ: 'h',
            ȟ: 'h',
            ḥ: 'h',
            ḩ: 'h',
            ḫ: 'h',
            ẖ: 'h',
            ħ: 'h',
            ⱨ: 'h',
            ⱶ: 'h',
            ɥ: 'h',
            ƕ: 'hv',
            'ⓘ': 'i',
            ｉ: 'i',
            ì: 'i',
            í: 'i',
            î: 'i',
            ĩ: 'i',
            ī: 'i',
            ĭ: 'i',
            ï: 'i',
            ḯ: 'i',
            ỉ: 'i',
            ǐ: 'i',
            ȉ: 'i',
            ȋ: 'i',
            ị: 'i',
            į: 'i',
            ḭ: 'i',
            ɨ: 'i',
            ı: 'i',
            'ⓙ': 'j',
            ｊ: 'j',
            ĵ: 'j',
            ǰ: 'j',
            ɉ: 'j',
            'ⓚ': 'k',
            ｋ: 'k',
            ḱ: 'k',
            ǩ: 'k',
            ḳ: 'k',
            ķ: 'k',
            ḵ: 'k',
            ƙ: 'k',
            ⱪ: 'k',
            ꝁ: 'k',
            ꝃ: 'k',
            ꝅ: 'k',
            ꞣ: 'k',
            'ⓛ': 'l',
            ｌ: 'l',
            ŀ: 'l',
            ĺ: 'l',
            ľ: 'l',
            ḷ: 'l',
            ḹ: 'l',
            ļ: 'l',
            ḽ: 'l',
            ḻ: 'l',
            ſ: 'l',
            ł: 'l',
            ƚ: 'l',
            ɫ: 'l',
            ⱡ: 'l',
            ꝉ: 'l',
            ꞁ: 'l',
            ꝇ: 'l',
            ǉ: 'lj',
            'ⓜ': 'm',
            ｍ: 'm',
            ḿ: 'm',
            ṁ: 'm',
            ṃ: 'm',
            ɱ: 'm',
            ɯ: 'm',
            'ⓝ': 'n',
            ｎ: 'n',
            ǹ: 'n',
            ń: 'n',
            ñ: 'n',
            ṅ: 'n',
            ň: 'n',
            ṇ: 'n',
            ņ: 'n',
            ṋ: 'n',
            ṉ: 'n',
            ƞ: 'n',
            ɲ: 'n',
            ŉ: 'n',
            ꞑ: 'n',
            ꞥ: 'n',
            ǌ: 'nj',
            'ⓞ': 'o',
            ｏ: 'o',
            ò: 'o',
            ó: 'o',
            ô: 'o',
            ồ: 'o',
            ố: 'o',
            ỗ: 'o',
            ổ: 'o',
            õ: 'o',
            ṍ: 'o',
            ȭ: 'o',
            ṏ: 'o',
            ō: 'o',
            ṑ: 'o',
            ṓ: 'o',
            ŏ: 'o',
            ȯ: 'o',
            ȱ: 'o',
            ö: 'o',
            ȫ: 'o',
            ỏ: 'o',
            ő: 'o',
            ǒ: 'o',
            ȍ: 'o',
            ȏ: 'o',
            ơ: 'o',
            ờ: 'o',
            ớ: 'o',
            ỡ: 'o',
            ở: 'o',
            ợ: 'o',
            ọ: 'o',
            ộ: 'o',
            ǫ: 'o',
            ǭ: 'o',
            ø: 'o',
            ǿ: 'o',
            ɔ: 'o',
            ꝋ: 'o',
            ꝍ: 'o',
            ɵ: 'o',
            œ: 'oe',
            ƣ: 'oi',
            ȣ: 'ou',
            ꝏ: 'oo',
            'ⓟ': 'p',
            ｐ: 'p',
            ṕ: 'p',
            ṗ: 'p',
            ƥ: 'p',
            ᵽ: 'p',
            ꝑ: 'p',
            ꝓ: 'p',
            ꝕ: 'p',
            'ⓠ': 'q',
            ｑ: 'q',
            ɋ: 'q',
            ꝗ: 'q',
            ꝙ: 'q',
            'ⓡ': 'r',
            ｒ: 'r',
            ŕ: 'r',
            ṙ: 'r',
            ř: 'r',
            ȑ: 'r',
            ȓ: 'r',
            ṛ: 'r',
            ṝ: 'r',
            ŗ: 'r',
            ṟ: 'r',
            ɍ: 'r',
            ɽ: 'r',
            ꝛ: 'r',
            ꞧ: 'r',
            ꞃ: 'r',
            'ⓢ': 's',
            ｓ: 's',
            ß: 's',
            ś: 's',
            ṥ: 's',
            ŝ: 's',
            ṡ: 's',
            š: 's',
            ṧ: 's',
            ṣ: 's',
            ṩ: 's',
            ș: 's',
            ş: 's',
            ȿ: 's',
            ꞩ: 's',
            ꞅ: 's',
            ẛ: 's',
            'ⓣ': 't',
            ｔ: 't',
            ṫ: 't',
            ẗ: 't',
            ť: 't',
            ṭ: 't',
            ț: 't',
            ţ: 't',
            ṱ: 't',
            ṯ: 't',
            ŧ: 't',
            ƭ: 't',
            ʈ: 't',
            ⱦ: 't',
            ꞇ: 't',
            ꜩ: 'tz',
            'ⓤ': 'u',
            ｕ: 'u',
            ù: 'u',
            ú: 'u',
            û: 'u',
            ũ: 'u',
            ṹ: 'u',
            ū: 'u',
            ṻ: 'u',
            ŭ: 'u',
            ü: 'u',
            ǜ: 'u',
            ǘ: 'u',
            ǖ: 'u',
            ǚ: 'u',
            ủ: 'u',
            ů: 'u',
            ű: 'u',
            ǔ: 'u',
            ȕ: 'u',
            ȗ: 'u',
            ư: 'u',
            ừ: 'u',
            ứ: 'u',
            ữ: 'u',
            ử: 'u',
            ự: 'u',
            ụ: 'u',
            ṳ: 'u',
            ų: 'u',
            ṷ: 'u',
            ṵ: 'u',
            ʉ: 'u',
            'ⓥ': 'v',
            ｖ: 'v',
            ṽ: 'v',
            ṿ: 'v',
            ʋ: 'v',
            ꝟ: 'v',
            ʌ: 'v',
            ꝡ: 'vy',
            'ⓦ': 'w',
            ｗ: 'w',
            ẁ: 'w',
            ẃ: 'w',
            ŵ: 'w',
            ẇ: 'w',
            ẅ: 'w',
            ẘ: 'w',
            ẉ: 'w',
            ⱳ: 'w',
            'ⓧ': 'x',
            ｘ: 'x',
            ẋ: 'x',
            ẍ: 'x',
            'ⓨ': 'y',
            ｙ: 'y',
            ỳ: 'y',
            ý: 'y',
            ŷ: 'y',
            ỹ: 'y',
            ȳ: 'y',
            ẏ: 'y',
            ÿ: 'y',
            ỷ: 'y',
            ẙ: 'y',
            ỵ: 'y',
            ƴ: 'y',
            ɏ: 'y',
            ỿ: 'y',
            'ⓩ': 'z',
            ｚ: 'z',
            ź: 'z',
            ẑ: 'z',
            ż: 'z',
            ž: 'z',
            ẓ: 'z',
            ẕ: 'z',
            ƶ: 'z',
            ȥ: 'z',
            ɀ: 'z',
            ⱬ: 'z',
            ꝣ: 'z',
            Ά: 'Α',
            Έ: 'Ε',
            Ή: 'Η',
            Ί: 'Ι',
            Ϊ: 'Ι',
            Ό: 'Ο',
            Ύ: 'Υ',
            Ϋ: 'Υ',
            Ώ: 'Ω',
            ά: 'α',
            έ: 'ε',
            ή: 'η',
            ί: 'ι',
            ϊ: 'ι',
            ΐ: 'ι',
            ό: 'ο',
            ύ: 'υ',
            ϋ: 'υ',
            ΰ: 'υ',
            ώ: 'ω',
            ς: 'σ',
            '’': "'",
          };
          return e;
        }),
        t.define('select2/data/base', ['../utils'], function (e) {
          function t(e, n) {
            t.__super__.constructor.call(this);
          }
          return (
            e.Extend(t, e.Observable),
            (t.prototype.current = function (e) {
              throw new Error(
                'The `current` method must be defined in child classes.'
              );
            }),
            (t.prototype.query = function (e, t) {
              throw new Error(
                'The `query` method must be defined in child classes.'
              );
            }),
            (t.prototype.bind = function (e, t) {}),
            (t.prototype.destroy = function () {}),
            (t.prototype.generateResultId = function (t, n) {
              var r = t.id + '-result-';
              return (
                (r += e.generateChars(4)),
                (r +=
                  null != n.id
                    ? '-' + n.id.toString()
                    : '-' + e.generateChars(4))
              );
            }),
            t
          );
        }),
        t.define(
          'select2/data/select',
          ['./base', '../utils', 'jquery'],
          function (e, t, n) {
            function r(e, t) {
              (this.$element = e),
                (this.options = t),
                r.__super__.constructor.call(this);
            }
            return (
              t.Extend(r, e),
              (r.prototype.current = function (e) {
                var t = [],
                  r = this;
                this.$element.find(':selected').each(function () {
                  var e = n(this),
                    i = r.item(e);
                  t.push(i);
                }),
                  e(t);
              }),
              (r.prototype.select = function (e) {
                var t = this;
                if (((e.selected = !0), n(e.element).is('option')))
                  return (
                    (e.element.selected = !0),
                    void this.$element.trigger('change')
                  );
                if (this.$element.prop('multiple'))
                  this.current(function (r) {
                    var i = [];
                    (e = [e]), e.push.apply(e, r);
                    for (var o = 0; o < e.length; o++) {
                      var s = e[o].id;
                      n.inArray(s, i) === -1 && i.push(s);
                    }
                    t.$element.val(i), t.$element.trigger('change');
                  });
                else {
                  var r = e.id;
                  this.$element.val(r), this.$element.trigger('change');
                }
              }),
              (r.prototype.unselect = function (e) {
                var t = this;
                if (this.$element.prop('multiple'))
                  return (
                    (e.selected = !1),
                    n(e.element).is('option')
                      ? ((e.element.selected = !1),
                        void this.$element.trigger('change'))
                      : void this.current(function (r) {
                          for (var i = [], o = 0; o < r.length; o++) {
                            var s = r[o].id;
                            s !== e.id && n.inArray(s, i) === -1 && i.push(s);
                          }
                          t.$element.val(i), t.$element.trigger('change');
                        })
                  );
              }),
              (r.prototype.bind = function (e, t) {
                var n = this;
                (this.container = e),
                  e.on('select', function (e) {
                    n.select(e.data);
                  }),
                  e.on('unselect', function (e) {
                    n.unselect(e.data);
                  });
              }),
              (r.prototype.destroy = function () {
                this.$element.find('*').each(function () {
                  t.RemoveData(this);
                });
              }),
              (r.prototype.query = function (e, t) {
                var r = [],
                  i = this,
                  o = this.$element.children();
                o.each(function () {
                  var t = n(this);
                  if (t.is('option') || t.is('optgroup')) {
                    var o = i.item(t),
                      s = i.matches(e, o);
                    null !== s && r.push(s);
                  }
                }),
                  t({ results: r });
              }),
              (r.prototype.addOptions = function (e) {
                t.appendMany(this.$element, e);
              }),
              (r.prototype.option = function (e) {
                var r;
                e.children
                  ? ((r = document.createElement('optgroup')),
                    (r.label = e.text))
                  : ((r = document.createElement('option')),
                    void 0 !== r.textContent
                      ? (r.textContent = e.text)
                      : (r.innerText = e.text)),
                  void 0 !== e.id && (r.value = e.id),
                  e.disabled && (r.disabled = !0),
                  e.selected && (r.selected = !0),
                  e.title && (r.title = e.title);
                var i = n(r),
                  o = this._normalizeItem(e);
                return (o.element = r), t.StoreData(r, 'data', o), i;
              }),
              (r.prototype.item = function (e) {
                var r = {};
                if (((r = t.GetData(e[0], 'data')), null != r)) return r;
                if (e.is('option'))
                  r = {
                    id: e.val(),
                    text: e.text(),
                    disabled: e.prop('disabled'),
                    selected: e.prop('selected'),
                    title: e.prop('title'),
                  };
                else if (e.is('optgroup')) {
                  r = {
                    text: e.prop('label'),
                    children: [],
                    title: e.prop('title'),
                  };
                  for (
                    var i = e.children('option'), o = [], s = 0;
                    s < i.length;
                    s++
                  ) {
                    var a = n(i[s]),
                      l = this.item(a);
                    o.push(l);
                  }
                  r.children = o;
                }
                return (
                  (r = this._normalizeItem(r)),
                  (r.element = e[0]),
                  t.StoreData(e[0], 'data', r),
                  r
                );
              }),
              (r.prototype._normalizeItem = function (e) {
                e !== Object(e) && (e = { id: e, text: e }),
                  (e = n.extend({}, { text: '' }, e));
                var t = { selected: !1, disabled: !1 };
                return (
                  null != e.id && (e.id = e.id.toString()),
                  null != e.text && (e.text = e.text.toString()),
                  null == e._resultId &&
                    e.id &&
                    null != this.container &&
                    (e._resultId = this.generateResultId(this.container, e)),
                  n.extend({}, t, e)
                );
              }),
              (r.prototype.matches = function (e, t) {
                var n = this.options.get('matcher');
                return n(e, t);
              }),
              r
            );
          }
        ),
        t.define(
          'select2/data/array',
          ['./select', '../utils', 'jquery'],
          function (e, t, n) {
            function r(e, t) {
              (this._dataToConvert = t.get('data') || []),
                r.__super__.constructor.call(this, e, t);
            }
            return (
              t.Extend(r, e),
              (r.prototype.bind = function (e, t) {
                r.__super__.bind.call(this, e, t),
                  this.addOptions(this.convertToOptions(this._dataToConvert));
              }),
              (r.prototype.select = function (e) {
                var t = this.$element.find('option').filter(function (t, n) {
                  return n.value == e.id.toString();
                });
                0 === t.length && ((t = this.option(e)), this.addOptions(t)),
                  r.__super__.select.call(this, e);
              }),
              (r.prototype.convertToOptions = function (e) {
                function r(e) {
                  return function () {
                    return n(this).val() == e.id;
                  };
                }
                for (
                  var i = this,
                    o = this.$element.find('option'),
                    s = o
                      .map(function () {
                        return i.item(n(this)).id;
                      })
                      .get(),
                    a = [],
                    l = 0;
                  l < e.length;
                  l++
                ) {
                  var c = this._normalizeItem(e[l]);
                  if (n.inArray(c.id, s) >= 0) {
                    var u = o.filter(r(c)),
                      d = this.item(u),
                      p = n.extend(!0, {}, c, d),
                      h = this.option(p);
                    u.replaceWith(h);
                  } else {
                    var f = this.option(c);
                    if (c.children) {
                      var g = this.convertToOptions(c.children);
                      t.appendMany(f, g);
                    }
                    a.push(f);
                  }
                }
                return a;
              }),
              r
            );
          }
        ),
        t.define(
          'select2/data/ajax',
          ['./array', '../utils', 'jquery'],
          function (e, t, n) {
            function r(e, t) {
              (this.ajaxOptions = this._applyDefaults(t.get('ajax'))),
                null != this.ajaxOptions.processResults &&
                  (this.processResults = this.ajaxOptions.processResults),
                r.__super__.constructor.call(this, e, t);
            }
            return (
              t.Extend(r, e),
              (r.prototype._applyDefaults = function (e) {
                var t = {
                  data: function (e) {
                    return n.extend({}, e, { q: e.term });
                  },
                  transport: function (e, t, r) {
                    var i = n.ajax(e);
                    return i.then(t), i.fail(r), i;
                  },
                };
                return n.extend({}, t, e, !0);
              }),
              (r.prototype.processResults = function (e) {
                return e;
              }),
              (r.prototype.query = function (e, t) {
                function r() {
                  var r = o.transport(
                    o,
                    function (r) {
                      var o = i.processResults(r, e);
                      i.options.get('debug') &&
                        window.console &&
                        console.error &&
                        ((o && o.results && n.isArray(o.results)) ||
                          console.error(
                            'Select2: The AJAX results did not return an array in the `results` key of the response.'
                          )),
                        t(o);
                    },
                    function () {
                      ('status' in r && (0 === r.status || '0' === r.status)) ||
                        i.trigger('results:message', {
                          message: 'errorLoading',
                        });
                    }
                  );
                  i._request = r;
                }
                var i = this;
                null != this._request &&
                  (n.isFunction(this._request.abort) && this._request.abort(),
                  (this._request = null));
                var o = n.extend({ type: 'GET' }, this.ajaxOptions);
                'function' == typeof o.url &&
                  (o.url = o.url.call(this.$element, e)),
                  'function' == typeof o.data &&
                    (o.data = o.data.call(this.$element, e)),
                  this.ajaxOptions.delay && null != e.term
                    ? (this._queryTimeout &&
                        window.clearTimeout(this._queryTimeout),
                      (this._queryTimeout = window.setTimeout(
                        r,
                        this.ajaxOptions.delay
                      )))
                    : r();
              }),
              r
            );
          }
        ),
        t.define('select2/data/tags', ['jquery'], function (e) {
          function t(t, n, r) {
            var i = r.get('tags'),
              o = r.get('createTag');
            void 0 !== o && (this.createTag = o);
            var s = r.get('insertTag');
            if (
              (void 0 !== s && (this.insertTag = s),
              t.call(this, n, r),
              e.isArray(i))
            )
              for (var a = 0; a < i.length; a++) {
                var l = i[a],
                  c = this._normalizeItem(l),
                  u = this.option(c);
                this.$element.append(u);
              }
          }
          return (
            (t.prototype.query = function (e, t, n) {
              function r(e, o) {
                for (var s = e.results, a = 0; a < s.length; a++) {
                  var l = s[a],
                    c = null != l.children && !r({ results: l.children }, !0),
                    u = (l.text || '').toUpperCase(),
                    d = (t.term || '').toUpperCase(),
                    p = u === d;
                  if (p || c) return !o && ((e.data = s), void n(e));
                }
                if (o) return !0;
                var h = i.createTag(t);
                if (null != h) {
                  var f = i.option(h);
                  f.attr('data-select2-tag', !0),
                    i.addOptions([f]),
                    i.insertTag(s, h);
                }
                (e.results = s), n(e);
              }
              var i = this;
              return (
                this._removeOldTags(),
                null == t.term || null != t.page
                  ? void e.call(this, t, n)
                  : void e.call(this, t, r)
              );
            }),
            (t.prototype.createTag = function (t, n) {
              var r = e.trim(n.term);
              return '' === r ? null : { id: r, text: r };
            }),
            (t.prototype.insertTag = function (e, t, n) {
              t.unshift(n);
            }),
            (t.prototype._removeOldTags = function (t) {
              var n = this.$element.find('option[data-select2-tag]');
              n.each(function () {
                this.selected || e(this).remove();
              });
            }),
            t
          );
        }),
        t.define('select2/data/tokenizer', ['jquery'], function (e) {
          function t(e, t, n) {
            var r = n.get('tokenizer');
            void 0 !== r && (this.tokenizer = r), e.call(this, t, n);
          }
          return (
            (t.prototype.bind = function (e, t, n) {
              e.call(this, t, n),
                (this.$search =
                  t.dropdown.$search ||
                  t.selection.$search ||
                  n.find('.select2-search__field'));
            }),
            (t.prototype.query = function (t, n, r) {
              function i(t) {
                var n = s._normalizeItem(t),
                  r = s.$element.find('option').filter(function () {
                    return e(this).val() === n.id;
                  });
                if (!r.length) {
                  var i = s.option(n);
                  i.attr('data-select2-tag', !0),
                    s._removeOldTags(),
                    s.addOptions([i]);
                }
                o(n);
              }
              function o(e) {
                s.trigger('select', { data: e });
              }
              var s = this;
              n.term = n.term || '';
              var a = this.tokenizer(n, this.options, i);
              a.term !== n.term &&
                (this.$search.length &&
                  (this.$search.val(a.term), this.$search.trigger('focus')),
                (n.term = a.term)),
                t.call(this, n, r);
            }),
            (t.prototype.tokenizer = function (t, n, r, i) {
              for (
                var o = r.get('tokenSeparators') || [],
                  s = n.term,
                  a = 0,
                  l =
                    this.createTag ||
                    function (e) {
                      return { id: e.term, text: e.term };
                    };
                a < s.length;

              ) {
                var c = s[a];
                if (e.inArray(c, o) !== -1) {
                  var u = s.substr(0, a),
                    d = e.extend({}, n, { term: u }),
                    p = l(d);
                  null != p
                    ? (i(p), (s = s.substr(a + 1) || ''), (a = 0))
                    : a++;
                } else a++;
              }
              return { term: s };
            }),
            t
          );
        }),
        t.define('select2/data/minimumInputLength', [], function () {
          function e(e, t, n) {
            (this.minimumInputLength = n.get('minimumInputLength')),
              e.call(this, t, n);
          }
          return (
            (e.prototype.query = function (e, t, n) {
              return (
                (t.term = t.term || ''),
                t.term.length < this.minimumInputLength
                  ? void this.trigger('results:message', {
                      message: 'inputTooShort',
                      args: {
                        minimum: this.minimumInputLength,
                        input: t.term,
                        params: t,
                      },
                    })
                  : void e.call(this, t, n)
              );
            }),
            e
          );
        }),
        t.define('select2/data/maximumInputLength', [], function () {
          function e(e, t, n) {
            (this.maximumInputLength = n.get('maximumInputLength')),
              e.call(this, t, n);
          }
          return (
            (e.prototype.query = function (e, t, n) {
              return (
                (t.term = t.term || ''),
                this.maximumInputLength > 0 &&
                t.term.length > this.maximumInputLength
                  ? void this.trigger('results:message', {
                      message: 'inputTooLong',
                      args: {
                        maximum: this.maximumInputLength,
                        input: t.term,
                        params: t,
                      },
                    })
                  : void e.call(this, t, n)
              );
            }),
            e
          );
        }),
        t.define('select2/data/maximumSelectionLength', [], function () {
          function e(e, t, n) {
            (this.maximumSelectionLength = n.get('maximumSelectionLength')),
              e.call(this, t, n);
          }
          return (
            (e.prototype.bind = function (e, t, n) {
              var r = this;
              e.call(this, t, n),
                t.on('select', function () {
                  r._checkIfMaximumSelected();
                });
            }),
            (e.prototype.query = function (e, t, n) {
              var r = this;
              this._checkIfMaximumSelected(function () {
                e.call(r, t, n);
              });
            }),
            (e.prototype._checkIfMaximumSelected = function (e, t) {
              var n = this;
              this.current(function (e) {
                var r = null != e ? e.length : 0;
                return n.maximumSelectionLength > 0 &&
                  r >= n.maximumSelectionLength
                  ? void n.trigger('results:message', {
                      message: 'maximumSelected',
                      args: { maximum: n.maximumSelectionLength },
                    })
                  : void (t && t());
              });
            }),
            e
          );
        }),
        t.define('select2/dropdown', ['jquery', './utils'], function (e, t) {
          function n(e, t) {
            (this.$element = e),
              (this.options = t),
              n.__super__.constructor.call(this);
          }
          return (
            t.Extend(n, t.Observable),
            (n.prototype.render = function () {
              var t = e(
                '<span class="select2-dropdown"><span class="select2-results"></span></span>'
              );
              return (
                t.attr('dir', this.options.get('dir')), (this.$dropdown = t), t
              );
            }),
            (n.prototype.bind = function () {}),
            (n.prototype.position = function (e, t) {}),
            (n.prototype.destroy = function () {
              this.$dropdown.remove();
            }),
            n
          );
        }),
        t.define(
          'select2/dropdown/search',
          ['jquery', '../utils'],
          function (e, t) {
            function n() {}
            return (
              (n.prototype.render = function (t) {
                var n = t.call(this),
                  r = e(
                    '<span class="select2-search select2-search--dropdown"><input class="select2-search__field" type="search" tabindex="-1" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" /></span>'
                  );
                return (
                  (this.$searchContainer = r),
                  (this.$search = r.find('input')),
                  n.prepend(r),
                  n
                );
              }),
              (n.prototype.bind = function (t, n, r) {
                var i = this,
                  o = n.id + '-results';
                t.call(this, n, r),
                  this.$search.on('keydown', function (e) {
                    i.trigger('keypress', e),
                      (i._keyUpPrevented = e.isDefaultPrevented());
                  }),
                  this.$search.on('input', function (t) {
                    e(this).off('keyup');
                  }),
                  this.$search.on('keyup input', function (e) {
                    i.handleSearch(e);
                  }),
                  n.on('open', function () {
                    i.$search.attr('tabindex', 0),
                      i.$search.attr('aria-controls', o),
                      i.$search.trigger('focus'),
                      window.setTimeout(function () {
                        i.$search.trigger('focus');
                      }, 0);
                  }),
                  n.on('close', function () {
                    i.$search.attr('tabindex', -1),
                      i.$search.removeAttr('aria-controls'),
                      i.$search.removeAttr('aria-activedescendant'),
                      i.$search.val(''),
                      i.$search.trigger('blur');
                  }),
                  n.on('focus', function () {
                    n.isOpen() || i.$search.trigger('focus');
                  }),
                  n.on('results:all', function (e) {
                    if (null == e.query.term || '' === e.query.term) {
                      var t = i.showSearch(e);
                      t
                        ? i.$searchContainer.removeClass('select2-search--hide')
                        : i.$searchContainer.addClass('select2-search--hide');
                    }
                  }),
                  n.on('results:focus', function (e) {
                    e.data._resultId
                      ? i.$search.attr(
                          'aria-activedescendant',
                          e.data._resultId
                        )
                      : i.$search.removeAttr('aria-activedescendant');
                  });
              }),
              (n.prototype.handleSearch = function (e) {
                if (!this._keyUpPrevented) {
                  var t = this.$search.val();
                  this.trigger('query', { term: t });
                }
                this._keyUpPrevented = !1;
              }),
              (n.prototype.showSearch = function (e, t) {
                return !0;
              }),
              n
            );
          }
        ),
        t.define('select2/dropdown/hidePlaceholder', [], function () {
          function e(e, t, n, r) {
            (this.placeholder = this.normalizePlaceholder(
              n.get('placeholder')
            )),
              e.call(this, t, n, r);
          }
          return (
            (e.prototype.append = function (e, t) {
              (t.results = this.removePlaceholder(t.results)), e.call(this, t);
            }),
            (e.prototype.normalizePlaceholder = function (e, t) {
              return 'string' == typeof t && (t = { id: '', text: t }), t;
            }),
            (e.prototype.removePlaceholder = function (e, t) {
              for (var n = t.slice(0), r = t.length - 1; r >= 0; r--) {
                var i = t[r];
                this.placeholder.id === i.id && n.splice(r, 1);
              }
              return n;
            }),
            e
          );
        }),
        t.define('select2/dropdown/infiniteScroll', ['jquery'], function (e) {
          function t(e, t, n, r) {
            (this.lastParams = {}),
              e.call(this, t, n, r),
              (this.$loadingMore = this.createLoadingMore()),
              (this.loading = !1);
          }
          return (
            (t.prototype.append = function (e, t) {
              this.$loadingMore.remove(),
                (this.loading = !1),
                e.call(this, t),
                this.showLoadingMore(t) &&
                  (this.$results.append(this.$loadingMore),
                  this.loadMoreIfNeeded());
            }),
            (t.prototype.bind = function (e, t, n) {
              var r = this;
              e.call(this, t, n),
                t.on('query', function (e) {
                  (r.lastParams = e), (r.loading = !0);
                }),
                t.on('query:append', function (e) {
                  (r.lastParams = e), (r.loading = !0);
                }),
                this.$results.on('scroll', this.loadMoreIfNeeded.bind(this));
            }),
            (t.prototype.loadMoreIfNeeded = function () {
              var t = e.contains(
                document.documentElement,
                this.$loadingMore[0]
              );
              if (!this.loading && t) {
                var n =
                    this.$results.offset().top + this.$results.outerHeight(!1),
                  r =
                    this.$loadingMore.offset().top +
                    this.$loadingMore.outerHeight(!1);
                n + 50 >= r && this.loadMore();
              }
            }),
            (t.prototype.loadMore = function () {
              this.loading = !0;
              var t = e.extend({}, { page: 1 }, this.lastParams);
              t.page++, this.trigger('query:append', t);
            }),
            (t.prototype.showLoadingMore = function (e, t) {
              return t.pagination && t.pagination.more;
            }),
            (t.prototype.createLoadingMore = function () {
              var t = e(
                  '<li class="select2-results__option select2-results__option--load-more"role="option" aria-disabled="true"></li>'
                ),
                n = this.options.get('translations').get('loadingMore');
              return t.html(n(this.lastParams)), t;
            }),
            t
          );
        }),
        t.define(
          'select2/dropdown/attachBody',
          ['jquery', '../utils'],
          function (e, t) {
            function n(t, n, r) {
              (this.$dropdownParent = e(
                r.get('dropdownParent') || document.body
              )),
                t.call(this, n, r);
            }
            return (
              (n.prototype.bind = function (e, t, n) {
                var r = this;
                e.call(this, t, n),
                  t.on('open', function () {
                    r._showDropdown(),
                      r._attachPositioningHandler(t),
                      r._bindContainerResultHandlers(t);
                  }),
                  t.on('close', function () {
                    r._hideDropdown(), r._detachPositioningHandler(t);
                  }),
                  this.$dropdownContainer.on('mousedown', function (e) {
                    e.stopPropagation();
                  });
              }),
              (n.prototype.destroy = function (e) {
                e.call(this), this.$dropdownContainer.remove();
              }),
              (n.prototype.position = function (e, t, n) {
                t.attr('class', n.attr('class')),
                  t.removeClass('select2'),
                  t.addClass('select2-container--open'),
                  t.css({ position: 'absolute', top: -999999 }),
                  (this.$container = n);
              }),
              (n.prototype.render = function (t) {
                var n = e('<span></span>'),
                  r = t.call(this);
                return n.append(r), (this.$dropdownContainer = n), n;
              }),
              (n.prototype._hideDropdown = function (e) {
                this.$dropdownContainer.detach();
              }),
              (n.prototype._bindContainerResultHandlers = function (e, t) {
                if (!this._containerResultsHandlersBound) {
                  var n = this;
                  t.on('results:all', function () {
                    n._positionDropdown(), n._resizeDropdown();
                  }),
                    t.on('results:append', function () {
                      n._positionDropdown(), n._resizeDropdown();
                    }),
                    t.on('results:message', function () {
                      n._positionDropdown(), n._resizeDropdown();
                    }),
                    t.on('select', function () {
                      n._positionDropdown(), n._resizeDropdown();
                    }),
                    t.on('unselect', function () {
                      n._positionDropdown(), n._resizeDropdown();
                    }),
                    (this._containerResultsHandlersBound = !0);
                }
              }),
              (n.prototype._attachPositioningHandler = function (n, r) {
                var i = this,
                  o = 'scroll.select2.' + r.id,
                  s = 'resize.select2.' + r.id,
                  a = 'orientationchange.select2.' + r.id,
                  l = this.$container.parents().filter(t.hasScroll);
                l.each(function () {
                  t.StoreData(this, 'select2-scroll-position', {
                    x: e(this).scrollLeft(),
                    y: e(this).scrollTop(),
                  });
                }),
                  l.on(o, function (n) {
                    var r = t.GetData(this, 'select2-scroll-position');
                    e(this).scrollTop(r.y);
                  }),
                  e(window).on(o + ' ' + s + ' ' + a, function (e) {
                    i._positionDropdown(), i._resizeDropdown();
                  });
              }),
              (n.prototype._detachPositioningHandler = function (n, r) {
                var i = 'scroll.select2.' + r.id,
                  o = 'resize.select2.' + r.id,
                  s = 'orientationchange.select2.' + r.id,
                  a = this.$container.parents().filter(t.hasScroll);
                a.off(i), e(window).off(i + ' ' + o + ' ' + s);
              }),
              (n.prototype._positionDropdown = function () {
                var t = e(window),
                  n = this.$dropdown.hasClass('select2-dropdown--above'),
                  r = this.$dropdown.hasClass('select2-dropdown--below'),
                  i = null,
                  o = this.$container.offset();
                o.bottom = o.top + this.$container.outerHeight(!1);
                var s = { height: this.$container.outerHeight(!1) };
                (s.top = o.top), (s.bottom = o.top + s.height);
                var a = { height: this.$dropdown.outerHeight(!1) },
                  l = {
                    top: t.scrollTop(),
                    bottom: t.scrollTop() + t.height(),
                  },
                  c = l.top < o.top - a.height,
                  u = l.bottom > o.bottom + a.height,
                  d = { left: o.left, top: s.bottom },
                  p = this.$dropdownParent;
                'static' === p.css('position') && (p = p.offsetParent());
                var h = p.offset();
                (d.top -= h.top),
                  (d.left -= h.left),
                  n || r || (i = 'below'),
                  u || !c || n ? !c && u && n && (i = 'below') : (i = 'above'),
                  ('above' == i || (n && 'below' !== i)) &&
                    (d.top = s.top - h.top - a.height),
                  null != i &&
                    (this.$dropdown
                      .removeClass(
                        'select2-dropdown--below select2-dropdown--above'
                      )
                      .addClass('select2-dropdown--' + i),
                    this.$container
                      .removeClass(
                        'select2-container--below select2-container--above'
                      )
                      .addClass('select2-container--' + i)),
                  this.$dropdownContainer.css(d);
              }),
              (n.prototype._resizeDropdown = function () {
                var e = { width: this.$container.outerWidth(!1) + 'px' };
                this.options.get('dropdownAutoWidth') &&
                  ((e.minWidth = e.width),
                  (e.position = 'relative'),
                  (e.width = 'auto')),
                  this.$dropdown.css(e);
              }),
              (n.prototype._showDropdown = function (e) {
                this.$dropdownContainer.appendTo(this.$dropdownParent),
                  this._positionDropdown(),
                  this._resizeDropdown();
              }),
              n
            );
          }
        ),
        t.define('select2/dropdown/minimumResultsForSearch', [], function () {
          function e(t) {
            for (var n = 0, r = 0; r < t.length; r++) {
              var i = t[r];
              i.children ? (n += e(i.children)) : n++;
            }
            return n;
          }
          function t(e, t, n, r) {
            (this.minimumResultsForSearch = n.get('minimumResultsForSearch')),
              this.minimumResultsForSearch < 0 &&
                (this.minimumResultsForSearch = 1 / 0),
              e.call(this, t, n, r);
          }
          return (
            (t.prototype.showSearch = function (t, n) {
              return (
                !(e(n.data.results) < this.minimumResultsForSearch) &&
                t.call(this, n)
              );
            }),
            t
          );
        }),
        t.define('select2/dropdown/selectOnClose', ['../utils'], function (e) {
          function t() {}
          return (
            (t.prototype.bind = function (e, t, n) {
              var r = this;
              e.call(this, t, n),
                t.on('close', function (e) {
                  r._handleSelectOnClose(e);
                });
            }),
            (t.prototype._handleSelectOnClose = function (t, n) {
              if (n && null != n.originalSelect2Event) {
                var r = n.originalSelect2Event;
                if ('select' === r._type || 'unselect' === r._type) return;
              }
              var i = this.getHighlightedResults();
              if (!(i.length < 1)) {
                var o = e.GetData(i[0], 'data');
                (null != o.element && o.element.selected) ||
                  (null == o.element && o.selected) ||
                  this.trigger('select', { data: o });
              }
            }),
            t
          );
        }),
        t.define('select2/dropdown/closeOnSelect', [], function () {
          function e() {}
          return (
            (e.prototype.bind = function (e, t, n) {
              var r = this;
              e.call(this, t, n),
                t.on('select', function (e) {
                  r._selectTriggered(e);
                }),
                t.on('unselect', function (e) {
                  r._selectTriggered(e);
                });
            }),
            (e.prototype._selectTriggered = function (e, t) {
              var n = t.originalEvent;
              (n && (n.ctrlKey || n.metaKey)) ||
                this.trigger('close', {
                  originalEvent: n,
                  originalSelect2Event: t,
                });
            }),
            e
          );
        }),
        t.define('select2/i18n/en', [], function () {
          return {
            errorLoading: function () {
              return 'The results could not be loaded.';
            },
            inputTooLong: function (e) {
              var t = e.input.length - e.maximum,
                n = 'Please delete ' + t + ' character';
              return 1 != t && (n += 's'), n;
            },
            inputTooShort: function (e) {
              var t = e.minimum - e.input.length,
                n = 'Please enter ' + t + ' or more characters';
              return n;
            },
            loadingMore: function () {
              return 'Loading more results…';
            },
            maximumSelected: function (e) {
              var t = 'You can only select ' + e.maximum + ' item';
              return 1 != e.maximum && (t += 's'), t;
            },
            noResults: function () {
              return 'No results found';
            },
            searching: function () {
              return 'Searching…';
            },
            removeAllItems: function () {
              return 'Remove all items';
            },
          };
        }),
        t.define(
          'select2/defaults',
          [
            'jquery',
            'require',
            './results',
            './selection/single',
            './selection/multiple',
            './selection/placeholder',
            './selection/allowClear',
            './selection/search',
            './selection/eventRelay',
            './utils',
            './translation',
            './diacritics',
            './data/select',
            './data/array',
            './data/ajax',
            './data/tags',
            './data/tokenizer',
            './data/minimumInputLength',
            './data/maximumInputLength',
            './data/maximumSelectionLength',
            './dropdown',
            './dropdown/search',
            './dropdown/hidePlaceholder',
            './dropdown/infiniteScroll',
            './dropdown/attachBody',
            './dropdown/minimumResultsForSearch',
            './dropdown/selectOnClose',
            './dropdown/closeOnSelect',
            './i18n/en',
          ],
          function (
            e,
            t,
            n,
            r,
            i,
            o,
            s,
            a,
            l,
            c,
            u,
            d,
            p,
            h,
            f,
            g,
            m,
            v,
            y,
            _,
            $,
            w,
            b,
            A,
            x,
            D,
            S,
            E,
            C
          ) {
            function O() {
              this.reset();
            }
            (O.prototype.apply = function (u) {
              if (
                ((u = e.extend(!0, {}, this.defaults, u)),
                null == u.dataAdapter)
              ) {
                if (
                  (null != u.ajax
                    ? (u.dataAdapter = f)
                    : null != u.data
                    ? (u.dataAdapter = h)
                    : (u.dataAdapter = p),
                  u.minimumInputLength > 0 &&
                    (u.dataAdapter = c.Decorate(u.dataAdapter, v)),
                  u.maximumInputLength > 0 &&
                    (u.dataAdapter = c.Decorate(u.dataAdapter, y)),
                  u.maximumSelectionLength > 0 &&
                    (u.dataAdapter = c.Decorate(u.dataAdapter, _)),
                  u.tags && (u.dataAdapter = c.Decorate(u.dataAdapter, g)),
                  (null == u.tokenSeparators && null == u.tokenizer) ||
                    (u.dataAdapter = c.Decorate(u.dataAdapter, m)),
                  null != u.query)
                ) {
                  var d = t(u.amdBase + 'compat/query');
                  u.dataAdapter = c.Decorate(u.dataAdapter, d);
                }
                if (null != u.initSelection) {
                  var C = t(u.amdBase + 'compat/initSelection');
                  u.dataAdapter = c.Decorate(u.dataAdapter, C);
                }
              }
              if (
                (null == u.resultsAdapter &&
                  ((u.resultsAdapter = n),
                  null != u.ajax &&
                    (u.resultsAdapter = c.Decorate(u.resultsAdapter, A)),
                  null != u.placeholder &&
                    (u.resultsAdapter = c.Decorate(u.resultsAdapter, b)),
                  u.selectOnClose &&
                    (u.resultsAdapter = c.Decorate(u.resultsAdapter, S))),
                null == u.dropdownAdapter)
              ) {
                if (u.multiple) u.dropdownAdapter = $;
                else {
                  var O = c.Decorate($, w);
                  u.dropdownAdapter = O;
                }
                if (
                  (0 !== u.minimumResultsForSearch &&
                    (u.dropdownAdapter = c.Decorate(u.dropdownAdapter, D)),
                  u.closeOnSelect &&
                    (u.dropdownAdapter = c.Decorate(u.dropdownAdapter, E)),
                  null != u.dropdownCssClass ||
                    null != u.dropdownCss ||
                    null != u.adaptDropdownCssClass)
                ) {
                  var T = t(u.amdBase + 'compat/dropdownCss');
                  u.dropdownAdapter = c.Decorate(u.dropdownAdapter, T);
                }
                u.dropdownAdapter = c.Decorate(u.dropdownAdapter, x);
              }
              if (null == u.selectionAdapter) {
                if (
                  (u.multiple
                    ? (u.selectionAdapter = i)
                    : (u.selectionAdapter = r),
                  null != u.placeholder &&
                    (u.selectionAdapter = c.Decorate(u.selectionAdapter, o)),
                  u.allowClear &&
                    (u.selectionAdapter = c.Decorate(u.selectionAdapter, s)),
                  u.multiple &&
                    (u.selectionAdapter = c.Decorate(u.selectionAdapter, a)),
                  null != u.containerCssClass ||
                    null != u.containerCss ||
                    null != u.adaptContainerCssClass)
                ) {
                  var q = t(u.amdBase + 'compat/containerCss');
                  u.selectionAdapter = c.Decorate(u.selectionAdapter, q);
                }
                u.selectionAdapter = c.Decorate(u.selectionAdapter, l);
              }
              (u.language = this._resolveLanguage(u.language)),
                u.language.push('en');
              for (var L = [], I = 0; I < u.language.length; I++) {
                var j = u.language[I];
                L.indexOf(j) === -1 && L.push(j);
              }
              return (
                (u.language = L),
                (u.translations = this._processTranslations(
                  u.language,
                  u.debug
                )),
                u
              );
            }),
              (O.prototype.reset = function () {
                function t(e) {
                  function t(e) {
                    return d[e] || e;
                  }
                  return e.replace(/[^\u0000-\u007E]/g, t);
                }
                function n(r, i) {
                  if ('' === e.trim(r.term)) return i;
                  if (i.children && i.children.length > 0) {
                    for (
                      var o = e.extend(!0, {}, i), s = i.children.length - 1;
                      s >= 0;
                      s--
                    ) {
                      var a = i.children[s],
                        l = n(r, a);
                      null == l && o.children.splice(s, 1);
                    }
                    return o.children.length > 0 ? o : n(r, o);
                  }
                  var c = t(i.text).toUpperCase(),
                    u = t(r.term).toUpperCase();
                  return c.indexOf(u) > -1 ? i : null;
                }
                this.defaults = {
                  amdBase: './',
                  amdLanguageBase: './i18n/',
                  closeOnSelect: !0,
                  debug: !1,
                  dropdownAutoWidth: !1,
                  escapeMarkup: c.escapeMarkup,
                  language: {},
                  matcher: n,
                  minimumInputLength: 0,
                  maximumInputLength: 0,
                  maximumSelectionLength: 0,
                  minimumResultsForSearch: 0,
                  selectOnClose: !1,
                  scrollAfterSelect: !1,
                  sorter: function (e) {
                    return e;
                  },
                  templateResult: function (e) {
                    return e.text;
                  },
                  templateSelection: function (e) {
                    return e.text;
                  },
                  theme: 'default',
                  width: 'resolve',
                };
              }),
              (O.prototype.applyFromElement = function (e, t) {
                var n = e.language,
                  r = this.defaults.language,
                  i = t.prop('lang'),
                  o = t.closest('[lang]').prop('lang'),
                  s = Array.prototype.concat.call(
                    this._resolveLanguage(i),
                    this._resolveLanguage(n),
                    this._resolveLanguage(r),
                    this._resolveLanguage(o)
                  );
                return (e.language = s), e;
              }),
              (O.prototype._resolveLanguage = function (t) {
                if (!t) return [];
                if (e.isEmptyObject(t)) return [];
                if (e.isPlainObject(t)) return [t];
                var n;
                n = e.isArray(t) ? t : [t];
                for (var r = [], i = 0; i < n.length; i++)
                  if (
                    (r.push(n[i]),
                    'string' == typeof n[i] && n[i].indexOf('-') > 0)
                  ) {
                    var o = n[i].split('-'),
                      s = o[0];
                    r.push(s);
                  }
                return r;
              }),
              (O.prototype._processTranslations = function (t, n) {
                for (var r = new u(), i = 0; i < t.length; i++) {
                  var o = new u(),
                    s = t[i];
                  if ('string' == typeof s)
                    try {
                      o = u.loadPath(s);
                    } catch (a) {
                      try {
                        (s = this.defaults.amdLanguageBase + s),
                          (o = u.loadPath(s));
                      } catch (l) {
                        n &&
                          window.console &&
                          console.warn &&
                          console.warn(
                            'Select2: The language file for "' +
                              s +
                              '" could not be automatically loaded. A fallback will be used instead.'
                          );
                      }
                    }
                  else o = e.isPlainObject(s) ? new u(s) : s;
                  r.extend(o);
                }
                return r;
              }),
              (O.prototype.set = function (t, n) {
                var r = e.camelCase(t),
                  i = {};
                i[r] = n;
                var o = c._convertData(i);
                e.extend(!0, this.defaults, o);
              });
            var T = new O();
            return T;
          }
        ),
        t.define(
          'select2/options',
          ['require', 'jquery', './defaults', './utils'],
          function (e, t, n, r) {
            function i(t, i) {
              if (
                ((this.options = t),
                null != i && this.fromElement(i),
                null != i &&
                  (this.options = n.applyFromElement(this.options, i)),
                (this.options = n.apply(this.options)),
                i && i.is('input'))
              ) {
                var o = e(this.get('amdBase') + 'compat/inputData');
                this.options.dataAdapter = r.Decorate(
                  this.options.dataAdapter,
                  o
                );
              }
            }
            return (
              (i.prototype.fromElement = function (e) {
                function n(e, t) {
                  return t.toUpperCase();
                }
                var i = ['select2'];
                null == this.options.multiple &&
                  (this.options.multiple = e.prop('multiple')),
                  null == this.options.disabled &&
                    (this.options.disabled = e.prop('disabled')),
                  null == this.options.dir &&
                    (e.prop('dir')
                      ? (this.options.dir = e.prop('dir'))
                      : e.closest('[dir]').prop('dir')
                      ? (this.options.dir = e.closest('[dir]').prop('dir'))
                      : (this.options.dir = 'ltr')),
                  e.prop('disabled', this.options.disabled),
                  e.prop('multiple', this.options.multiple),
                  r.GetData(e[0], 'select2Tags') &&
                    (this.options.debug &&
                      window.console &&
                      console.warn &&
                      console.warn(
                        'Select2: The `data-select2-tags` attribute has been changed to use the `data-data` and `data-tags="true"` attributes and will be removed in future versions of Select2.'
                      ),
                    r.StoreData(e[0], 'data', r.GetData(e[0], 'select2Tags')),
                    r.StoreData(e[0], 'tags', !0)),
                  r.GetData(e[0], 'ajaxUrl') &&
                    (this.options.debug &&
                      window.console &&
                      console.warn &&
                      console.warn(
                        'Select2: The `data-ajax-url` attribute has been changed to `data-ajax--url` and support for the old attribute will be removed in future versions of Select2.'
                      ),
                    e.attr('ajax--url', r.GetData(e[0], 'ajaxUrl')),
                    r.StoreData(e[0], 'ajax-Url', r.GetData(e[0], 'ajaxUrl')));
                for (var o = {}, s = 0; s < e[0].attributes.length; s++) {
                  var a = e[0].attributes[s].name,
                    l = 'data-';
                  if (a.substr(0, l.length) == l) {
                    var c = a.substring(l.length),
                      u = r.GetData(e[0], c),
                      d = c.replace(/-([a-z])/g, n);
                    o[d] = u;
                  }
                }
                t.fn.jquery &&
                  '1.' == t.fn.jquery.substr(0, 2) &&
                  e[0].dataset &&
                  (o = t.extend(!0, {}, e[0].dataset, o));
                var p = t.extend(!0, {}, r.GetData(e[0]), o);
                p = r._convertData(p);
                for (var h in p)
                  t.inArray(h, i) > -1 ||
                    (t.isPlainObject(this.options[h])
                      ? t.extend(this.options[h], p[h])
                      : (this.options[h] = p[h]));
                return this;
              }),
              (i.prototype.get = function (e) {
                return this.options[e];
              }),
              (i.prototype.set = function (e, t) {
                this.options[e] = t;
              }),
              i
            );
          }
        ),
        t.define(
          'select2/core',
          ['jquery', './options', './utils', './keys'],
          function (e, t, n, r) {
            var i = function (e, r) {
              null != n.GetData(e[0], 'select2') &&
                n.GetData(e[0], 'select2').destroy(),
                (this.$element = e),
                (this.id = this._generateId(e)),
                (r = r || {}),
                (this.options = new t(r, e)),
                i.__super__.constructor.call(this);
              var o = e.attr('tabindex') || 0;
              n.StoreData(e[0], 'old-tabindex', o), e.attr('tabindex', '-1');
              var s = this.options.get('dataAdapter');
              this.dataAdapter = new s(e, this.options);
              var a = this.render();
              this._placeContainer(a);
              var l = this.options.get('selectionAdapter');
              (this.selection = new l(e, this.options)),
                (this.$selection = this.selection.render()),
                this.selection.position(this.$selection, a);
              var c = this.options.get('dropdownAdapter');
              (this.dropdown = new c(e, this.options)),
                (this.$dropdown = this.dropdown.render()),
                this.dropdown.position(this.$dropdown, a);
              var u = this.options.get('resultsAdapter');
              (this.results = new u(e, this.options, this.dataAdapter)),
                (this.$results = this.results.render()),
                this.results.position(this.$results, this.$dropdown);
              var d = this;
              this._bindAdapters(),
                this._registerDomEvents(),
                this._registerDataEvents(),
                this._registerSelectionEvents(),
                this._registerDropdownEvents(),
                this._registerResultsEvents(),
                this._registerEvents(),
                this.dataAdapter.current(function (e) {
                  d.trigger('selection:update', { data: e });
                }),
                e.addClass('select2-hidden-accessible'),
                e.attr('aria-hidden', 'true'),
                this._syncAttributes(),
                n.StoreData(e[0], 'select2', this),
                e.data('select2', this);
            };
            return (
              n.Extend(i, n.Observable),
              (i.prototype._generateId = function (e) {
                var t = '';
                return (
                  (t =
                    null != e.attr('id')
                      ? e.attr('id')
                      : null != e.attr('name')
                      ? e.attr('name') + '-' + n.generateChars(2)
                      : n.generateChars(4)),
                  (t = t.replace(/(:|\.|\[|\]|,)/g, '')),
                  (t = 'select2-' + t)
                );
              }),
              (i.prototype._placeContainer = function (e) {
                e.insertAfter(this.$element);
                var t = this._resolveWidth(
                  this.$element,
                  this.options.get('width')
                );
                null != t && e.css('width', t);
              }),
              (i.prototype._resolveWidth = function (e, t) {
                var n =
                  /^width:(([-+]?([0-9]*\.)?[0-9]+)(px|em|ex|%|in|cm|mm|pt|pc))/i;
                if ('resolve' == t) {
                  var r = this._resolveWidth(e, 'style');
                  return null != r ? r : this._resolveWidth(e, 'element');
                }
                if ('element' == t) {
                  var i = e.outerWidth(!1);
                  return i <= 0 ? 'auto' : i + 'px';
                }
                if ('style' == t) {
                  var o = e.attr('style');
                  if ('string' != typeof o) return null;
                  for (
                    var s = o.split(';'), a = 0, l = s.length;
                    a < l;
                    a += 1
                  ) {
                    var c = s[a].replace(/\s/g, ''),
                      u = c.match(n);
                    if (null !== u && u.length >= 1) return u[1];
                  }
                  return null;
                }
                if ('computedstyle' == t) {
                  var d = window.getComputedStyle(e[0]);
                  return d.width;
                }
                return t;
              }),
              (i.prototype._bindAdapters = function () {
                this.dataAdapter.bind(this, this.$container),
                  this.selection.bind(this, this.$container),
                  this.dropdown.bind(this, this.$container),
                  this.results.bind(this, this.$container);
              }),
              (i.prototype._registerDomEvents = function () {
                var t = this;
                this.$element.on('change.select2', function () {
                  t.dataAdapter.current(function (e) {
                    t.trigger('selection:update', { data: e });
                  });
                }),
                  this.$element.on('focus.select2', function (e) {
                    t.trigger('focus', e);
                  }),
                  (this._syncA = n.bind(this._syncAttributes, this)),
                  (this._syncS = n.bind(this._syncSubtree, this)),
                  this.$element[0].attachEvent &&
                    this.$element[0].attachEvent(
                      'onpropertychange',
                      this._syncA
                    );
                var r =
                  window.MutationObserver ||
                  window.WebKitMutationObserver ||
                  window.MozMutationObserver;
                null != r
                  ? ((this._observer = new r(function (n) {
                      e.each(n, t._syncA), e.each(n, t._syncS);
                    })),
                    this._observer.observe(this.$element[0], {
                      attributes: !0,
                      childList: !0,
                      subtree: !1,
                    }))
                  : this.$element[0].addEventListener &&
                    (this.$element[0].addEventListener(
                      'DOMAttrModified',
                      t._syncA,
                      !1
                    ),
                    this.$element[0].addEventListener(
                      'DOMNodeInserted',
                      t._syncS,
                      !1
                    ),
                    this.$element[0].addEventListener(
                      'DOMNodeRemoved',
                      t._syncS,
                      !1
                    ));
              }),
              (i.prototype._registerDataEvents = function () {
                var e = this;
                this.dataAdapter.on('*', function (t, n) {
                  e.trigger(t, n);
                });
              }),
              (i.prototype._registerSelectionEvents = function () {
                var t = this,
                  n = ['toggle', 'focus'];
                this.selection.on('toggle', function () {
                  t.toggleDropdown();
                }),
                  this.selection.on('focus', function (e) {
                    t.focus(e);
                  }),
                  this.selection.on('*', function (r, i) {
                    e.inArray(r, n) === -1 && t.trigger(r, i);
                  });
              }),
              (i.prototype._registerDropdownEvents = function () {
                var e = this;
                this.dropdown.on('*', function (t, n) {
                  e.trigger(t, n);
                });
              }),
              (i.prototype._registerResultsEvents = function () {
                var e = this;
                this.results.on('*', function (t, n) {
                  e.trigger(t, n);
                });
              }),
              (i.prototype._registerEvents = function () {
                var e = this;
                this.on('open', function () {
                  e.$container.addClass('select2-container--open');
                }),
                  this.on('close', function () {
                    e.$container.removeClass('select2-container--open');
                  }),
                  this.on('enable', function () {
                    e.$container.removeClass('select2-container--disabled');
                  }),
                  this.on('disable', function () {
                    e.$container.addClass('select2-container--disabled');
                  }),
                  this.on('blur', function () {
                    e.$container.removeClass('select2-container--focus');
                  }),
                  this.on('query', function (t) {
                    e.isOpen() || e.trigger('open', {}),
                      this.dataAdapter.query(t, function (n) {
                        e.trigger('results:all', { data: n, query: t });
                      });
                  }),
                  this.on('query:append', function (t) {
                    this.dataAdapter.query(t, function (n) {
                      e.trigger('results:append', { data: n, query: t });
                    });
                  }),
                  this.on('keypress', function (t) {
                    var n = t.which;
                    e.isOpen()
                      ? n === r.ESC || n === r.TAB || (n === r.UP && t.altKey)
                        ? (e.close(), t.preventDefault())
                        : n === r.ENTER
                        ? (e.trigger('results:select', {}), t.preventDefault())
                        : n === r.SPACE && t.ctrlKey
                        ? (e.trigger('results:toggle', {}), t.preventDefault())
                        : n === r.UP
                        ? (e.trigger('results:previous', {}),
                          t.preventDefault())
                        : n === r.DOWN &&
                          (e.trigger('results:next', {}), t.preventDefault())
                      : (n === r.ENTER ||
                          n === r.SPACE ||
                          (n === r.DOWN && t.altKey)) &&
                        (e.open(), t.preventDefault());
                  });
              }),
              (i.prototype._syncAttributes = function () {
                this.options.set('disabled', this.$element.prop('disabled')),
                  this.options.get('disabled')
                    ? (this.isOpen() && this.close(),
                      this.trigger('disable', {}))
                    : this.trigger('enable', {});
              }),
              (i.prototype._syncSubtree = function (e, t) {
                var n = !1,
                  r = this;
                if (
                  !e ||
                  !e.target ||
                  'OPTION' === e.target.nodeName ||
                  'OPTGROUP' === e.target.nodeName
                ) {
                  if (t)
                    if (t.addedNodes && t.addedNodes.length > 0)
                      for (var i = 0; i < t.addedNodes.length; i++) {
                        var o = t.addedNodes[i];
                        o.selected && (n = !0);
                      }
                    else
                      t.removedNodes && t.removedNodes.length > 0 && (n = !0);
                  else n = !0;
                  n &&
                    this.dataAdapter.current(function (e) {
                      r.trigger('selection:update', { data: e });
                    });
                }
              }),
              (i.prototype.trigger = function (e, t) {
                var n = i.__super__.trigger,
                  r = {
                    open: 'opening',
                    close: 'closing',
                    select: 'selecting',
                    unselect: 'unselecting',
                    clear: 'clearing',
                  };
                if ((void 0 === t && (t = {}), e in r)) {
                  var o = r[e],
                    s = { prevented: !1, name: e, args: t };
                  if ((n.call(this, o, s), s.prevented))
                    return void (t.prevented = !0);
                }
                n.call(this, e, t);
              }),
              (i.prototype.toggleDropdown = function () {
                this.options.get('disabled') ||
                  (this.isOpen() ? this.close() : this.open());
              }),
              (i.prototype.open = function () {
                this.isOpen() || this.trigger('query', {});
              }),
              (i.prototype.close = function () {
                this.isOpen() && this.trigger('close', {});
              }),
              (i.prototype.isOpen = function () {
                return this.$container.hasClass('select2-container--open');
              }),
              (i.prototype.hasFocus = function () {
                return this.$container.hasClass('select2-container--focus');
              }),
              (i.prototype.focus = function (e) {
                this.hasFocus() ||
                  (this.$container.addClass('select2-container--focus'),
                  this.trigger('focus', {}));
              }),
              (i.prototype.enable = function (e) {
                this.options.get('debug') &&
                  window.console &&
                  console.warn &&
                  console.warn(
                    'Select2: The `select2("enable")` method has been deprecated and will be removed in later Select2 versions. Use $element.prop("disabled") instead.'
                  ),
                  (null != e && 0 !== e.length) || (e = [!0]);
                var t = !e[0];
                this.$element.prop('disabled', t);
              }),
              (i.prototype.data = function () {
                this.options.get('debug') &&
                  arguments.length > 0 &&
                  window.console &&
                  console.warn &&
                  console.warn(
                    'Select2: Data can no longer be set using `select2("data")`. You should consider setting the value instead using `$element.val()`.'
                  );
                var e = [];
                return (
                  this.dataAdapter.current(function (t) {
                    e = t;
                  }),
                  e
                );
              }),
              (i.prototype.val = function (t) {
                if (
                  (this.options.get('debug') &&
                    window.console &&
                    console.warn &&
                    console.warn(
                      'Select2: The `select2("val")` method has been deprecated and will be removed in later Select2 versions. Use $element.val() instead.'
                    ),
                  null == t || 0 === t.length)
                )
                  return this.$element.val();
                var n = t[0];
                e.isArray(n) &&
                  (n = e.map(n, function (e) {
                    return e.toString();
                  })),
                  this.$element.val(n).trigger('change');
              }),
              (i.prototype.destroy = function () {
                this.$container.remove(),
                  this.$element[0].detachEvent &&
                    this.$element[0].detachEvent(
                      'onpropertychange',
                      this._syncA
                    ),
                  null != this._observer
                    ? (this._observer.disconnect(), (this._observer = null))
                    : this.$element[0].removeEventListener &&
                      (this.$element[0].removeEventListener(
                        'DOMAttrModified',
                        this._syncA,
                        !1
                      ),
                      this.$element[0].removeEventListener(
                        'DOMNodeInserted',
                        this._syncS,
                        !1
                      ),
                      this.$element[0].removeEventListener(
                        'DOMNodeRemoved',
                        this._syncS,
                        !1
                      )),
                  (this._syncA = null),
                  (this._syncS = null),
                  this.$element.off('.select2'),
                  this.$element.attr(
                    'tabindex',
                    n.GetData(this.$element[0], 'old-tabindex')
                  ),
                  this.$element.removeClass('select2-hidden-accessible'),
                  this.$element.attr('aria-hidden', 'false'),
                  n.RemoveData(this.$element[0]),
                  this.$element.removeData('select2'),
                  this.dataAdapter.destroy(),
                  this.selection.destroy(),
                  this.dropdown.destroy(),
                  this.results.destroy(),
                  (this.dataAdapter = null),
                  (this.selection = null),
                  (this.dropdown = null),
                  (this.results = null);
              }),
              (i.prototype.render = function () {
                var t = e(
                  '<span class="select2 select2-container"><span class="selection"></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>'
                );
                return (
                  t.attr('dir', this.options.get('dir')),
                  (this.$container = t),
                  this.$container.addClass(
                    'select2-container--' + this.options.get('theme')
                  ),
                  n.StoreData(t[0], 'element', this.$element),
                  t
                );
              }),
              i
            );
          }
        ),
        t.define('jquery-mousewheel', ['jquery'], function (e) {
          return e;
        }),
        t.define(
          'jquery.select2',
          [
            'jquery',
            'jquery-mousewheel',
            './select2/core',
            './select2/defaults',
            './select2/utils',
          ],
          function (e, t, n, r, i) {
            if (null == e.fn.select2) {
              var o = ['open', 'close', 'destroy'];
              e.fn.select2 = function (t) {
                if (((t = t || {}), 'object' == typeof t))
                  return (
                    this.each(function () {
                      var r = e.extend(!0, {}, t);
                      new n(e(this), r);
                    }),
                    this
                  );
                if ('string' == typeof t) {
                  var r,
                    s = Array.prototype.slice.call(arguments, 1);
                  return (
                    this.each(function () {
                      var e = i.GetData(this, 'select2');
                      null == e &&
                        window.console &&
                        console.error &&
                        console.error(
                          "The select2('" +
                            t +
                            "') method was called on an element that is not using Select2."
                        ),
                        (r = e[t].apply(e, s));
                    }),
                    e.inArray(t, o) > -1 ? this : r
                  );
                }
                throw new Error('Invalid arguments for Select2: ' + t);
              };
            }
            return (
              null == e.fn.select2.defaults && (e.fn.select2.defaults = r), n
            );
          }
        ),
        { define: t.define, require: t.require }
      );
    })(),
    n = t.require('jquery.select2');
  return (e.fn.select2.amd = t), n;
});
!(function (t, i, e, s) {
  function o(i, e) {
    var h = this;
    'object' == typeof e &&
      (delete e.refresh, delete e.render, t.extend(this, e)),
      (this.$element = t(i)),
      !this.imageSrc &&
        this.$element.is('img') &&
        (this.imageSrc = this.$element.attr('src'));
    var r = (this.position + '').toLowerCase().match(/\S+/g) || [];
    if (
      (r.length < 1 && r.push('center'),
      1 == r.length && r.push(r[0]),
      ('top' != r[0] &&
        'bottom' != r[0] &&
        'left' != r[1] &&
        'right' != r[1]) ||
        (r = [r[1], r[0]]),
      this.positionX !== s && (r[0] = this.positionX.toLowerCase()),
      this.positionY !== s && (r[1] = this.positionY.toLowerCase()),
      (h.positionX = r[0]),
      (h.positionY = r[1]),
      'left' != this.positionX &&
        'right' != this.positionX &&
        (isNaN(parseInt(this.positionX))
          ? (this.positionX = 'center')
          : (this.positionX = parseInt(this.positionX))),
      'top' != this.positionY &&
        'bottom' != this.positionY &&
        (isNaN(parseInt(this.positionY))
          ? (this.positionY = 'center')
          : (this.positionY = parseInt(this.positionY))),
      (this.position =
        this.positionX +
        (isNaN(this.positionX) ? '' : 'px') +
        ' ' +
        this.positionY +
        (isNaN(this.positionY) ? '' : 'px')),
      navigator.userAgent.match(/(iPod|iPhone|iPad)/))
    )
      return (
        this.imageSrc &&
          this.iosFix &&
          !this.$element.is('img') &&
          this.$element.css({
            backgroundImage: 'url(' + this.imageSrc + ')',
            backgroundSize: 'cover',
            backgroundPosition: this.position,
          }),
        this
      );
    if (navigator.userAgent.match(/(Android)/))
      return (
        this.imageSrc &&
          this.androidFix &&
          !this.$element.is('img') &&
          this.$element.css({
            backgroundImage: 'url(' + this.imageSrc + ')',
            backgroundSize: 'cover',
            backgroundPosition: this.position,
          }),
        this
      );
    this.$mirror = t('<div />').prependTo(this.mirrorContainer);
    var a = this.$element.find('>.parallax-slider'),
      n = !1;
    0 == a.length
      ? (this.$slider = t('<img />').prependTo(this.$mirror))
      : ((this.$slider = a.prependTo(this.$mirror)), (n = !0)),
      this.$mirror
        .addClass('parallax-mirror')
        .css({
          visibility: 'hidden',
          zIndex: this.zIndex,
          position: 'fixed',
          top: 0,
          left: 0,
          overflow: 'hidden',
        }),
      this.$slider.addClass('parallax-slider').one('load', function () {
        (h.naturalHeight && h.naturalWidth) ||
          ((h.naturalHeight = this.naturalHeight || this.height || 1),
          (h.naturalWidth = this.naturalWidth || this.width || 1)),
          (h.aspectRatio = h.naturalWidth / h.naturalHeight),
          o.isSetup || o.setup(),
          o.sliders.push(h),
          (o.isFresh = !1),
          o.requestRender();
      }),
      n || (this.$slider[0].src = this.imageSrc),
      ((this.naturalHeight && this.naturalWidth) ||
        this.$slider[0].complete ||
        a.length > 0) &&
        this.$slider.trigger('load');
  }
  function h(s) {
    return this.each(function () {
      var h = t(this),
        r = 'object' == typeof s && s;
      this == i || this == e || h.is('body')
        ? o.configure(r)
        : h.data('px.parallax')
        ? 'object' == typeof s && t.extend(h.data('px.parallax'), r)
        : ((r = t.extend({}, h.data(), r)),
          h.data('px.parallax', new o(this, r))),
        'string' == typeof s && ('destroy' == s ? o.destroy(this) : o[s]());
    });
  }
  !(function () {
    for (
      var t = 0, e = ['ms', 'moz', 'webkit', 'o'], s = 0;
      s < e.length && !i.requestAnimationFrame;
      ++s
    )
      (i.requestAnimationFrame = i[e[s] + 'RequestAnimationFrame']),
        (i.cancelAnimationFrame =
          i[e[s] + 'CancelAnimationFrame'] ||
          i[e[s] + 'CancelRequestAnimationFrame']);
    i.requestAnimationFrame ||
      (i.requestAnimationFrame = function (e) {
        var s = new Date().getTime(),
          o = Math.max(0, 16 - (s - t)),
          h = i.setTimeout(function () {
            e(s + o);
          }, o);
        return (t = s + o), h;
      }),
      i.cancelAnimationFrame ||
        (i.cancelAnimationFrame = function (t) {
          clearTimeout(t);
        });
  })(),
    t.extend(o.prototype, {
      speed: 0.2,
      bleed: 0,
      zIndex: -100,
      iosFix: !0,
      androidFix: !0,
      position: 'center',
      overScrollFix: !1,
      mirrorContainer: 'body',
      refresh: function () {
        (this.boxWidth = this.$element.outerWidth()),
          (this.boxHeight = this.$element.outerHeight() + 2 * this.bleed),
          (this.boxOffsetTop = this.$element.offset().top - this.bleed),
          (this.boxOffsetLeft = this.$element.offset().left),
          (this.boxOffsetBottom = this.boxOffsetTop + this.boxHeight);
        var t,
          i = o.winHeight,
          e = o.docHeight,
          s = Math.min(this.boxOffsetTop, e - i),
          h = Math.max(this.boxOffsetTop + this.boxHeight - i, 0),
          r = (this.boxHeight + (s - h) * (1 - this.speed)) | 0,
          a = ((this.boxOffsetTop - s) * (1 - this.speed)) | 0;
        r * this.aspectRatio >= this.boxWidth
          ? ((this.imageWidth = (r * this.aspectRatio) | 0),
            (this.imageHeight = r),
            (this.offsetBaseTop = a),
            (t = this.imageWidth - this.boxWidth),
            'left' == this.positionX
              ? (this.offsetLeft = 0)
              : 'right' == this.positionX
              ? (this.offsetLeft = -t)
              : isNaN(this.positionX)
              ? (this.offsetLeft = (-t / 2) | 0)
              : (this.offsetLeft = Math.max(this.positionX, -t)))
          : ((this.imageWidth = this.boxWidth),
            (this.imageHeight = (this.boxWidth / this.aspectRatio) | 0),
            (this.offsetLeft = 0),
            (t = this.imageHeight - r),
            'top' == this.positionY
              ? (this.offsetBaseTop = a)
              : 'bottom' == this.positionY
              ? (this.offsetBaseTop = a - t)
              : isNaN(this.positionY)
              ? (this.offsetBaseTop = (a - t / 2) | 0)
              : (this.offsetBaseTop = a + Math.max(this.positionY, -t)));
      },
      render: function () {
        var t = o.scrollTop,
          i = o.scrollLeft,
          e = this.overScrollFix ? o.overScroll : 0,
          s = t + o.winHeight;
        this.boxOffsetBottom > t && this.boxOffsetTop <= s
          ? ((this.visibility = 'visible'),
            (this.mirrorTop = this.boxOffsetTop - t),
            (this.mirrorLeft = this.boxOffsetLeft - i),
            (this.offsetTop =
              this.offsetBaseTop - this.mirrorTop * (1 - this.speed)))
          : (this.visibility = 'hidden'),
          this.$mirror.css({
            transform:
              'translate3d(' +
              this.mirrorLeft +
              'px, ' +
              (this.mirrorTop - e) +
              'px, 0px)',
            visibility: this.visibility,
            height: this.boxHeight,
            width: this.boxWidth,
          }),
          this.$slider.css({
            transform:
              'translate3d(' +
              this.offsetLeft +
              'px, ' +
              this.offsetTop +
              'px, 0px)',
            position: 'absolute',
            height: this.imageHeight,
            width: this.imageWidth,
            maxWidth: 'none',
          });
      },
    }),
    t.extend(o, {
      scrollTop: 0,
      scrollLeft: 0,
      winHeight: 0,
      winWidth: 0,
      docHeight: 1 << 30,
      docWidth: 1 << 30,
      sliders: [],
      isReady: !1,
      isFresh: !1,
      isBusy: !1,
      setup: function () {
        function s() {
          return p == i.pageYOffset
            ? (i.requestAnimationFrame(s), !1)
            : ((p = i.pageYOffset),
              h.render(),
              void i.requestAnimationFrame(s));
        }
        if (!this.isReady) {
          var h = this,
            r = t(e),
            a = t(i),
            n = function () {
              (o.winHeight = a.height()),
                (o.winWidth = a.width()),
                (o.docHeight = r.height()),
                (o.docWidth = r.width());
            },
            l = function () {
              var t = a.scrollTop(),
                i = o.docHeight - o.winHeight,
                e = o.docWidth - o.winWidth;
              (o.scrollTop = Math.max(0, Math.min(i, t))),
                (o.scrollLeft = Math.max(0, Math.min(e, a.scrollLeft()))),
                (o.overScroll = Math.max(t - i, Math.min(t, 0)));
            };
          a
            .on('resize.px.parallax load.px.parallax', function () {
              n(), h.refresh(), (o.isFresh = !1), o.requestRender();
            })
            .on('scroll.px.parallax load.px.parallax', function () {
              l(), o.requestRender();
            }),
            n(),
            l(),
            (this.isReady = !0);
          var p = -1;
          s();
        }
      },
      configure: function (i) {
        'object' == typeof i &&
          (delete i.refresh, delete i.render, t.extend(this.prototype, i));
      },
      refresh: function () {
        t.each(this.sliders, function () {
          this.refresh();
        }),
          (this.isFresh = !0);
      },
      render: function () {
        this.isFresh || this.refresh(),
          t.each(this.sliders, function () {
            this.render();
          });
      },
      requestRender: function () {
        var t = this;
        t.render(), (t.isBusy = !1);
      },
      destroy: function (e) {
        var s,
          h = t(e).data('px.parallax');
        for (h.$mirror.remove(), s = 0; s < this.sliders.length; s += 1)
          this.sliders[s] == h && this.sliders.splice(s, 1);
        t(e).data('px.parallax', !1),
          0 === this.sliders.length &&
            (t(i).off('scroll.px.parallax resize.px.parallax load.px.parallax'),
            (this.isReady = !1),
            (o.isSetup = !1));
      },
    });
  var r = t.fn.parallax;
  (t.fn.parallax = h),
    (t.fn.parallax.Constructor = o),
    (t.fn.parallax.noConflict = function () {
      return (t.fn.parallax = r), this;
    }),
    t(function () {
      t('[data-parallax="scroll"]').parallax();
    });
})(jQuery, window, document);
!(function (e, t, r) {
  'use strict';
  function n(r) {
    if (
      ((o = t.documentElement),
      (a = t.body),
      Y(),
      (le = this),
      (r = r || {}),
      (me = r.constants || {}),
      r.easing)
    )
      for (var n in r.easing) X[n] = r.easing[n];
    (Te = r.edgeStrategy || 'set'),
      (fe = {
        beforerender: r.beforerender,
        render: r.render,
        keyframe: r.keyframe,
      }),
      (ue = r.forceHeight !== !1),
      ue && (ze = r.scale || 1),
      (pe = r.mobileDeceleration || x),
      (ge = r.smoothScrolling !== !1),
      (ve = r.smoothScrollingDuration || A),
      (he = { targetTop: le.getScrollTop() }),
      (Ke = (
        r.mobileCheck ||
        function () {
          return /Android|iPhone|iPad|iPod|BlackBerry/i.test(
            navigator.userAgent || navigator.vendor || e.opera
          );
        }
      )()),
      Ke
        ? ((ce = t.getElementById(r.skrollrBody || E)),
          ce && ie(),
          j(),
          De(o, [y, S], [T]))
        : De(o, [y, b], [T]),
      le.refresh(),
      ke(e, 'resize orientationchange', function () {
        var e = o.clientWidth,
          t = o.clientHeight;
        (t === Be && e === $e) || ((Be = t), ($e = e), (_e = !0));
      });
    var i = R();
    return (
      (function l() {
        J(), (Se = i(l));
      })(),
      le
    );
  }
  var o,
    a,
    i = {
      get: function () {
        return le;
      },
      init: function (e) {
        return le || new n(e);
      },
      VERSION: '0.6.29',
    },
    l = Object.prototype.hasOwnProperty,
    s = e.Math,
    c = e.getComputedStyle,
    f = 'touchstart',
    u = 'touchmove',
    m = 'touchcancel',
    p = 'touchend',
    d = 'skrollable',
    g = d + '-before',
    v = d + '-between',
    h = d + '-after',
    y = 'skrollr',
    T = 'no-' + y,
    b = y + '-desktop',
    S = y + '-mobile',
    k = 'linear',
    w = 1e3,
    x = 0.004,
    E = 'skrollr-body',
    A = 200,
    F = 'start',
    C = 'end',
    H = 'center',
    D = 'bottom',
    I = '___skrollable_id',
    P = /^(?:input|textarea|button|select)$/i,
    N = /^\s+|\s+$/g,
    O =
      /^data(?:-(_\w+))?(?:-?(-?\d*\.?\d+p?))?(?:-?(start|end|top|center|bottom))?(?:-?(top|center|bottom))?$/,
    V = /\s*(@?[\w\-\[\]]+)\s*:\s*(.+?)\s*(?:;|$)/gi,
    z = /^(@?[a-z\-]+)\[(\w+)\]$/,
    q = /-([a-z0-9_])/g,
    L = function (e, t) {
      return t.toUpperCase();
    },
    M = /[\-+]?[\d]*\.?[\d]+/g,
    $ = /\{\?\}/g,
    B = /rgba?\(\s*-?\d+\s*,\s*-?\d+\s*,\s*-?\d+/g,
    _ = /[a-z\-]+-gradient/g,
    G = '',
    K = '',
    Y = function () {
      var e = /^(?:O|Moz|webkit|ms)|(?:-(?:o|moz|webkit|ms)-)/;
      if (c) {
        var t = c(a, null);
        for (var r in t)
          if ((G = r.match(e) || (+r == r && t[r].match(e)))) break;
        if (!G) return void (G = K = '');
        (G = G[0]),
          '-' === G.slice(0, 1)
            ? ((K = G),
              (G = {
                '-webkit-': 'webkit',
                '-moz-': 'Moz',
                '-ms-': 'ms',
                '-o-': 'O',
              }[G]))
            : (K = '-' + G.toLowerCase() + '-');
      }
    },
    R = function () {
      var t =
          e.requestAnimationFrame ||
          e[G.toLowerCase() + 'RequestAnimationFrame'],
        r = Ne();
      return (
        (!Ke && t) ||
          (t = function (t) {
            var n = Ne() - r,
              o = s.max(0, 1e3 / 60 - n);
            return e.setTimeout(function () {
              (r = Ne()), t();
            }, o);
          }),
        t
      );
    },
    U = function () {
      var t =
        e.cancelAnimationFrame || e[G.toLowerCase() + 'CancelAnimationFrame'];
      return (
        (!Ke && t) ||
          (t = function (t) {
            return e.clearTimeout(t);
          }),
        t
      );
    },
    X = {
      begin: function () {
        return 0;
      },
      end: function () {
        return 1;
      },
      linear: function (e) {
        return e;
      },
      quadratic: function (e) {
        return e * e;
      },
      cubic: function (e) {
        return e * e * e;
      },
      swing: function (e) {
        return -s.cos(e * s.PI) / 2 + 0.5;
      },
      sqrt: function (e) {
        return s.sqrt(e);
      },
      outCubic: function (e) {
        return s.pow(e - 1, 3) + 1;
      },
      bounce: function (e) {
        var t;
        if (e <= 0.5083) t = 3;
        else if (e <= 0.8489) t = 9;
        else if (e <= 0.96208) t = 27;
        else {
          if (!(e <= 0.99981)) return 1;
          t = 91;
        }
        return 1 - s.abs((3 * s.cos(e * t * 1.028)) / t);
      },
    };
  (n.prototype.refresh = function (e) {
    var n,
      o,
      a = !1;
    for (
      e === r
        ? ((a = !0), (se = []), (Ge = 0), (e = t.getElementsByTagName('*')))
        : e.length === r && (e = [e]),
        n = 0,
        o = e.length;
      n < o;
      n++
    ) {
      var i = e[n],
        l = i,
        s = [],
        c = ge,
        f = Te,
        u = !1;
      if ((a && I in i && delete i[I], i.attributes)) {
        for (var m = 0, p = i.attributes.length; m < p; m++) {
          var g = i.attributes[m];
          if ('data-anchor-target' !== g.name)
            if ('data-smooth-scrolling' !== g.name)
              if ('data-edge-strategy' !== g.name)
                if ('data-emit-events' !== g.name) {
                  var v = g.name.match(O);
                  if (null !== v) {
                    var h = {
                      props: g.value,
                      element: i,
                      eventType: g.name.replace(q, L),
                    };
                    s.push(h);
                    var y = v[1];
                    y && (h.constant = y.substr(1));
                    var T = v[2];
                    /p$/.test(T)
                      ? ((h.isPercentage = !0),
                        (h.offset = (0 | T.slice(0, -1)) / 100))
                      : (h.offset = 0 | T);
                    var b = v[3],
                      S = v[4] || b;
                    b && b !== F && b !== C
                      ? ((h.mode = 'relative'), (h.anchors = [b, S]))
                      : ((h.mode = 'absolute'),
                        b === C
                          ? (h.isEnd = !0)
                          : h.isPercentage || (h.offset = h.offset * ze));
                  }
                } else u = !0;
              else f = g.value;
            else c = 'off' !== g.value;
          else if (((l = t.querySelector(g.value)), null === l))
            throw 'Unable to find anchor target "' + g.value + '"';
        }
        if (s.length) {
          var k, w, x;
          !a && I in i
            ? ((x = i[I]), (k = se[x].styleAttr), (w = se[x].classAttr))
            : ((x = i[I] = Ge++), (k = i.style.cssText), (w = He(i))),
            (se[x] = {
              element: i,
              styleAttr: k,
              classAttr: w,
              anchorTarget: l,
              keyFrames: s,
              smoothScrolling: c,
              edgeStrategy: f,
              emitEvents: u,
              lastFrameIndex: -1,
            }),
            De(i, [d], []);
        }
      }
    }
    for (Ae(), n = 0, o = e.length; n < o; n++) {
      var E = se[e[n][I]];
      E !== r && (Q(E), te(E));
    }
    return le;
  }),
    (n.prototype.relativeToAbsolute = function (e, t, r) {
      var n = o.clientHeight,
        a = e.getBoundingClientRect(),
        i = a.top,
        l = a.bottom - a.top;
      return (
        t === D ? (i -= n) : t === H && (i -= n / 2),
        r === D ? (i += l) : r === H && (i += l / 2),
        (i += le.getScrollTop()),
        (i + 0.5) | 0
      );
    }),
    (n.prototype.animateTo = function (e, t) {
      t = t || {};
      var n = Ne(),
        o = le.getScrollTop(),
        a = t.duration === r ? w : t.duration;
      return (
        (de = {
          startTop: o,
          topDiff: e - o,
          targetTop: e,
          duration: a,
          startTime: n,
          endTime: n + a,
          easing: X[t.easing || k],
          done: t.done,
        }),
        de.topDiff || (de.done && de.done.call(le, !1), (de = r)),
        le
      );
    }),
    (n.prototype.stopAnimateTo = function () {
      de && de.done && de.done.call(le, !0), (de = r);
    }),
    (n.prototype.isAnimatingTo = function () {
      return !!de;
    }),
    (n.prototype.isMobile = function () {
      return Ke;
    }),
    (n.prototype.setScrollTop = function (t, r) {
      return (
        (ye = r === !0),
        Ke ? (Ye = s.min(s.max(t, 0), Ve)) : e.scrollTo(0, t),
        le
      );
    }),
    (n.prototype.getScrollTop = function () {
      return Ke ? Ye : e.pageYOffset || o.scrollTop || a.scrollTop || 0;
    }),
    (n.prototype.getMaxScrollTop = function () {
      return Ve;
    }),
    (n.prototype.on = function (e, t) {
      return (fe[e] = t), le;
    }),
    (n.prototype.off = function (e) {
      return delete fe[e], le;
    }),
    (n.prototype.destroy = function () {
      var e = U();
      e(Se), xe(), De(o, [T], [y, b, S]);
      for (var t = 0, n = se.length; t < n; t++) ae(se[t].element);
      (o.style.overflow = a.style.overflow = ''),
        (o.style.height = a.style.height = ''),
        ce && i.setStyle(ce, 'transform', 'none'),
        (le = r),
        (ce = r),
        (fe = r),
        (ue = r),
        (Ve = 0),
        (ze = 1),
        (me = r),
        (pe = r),
        (qe = 'down'),
        (Le = -1),
        ($e = 0),
        (Be = 0),
        (_e = !1),
        (de = r),
        (ge = r),
        (ve = r),
        (he = r),
        (ye = r),
        (Ge = 0),
        (Te = r),
        (Ke = !1),
        (Ye = 0),
        (be = r);
    });
  var j = function () {
      var n, i, l, c, d, g, v, h, y, T, b, S;
      ke(o, [f, u, m, p].join(' '), function (e) {
        var o = e.changedTouches[0];
        for (c = e.target; 3 === c.nodeType; ) c = c.parentNode;
        switch (
          ((d = o.clientY),
          (g = o.clientX),
          (T = e.timeStamp),
          P.test(c.tagName) || e.preventDefault(),
          e.type)
        ) {
          case f:
            n && n.blur(),
              le.stopAnimateTo(),
              (n = c),
              (i = v = d),
              (l = g),
              (y = T);
            break;
          case u:
            P.test(c.tagName) && t.activeElement !== c && e.preventDefault(),
              (h = d - v),
              (S = T - b),
              le.setScrollTop(Ye - h, !0),
              (v = d),
              (b = T);
            break;
          default:
          case m:
          case p:
            var a = i - d,
              k = l - g,
              w = k * k + a * a;
            if (w < 49) {
              if (!P.test(n.tagName)) {
                n.focus();
                var x = t.createEvent('MouseEvents');
                x.initMouseEvent(
                  'click',
                  !0,
                  !0,
                  e.view,
                  1,
                  o.screenX,
                  o.screenY,
                  o.clientX,
                  o.clientY,
                  e.ctrlKey,
                  e.altKey,
                  e.shiftKey,
                  e.metaKey,
                  0,
                  null
                ),
                  n.dispatchEvent(x);
              }
              return;
            }
            n = r;
            var E = h / S;
            E = s.max(s.min(E, 3), -3);
            var A = s.abs(E / pe),
              F = E * A + 0.5 * pe * A * A,
              C = le.getScrollTop() - F,
              H = 0;
            C > Ve
              ? ((H = (Ve - C) / F), (C = Ve))
              : C < 0 && ((H = -C / F), (C = 0)),
              (A *= 1 - H),
              le.animateTo((C + 0.5) | 0, { easing: 'outCubic', duration: A });
        }
      }),
        e.scrollTo(0, 0),
        (o.style.overflow = a.style.overflow = 'hidden');
    },
    W = function () {
      var e,
        t,
        r,
        n,
        a,
        i,
        l,
        c,
        f,
        u,
        m,
        p = o.clientHeight,
        d = Fe();
      for (c = 0, f = se.length; c < f; c++)
        for (
          e = se[c],
            t = e.element,
            r = e.anchorTarget,
            n = e.keyFrames,
            a = 0,
            i = n.length;
          a < i;
          a++
        )
          (l = n[a]),
            (u = l.offset),
            (m = d[l.constant] || 0),
            (l.frame = u),
            l.isPercentage && ((u *= p), (l.frame = u)),
            'relative' === l.mode &&
              (ae(t),
              (l.frame =
                le.relativeToAbsolute(r, l.anchors[0], l.anchors[1]) - u),
              ae(t, !0)),
            (l.frame += m),
            ue && !l.isEnd && l.frame > Ve && (Ve = l.frame);
      for (Ve = s.max(Ve, Ce()), c = 0, f = se.length; c < f; c++) {
        for (e = se[c], n = e.keyFrames, a = 0, i = n.length; a < i; a++)
          (l = n[a]),
            (m = d[l.constant] || 0),
            l.isEnd && (l.frame = Ve - l.offset + m);
        e.keyFrames.sort(Oe);
      }
    },
    Z = function (e, t) {
      for (var r = 0, n = se.length; r < n; r++) {
        var o,
          a,
          s = se[r],
          c = s.element,
          f = s.smoothScrolling ? e : t,
          u = s.keyFrames,
          m = u.length,
          p = u[0],
          y = u[u.length - 1],
          T = f < p.frame,
          b = f > y.frame,
          S = T ? p : y,
          k = s.emitEvents,
          w = s.lastFrameIndex;
        if (T || b) {
          if ((T && s.edge === -1) || (b && 1 === s.edge)) continue;
          switch (
            (T
              ? (De(c, [g], [h, v]),
                k &&
                  w > -1 &&
                  (Ee(c, p.eventType, qe), (s.lastFrameIndex = -1)))
              : (De(c, [h], [g, v]),
                k && w < m && (Ee(c, y.eventType, qe), (s.lastFrameIndex = m))),
            (s.edge = T ? -1 : 1),
            s.edgeStrategy)
          ) {
            case 'reset':
              ae(c);
              continue;
            case 'ease':
              f = S.frame;
              break;
            default:
            case 'set':
              var x = S.props;
              for (o in x)
                l.call(x, o) &&
                  ((a = oe(x[o].value)),
                  0 === o.indexOf('@')
                    ? c.setAttribute(o.substr(1), a)
                    : i.setStyle(c, o, a));
              continue;
          }
        } else 0 !== s.edge && (De(c, [d, v], [g, h]), (s.edge = 0));
        for (var E = 0; E < m - 1; E++)
          if (f >= u[E].frame && f <= u[E + 1].frame) {
            var A = u[E],
              F = u[E + 1];
            for (o in A.props)
              if (l.call(A.props, o)) {
                var C = (f - A.frame) / (F.frame - A.frame);
                (C = A.props[o].easing(C)),
                  (a = ne(A.props[o].value, F.props[o].value, C)),
                  (a = oe(a)),
                  0 === o.indexOf('@')
                    ? c.setAttribute(o.substr(1), a)
                    : i.setStyle(c, o, a);
              }
            k &&
              w !== E &&
              ('down' === qe ? Ee(c, A.eventType, qe) : Ee(c, F.eventType, qe),
              (s.lastFrameIndex = E));
            break;
          }
      }
    },
    J = function () {
      _e && ((_e = !1), Ae());
      var e,
        t,
        n = le.getScrollTop(),
        o = Ne();
      if (de)
        o >= de.endTime
          ? ((n = de.targetTop), (e = de.done), (de = r))
          : ((t = de.easing((o - de.startTime) / de.duration)),
            (n = (de.startTop + t * de.topDiff) | 0)),
          le.setScrollTop(n, !0);
      else if (!ye) {
        var a = he.targetTop - n;
        a &&
          (he = {
            startTop: Le,
            topDiff: n - Le,
            targetTop: n,
            startTime: Me,
            endTime: Me + ve,
          }),
          o <= he.endTime &&
            ((t = X.sqrt((o - he.startTime) / ve)),
            (n = (he.startTop + t * he.topDiff) | 0));
      }
      if (ye || Le !== n) {
        (qe = n > Le ? 'down' : n < Le ? 'up' : qe), (ye = !1);
        var l = { curTop: n, lastTop: Le, maxTop: Ve, direction: qe },
          s = fe.beforerender && fe.beforerender.call(le, l);
        s !== !1 &&
          (Z(n, le.getScrollTop()),
          Ke &&
            ce &&
            i.setStyle(ce, 'transform', 'translate(0, ' + -Ye + 'px) ' + be),
          (Le = n),
          fe.render && fe.render.call(le, l)),
          e && e.call(le, !1);
      }
      Me = o;
    },
    Q = function (e) {
      for (var t = 0, r = e.keyFrames.length; t < r; t++) {
        for (
          var n, o, a, i, l = e.keyFrames[t], s = {};
          null !== (i = V.exec(l.props));

        )
          (a = i[1]),
            (o = i[2]),
            (n = a.match(z)),
            null !== n ? ((a = n[1]), (n = n[2])) : (n = k),
            (o = o.indexOf('!') ? ee(o) : [o.slice(1)]),
            (s[a] = { value: o, easing: X[n] });
        l.props = s;
      }
    },
    ee = function (e) {
      var t = [];
      return (
        (B.lastIndex = 0),
        (e = e.replace(B, function (e) {
          return e.replace(M, function (e) {
            return (e / 255) * 100 + '%';
          });
        })),
        K &&
          ((_.lastIndex = 0),
          (e = e.replace(_, function (e) {
            return K + e;
          }))),
        (e = e.replace(M, function (e) {
          return t.push(+e), '{?}';
        })),
        t.unshift(e),
        t
      );
    },
    te = function (e) {
      var t,
        r,
        n = {};
      for (t = 0, r = e.keyFrames.length; t < r; t++) re(e.keyFrames[t], n);
      for (n = {}, t = e.keyFrames.length - 1; t >= 0; t--)
        re(e.keyFrames[t], n);
    },
    re = function (e, t) {
      var r;
      for (r in t) l.call(e.props, r) || (e.props[r] = t[r]);
      for (r in e.props) t[r] = e.props[r];
    },
    ne = function (e, t, r) {
      var n,
        o = e.length;
      if (o !== t.length)
        throw 'Can\'t interpolate between "' + e[0] + '" and "' + t[0] + '"';
      var a = [e[0]];
      for (n = 1; n < o; n++) a[n] = e[n] + (t[n] - e[n]) * r;
      return a;
    },
    oe = function (e) {
      var t = 1;
      return (
        ($.lastIndex = 0),
        e[0].replace($, function () {
          return e[t++];
        })
      );
    },
    ae = function (e, t) {
      e = [].concat(e);
      for (var r, n, o = 0, a = e.length; o < a; o++)
        (n = e[o]),
          (r = se[n[I]]),
          r &&
            (t
              ? ((n.style.cssText = r.dirtyStyleAttr), De(n, r.dirtyClassAttr))
              : ((r.dirtyStyleAttr = n.style.cssText),
                (r.dirtyClassAttr = He(n)),
                (n.style.cssText = r.styleAttr),
                De(n, r.classAttr)));
    },
    ie = function () {
      (be = 'translateZ(0)'), i.setStyle(ce, 'transform', be);
      var e = c(ce),
        t = e.getPropertyValue('transform'),
        r = e.getPropertyValue(K + 'transform'),
        n = (t && 'none' !== t) || (r && 'none' !== r);
      n || (be = '');
    };
  i.setStyle = function (e, t, r) {
    var n = e.style;
    if (((t = t.replace(q, L).replace('-', '')), 'zIndex' === t))
      isNaN(r) ? (n[t] = r) : (n[t] = '' + (0 | r));
    else if ('float' === t) n.styleFloat = n.cssFloat = r;
    else
      try {
        G && (n[G + t.slice(0, 1).toUpperCase() + t.slice(1)] = r), (n[t] = r);
      } catch (o) {}
  };
  var le,
    se,
    ce,
    fe,
    ue,
    me,
    pe,
    de,
    ge,
    ve,
    he,
    ye,
    Te,
    be,
    Se,
    ke = (i.addEvent = function (t, r, n) {
      var o = function (t) {
        return (
          (t = t || e.event),
          t.target || (t.target = t.srcElement),
          t.preventDefault ||
            (t.preventDefault = function () {
              (t.returnValue = !1), (t.defaultPrevented = !0);
            }),
          n.call(this, t)
        );
      };
      r = r.split(' ');
      for (var a, i = 0, l = r.length; i < l; i++)
        (a = r[i]),
          t.addEventListener
            ? t.addEventListener(a, n, !1)
            : t.attachEvent('on' + a, o),
          Re.push({ element: t, name: a, listener: n });
    }),
    we = (i.removeEvent = function (e, t, r) {
      t = t.split(' ');
      for (var n = 0, o = t.length; n < o; n++)
        e.removeEventListener
          ? e.removeEventListener(t[n], r, !1)
          : e.detachEvent('on' + t[n], r);
    }),
    xe = function () {
      for (var e, t = 0, r = Re.length; t < r; t++)
        (e = Re[t]), we(e.element, e.name, e.listener);
      Re = [];
    },
    Ee = function (e, t, r) {
      fe.keyframe && fe.keyframe.call(le, e, t, r);
    },
    Ae = function () {
      var e = le.getScrollTop();
      (Ve = 0),
        ue && !Ke && (a.style.height = ''),
        W(),
        ue && !Ke && (a.style.height = Ve + o.clientHeight + 'px'),
        Ke
          ? le.setScrollTop(s.min(le.getScrollTop(), Ve))
          : le.setScrollTop(e, !0),
        (ye = !0);
    },
    Fe = function () {
      var e,
        t,
        r = o.clientHeight,
        n = {};
      for (e in me)
        (t = me[e]),
          'function' == typeof t
            ? (t = t.call(le))
            : /p$/.test(t) && (t = (t.slice(0, -1) / 100) * r),
          (n[e] = t);
      return n;
    },
    Ce = function () {
      var e,
        t = 0;
      return (
        ce && (t = s.max(ce.offsetHeight, ce.scrollHeight)),
        (e = s.max(
          t,
          a.scrollHeight,
          a.offsetHeight,
          o.scrollHeight,
          o.offsetHeight,
          o.clientHeight
        )),
        e - o.clientHeight
      );
    },
    He = function (t) {
      var r = 'className';
      return (
        e.SVGElement &&
          t instanceof e.SVGElement &&
          ((t = t[r]), (r = 'baseVal')),
        t[r]
      );
    },
    De = function (t, n, o) {
      var a = 'className';
      if (
        (e.SVGElement &&
          t instanceof e.SVGElement &&
          ((t = t[a]), (a = 'baseVal')),
        o === r)
      )
        return void (t[a] = n);
      for (var i = t[a], l = 0, s = o.length; l < s; l++)
        i = Pe(i).replace(Pe(o[l]), ' ');
      i = Ie(i);
      for (var c = 0, f = n.length; c < f; c++)
        Pe(i).indexOf(Pe(n[c])) === -1 && (i += ' ' + n[c]);
      t[a] = Ie(i);
    },
    Ie = function (e) {
      return e.replace(N, '');
    },
    Pe = function (e) {
      return ' ' + e + ' ';
    },
    Ne =
      Date.now ||
      function () {
        return +new Date();
      },
    Oe = function (e, t) {
      return e.frame - t.frame;
    },
    Ve = 0,
    ze = 1,
    qe = 'down',
    Le = -1,
    Me = Ne(),
    $e = 0,
    Be = 0,
    _e = !1,
    Ge = 0,
    Ke = !1,
    Ye = 0,
    Re = [];
  'function' == typeof define && define.amd
    ? define([], function () {
        return i;
      })
    : 'undefined' != typeof module && module.exports
    ? (module.exports = i)
    : (e.skrollr = i);
})(window, document);
!(function (i) {
  'use strict';
  'function' == typeof define && define.amd
    ? define(['jquery'], i)
    : 'undefined' != typeof exports
    ? (module.exports = i(require('jquery')))
    : i(jQuery);
})(function (i) {
  'use strict';
  var e = window.Slick || {};
  (e = (function () {
    function e(e, o) {
      var s,
        n = this;
      (n.defaults = {
        accessibility: !0,
        adaptiveHeight: !1,
        appendArrows: i(e),
        appendDots: i(e),
        arrows: !0,
        asNavFor: null,
        prevArrow:
          '<button class="slick-prev" aria-label="Previous" type="button">Previous</button>',
        nextArrow:
          '<button class="slick-next" aria-label="Next" type="button">Next</button>',
        autoplay: !1,
        autoplaySpeed: 3e3,
        centerMode: !1,
        centerPadding: '50px',
        cssEase: 'ease',
        customPaging: function (e, t) {
          return i('<button type="button" />').text(t + 1);
        },
        dots: !1,
        dotsClass: 'slick-dots',
        draggable: !0,
        easing: 'linear',
        edgeFriction: 0.35,
        fade: !1,
        focusOnSelect: !1,
        focusOnChange: !1,
        infinite: !0,
        initialSlide: 0,
        lazyLoad: 'ondemand',
        mobileFirst: !1,
        pauseOnHover: !0,
        pauseOnFocus: !0,
        pauseOnDotsHover: !1,
        respondTo: 'window',
        responsive: null,
        rows: 1,
        rtl: !1,
        slide: '',
        slidesPerRow: 1,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 500,
        swipe: !0,
        swipeToSlide: !1,
        touchMove: !0,
        touchThreshold: 5,
        useCSS: !0,
        useTransform: !0,
        variableWidth: !1,
        vertical: !1,
        verticalSwiping: !1,
        waitForAnimate: !0,
        zIndex: 1e3,
      }),
        (n.initials = {
          animating: !1,
          dragging: !1,
          autoPlayTimer: null,
          currentDirection: 0,
          currentLeft: null,
          currentSlide: 0,
          direction: 1,
          $dots: null,
          listWidth: null,
          listHeight: null,
          loadIndex: 0,
          $nextArrow: null,
          $prevArrow: null,
          scrolling: !1,
          slideCount: null,
          slideWidth: null,
          $slideTrack: null,
          $slides: null,
          sliding: !1,
          slideOffset: 0,
          swipeLeft: null,
          swiping: !1,
          $list: null,
          touchObject: {},
          transformsEnabled: !1,
          unslicked: !1,
        }),
        i.extend(n, n.initials),
        (n.activeBreakpoint = null),
        (n.animType = null),
        (n.animProp = null),
        (n.breakpoints = []),
        (n.breakpointSettings = []),
        (n.cssTransitions = !1),
        (n.focussed = !1),
        (n.interrupted = !1),
        (n.hidden = 'hidden'),
        (n.paused = !0),
        (n.positionProp = null),
        (n.respondTo = null),
        (n.rowCount = 1),
        (n.shouldClick = !0),
        (n.$slider = i(e)),
        (n.$slidesCache = null),
        (n.transformType = null),
        (n.transitionType = null),
        (n.visibilityChange = 'visibilitychange'),
        (n.windowWidth = 0),
        (n.windowTimer = null),
        (s = i(e).data('slick') || {}),
        (n.options = i.extend({}, n.defaults, o, s)),
        (n.currentSlide = n.options.initialSlide),
        (n.originalSettings = n.options),
        'undefined' != typeof document.mozHidden
          ? ((n.hidden = 'mozHidden'),
            (n.visibilityChange = 'mozvisibilitychange'))
          : 'undefined' != typeof document.webkitHidden &&
            ((n.hidden = 'webkitHidden'),
            (n.visibilityChange = 'webkitvisibilitychange')),
        (n.autoPlay = i.proxy(n.autoPlay, n)),
        (n.autoPlayClear = i.proxy(n.autoPlayClear, n)),
        (n.autoPlayIterator = i.proxy(n.autoPlayIterator, n)),
        (n.changeSlide = i.proxy(n.changeSlide, n)),
        (n.clickHandler = i.proxy(n.clickHandler, n)),
        (n.selectHandler = i.proxy(n.selectHandler, n)),
        (n.setPosition = i.proxy(n.setPosition, n)),
        (n.swipeHandler = i.proxy(n.swipeHandler, n)),
        (n.dragHandler = i.proxy(n.dragHandler, n)),
        (n.keyHandler = i.proxy(n.keyHandler, n)),
        (n.instanceUid = t++),
        (n.htmlExpr = /^(?:\s*(<[\w\W]+>)[^>]*)$/),
        n.registerBreakpoints(),
        n.init(!0);
    }
    var t = 0;
    return e;
  })()),
    (e.prototype.activateADA = function () {
      var i = this;
      i.$slideTrack
        .find('.slick-active')
        .attr({ 'aria-hidden': 'false' })
        .find('a, input, button, select')
        .attr({ tabindex: '0' });
    }),
    (e.prototype.addSlide = e.prototype.slickAdd =
      function (e, t, o) {
        var s = this;
        if ('boolean' == typeof t) (o = t), (t = null);
        else if (t < 0 || t >= s.slideCount) return !1;
        s.unload(),
          'number' == typeof t
            ? 0 === t && 0 === s.$slides.length
              ? i(e).appendTo(s.$slideTrack)
              : o
              ? i(e).insertBefore(s.$slides.eq(t))
              : i(e).insertAfter(s.$slides.eq(t))
            : o === !0
            ? i(e).prependTo(s.$slideTrack)
            : i(e).appendTo(s.$slideTrack),
          (s.$slides = s.$slideTrack.children(this.options.slide)),
          s.$slideTrack.children(this.options.slide).detach(),
          s.$slideTrack.append(s.$slides),
          s.$slides.each(function (e, t) {
            i(t).attr('data-slick-index', e);
          }),
          (s.$slidesCache = s.$slides),
          s.reinit();
      }),
    (e.prototype.animateHeight = function () {
      var i = this;
      if (
        1 === i.options.slidesToShow &&
        i.options.adaptiveHeight === !0 &&
        i.options.vertical === !1
      ) {
        var e = i.$slides.eq(i.currentSlide).outerHeight(!0);
        i.$list.animate({ height: e }, i.options.speed);
      }
    }),
    (e.prototype.animateSlide = function (e, t) {
      var o = {},
        s = this;
      s.animateHeight(),
        s.options.rtl === !0 && s.options.vertical === !1 && (e = -e),
        s.transformsEnabled === !1
          ? s.options.vertical === !1
            ? s.$slideTrack.animate(
                { left: e },
                s.options.speed,
                s.options.easing,
                t
              )
            : s.$slideTrack.animate(
                { top: e },
                s.options.speed,
                s.options.easing,
                t
              )
          : s.cssTransitions === !1
          ? (s.options.rtl === !0 && (s.currentLeft = -s.currentLeft),
            i({ animStart: s.currentLeft }).animate(
              { animStart: e },
              {
                duration: s.options.speed,
                easing: s.options.easing,
                step: function (i) {
                  (i = Math.ceil(i)),
                    s.options.vertical === !1
                      ? ((o[s.animType] = 'translate(' + i + 'px, 0px)'),
                        s.$slideTrack.css(o))
                      : ((o[s.animType] = 'translate(0px,' + i + 'px)'),
                        s.$slideTrack.css(o));
                },
                complete: function () {
                  t && t.call();
                },
              }
            ))
          : (s.applyTransition(),
            (e = Math.ceil(e)),
            s.options.vertical === !1
              ? (o[s.animType] = 'translate3d(' + e + 'px, 0px, 0px)')
              : (o[s.animType] = 'translate3d(0px,' + e + 'px, 0px)'),
            s.$slideTrack.css(o),
            t &&
              setTimeout(function () {
                s.disableTransition(), t.call();
              }, s.options.speed));
    }),
    (e.prototype.getNavTarget = function () {
      var e = this,
        t = e.options.asNavFor;
      return t && null !== t && (t = i(t).not(e.$slider)), t;
    }),
    (e.prototype.asNavFor = function (e) {
      var t = this,
        o = t.getNavTarget();
      null !== o &&
        'object' == typeof o &&
        o.each(function () {
          var t = i(this).slick('getSlick');
          t.unslicked || t.slideHandler(e, !0);
        });
    }),
    (e.prototype.applyTransition = function (i) {
      var e = this,
        t = {};
      e.options.fade === !1
        ? (t[e.transitionType] =
            e.transformType + ' ' + e.options.speed + 'ms ' + e.options.cssEase)
        : (t[e.transitionType] =
            'opacity ' + e.options.speed + 'ms ' + e.options.cssEase),
        e.options.fade === !1 ? e.$slideTrack.css(t) : e.$slides.eq(i).css(t);
    }),
    (e.prototype.autoPlay = function () {
      var i = this;
      i.autoPlayClear(),
        i.slideCount > i.options.slidesToShow &&
          (i.autoPlayTimer = setInterval(
            i.autoPlayIterator,
            i.options.autoplaySpeed
          ));
    }),
    (e.prototype.autoPlayClear = function () {
      var i = this;
      i.autoPlayTimer && clearInterval(i.autoPlayTimer);
    }),
    (e.prototype.autoPlayIterator = function () {
      var i = this,
        e = i.currentSlide + i.options.slidesToScroll;
      i.paused ||
        i.interrupted ||
        i.focussed ||
        (i.options.infinite === !1 &&
          (1 === i.direction && i.currentSlide + 1 === i.slideCount - 1
            ? (i.direction = 0)
            : 0 === i.direction &&
              ((e = i.currentSlide - i.options.slidesToScroll),
              i.currentSlide - 1 === 0 && (i.direction = 1))),
        i.slideHandler(e));
    }),
    (e.prototype.buildArrows = function () {
      var e = this;
      e.options.arrows === !0 &&
        ((e.$prevArrow = i(e.options.prevArrow).addClass('slick-arrow')),
        (e.$nextArrow = i(e.options.nextArrow).addClass('slick-arrow')),
        e.slideCount > e.options.slidesToShow
          ? (e.$prevArrow
              .removeClass('slick-hidden')
              .removeAttr('aria-hidden tabindex'),
            e.$nextArrow
              .removeClass('slick-hidden')
              .removeAttr('aria-hidden tabindex'),
            e.htmlExpr.test(e.options.prevArrow) &&
              e.$prevArrow.prependTo(e.options.appendArrows),
            e.htmlExpr.test(e.options.nextArrow) &&
              e.$nextArrow.appendTo(e.options.appendArrows),
            e.options.infinite !== !0 &&
              e.$prevArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'))
          : e.$prevArrow
              .add(e.$nextArrow)
              .addClass('slick-hidden')
              .attr({ 'aria-disabled': 'true', tabindex: '-1' }));
    }),
    (e.prototype.buildDots = function () {
      var e,
        t,
        o = this;
      if (o.options.dots === !0 && o.slideCount > o.options.slidesToShow) {
        for (
          o.$slider.addClass('slick-dotted'),
            t = i('<ul />').addClass(o.options.dotsClass),
            e = 0;
          e <= o.getDotCount();
          e += 1
        )
          t.append(i('<li />').append(o.options.customPaging.call(this, o, e)));
        (o.$dots = t.appendTo(o.options.appendDots)),
          o.$dots.find('li').first().addClass('slick-active');
      }
    }),
    (e.prototype.buildOut = function () {
      var e = this;
      (e.$slides = e.$slider
        .children(e.options.slide + ':not(.slick-cloned)')
        .addClass('slick-slide')),
        (e.slideCount = e.$slides.length),
        e.$slides.each(function (e, t) {
          i(t)
            .attr('data-slick-index', e)
            .data('originalStyling', i(t).attr('style') || '');
        }),
        e.$slider.addClass('slick-slider'),
        (e.$slideTrack =
          0 === e.slideCount
            ? i('<div class="slick-track"/>').appendTo(e.$slider)
            : e.$slides.wrapAll('<div class="slick-track"/>').parent()),
        (e.$list = e.$slideTrack.wrap('<div class="slick-list"/>').parent()),
        e.$slideTrack.css('opacity', 0),
        (e.options.centerMode !== !0 && e.options.swipeToSlide !== !0) ||
          (e.options.slidesToScroll = 1),
        i('img[data-lazy]', e.$slider).not('[src]').addClass('slick-loading'),
        e.setupInfinite(),
        e.buildArrows(),
        e.buildDots(),
        e.updateDots(),
        e.setSlideClasses(
          'number' == typeof e.currentSlide ? e.currentSlide : 0
        ),
        e.options.draggable === !0 && e.$list.addClass('draggable');
    }),
    (e.prototype.buildRows = function () {
      var i,
        e,
        t,
        o,
        s,
        n,
        r,
        l = this;
      if (
        ((o = document.createDocumentFragment()),
        (n = l.$slider.children()),
        l.options.rows > 0)
      ) {
        for (
          r = l.options.slidesPerRow * l.options.rows,
            s = Math.ceil(n.length / r),
            i = 0;
          i < s;
          i++
        ) {
          var d = document.createElement('div');
          for (e = 0; e < l.options.rows; e++) {
            var a = document.createElement('div');
            for (t = 0; t < l.options.slidesPerRow; t++) {
              var c = i * r + (e * l.options.slidesPerRow + t);
              n.get(c) && a.appendChild(n.get(c));
            }
            d.appendChild(a);
          }
          o.appendChild(d);
        }
        l.$slider.empty().append(o),
          l.$slider
            .children()
            .children()
            .children()
            .css({
              width: 100 / l.options.slidesPerRow + '%',
              display: 'inline-block',
            });
      }
    }),
    (e.prototype.checkResponsive = function (e, t) {
      var o,
        s,
        n,
        r = this,
        l = !1,
        d = r.$slider.width(),
        a = window.innerWidth || i(window).width();
      if (
        ('window' === r.respondTo
          ? (n = a)
          : 'slider' === r.respondTo
          ? (n = d)
          : 'min' === r.respondTo && (n = Math.min(a, d)),
        r.options.responsive &&
          r.options.responsive.length &&
          null !== r.options.responsive)
      ) {
        s = null;
        for (o in r.breakpoints)
          r.breakpoints.hasOwnProperty(o) &&
            (r.originalSettings.mobileFirst === !1
              ? n < r.breakpoints[o] && (s = r.breakpoints[o])
              : n > r.breakpoints[o] && (s = r.breakpoints[o]));
        null !== s
          ? null !== r.activeBreakpoint
            ? (s !== r.activeBreakpoint || t) &&
              ((r.activeBreakpoint = s),
              'unslick' === r.breakpointSettings[s]
                ? r.unslick(s)
                : ((r.options = i.extend(
                    {},
                    r.originalSettings,
                    r.breakpointSettings[s]
                  )),
                  e === !0 && (r.currentSlide = r.options.initialSlide),
                  r.refresh(e)),
              (l = s))
            : ((r.activeBreakpoint = s),
              'unslick' === r.breakpointSettings[s]
                ? r.unslick(s)
                : ((r.options = i.extend(
                    {},
                    r.originalSettings,
                    r.breakpointSettings[s]
                  )),
                  e === !0 && (r.currentSlide = r.options.initialSlide),
                  r.refresh(e)),
              (l = s))
          : null !== r.activeBreakpoint &&
            ((r.activeBreakpoint = null),
            (r.options = r.originalSettings),
            e === !0 && (r.currentSlide = r.options.initialSlide),
            r.refresh(e),
            (l = s)),
          e || l === !1 || r.$slider.trigger('breakpoint', [r, l]);
      }
    }),
    (e.prototype.changeSlide = function (e, t) {
      var o,
        s,
        n,
        r = this,
        l = i(e.currentTarget);
      switch (
        (l.is('a') && e.preventDefault(),
        l.is('li') || (l = l.closest('li')),
        (n = r.slideCount % r.options.slidesToScroll !== 0),
        (o = n
          ? 0
          : (r.slideCount - r.currentSlide) % r.options.slidesToScroll),
        e.data.message)
      ) {
        case 'previous':
          (s = 0 === o ? r.options.slidesToScroll : r.options.slidesToShow - o),
            r.slideCount > r.options.slidesToShow &&
              r.slideHandler(r.currentSlide - s, !1, t);
          break;
        case 'next':
          (s = 0 === o ? r.options.slidesToScroll : o),
            r.slideCount > r.options.slidesToShow &&
              r.slideHandler(r.currentSlide + s, !1, t);
          break;
        case 'index':
          var d =
            0 === e.data.index
              ? 0
              : e.data.index || l.index() * r.options.slidesToScroll;
          r.slideHandler(r.checkNavigable(d), !1, t),
            l.children().trigger('focus');
          break;
        default:
          return;
      }
    }),
    (e.prototype.checkNavigable = function (i) {
      var e,
        t,
        o = this;
      if (((e = o.getNavigableIndexes()), (t = 0), i > e[e.length - 1]))
        i = e[e.length - 1];
      else
        for (var s in e) {
          if (i < e[s]) {
            i = t;
            break;
          }
          t = e[s];
        }
      return i;
    }),
    (e.prototype.cleanUpEvents = function () {
      var e = this;
      e.options.dots &&
        null !== e.$dots &&
        (i('li', e.$dots)
          .off('click.slick', e.changeSlide)
          .off('mouseenter.slick', i.proxy(e.interrupt, e, !0))
          .off('mouseleave.slick', i.proxy(e.interrupt, e, !1)),
        e.options.accessibility === !0 &&
          e.$dots.off('keydown.slick', e.keyHandler)),
        e.$slider.off('focus.slick blur.slick'),
        e.options.arrows === !0 &&
          e.slideCount > e.options.slidesToShow &&
          (e.$prevArrow && e.$prevArrow.off('click.slick', e.changeSlide),
          e.$nextArrow && e.$nextArrow.off('click.slick', e.changeSlide),
          e.options.accessibility === !0 &&
            (e.$prevArrow && e.$prevArrow.off('keydown.slick', e.keyHandler),
            e.$nextArrow && e.$nextArrow.off('keydown.slick', e.keyHandler))),
        e.$list.off('touchstart.slick mousedown.slick', e.swipeHandler),
        e.$list.off('touchmove.slick mousemove.slick', e.swipeHandler),
        e.$list.off('touchend.slick mouseup.slick', e.swipeHandler),
        e.$list.off('touchcancel.slick mouseleave.slick', e.swipeHandler),
        e.$list.off('click.slick', e.clickHandler),
        i(document).off(e.visibilityChange, e.visibility),
        e.cleanUpSlideEvents(),
        e.options.accessibility === !0 &&
          e.$list.off('keydown.slick', e.keyHandler),
        e.options.focusOnSelect === !0 &&
          i(e.$slideTrack).children().off('click.slick', e.selectHandler),
        i(window).off(
          'orientationchange.slick.slick-' + e.instanceUid,
          e.orientationChange
        ),
        i(window).off('resize.slick.slick-' + e.instanceUid, e.resize),
        i('[draggable!=true]', e.$slideTrack).off(
          'dragstart',
          e.preventDefault
        ),
        i(window).off('load.slick.slick-' + e.instanceUid, e.setPosition);
    }),
    (e.prototype.cleanUpSlideEvents = function () {
      var e = this;
      e.$list.off('mouseenter.slick', i.proxy(e.interrupt, e, !0)),
        e.$list.off('mouseleave.slick', i.proxy(e.interrupt, e, !1));
    }),
    (e.prototype.cleanUpRows = function () {
      var i,
        e = this;
      e.options.rows > 0 &&
        ((i = e.$slides.children().children()),
        i.removeAttr('style'),
        e.$slider.empty().append(i));
    }),
    (e.prototype.clickHandler = function (i) {
      var e = this;
      e.shouldClick === !1 &&
        (i.stopImmediatePropagation(), i.stopPropagation(), i.preventDefault());
    }),
    (e.prototype.destroy = function (e) {
      var t = this;
      t.autoPlayClear(),
        (t.touchObject = {}),
        t.cleanUpEvents(),
        i('.slick-cloned', t.$slider).detach(),
        t.$dots && t.$dots.remove(),
        t.$prevArrow &&
          t.$prevArrow.length &&
          (t.$prevArrow
            .removeClass('slick-disabled slick-arrow slick-hidden')
            .removeAttr('aria-hidden aria-disabled tabindex')
            .css('display', ''),
          t.htmlExpr.test(t.options.prevArrow) && t.$prevArrow.remove()),
        t.$nextArrow &&
          t.$nextArrow.length &&
          (t.$nextArrow
            .removeClass('slick-disabled slick-arrow slick-hidden')
            .removeAttr('aria-hidden aria-disabled tabindex')
            .css('display', ''),
          t.htmlExpr.test(t.options.nextArrow) && t.$nextArrow.remove()),
        t.$slides &&
          (t.$slides
            .removeClass(
              'slick-slide slick-active slick-center slick-visible slick-current'
            )
            .removeAttr('aria-hidden')
            .removeAttr('data-slick-index')
            .each(function () {
              i(this).attr('style', i(this).data('originalStyling'));
            }),
          t.$slideTrack.children(this.options.slide).detach(),
          t.$slideTrack.detach(),
          t.$list.detach(),
          t.$slider.append(t.$slides)),
        t.cleanUpRows(),
        t.$slider.removeClass('slick-slider'),
        t.$slider.removeClass('slick-initialized'),
        t.$slider.removeClass('slick-dotted'),
        (t.unslicked = !0),
        e || t.$slider.trigger('destroy', [t]);
    }),
    (e.prototype.disableTransition = function (i) {
      var e = this,
        t = {};
      (t[e.transitionType] = ''),
        e.options.fade === !1 ? e.$slideTrack.css(t) : e.$slides.eq(i).css(t);
    }),
    (e.prototype.fadeSlide = function (i, e) {
      var t = this;
      t.cssTransitions === !1
        ? (t.$slides.eq(i).css({ zIndex: t.options.zIndex }),
          t.$slides
            .eq(i)
            .animate({ opacity: 1 }, t.options.speed, t.options.easing, e))
        : (t.applyTransition(i),
          t.$slides.eq(i).css({ opacity: 1, zIndex: t.options.zIndex }),
          e &&
            setTimeout(function () {
              t.disableTransition(i), e.call();
            }, t.options.speed));
    }),
    (e.prototype.fadeSlideOut = function (i) {
      var e = this;
      e.cssTransitions === !1
        ? e.$slides
            .eq(i)
            .animate(
              { opacity: 0, zIndex: e.options.zIndex - 2 },
              e.options.speed,
              e.options.easing
            )
        : (e.applyTransition(i),
          e.$slides.eq(i).css({ opacity: 0, zIndex: e.options.zIndex - 2 }));
    }),
    (e.prototype.filterSlides = e.prototype.slickFilter =
      function (i) {
        var e = this;
        null !== i &&
          ((e.$slidesCache = e.$slides),
          e.unload(),
          e.$slideTrack.children(this.options.slide).detach(),
          e.$slidesCache.filter(i).appendTo(e.$slideTrack),
          e.reinit());
      }),
    (e.prototype.focusHandler = function () {
      var e = this;
      e.$slider
        .off('focus.slick blur.slick')
        .on('focus.slick blur.slick', '*', function (t) {
          t.stopImmediatePropagation();
          var o = i(this);
          setTimeout(function () {
            e.options.pauseOnFocus &&
              ((e.focussed = o.is(':focus')), e.autoPlay());
          }, 0);
        });
    }),
    (e.prototype.getCurrent = e.prototype.slickCurrentSlide =
      function () {
        var i = this;
        return i.currentSlide;
      }),
    (e.prototype.getDotCount = function () {
      var i = this,
        e = 0,
        t = 0,
        o = 0;
      if (i.options.infinite === !0)
        if (i.slideCount <= i.options.slidesToShow) ++o;
        else
          for (; e < i.slideCount; )
            ++o,
              (e = t + i.options.slidesToScroll),
              (t +=
                i.options.slidesToScroll <= i.options.slidesToShow
                  ? i.options.slidesToScroll
                  : i.options.slidesToShow);
      else if (i.options.centerMode === !0) o = i.slideCount;
      else if (i.options.asNavFor)
        for (; e < i.slideCount; )
          ++o,
            (e = t + i.options.slidesToScroll),
            (t +=
              i.options.slidesToScroll <= i.options.slidesToShow
                ? i.options.slidesToScroll
                : i.options.slidesToShow);
      else
        o =
          1 +
          Math.ceil(
            (i.slideCount - i.options.slidesToShow) / i.options.slidesToScroll
          );
      return o - 1;
    }),
    (e.prototype.getLeft = function (i) {
      var e,
        t,
        o,
        s,
        n = this,
        r = 0;
      return (
        (n.slideOffset = 0),
        (t = n.$slides.first().outerHeight(!0)),
        n.options.infinite === !0
          ? (n.slideCount > n.options.slidesToShow &&
              ((n.slideOffset = n.slideWidth * n.options.slidesToShow * -1),
              (s = -1),
              n.options.vertical === !0 &&
                n.options.centerMode === !0 &&
                (2 === n.options.slidesToShow
                  ? (s = -1.5)
                  : 1 === n.options.slidesToShow && (s = -2)),
              (r = t * n.options.slidesToShow * s)),
            n.slideCount % n.options.slidesToScroll !== 0 &&
              i + n.options.slidesToScroll > n.slideCount &&
              n.slideCount > n.options.slidesToShow &&
              (i > n.slideCount
                ? ((n.slideOffset =
                    (n.options.slidesToShow - (i - n.slideCount)) *
                    n.slideWidth *
                    -1),
                  (r = (n.options.slidesToShow - (i - n.slideCount)) * t * -1))
                : ((n.slideOffset =
                    (n.slideCount % n.options.slidesToScroll) *
                    n.slideWidth *
                    -1),
                  (r = (n.slideCount % n.options.slidesToScroll) * t * -1))))
          : i + n.options.slidesToShow > n.slideCount &&
            ((n.slideOffset =
              (i + n.options.slidesToShow - n.slideCount) * n.slideWidth),
            (r = (i + n.options.slidesToShow - n.slideCount) * t)),
        n.slideCount <= n.options.slidesToShow &&
          ((n.slideOffset = 0), (r = 0)),
        n.options.centerMode === !0 && n.slideCount <= n.options.slidesToShow
          ? (n.slideOffset =
              (n.slideWidth * Math.floor(n.options.slidesToShow)) / 2 -
              (n.slideWidth * n.slideCount) / 2)
          : n.options.centerMode === !0 && n.options.infinite === !0
          ? (n.slideOffset +=
              n.slideWidth * Math.floor(n.options.slidesToShow / 2) -
              n.slideWidth)
          : n.options.centerMode === !0 &&
            ((n.slideOffset = 0),
            (n.slideOffset +=
              n.slideWidth * Math.floor(n.options.slidesToShow / 2))),
        (e =
          n.options.vertical === !1
            ? i * n.slideWidth * -1 + n.slideOffset
            : i * t * -1 + r),
        n.options.variableWidth === !0 &&
          ((o =
            n.slideCount <= n.options.slidesToShow || n.options.infinite === !1
              ? n.$slideTrack.children('.slick-slide').eq(i)
              : n.$slideTrack
                  .children('.slick-slide')
                  .eq(i + n.options.slidesToShow)),
          (e =
            n.options.rtl === !0
              ? o[0]
                ? (n.$slideTrack.width() - o[0].offsetLeft - o.width()) * -1
                : 0
              : o[0]
              ? o[0].offsetLeft * -1
              : 0),
          n.options.centerMode === !0 &&
            ((o =
              n.slideCount <= n.options.slidesToShow ||
              n.options.infinite === !1
                ? n.$slideTrack.children('.slick-slide').eq(i)
                : n.$slideTrack
                    .children('.slick-slide')
                    .eq(i + n.options.slidesToShow + 1)),
            (e =
              n.options.rtl === !0
                ? o[0]
                  ? (n.$slideTrack.width() - o[0].offsetLeft - o.width()) * -1
                  : 0
                : o[0]
                ? o[0].offsetLeft * -1
                : 0),
            (e += (n.$list.width() - o.outerWidth()) / 2))),
        e
      );
    }),
    (e.prototype.getOption = e.prototype.slickGetOption =
      function (i) {
        var e = this;
        return e.options[i];
      }),
    (e.prototype.getNavigableIndexes = function () {
      var i,
        e = this,
        t = 0,
        o = 0,
        s = [];
      for (
        e.options.infinite === !1
          ? (i = e.slideCount)
          : ((t = e.options.slidesToScroll * -1),
            (o = e.options.slidesToScroll * -1),
            (i = 2 * e.slideCount));
        t < i;

      )
        s.push(t),
          (t = o + e.options.slidesToScroll),
          (o +=
            e.options.slidesToScroll <= e.options.slidesToShow
              ? e.options.slidesToScroll
              : e.options.slidesToShow);
      return s;
    }),
    (e.prototype.getSlick = function () {
      return this;
    }),
    (e.prototype.getSlideCount = function () {
      var e,
        t,
        o,
        s = this;
      return (
        (o =
          s.options.centerMode === !0
            ? s.slideWidth * Math.floor(s.options.slidesToShow / 2)
            : 0),
        s.options.swipeToSlide === !0
          ? (s.$slideTrack.find('.slick-slide').each(function (e, n) {
              if (n.offsetLeft - o + i(n).outerWidth() / 2 > s.swipeLeft * -1)
                return (t = n), !1;
            }),
            (e = Math.abs(i(t).attr('data-slick-index') - s.currentSlide) || 1))
          : s.options.slidesToScroll
      );
    }),
    (e.prototype.goTo = e.prototype.slickGoTo =
      function (i, e) {
        var t = this;
        t.changeSlide({ data: { message: 'index', index: parseInt(i) } }, e);
      }),
    (e.prototype.init = function (e) {
      var t = this;
      i(t.$slider).hasClass('slick-initialized') ||
        (i(t.$slider).addClass('slick-initialized'),
        t.buildRows(),
        t.buildOut(),
        t.setProps(),
        t.startLoad(),
        t.loadSlider(),
        t.initializeEvents(),
        t.updateArrows(),
        t.updateDots(),
        t.checkResponsive(!0),
        t.focusHandler()),
        e && t.$slider.trigger('init', [t]),
        t.options.accessibility === !0 && t.initADA(),
        t.options.autoplay && ((t.paused = !1), t.autoPlay());
    }),
    (e.prototype.initADA = function () {
      var e = this,
        t = Math.ceil(e.slideCount / e.options.slidesToShow),
        o = e.getNavigableIndexes().filter(function (i) {
          return i >= 0 && i < e.slideCount;
        });
      e.$slides
        .add(e.$slideTrack.find('.slick-cloned'))
        .attr({ 'aria-hidden': 'true', tabindex: '-1' })
        .find('a, input, button, select')
        .attr({ tabindex: '-1' }),
        null !== e.$dots &&
          (e.$slides
            .not(e.$slideTrack.find('.slick-cloned'))
            .each(function (t) {
              var s = o.indexOf(t);
              if (
                (i(this).attr({
                  role: 'tabpanel',
                  id: 'slick-slide' + e.instanceUid + t,
                  tabindex: -1,
                }),
                s !== -1)
              ) {
                var n = 'slick-slide-control' + e.instanceUid + s;
                i('#' + n).length && i(this).attr({ 'aria-describedby': n });
              }
            }),
          e.$dots
            .attr('role', 'tablist')
            .find('li')
            .each(function (s) {
              var n = o[s];
              i(this).attr({ role: 'presentation' }),
                i(this)
                  .find('button')
                  .first()
                  .attr({
                    role: 'tab',
                    id: 'slick-slide-control' + e.instanceUid + s,
                    'aria-controls': 'slick-slide' + e.instanceUid + n,
                    'aria-label': s + 1 + ' of ' + t,
                    'aria-selected': null,
                    tabindex: '-1',
                  });
            })
            .eq(e.currentSlide)
            .find('button')
            .attr({ 'aria-selected': 'true', tabindex: '0' })
            .end());
      for (var s = e.currentSlide, n = s + e.options.slidesToShow; s < n; s++)
        e.options.focusOnChange
          ? e.$slides.eq(s).attr({ tabindex: '0' })
          : e.$slides.eq(s).removeAttr('tabindex');
      e.activateADA();
    }),
    (e.prototype.initArrowEvents = function () {
      var i = this;
      i.options.arrows === !0 &&
        i.slideCount > i.options.slidesToShow &&
        (i.$prevArrow
          .off('click.slick')
          .on('click.slick', { message: 'previous' }, i.changeSlide),
        i.$nextArrow
          .off('click.slick')
          .on('click.slick', { message: 'next' }, i.changeSlide),
        i.options.accessibility === !0 &&
          (i.$prevArrow.on('keydown.slick', i.keyHandler),
          i.$nextArrow.on('keydown.slick', i.keyHandler)));
    }),
    (e.prototype.initDotEvents = function () {
      var e = this;
      e.options.dots === !0 &&
        e.slideCount > e.options.slidesToShow &&
        (i('li', e.$dots).on(
          'click.slick',
          { message: 'index' },
          e.changeSlide
        ),
        e.options.accessibility === !0 &&
          e.$dots.on('keydown.slick', e.keyHandler)),
        e.options.dots === !0 &&
          e.options.pauseOnDotsHover === !0 &&
          e.slideCount > e.options.slidesToShow &&
          i('li', e.$dots)
            .on('mouseenter.slick', i.proxy(e.interrupt, e, !0))
            .on('mouseleave.slick', i.proxy(e.interrupt, e, !1));
    }),
    (e.prototype.initSlideEvents = function () {
      var e = this;
      e.options.pauseOnHover &&
        (e.$list.on('mouseenter.slick', i.proxy(e.interrupt, e, !0)),
        e.$list.on('mouseleave.slick', i.proxy(e.interrupt, e, !1)));
    }),
    (e.prototype.initializeEvents = function () {
      var e = this;
      e.initArrowEvents(),
        e.initDotEvents(),
        e.initSlideEvents(),
        e.$list.on(
          'touchstart.slick mousedown.slick',
          { action: 'start' },
          e.swipeHandler
        ),
        e.$list.on(
          'touchmove.slick mousemove.slick',
          { action: 'move' },
          e.swipeHandler
        ),
        e.$list.on(
          'touchend.slick mouseup.slick',
          { action: 'end' },
          e.swipeHandler
        ),
        e.$list.on(
          'touchcancel.slick mouseleave.slick',
          { action: 'end' },
          e.swipeHandler
        ),
        e.$list.on('click.slick', e.clickHandler),
        i(document).on(e.visibilityChange, i.proxy(e.visibility, e)),
        e.options.accessibility === !0 &&
          e.$list.on('keydown.slick', e.keyHandler),
        e.options.focusOnSelect === !0 &&
          i(e.$slideTrack).children().on('click.slick', e.selectHandler),
        i(window).on(
          'orientationchange.slick.slick-' + e.instanceUid,
          i.proxy(e.orientationChange, e)
        ),
        i(window).on(
          'resize.slick.slick-' + e.instanceUid,
          i.proxy(e.resize, e)
        ),
        i('[draggable!=true]', e.$slideTrack).on('dragstart', e.preventDefault),
        i(window).on('load.slick.slick-' + e.instanceUid, e.setPosition),
        i(e.setPosition);
    }),
    (e.prototype.initUI = function () {
      var i = this;
      i.options.arrows === !0 &&
        i.slideCount > i.options.slidesToShow &&
        (i.$prevArrow.show(), i.$nextArrow.show()),
        i.options.dots === !0 &&
          i.slideCount > i.options.slidesToShow &&
          i.$dots.show();
    }),
    (e.prototype.keyHandler = function (i) {
      var e = this;
      i.target.tagName.match('TEXTAREA|INPUT|SELECT') ||
        (37 === i.keyCode && e.options.accessibility === !0
          ? e.changeSlide({
              data: { message: e.options.rtl === !0 ? 'next' : 'previous' },
            })
          : 39 === i.keyCode &&
            e.options.accessibility === !0 &&
            e.changeSlide({
              data: { message: e.options.rtl === !0 ? 'previous' : 'next' },
            }));
    }),
    (e.prototype.lazyLoad = function () {
      function e(e) {
        i('img[data-lazy]', e).each(function () {
          var e = i(this),
            t = i(this).attr('data-lazy'),
            o = i(this).attr('data-srcset'),
            s = i(this).attr('data-sizes') || r.$slider.attr('data-sizes'),
            n = document.createElement('img');
          (n.onload = function () {
            e.animate({ opacity: 0 }, 100, function () {
              o && (e.attr('srcset', o), s && e.attr('sizes', s)),
                e.attr('src', t).animate({ opacity: 1 }, 200, function () {
                  e.removeAttr('data-lazy data-srcset data-sizes').removeClass(
                    'slick-loading'
                  );
                }),
                r.$slider.trigger('lazyLoaded', [r, e, t]);
            });
          }),
            (n.onerror = function () {
              e
                .removeAttr('data-lazy')
                .removeClass('slick-loading')
                .addClass('slick-lazyload-error'),
                r.$slider.trigger('lazyLoadError', [r, e, t]);
            }),
            (n.src = t);
        });
      }
      var t,
        o,
        s,
        n,
        r = this;
      if (
        (r.options.centerMode === !0
          ? r.options.infinite === !0
            ? ((s = r.currentSlide + (r.options.slidesToShow / 2 + 1)),
              (n = s + r.options.slidesToShow + 2))
            : ((s = Math.max(
                0,
                r.currentSlide - (r.options.slidesToShow / 2 + 1)
              )),
              (n = 2 + (r.options.slidesToShow / 2 + 1) + r.currentSlide))
          : ((s = r.options.infinite
              ? r.options.slidesToShow + r.currentSlide
              : r.currentSlide),
            (n = Math.ceil(s + r.options.slidesToShow)),
            r.options.fade === !0 && (s > 0 && s--, n <= r.slideCount && n++)),
        (t = r.$slider.find('.slick-slide').slice(s, n)),
        'anticipated' === r.options.lazyLoad)
      )
        for (
          var l = s - 1, d = n, a = r.$slider.find('.slick-slide'), c = 0;
          c < r.options.slidesToScroll;
          c++
        )
          l < 0 && (l = r.slideCount - 1),
            (t = t.add(a.eq(l))),
            (t = t.add(a.eq(d))),
            l--,
            d++;
      e(t),
        r.slideCount <= r.options.slidesToShow
          ? ((o = r.$slider.find('.slick-slide')), e(o))
          : r.currentSlide >= r.slideCount - r.options.slidesToShow
          ? ((o = r.$slider
              .find('.slick-cloned')
              .slice(0, r.options.slidesToShow)),
            e(o))
          : 0 === r.currentSlide &&
            ((o = r.$slider
              .find('.slick-cloned')
              .slice(r.options.slidesToShow * -1)),
            e(o));
    }),
    (e.prototype.loadSlider = function () {
      var i = this;
      i.setPosition(),
        i.$slideTrack.css({ opacity: 1 }),
        i.$slider.removeClass('slick-loading'),
        i.initUI(),
        'progressive' === i.options.lazyLoad && i.progressiveLazyLoad();
    }),
    (e.prototype.next = e.prototype.slickNext =
      function () {
        var i = this;
        i.changeSlide({ data: { message: 'next' } });
      }),
    (e.prototype.orientationChange = function () {
      var i = this;
      i.checkResponsive(), i.setPosition();
    }),
    (e.prototype.pause = e.prototype.slickPause =
      function () {
        var i = this;
        i.autoPlayClear(), (i.paused = !0);
      }),
    (e.prototype.play = e.prototype.slickPlay =
      function () {
        var i = this;
        i.autoPlay(),
          (i.options.autoplay = !0),
          (i.paused = !1),
          (i.focussed = !1),
          (i.interrupted = !1);
      }),
    (e.prototype.postSlide = function (e) {
      var t = this;
      if (
        !t.unslicked &&
        (t.$slider.trigger('afterChange', [t, e]),
        (t.animating = !1),
        t.slideCount > t.options.slidesToShow && t.setPosition(),
        (t.swipeLeft = null),
        t.options.autoplay && t.autoPlay(),
        t.options.accessibility === !0 &&
          (t.initADA(), t.options.focusOnChange))
      ) {
        var o = i(t.$slides.get(t.currentSlide));
        o.attr('tabindex', 0).focus();
      }
    }),
    (e.prototype.prev = e.prototype.slickPrev =
      function () {
        var i = this;
        i.changeSlide({ data: { message: 'previous' } });
      }),
    (e.prototype.preventDefault = function (i) {
      i.preventDefault();
    }),
    (e.prototype.progressiveLazyLoad = function (e) {
      e = e || 1;
      var t,
        o,
        s,
        n,
        r,
        l = this,
        d = i('img[data-lazy]', l.$slider);
      d.length
        ? ((t = d.first()),
          (o = t.attr('data-lazy')),
          (s = t.attr('data-srcset')),
          (n = t.attr('data-sizes') || l.$slider.attr('data-sizes')),
          (r = document.createElement('img')),
          (r.onload = function () {
            s && (t.attr('srcset', s), n && t.attr('sizes', n)),
              t
                .attr('src', o)
                .removeAttr('data-lazy data-srcset data-sizes')
                .removeClass('slick-loading'),
              l.options.adaptiveHeight === !0 && l.setPosition(),
              l.$slider.trigger('lazyLoaded', [l, t, o]),
              l.progressiveLazyLoad();
          }),
          (r.onerror = function () {
            e < 3
              ? setTimeout(function () {
                  l.progressiveLazyLoad(e + 1);
                }, 500)
              : (t
                  .removeAttr('data-lazy')
                  .removeClass('slick-loading')
                  .addClass('slick-lazyload-error'),
                l.$slider.trigger('lazyLoadError', [l, t, o]),
                l.progressiveLazyLoad());
          }),
          (r.src = o))
        : l.$slider.trigger('allImagesLoaded', [l]);
    }),
    (e.prototype.refresh = function (e) {
      var t,
        o,
        s = this;
      (o = s.slideCount - s.options.slidesToShow),
        !s.options.infinite && s.currentSlide > o && (s.currentSlide = o),
        s.slideCount <= s.options.slidesToShow && (s.currentSlide = 0),
        (t = s.currentSlide),
        s.destroy(!0),
        i.extend(s, s.initials, { currentSlide: t }),
        s.init(),
        e || s.changeSlide({ data: { message: 'index', index: t } }, !1);
    }),
    (e.prototype.registerBreakpoints = function () {
      var e,
        t,
        o,
        s = this,
        n = s.options.responsive || null;
      if ('array' === i.type(n) && n.length) {
        s.respondTo = s.options.respondTo || 'window';
        for (e in n)
          if (((o = s.breakpoints.length - 1), n.hasOwnProperty(e))) {
            for (t = n[e].breakpoint; o >= 0; )
              s.breakpoints[o] &&
                s.breakpoints[o] === t &&
                s.breakpoints.splice(o, 1),
                o--;
            s.breakpoints.push(t), (s.breakpointSettings[t] = n[e].settings);
          }
        s.breakpoints.sort(function (i, e) {
          return s.options.mobileFirst ? i - e : e - i;
        });
      }
    }),
    (e.prototype.reinit = function () {
      var e = this;
      (e.$slides = e.$slideTrack
        .children(e.options.slide)
        .addClass('slick-slide')),
        (e.slideCount = e.$slides.length),
        e.currentSlide >= e.slideCount &&
          0 !== e.currentSlide &&
          (e.currentSlide = e.currentSlide - e.options.slidesToScroll),
        e.slideCount <= e.options.slidesToShow && (e.currentSlide = 0),
        e.registerBreakpoints(),
        e.setProps(),
        e.setupInfinite(),
        e.buildArrows(),
        e.updateArrows(),
        e.initArrowEvents(),
        e.buildDots(),
        e.updateDots(),
        e.initDotEvents(),
        e.cleanUpSlideEvents(),
        e.initSlideEvents(),
        e.checkResponsive(!1, !0),
        e.options.focusOnSelect === !0 &&
          i(e.$slideTrack).children().on('click.slick', e.selectHandler),
        e.setSlideClasses(
          'number' == typeof e.currentSlide ? e.currentSlide : 0
        ),
        e.setPosition(),
        e.focusHandler(),
        (e.paused = !e.options.autoplay),
        e.autoPlay(),
        e.$slider.trigger('reInit', [e]);
    }),
    (e.prototype.resize = function () {
      var e = this;
      i(window).width() !== e.windowWidth &&
        (clearTimeout(e.windowDelay),
        (e.windowDelay = window.setTimeout(function () {
          (e.windowWidth = i(window).width()),
            e.checkResponsive(),
            e.unslicked || e.setPosition();
        }, 50)));
    }),
    (e.prototype.removeSlide = e.prototype.slickRemove =
      function (i, e, t) {
        var o = this;
        return (
          'boolean' == typeof i
            ? ((e = i), (i = e === !0 ? 0 : o.slideCount - 1))
            : (i = e === !0 ? --i : i),
          !(o.slideCount < 1 || i < 0 || i > o.slideCount - 1) &&
            (o.unload(),
            t === !0
              ? o.$slideTrack.children().remove()
              : o.$slideTrack.children(this.options.slide).eq(i).remove(),
            (o.$slides = o.$slideTrack.children(this.options.slide)),
            o.$slideTrack.children(this.options.slide).detach(),
            o.$slideTrack.append(o.$slides),
            (o.$slidesCache = o.$slides),
            void o.reinit())
        );
      }),
    (e.prototype.setCSS = function (i) {
      var e,
        t,
        o = this,
        s = {};
      o.options.rtl === !0 && (i = -i),
        (e = 'left' == o.positionProp ? Math.ceil(i) + 'px' : '0px'),
        (t = 'top' == o.positionProp ? Math.ceil(i) + 'px' : '0px'),
        (s[o.positionProp] = i),
        o.transformsEnabled === !1
          ? o.$slideTrack.css(s)
          : ((s = {}),
            o.cssTransitions === !1
              ? ((s[o.animType] = 'translate(' + e + ', ' + t + ')'),
                o.$slideTrack.css(s))
              : ((s[o.animType] = 'translate3d(' + e + ', ' + t + ', 0px)'),
                o.$slideTrack.css(s)));
    }),
    (e.prototype.setDimensions = function () {
      var i = this;
      i.options.vertical === !1
        ? i.options.centerMode === !0 &&
          i.$list.css({ padding: '0px ' + i.options.centerPadding })
        : (i.$list.height(
            i.$slides.first().outerHeight(!0) * i.options.slidesToShow
          ),
          i.options.centerMode === !0 &&
            i.$list.css({ padding: i.options.centerPadding + ' 0px' })),
        (i.listWidth = i.$list.width()),
        (i.listHeight = i.$list.height()),
        i.options.vertical === !1 && i.options.variableWidth === !1
          ? ((i.slideWidth = Math.ceil(i.listWidth / i.options.slidesToShow)),
            i.$slideTrack.width(
              Math.ceil(
                i.slideWidth * i.$slideTrack.children('.slick-slide').length
              )
            ))
          : i.options.variableWidth === !0
          ? i.$slideTrack.width(5e3 * i.slideCount)
          : ((i.slideWidth = Math.ceil(i.listWidth)),
            i.$slideTrack.height(
              Math.ceil(
                i.$slides.first().outerHeight(!0) *
                  i.$slideTrack.children('.slick-slide').length
              )
            ));
      var e = i.$slides.first().outerWidth(!0) - i.$slides.first().width();
      i.options.variableWidth === !1 &&
        i.$slideTrack.children('.slick-slide').width(i.slideWidth - e);
    }),
    (e.prototype.setFade = function () {
      var e,
        t = this;
      t.$slides.each(function (o, s) {
        (e = t.slideWidth * o * -1),
          t.options.rtl === !0
            ? i(s).css({
                position: 'relative',
                right: e,
                top: 0,
                zIndex: t.options.zIndex - 2,
                opacity: 0,
              })
            : i(s).css({
                position: 'relative',
                left: e,
                top: 0,
                zIndex: t.options.zIndex - 2,
                opacity: 0,
              });
      }),
        t.$slides
          .eq(t.currentSlide)
          .css({ zIndex: t.options.zIndex - 1, opacity: 1 });
    }),
    (e.prototype.setHeight = function () {
      var i = this;
      if (
        1 === i.options.slidesToShow &&
        i.options.adaptiveHeight === !0 &&
        i.options.vertical === !1
      ) {
        var e = i.$slides.eq(i.currentSlide).outerHeight(!0);
        i.$list.css('height', e);
      }
    }),
    (e.prototype.setOption = e.prototype.slickSetOption =
      function () {
        var e,
          t,
          o,
          s,
          n,
          r = this,
          l = !1;
        if (
          ('object' === i.type(arguments[0])
            ? ((o = arguments[0]), (l = arguments[1]), (n = 'multiple'))
            : 'string' === i.type(arguments[0]) &&
              ((o = arguments[0]),
              (s = arguments[1]),
              (l = arguments[2]),
              'responsive' === arguments[0] && 'array' === i.type(arguments[1])
                ? (n = 'responsive')
                : 'undefined' != typeof arguments[1] && (n = 'single')),
          'single' === n)
        )
          r.options[o] = s;
        else if ('multiple' === n)
          i.each(o, function (i, e) {
            r.options[i] = e;
          });
        else if ('responsive' === n)
          for (t in s)
            if ('array' !== i.type(r.options.responsive))
              r.options.responsive = [s[t]];
            else {
              for (e = r.options.responsive.length - 1; e >= 0; )
                r.options.responsive[e].breakpoint === s[t].breakpoint &&
                  r.options.responsive.splice(e, 1),
                  e--;
              r.options.responsive.push(s[t]);
            }
        l && (r.unload(), r.reinit());
      }),
    (e.prototype.setPosition = function () {
      var i = this;
      i.setDimensions(),
        i.setHeight(),
        i.options.fade === !1
          ? i.setCSS(i.getLeft(i.currentSlide))
          : i.setFade(),
        i.$slider.trigger('setPosition', [i]);
    }),
    (e.prototype.setProps = function () {
      var i = this,
        e = document.body.style;
      (i.positionProp = i.options.vertical === !0 ? 'top' : 'left'),
        'top' === i.positionProp
          ? i.$slider.addClass('slick-vertical')
          : i.$slider.removeClass('slick-vertical'),
        (void 0 === e.WebkitTransition &&
          void 0 === e.MozTransition &&
          void 0 === e.msTransition) ||
          (i.options.useCSS === !0 && (i.cssTransitions = !0)),
        i.options.fade &&
          ('number' == typeof i.options.zIndex
            ? i.options.zIndex < 3 && (i.options.zIndex = 3)
            : (i.options.zIndex = i.defaults.zIndex)),
        void 0 !== e.OTransform &&
          ((i.animType = 'OTransform'),
          (i.transformType = '-o-transform'),
          (i.transitionType = 'OTransition'),
          void 0 === e.perspectiveProperty &&
            void 0 === e.webkitPerspective &&
            (i.animType = !1)),
        void 0 !== e.MozTransform &&
          ((i.animType = 'MozTransform'),
          (i.transformType = '-moz-transform'),
          (i.transitionType = 'MozTransition'),
          void 0 === e.perspectiveProperty &&
            void 0 === e.MozPerspective &&
            (i.animType = !1)),
        void 0 !== e.webkitTransform &&
          ((i.animType = 'webkitTransform'),
          (i.transformType = '-webkit-transform'),
          (i.transitionType = 'webkitTransition'),
          void 0 === e.perspectiveProperty &&
            void 0 === e.webkitPerspective &&
            (i.animType = !1)),
        void 0 !== e.msTransform &&
          ((i.animType = 'msTransform'),
          (i.transformType = '-ms-transform'),
          (i.transitionType = 'msTransition'),
          void 0 === e.msTransform && (i.animType = !1)),
        void 0 !== e.transform &&
          i.animType !== !1 &&
          ((i.animType = 'transform'),
          (i.transformType = 'transform'),
          (i.transitionType = 'transition')),
        (i.transformsEnabled =
          i.options.useTransform && null !== i.animType && i.animType !== !1);
    }),
    (e.prototype.setSlideClasses = function (i) {
      var e,
        t,
        o,
        s,
        n = this;
      if (
        ((t = n.$slider
          .find('.slick-slide')
          .removeClass('slick-active slick-center slick-current')
          .attr('aria-hidden', 'true')),
        n.$slides.eq(i).addClass('slick-current'),
        n.options.centerMode === !0)
      ) {
        var r = n.options.slidesToShow % 2 === 0 ? 1 : 0;
        (e = Math.floor(n.options.slidesToShow / 2)),
          n.options.infinite === !0 &&
            (i >= e && i <= n.slideCount - 1 - e
              ? n.$slides
                  .slice(i - e + r, i + e + 1)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')
              : ((o = n.options.slidesToShow + i),
                t
                  .slice(o - e + 1 + r, o + e + 2)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')),
            0 === i
              ? t
                  .eq(t.length - 1 - n.options.slidesToShow)
                  .addClass('slick-center')
              : i === n.slideCount - 1 &&
                t.eq(n.options.slidesToShow).addClass('slick-center')),
          n.$slides.eq(i).addClass('slick-center');
      } else
        i >= 0 && i <= n.slideCount - n.options.slidesToShow
          ? n.$slides
              .slice(i, i + n.options.slidesToShow)
              .addClass('slick-active')
              .attr('aria-hidden', 'false')
          : t.length <= n.options.slidesToShow
          ? t.addClass('slick-active').attr('aria-hidden', 'false')
          : ((s = n.slideCount % n.options.slidesToShow),
            (o = n.options.infinite === !0 ? n.options.slidesToShow + i : i),
            n.options.slidesToShow == n.options.slidesToScroll &&
            n.slideCount - i < n.options.slidesToShow
              ? t
                  .slice(o - (n.options.slidesToShow - s), o + s)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false')
              : t
                  .slice(o, o + n.options.slidesToShow)
                  .addClass('slick-active')
                  .attr('aria-hidden', 'false'));
      ('ondemand' !== n.options.lazyLoad &&
        'anticipated' !== n.options.lazyLoad) ||
        n.lazyLoad();
    }),
    (e.prototype.setupInfinite = function () {
      var e,
        t,
        o,
        s = this;
      if (
        (s.options.fade === !0 && (s.options.centerMode = !1),
        s.options.infinite === !0 &&
          s.options.fade === !1 &&
          ((t = null), s.slideCount > s.options.slidesToShow))
      ) {
        for (
          o =
            s.options.centerMode === !0
              ? s.options.slidesToShow + 1
              : s.options.slidesToShow,
            e = s.slideCount;
          e > s.slideCount - o;
          e -= 1
        )
          (t = e - 1),
            i(s.$slides[t])
              .clone(!0)
              .attr('id', '')
              .attr('data-slick-index', t - s.slideCount)
              .prependTo(s.$slideTrack)
              .addClass('slick-cloned');
        for (e = 0; e < o + s.slideCount; e += 1)
          (t = e),
            i(s.$slides[t])
              .clone(!0)
              .attr('id', '')
              .attr('data-slick-index', t + s.slideCount)
              .appendTo(s.$slideTrack)
              .addClass('slick-cloned');
        s.$slideTrack
          .find('.slick-cloned')
          .find('[id]')
          .each(function () {
            i(this).attr('id', '');
          });
      }
    }),
    (e.prototype.interrupt = function (i) {
      var e = this;
      i || e.autoPlay(), (e.interrupted = i);
    }),
    (e.prototype.selectHandler = function (e) {
      var t = this,
        o = i(e.target).is('.slick-slide')
          ? i(e.target)
          : i(e.target).parents('.slick-slide'),
        s = parseInt(o.attr('data-slick-index'));
      return (
        s || (s = 0),
        t.slideCount <= t.options.slidesToShow
          ? void t.slideHandler(s, !1, !0)
          : void t.slideHandler(s)
      );
    }),
    (e.prototype.slideHandler = function (i, e, t) {
      var o,
        s,
        n,
        r,
        l,
        d = null,
        a = this;
      if (
        ((e = e || !1),
        !(
          (a.animating === !0 && a.options.waitForAnimate === !0) ||
          (a.options.fade === !0 && a.currentSlide === i)
        ))
      )
        return (
          e === !1 && a.asNavFor(i),
          (o = i),
          (d = a.getLeft(o)),
          (r = a.getLeft(a.currentSlide)),
          (a.currentLeft = null === a.swipeLeft ? r : a.swipeLeft),
          a.options.infinite === !1 &&
          a.options.centerMode === !1 &&
          (i < 0 || i > a.getDotCount() * a.options.slidesToScroll)
            ? void (
                a.options.fade === !1 &&
                ((o = a.currentSlide),
                t !== !0 && a.slideCount > a.options.slidesToShow
                  ? a.animateSlide(r, function () {
                      a.postSlide(o);
                    })
                  : a.postSlide(o))
              )
            : a.options.infinite === !1 &&
              a.options.centerMode === !0 &&
              (i < 0 || i > a.slideCount - a.options.slidesToScroll)
            ? void (
                a.options.fade === !1 &&
                ((o = a.currentSlide),
                t !== !0 && a.slideCount > a.options.slidesToShow
                  ? a.animateSlide(r, function () {
                      a.postSlide(o);
                    })
                  : a.postSlide(o))
              )
            : (a.options.autoplay && clearInterval(a.autoPlayTimer),
              (s =
                o < 0
                  ? a.slideCount % a.options.slidesToScroll !== 0
                    ? a.slideCount - (a.slideCount % a.options.slidesToScroll)
                    : a.slideCount + o
                  : o >= a.slideCount
                  ? a.slideCount % a.options.slidesToScroll !== 0
                    ? 0
                    : o - a.slideCount
                  : o),
              (a.animating = !0),
              a.$slider.trigger('beforeChange', [a, a.currentSlide, s]),
              (n = a.currentSlide),
              (a.currentSlide = s),
              a.setSlideClasses(a.currentSlide),
              a.options.asNavFor &&
                ((l = a.getNavTarget()),
                (l = l.slick('getSlick')),
                l.slideCount <= l.options.slidesToShow &&
                  l.setSlideClasses(a.currentSlide)),
              a.updateDots(),
              a.updateArrows(),
              a.options.fade === !0
                ? (t !== !0
                    ? (a.fadeSlideOut(n),
                      a.fadeSlide(s, function () {
                        a.postSlide(s);
                      }))
                    : a.postSlide(s),
                  void a.animateHeight())
                : void (t !== !0 && a.slideCount > a.options.slidesToShow
                    ? a.animateSlide(d, function () {
                        a.postSlide(s);
                      })
                    : a.postSlide(s)))
        );
    }),
    (e.prototype.startLoad = function () {
      var i = this;
      i.options.arrows === !0 &&
        i.slideCount > i.options.slidesToShow &&
        (i.$prevArrow.hide(), i.$nextArrow.hide()),
        i.options.dots === !0 &&
          i.slideCount > i.options.slidesToShow &&
          i.$dots.hide(),
        i.$slider.addClass('slick-loading');
    }),
    (e.prototype.swipeDirection = function () {
      var i,
        e,
        t,
        o,
        s = this;
      return (
        (i = s.touchObject.startX - s.touchObject.curX),
        (e = s.touchObject.startY - s.touchObject.curY),
        (t = Math.atan2(e, i)),
        (o = Math.round((180 * t) / Math.PI)),
        o < 0 && (o = 360 - Math.abs(o)),
        o <= 45 && o >= 0
          ? s.options.rtl === !1
            ? 'left'
            : 'right'
          : o <= 360 && o >= 315
          ? s.options.rtl === !1
            ? 'left'
            : 'right'
          : o >= 135 && o <= 225
          ? s.options.rtl === !1
            ? 'right'
            : 'left'
          : s.options.verticalSwiping === !0
          ? o >= 35 && o <= 135
            ? 'down'
            : 'up'
          : 'vertical'
      );
    }),
    (e.prototype.swipeEnd = function (i) {
      var e,
        t,
        o = this;
      if (((o.dragging = !1), (o.swiping = !1), o.scrolling))
        return (o.scrolling = !1), !1;
      if (
        ((o.interrupted = !1),
        (o.shouldClick = !(o.touchObject.swipeLength > 10)),
        void 0 === o.touchObject.curX)
      )
        return !1;
      if (
        (o.touchObject.edgeHit === !0 &&
          o.$slider.trigger('edge', [o, o.swipeDirection()]),
        o.touchObject.swipeLength >= o.touchObject.minSwipe)
      ) {
        switch ((t = o.swipeDirection())) {
          case 'left':
          case 'down':
            (e = o.options.swipeToSlide
              ? o.checkNavigable(o.currentSlide + o.getSlideCount())
              : o.currentSlide + o.getSlideCount()),
              (o.currentDirection = 0);
            break;
          case 'right':
          case 'up':
            (e = o.options.swipeToSlide
              ? o.checkNavigable(o.currentSlide - o.getSlideCount())
              : o.currentSlide - o.getSlideCount()),
              (o.currentDirection = 1);
        }
        'vertical' != t &&
          (o.slideHandler(e),
          (o.touchObject = {}),
          o.$slider.trigger('swipe', [o, t]));
      } else
        o.touchObject.startX !== o.touchObject.curX &&
          (o.slideHandler(o.currentSlide), (o.touchObject = {}));
    }),
    (e.prototype.swipeHandler = function (i) {
      var e = this;
      if (
        !(
          e.options.swipe === !1 ||
          ('ontouchend' in document && e.options.swipe === !1) ||
          (e.options.draggable === !1 && i.type.indexOf('mouse') !== -1)
        )
      )
        switch (
          ((e.touchObject.fingerCount =
            i.originalEvent && void 0 !== i.originalEvent.touches
              ? i.originalEvent.touches.length
              : 1),
          (e.touchObject.minSwipe = e.listWidth / e.options.touchThreshold),
          e.options.verticalSwiping === !0 &&
            (e.touchObject.minSwipe = e.listHeight / e.options.touchThreshold),
          i.data.action)
        ) {
          case 'start':
            e.swipeStart(i);
            break;
          case 'move':
            e.swipeMove(i);
            break;
          case 'end':
            e.swipeEnd(i);
        }
    }),
    (e.prototype.swipeMove = function (i) {
      var e,
        t,
        o,
        s,
        n,
        r,
        l = this;
      return (
        (n = void 0 !== i.originalEvent ? i.originalEvent.touches : null),
        !(!l.dragging || l.scrolling || (n && 1 !== n.length)) &&
          ((e = l.getLeft(l.currentSlide)),
          (l.touchObject.curX = void 0 !== n ? n[0].pageX : i.clientX),
          (l.touchObject.curY = void 0 !== n ? n[0].pageY : i.clientY),
          (l.touchObject.swipeLength = Math.round(
            Math.sqrt(Math.pow(l.touchObject.curX - l.touchObject.startX, 2))
          )),
          (r = Math.round(
            Math.sqrt(Math.pow(l.touchObject.curY - l.touchObject.startY, 2))
          )),
          !l.options.verticalSwiping && !l.swiping && r > 4
            ? ((l.scrolling = !0), !1)
            : (l.options.verticalSwiping === !0 &&
                (l.touchObject.swipeLength = r),
              (t = l.swipeDirection()),
              void 0 !== i.originalEvent &&
                l.touchObject.swipeLength > 4 &&
                ((l.swiping = !0), i.preventDefault()),
              (s =
                (l.options.rtl === !1 ? 1 : -1) *
                (l.touchObject.curX > l.touchObject.startX ? 1 : -1)),
              l.options.verticalSwiping === !0 &&
                (s = l.touchObject.curY > l.touchObject.startY ? 1 : -1),
              (o = l.touchObject.swipeLength),
              (l.touchObject.edgeHit = !1),
              l.options.infinite === !1 &&
                ((0 === l.currentSlide && 'right' === t) ||
                  (l.currentSlide >= l.getDotCount() && 'left' === t)) &&
                ((o = l.touchObject.swipeLength * l.options.edgeFriction),
                (l.touchObject.edgeHit = !0)),
              l.options.vertical === !1
                ? (l.swipeLeft = e + o * s)
                : (l.swipeLeft = e + o * (l.$list.height() / l.listWidth) * s),
              l.options.verticalSwiping === !0 && (l.swipeLeft = e + o * s),
              l.options.fade !== !0 &&
                l.options.touchMove !== !1 &&
                (l.animating === !0
                  ? ((l.swipeLeft = null), !1)
                  : void l.setCSS(l.swipeLeft))))
      );
    }),
    (e.prototype.swipeStart = function (i) {
      var e,
        t = this;
      return (
        (t.interrupted = !0),
        1 !== t.touchObject.fingerCount ||
        t.slideCount <= t.options.slidesToShow
          ? ((t.touchObject = {}), !1)
          : (void 0 !== i.originalEvent &&
              void 0 !== i.originalEvent.touches &&
              (e = i.originalEvent.touches[0]),
            (t.touchObject.startX = t.touchObject.curX =
              void 0 !== e ? e.pageX : i.clientX),
            (t.touchObject.startY = t.touchObject.curY =
              void 0 !== e ? e.pageY : i.clientY),
            void (t.dragging = !0))
      );
    }),
    (e.prototype.unfilterSlides = e.prototype.slickUnfilter =
      function () {
        var i = this;
        null !== i.$slidesCache &&
          (i.unload(),
          i.$slideTrack.children(this.options.slide).detach(),
          i.$slidesCache.appendTo(i.$slideTrack),
          i.reinit());
      }),
    (e.prototype.unload = function () {
      var e = this;
      i('.slick-cloned', e.$slider).remove(),
        e.$dots && e.$dots.remove(),
        e.$prevArrow &&
          e.htmlExpr.test(e.options.prevArrow) &&
          e.$prevArrow.remove(),
        e.$nextArrow &&
          e.htmlExpr.test(e.options.nextArrow) &&
          e.$nextArrow.remove(),
        e.$slides
          .removeClass('slick-slide slick-active slick-visible slick-current')
          .attr('aria-hidden', 'true')
          .css('width', '');
    }),
    (e.prototype.unslick = function (i) {
      var e = this;
      e.$slider.trigger('unslick', [e, i]), e.destroy();
    }),
    (e.prototype.updateArrows = function () {
      var i,
        e = this;
      (i = Math.floor(e.options.slidesToShow / 2)),
        e.options.arrows === !0 &&
          e.slideCount > e.options.slidesToShow &&
          !e.options.infinite &&
          (e.$prevArrow
            .removeClass('slick-disabled')
            .attr('aria-disabled', 'false'),
          e.$nextArrow
            .removeClass('slick-disabled')
            .attr('aria-disabled', 'false'),
          0 === e.currentSlide
            ? (e.$prevArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'),
              e.$nextArrow
                .removeClass('slick-disabled')
                .attr('aria-disabled', 'false'))
            : e.currentSlide >= e.slideCount - e.options.slidesToShow &&
              e.options.centerMode === !1
            ? (e.$nextArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'),
              e.$prevArrow
                .removeClass('slick-disabled')
                .attr('aria-disabled', 'false'))
            : e.currentSlide >= e.slideCount - 1 &&
              e.options.centerMode === !0 &&
              (e.$nextArrow
                .addClass('slick-disabled')
                .attr('aria-disabled', 'true'),
              e.$prevArrow
                .removeClass('slick-disabled')
                .attr('aria-disabled', 'false')));
    }),
    (e.prototype.updateDots = function () {
      var i = this;
      null !== i.$dots &&
        (i.$dots.find('li').removeClass('slick-active').end(),
        i.$dots
          .find('li')
          .eq(Math.floor(i.currentSlide / i.options.slidesToScroll))
          .addClass('slick-active'));
    }),
    (e.prototype.visibility = function () {
      var i = this;
      i.options.autoplay &&
        (document[i.hidden] ? (i.interrupted = !0) : (i.interrupted = !1));
    }),
    (i.fn.slick = function () {
      var i,
        t,
        o = this,
        s = arguments[0],
        n = Array.prototype.slice.call(arguments, 1),
        r = o.length;
      for (i = 0; i < r; i++)
        if (
          ('object' == typeof s || 'undefined' == typeof s
            ? (o[i].slick = new e(o[i], s))
            : (t = o[i].slick[s].apply(o[i].slick, n)),
          'undefined' != typeof t)
        )
          return t;
      return o;
    });
});
!(function (e) {
  var t = !0;
  (e.flexslider = function (a, n) {
    var i = e(a);
    'undefined' == typeof n.rtl &&
      'rtl' == e('html').attr('dir') &&
      (n.rtl = !0),
      (i.vars = e.extend({}, e.flexslider.defaults, n));
    var r,
      s = i.vars.namespace,
      o =
        window.navigator &&
        window.navigator.msPointerEnabled &&
        window.MSGesture,
      l =
        ('ontouchstart' in window ||
          o ||
          (window.DocumentTouch && document instanceof DocumentTouch)) &&
        i.vars.touch,
      d = 'click touchend MSPointerUp keyup',
      c = '',
      u = 'vertical' === i.vars.direction,
      v = i.vars.reverse,
      p = i.vars.itemWidth > 0,
      m = 'fade' === i.vars.animation,
      f = '' !== i.vars.asNavFor,
      g = {};
    e.data(a, 'flexslider', i),
      (g = {
        init: function () {
          (i.animating = !1),
            (i.currentSlide = parseInt(
              i.vars.startAt ? i.vars.startAt : 0,
              10
            )),
            isNaN(i.currentSlide) && (i.currentSlide = 0),
            (i.animatingTo = i.currentSlide),
            (i.atEnd = 0 === i.currentSlide || i.currentSlide === i.last),
            (i.containerSelector = i.vars.selector.substr(
              0,
              i.vars.selector.search(' ')
            )),
            (i.slides = e(i.vars.selector, i)),
            (i.container = e(i.containerSelector, i)),
            (i.count = i.slides.length),
            (i.syncExists = e(i.vars.sync).length > 0),
            'slide' === i.vars.animation && (i.vars.animation = 'swing'),
            (i.prop = u ? 'top' : i.vars.rtl ? 'marginRight' : 'marginLeft'),
            (i.args = {}),
            (i.manualPause = !1),
            (i.stopped = !1),
            (i.started = !1),
            (i.startTimeout = null),
            (i.transitions =
              !i.vars.video &&
              !m &&
              i.vars.useCSS &&
              (function () {
                var e = document.createElement('div'),
                  t = [
                    'perspectiveProperty',
                    'WebkitPerspective',
                    'MozPerspective',
                    'OPerspective',
                    'msPerspective',
                  ];
                for (var a in t)
                  if (void 0 !== e.style[t[a]])
                    return (
                      (i.pfx = t[a].replace('Perspective', '').toLowerCase()),
                      (i.prop = '-' + i.pfx + '-transform'),
                      !0
                    );
                return !1;
              })()),
            (i.isFirefox =
              navigator.userAgent.toLowerCase().indexOf('firefox') > -1),
            (i.ensureAnimationEnd = ''),
            '' !== i.vars.controlsContainer &&
              (i.controlsContainer =
                e(i.vars.controlsContainer).length > 0 &&
                e(i.vars.controlsContainer)),
            '' !== i.vars.manualControls &&
              (i.manualControls =
                e(i.vars.manualControls).length > 0 &&
                e(i.vars.manualControls)),
            '' !== i.vars.customDirectionNav &&
              (i.customDirectionNav =
                2 === e(i.vars.customDirectionNav).length &&
                e(i.vars.customDirectionNav)),
            i.vars.randomize &&
              (i.slides.sort(function () {
                return Math.round(Math.random()) - 0.5;
              }),
              i.container.empty().append(i.slides)),
            i.doMath(),
            i.setup('init'),
            i.vars.controlNav && g.controlNav.setup(),
            i.vars.directionNav && g.directionNav.setup(),
            i.vars.keyboard &&
              (1 === e(i.containerSelector).length ||
                i.vars.multipleKeyboard) &&
              e(document).bind('keyup', function (e) {
                var t = e.keyCode;
                if (!i.animating && (39 === t || 37 === t)) {
                  var a = i.vars.rtl
                    ? 37 === t
                      ? i.getTarget('next')
                      : 39 === t && i.getTarget('prev')
                    : 39 === t
                    ? i.getTarget('next')
                    : 37 === t && i.getTarget('prev');
                  i.flexAnimate(a, i.vars.pauseOnAction);
                }
              }),
            i.vars.mousewheel &&
              i.bind('mousewheel', function (e, t, a, n) {
                e.preventDefault();
                var r = t < 0 ? i.getTarget('next') : i.getTarget('prev');
                i.flexAnimate(r, i.vars.pauseOnAction);
              }),
            i.vars.pausePlay && g.pausePlay.setup(),
            i.vars.slideshow &&
              i.vars.pauseInvisible &&
              g.pauseInvisible.init(),
            i.vars.slideshow &&
              (i.vars.pauseOnHover &&
                i.hover(
                  function () {
                    i.manualPlay || i.manualPause || i.pause();
                  },
                  function () {
                    i.manualPause || i.manualPlay || i.stopped || i.play();
                  }
                ),
              (i.vars.pauseInvisible && g.pauseInvisible.isHidden()) ||
                (i.vars.initDelay > 0
                  ? (i.startTimeout = setTimeout(i.play, i.vars.initDelay))
                  : i.play())),
            f && g.asNav.setup(),
            l && i.vars.touch && g.touch(),
            (!m || (m && i.vars.smoothHeight)) &&
              e(window).bind('resize orientationchange focus', g.resize),
            i.find('img').attr('draggable', 'false'),
            setTimeout(function () {
              i.vars.start(i);
            }, 200);
        },
        asNav: {
          setup: function () {
            (i.asNav = !0),
              (i.animatingTo = Math.floor(i.currentSlide / i.move)),
              (i.currentItem = i.currentSlide),
              i.slides
                .removeClass(s + 'active-slide')
                .eq(i.currentItem)
                .addClass(s + 'active-slide'),
              o
                ? ((a._slider = i),
                  i.slides.each(function () {
                    var t = this;
                    (t._gesture = new MSGesture()),
                      (t._gesture.target = t),
                      t.addEventListener(
                        'MSPointerDown',
                        function (e) {
                          e.preventDefault(),
                            e.currentTarget._gesture &&
                              e.currentTarget._gesture.addPointer(e.pointerId);
                        },
                        !1
                      ),
                      t.addEventListener('MSGestureTap', function (t) {
                        t.preventDefault();
                        var a = e(this),
                          n = a.index();
                        e(i.vars.asNavFor).data('flexslider').animating ||
                          a.hasClass('active') ||
                          ((i.direction = i.currentItem < n ? 'next' : 'prev'),
                          i.flexAnimate(n, i.vars.pauseOnAction, !1, !0, !0));
                      });
                  }))
                : i.slides.on(d, function (t) {
                    t.preventDefault();
                    var a,
                      n = e(this),
                      r = n.index();
                    (a = i.vars.rtl
                      ? -1 * (n.offset().right - e(i).scrollLeft())
                      : n.offset().left - e(i).scrollLeft()),
                      a <= 0 && n.hasClass(s + 'active-slide')
                        ? i.flexAnimate(i.getTarget('prev'), !0)
                        : e(i.vars.asNavFor).data('flexslider').animating ||
                          n.hasClass(s + 'active-slide') ||
                          ((i.direction = i.currentItem < r ? 'next' : 'prev'),
                          i.flexAnimate(r, i.vars.pauseOnAction, !1, !0, !0));
                  });
          },
        },
        controlNav: {
          setup: function () {
            i.manualControls
              ? g.controlNav.setupManual()
              : g.controlNav.setupPaging();
          },
          setupPaging: function () {
            var t,
              a,
              n =
                'thumbnails' === i.vars.controlNav
                  ? 'control-thumbs'
                  : 'control-paging',
              r = 1;
            if (
              ((i.controlNavScaffold = e(
                '<ol class="' + s + 'control-nav ' + s + n + '"></ol>'
              )),
              i.pagingCount > 1)
            )
              for (var o = 0; o < i.pagingCount; o++) {
                if (
                  ((a = i.slides.eq(o)),
                  void 0 === a.attr('data-thumb-alt') &&
                    a.attr('data-thumb-alt', ''),
                  (t = e('<a></a>').attr('href', '#').text(r)),
                  'thumbnails' === i.vars.controlNav &&
                    (t = e('<img/>').attr('src', a.attr('data-thumb'))),
                  '' !== a.attr('data-thumb-alt') &&
                    t.attr('alt', a.attr('data-thumb-alt')),
                  'thumbnails' === i.vars.controlNav &&
                    !0 === i.vars.thumbCaptions)
                ) {
                  var l = a.attr('data-thumbcaption');
                  if ('' !== l && void 0 !== l) {
                    var u = e('<span></span>')
                      .addClass(s + 'caption')
                      .text(l);
                    t.append(u);
                  }
                }
                var v = e('<li>');
                t.appendTo(v),
                  v.append('</li>'),
                  i.controlNavScaffold.append(v),
                  r++;
              }
            i.controlsContainer
              ? e(i.controlsContainer).append(i.controlNavScaffold)
              : i.append(i.controlNavScaffold),
              g.controlNav.set(),
              g.controlNav.active(),
              i.controlNavScaffold.delegate('a, img', d, function (t) {
                if ((t.preventDefault(), '' === c || c === t.type)) {
                  var a = e(this),
                    n = i.controlNav.index(a);
                  a.hasClass(s + 'active') ||
                    ((i.direction = n > i.currentSlide ? 'next' : 'prev'),
                    i.flexAnimate(n, i.vars.pauseOnAction));
                }
                '' === c && (c = t.type), g.setToClearWatchedEvent();
              });
          },
          setupManual: function () {
            (i.controlNav = i.manualControls),
              g.controlNav.active(),
              i.controlNav.bind(d, function (t) {
                if ((t.preventDefault(), '' === c || c === t.type)) {
                  var a = e(this),
                    n = i.controlNav.index(a);
                  a.hasClass(s + 'active') ||
                    (n > i.currentSlide
                      ? (i.direction = 'next')
                      : (i.direction = 'prev'),
                    i.flexAnimate(n, i.vars.pauseOnAction));
                }
                '' === c && (c = t.type), g.setToClearWatchedEvent();
              });
          },
          set: function () {
            var t = 'thumbnails' === i.vars.controlNav ? 'img' : 'a';
            i.controlNav = e(
              '.' + s + 'control-nav li ' + t,
              i.controlsContainer ? i.controlsContainer : i
            );
          },
          active: function () {
            i.controlNav
              .removeClass(s + 'active')
              .eq(i.animatingTo)
              .addClass(s + 'active');
          },
          update: function (t, a) {
            i.pagingCount > 1 && 'add' === t
              ? i.controlNavScaffold.append(
                  e('<li><a href="#">' + i.count + '</a></li>')
                )
              : 1 === i.pagingCount
              ? i.controlNavScaffold.find('li').remove()
              : i.controlNav.eq(a).closest('li').remove(),
              g.controlNav.set(),
              i.pagingCount > 1 && i.pagingCount !== i.controlNav.length
                ? i.update(a, t)
                : g.controlNav.active();
          },
        },
        directionNav: {
          setup: function () {
            var t = e(
              '<ul class="' +
                s +
                'direction-nav"><li class="' +
                s +
                'nav-prev"><a class="' +
                s +
                'prev" href="#">' +
                i.vars.prevText +
                '</a></li><li class="' +
                s +
                'nav-next"><a class="' +
                s +
                'next" href="#">' +
                i.vars.nextText +
                '</a></li></ul>'
            );
            i.customDirectionNav
              ? (i.directionNav = i.customDirectionNav)
              : i.controlsContainer
              ? (e(i.controlsContainer).append(t),
                (i.directionNav = e(
                  '.' + s + 'direction-nav li a',
                  i.controlsContainer
                )))
              : (i.append(t),
                (i.directionNav = e('.' + s + 'direction-nav li a', i))),
              g.directionNav.update(),
              i.directionNav.bind(d, function (t) {
                t.preventDefault();
                var a;
                ('' !== c && c !== t.type) ||
                  ((a = e(this).hasClass(s + 'next')
                    ? i.getTarget('next')
                    : i.getTarget('prev')),
                  i.flexAnimate(a, i.vars.pauseOnAction)),
                  '' === c && (c = t.type),
                  g.setToClearWatchedEvent();
              });
          },
          update: function () {
            var e = s + 'disabled';
            1 === i.pagingCount
              ? i.directionNav.addClass(e).attr('tabindex', '-1')
              : i.vars.animationLoop
              ? i.directionNav.removeClass(e).removeAttr('tabindex')
              : 0 === i.animatingTo
              ? i.directionNav
                  .removeClass(e)
                  .filter('.' + s + 'prev')
                  .addClass(e)
                  .attr('tabindex', '-1')
              : i.animatingTo === i.last
              ? i.directionNav
                  .removeClass(e)
                  .filter('.' + s + 'next')
                  .addClass(e)
                  .attr('tabindex', '-1')
              : i.directionNav.removeClass(e).removeAttr('tabindex');
          },
        },
        pausePlay: {
          setup: function () {
            var t = e('<div class="' + s + 'pauseplay"><a href="#"></a></div>');
            i.controlsContainer
              ? (i.controlsContainer.append(t),
                (i.pausePlay = e('.' + s + 'pauseplay a', i.controlsContainer)))
              : (i.append(t), (i.pausePlay = e('.' + s + 'pauseplay a', i))),
              g.pausePlay.update(i.vars.slideshow ? s + 'pause' : s + 'play'),
              i.pausePlay.bind(d, function (t) {
                t.preventDefault(),
                  ('' !== c && c !== t.type) ||
                    (e(this).hasClass(s + 'pause')
                      ? ((i.manualPause = !0), (i.manualPlay = !1), i.pause())
                      : ((i.manualPause = !1), (i.manualPlay = !0), i.play())),
                  '' === c && (c = t.type),
                  g.setToClearWatchedEvent();
              });
          },
          update: function (e) {
            'play' === e
              ? i.pausePlay
                  .removeClass(s + 'pause')
                  .addClass(s + 'play')
                  .html(i.vars.playText)
              : i.pausePlay
                  .removeClass(s + 'play')
                  .addClass(s + 'pause')
                  .html(i.vars.pauseText);
          },
        },
        touch: function () {
          function e(e) {
            e.stopPropagation(),
              i.animating
                ? e.preventDefault()
                : (i.pause(),
                  a._gesture.addPointer(e.pointerId),
                  (w = 0),
                  (d = u ? i.h : i.w),
                  (f = Number(new Date())),
                  (l =
                    p && v && i.animatingTo === i.last
                      ? 0
                      : p && v
                      ? i.limit -
                        (i.itemW + i.vars.itemMargin) * i.move * i.animatingTo
                      : p && i.currentSlide === i.last
                      ? i.limit
                      : p
                      ? (i.itemW + i.vars.itemMargin) * i.move * i.currentSlide
                      : v
                      ? (i.last - i.currentSlide + i.cloneOffset) * d
                      : (i.currentSlide + i.cloneOffset) * d));
          }
          function t(e) {
            e.stopPropagation();
            var t = e.target._slider;
            if (t) {
              var n = -e.translationX,
                i = -e.translationY;
              return (
                (w += u ? i : n),
                (c = (t.vars.rtl ? -1 : 1) * w),
                (x = u
                  ? Math.abs(w) < Math.abs(-n)
                  : Math.abs(w) < Math.abs(-i)),
                e.detail === e.MSGESTURE_FLAG_INERTIA
                  ? void setImmediate(function () {
                      a._gesture.stop();
                    })
                  : void (
                      (!x || Number(new Date()) - f > 500) &&
                      (e.preventDefault(),
                      !m &&
                        t.transitions &&
                        (t.vars.animationLoop ||
                          (c =
                            w /
                            ((0 === t.currentSlide && w < 0) ||
                            (t.currentSlide === t.last && w > 0)
                              ? Math.abs(w) / d + 2
                              : 1)),
                        t.setProps(l + c, 'setTouch')))
                    )
              );
            }
          }
          function n(e) {
            e.stopPropagation();
            var t = e.target._slider;
            if (t) {
              if (t.animatingTo === t.currentSlide && !x && null !== c) {
                var a = v ? -c : c,
                  n = a > 0 ? t.getTarget('next') : t.getTarget('prev');
                t.canAdvance(n) &&
                ((Number(new Date()) - f < 550 && Math.abs(a) > 50) ||
                  Math.abs(a) > d / 2)
                  ? t.flexAnimate(n, t.vars.pauseOnAction)
                  : m ||
                    t.flexAnimate(t.currentSlide, t.vars.pauseOnAction, !0);
              }
              (r = null), (s = null), (c = null), (l = null), (w = 0);
            }
          }
          var r,
            s,
            l,
            d,
            c,
            f,
            g,
            h,
            S,
            x = !1,
            y = 0,
            b = 0,
            w = 0;
          o
            ? ((a.style.msTouchAction = 'none'),
              (a._gesture = new MSGesture()),
              (a._gesture.target = a),
              a.addEventListener('MSPointerDown', e, !1),
              (a._slider = i),
              a.addEventListener('MSGestureChange', t, !1),
              a.addEventListener('MSGestureEnd', n, !1))
            : ((g = function (e) {
                i.animating
                  ? e.preventDefault()
                  : (window.navigator.msPointerEnabled ||
                      1 === e.touches.length) &&
                    (i.pause(),
                    (d = u ? i.h : i.w),
                    (f = Number(new Date())),
                    (y = e.touches[0].pageX),
                    (b = e.touches[0].pageY),
                    (l =
                      p && v && i.animatingTo === i.last
                        ? 0
                        : p && v
                        ? i.limit -
                          (i.itemW + i.vars.itemMargin) * i.move * i.animatingTo
                        : p && i.currentSlide === i.last
                        ? i.limit
                        : p
                        ? (i.itemW + i.vars.itemMargin) *
                          i.move *
                          i.currentSlide
                        : v
                        ? (i.last - i.currentSlide + i.cloneOffset) * d
                        : (i.currentSlide + i.cloneOffset) * d),
                    (r = u ? b : y),
                    (s = u ? y : b),
                    a.addEventListener('touchmove', h, !1),
                    a.addEventListener('touchend', S, !1));
              }),
              (h = function (e) {
                (y = e.touches[0].pageX),
                  (b = e.touches[0].pageY),
                  (c = u ? r - b : (i.vars.rtl ? -1 : 1) * (r - y)),
                  (x = u
                    ? Math.abs(c) < Math.abs(y - s)
                    : Math.abs(c) < Math.abs(b - s));
                var t = 500;
                (!x || Number(new Date()) - f > t) &&
                  (e.preventDefault(),
                  !m &&
                    i.transitions &&
                    (i.vars.animationLoop ||
                      (c /=
                        (0 === i.currentSlide && c < 0) ||
                        (i.currentSlide === i.last && c > 0)
                          ? Math.abs(c) / d + 2
                          : 1),
                    i.setProps(l + c, 'setTouch')));
              }),
              (S = function (e) {
                if (
                  (a.removeEventListener('touchmove', h, !1),
                  i.animatingTo === i.currentSlide && !x && null !== c)
                ) {
                  var t = v ? -c : c,
                    n = t > 0 ? i.getTarget('next') : i.getTarget('prev');
                  i.canAdvance(n) &&
                  ((Number(new Date()) - f < 550 && Math.abs(t) > 50) ||
                    Math.abs(t) > d / 2)
                    ? i.flexAnimate(n, i.vars.pauseOnAction)
                    : m ||
                      i.flexAnimate(i.currentSlide, i.vars.pauseOnAction, !0);
                }
                a.removeEventListener('touchend', S, !1),
                  (r = null),
                  (s = null),
                  (c = null),
                  (l = null);
              }),
              a.addEventListener('touchstart', g, !1));
        },
        resize: function () {
          !i.animating &&
            i.is(':visible') &&
            (p || i.doMath(),
            m
              ? g.smoothHeight()
              : p
              ? (i.slides.width(i.computedW),
                i.update(i.pagingCount),
                i.setProps())
              : u
              ? (i.viewport.height(i.h), i.setProps(i.h, 'setTotal'))
              : (i.vars.smoothHeight && g.smoothHeight(),
                i.newSlides.width(i.computedW),
                i.setProps(i.computedW, 'setTotal')));
        },
        smoothHeight: function (e) {
          if (!u || m) {
            var t = m ? i : i.viewport;
            e
              ? t.animate(
                  { height: i.slides.eq(i.animatingTo).innerHeight() },
                  e
                )
              : t.innerHeight(i.slides.eq(i.animatingTo).innerHeight());
          }
        },
        sync: function (t) {
          var a = e(i.vars.sync).data('flexslider'),
            n = i.animatingTo;
          switch (t) {
            case 'animate':
              a.flexAnimate(n, i.vars.pauseOnAction, !1, !0);
              break;
            case 'play':
              a.playing || a.asNav || a.play();
              break;
            case 'pause':
              a.pause();
          }
        },
        uniqueID: function (t) {
          return (
            t
              .filter('[id]')
              .add(t.find('[id]'))
              .each(function () {
                var t = e(this);
                t.attr('id', t.attr('id') + '_clone');
              }),
            t
          );
        },
        pauseInvisible: {
          visProp: null,
          init: function () {
            var e = g.pauseInvisible.getHiddenProp();
            if (e) {
              var t = e.replace(/[H|h]idden/, '') + 'visibilitychange';
              document.addEventListener(t, function () {
                g.pauseInvisible.isHidden()
                  ? i.startTimeout
                    ? clearTimeout(i.startTimeout)
                    : i.pause()
                  : i.started
                  ? i.play()
                  : i.vars.initDelay > 0
                  ? setTimeout(i.play, i.vars.initDelay)
                  : i.play();
              });
            }
          },
          isHidden: function () {
            var e = g.pauseInvisible.getHiddenProp();
            return !!e && document[e];
          },
          getHiddenProp: function () {
            var e = ['webkit', 'moz', 'ms', 'o'];
            if ('hidden' in document) return 'hidden';
            for (var t = 0; t < e.length; t++)
              if (e[t] + 'Hidden' in document) return e[t] + 'Hidden';
            return null;
          },
        },
        setToClearWatchedEvent: function () {
          clearTimeout(r),
            (r = setTimeout(function () {
              c = '';
            }, 3e3));
        },
      }),
      (i.flexAnimate = function (t, a, n, r, o) {
        if (
          (i.vars.animationLoop ||
            t === i.currentSlide ||
            (i.direction = t > i.currentSlide ? 'next' : 'prev'),
          f &&
            1 === i.pagingCount &&
            (i.direction = i.currentItem < t ? 'next' : 'prev'),
          !i.animating && (i.canAdvance(t, o) || n) && i.is(':visible'))
        ) {
          if (f && r) {
            var d = e(i.vars.asNavFor).data('flexslider');
            if (
              ((i.atEnd = 0 === t || t === i.count - 1),
              d.flexAnimate(t, !0, !1, !0, o),
              (i.direction = i.currentItem < t ? 'next' : 'prev'),
              (d.direction = i.direction),
              Math.ceil((t + 1) / i.visible) - 1 === i.currentSlide || 0 === t)
            )
              return (
                (i.currentItem = t),
                i.slides
                  .removeClass(s + 'active-slide')
                  .eq(t)
                  .addClass(s + 'active-slide'),
                !1
              );
            (i.currentItem = t),
              i.slides
                .removeClass(s + 'active-slide')
                .eq(t)
                .addClass(s + 'active-slide'),
              (t = Math.floor(t / i.visible));
          }
          if (
            ((i.animating = !0),
            (i.animatingTo = t),
            a && i.pause(),
            i.vars.before(i),
            i.syncExists && !o && g.sync('animate'),
            i.vars.controlNav && g.controlNav.active(),
            p ||
              i.slides
                .removeClass(s + 'active-slide')
                .eq(t)
                .addClass(s + 'active-slide'),
            (i.atEnd = 0 === t || t === i.last),
            i.vars.directionNav && g.directionNav.update(),
            t === i.last && (i.vars.end(i), i.vars.animationLoop || i.pause()),
            m)
          )
            l
              ? (i.slides.eq(i.currentSlide).css({ opacity: 0, zIndex: 1 }),
                i.slides.eq(t).css({ opacity: 1, zIndex: 2 }),
                i.wrapup(x))
              : (i.slides
                  .eq(i.currentSlide)
                  .css({ zIndex: 1 })
                  .animate(
                    { opacity: 0 },
                    i.vars.animationSpeed,
                    i.vars.easing
                  ),
                i.slides
                  .eq(t)
                  .css({ zIndex: 2 })
                  .animate(
                    { opacity: 1 },
                    i.vars.animationSpeed,
                    i.vars.easing,
                    i.wrapup
                  ));
          else {
            var c,
              h,
              S,
              x = u ? i.slides.filter(':first').height() : i.computedW;
            p
              ? ((c = i.vars.itemMargin),
                (S = (i.itemW + c) * i.move * i.animatingTo),
                (h = S > i.limit && 1 !== i.visible ? i.limit : S))
              : (h =
                  0 === i.currentSlide &&
                  t === i.count - 1 &&
                  i.vars.animationLoop &&
                  'next' !== i.direction
                    ? v
                      ? (i.count + i.cloneOffset) * x
                      : 0
                    : i.currentSlide === i.last &&
                      0 === t &&
                      i.vars.animationLoop &&
                      'prev' !== i.direction
                    ? v
                      ? 0
                      : (i.count + 1) * x
                    : v
                    ? (i.count - 1 - t + i.cloneOffset) * x
                    : (t + i.cloneOffset) * x),
              i.setProps(h, '', i.vars.animationSpeed),
              i.transitions
                ? ((i.vars.animationLoop && i.atEnd) ||
                    ((i.animating = !1), (i.currentSlide = i.animatingTo)),
                  i.container.unbind('webkitTransitionEnd transitionend'),
                  i.container.bind(
                    'webkitTransitionEnd transitionend',
                    function () {
                      clearTimeout(i.ensureAnimationEnd), i.wrapup(x);
                    }
                  ),
                  clearTimeout(i.ensureAnimationEnd),
                  (i.ensureAnimationEnd = setTimeout(function () {
                    i.wrapup(x);
                  }, i.vars.animationSpeed + 100)))
                : i.container.animate(
                    i.args,
                    i.vars.animationSpeed,
                    i.vars.easing,
                    function () {
                      i.wrapup(x);
                    }
                  );
          }
          i.vars.smoothHeight && g.smoothHeight(i.vars.animationSpeed);
        }
      }),
      (i.wrapup = function (e) {
        m ||
          p ||
          (0 === i.currentSlide &&
          i.animatingTo === i.last &&
          i.vars.animationLoop
            ? i.setProps(e, 'jumpEnd')
            : i.currentSlide === i.last &&
              0 === i.animatingTo &&
              i.vars.animationLoop &&
              i.setProps(e, 'jumpStart')),
          (i.animating = !1),
          (i.currentSlide = i.animatingTo),
          i.vars.after(i);
      }),
      (i.animateSlides = function () {
        !i.animating && t && i.flexAnimate(i.getTarget('next'));
      }),
      (i.pause = function () {
        clearInterval(i.animatedSlides),
          (i.animatedSlides = null),
          (i.playing = !1),
          i.vars.pausePlay && g.pausePlay.update('play'),
          i.syncExists && g.sync('pause');
      }),
      (i.play = function () {
        i.playing && clearInterval(i.animatedSlides),
          (i.animatedSlides =
            i.animatedSlides ||
            setInterval(i.animateSlides, i.vars.slideshowSpeed)),
          (i.started = i.playing = !0),
          i.vars.pausePlay && g.pausePlay.update('pause'),
          i.syncExists && g.sync('play');
      }),
      (i.stop = function () {
        i.pause(), (i.stopped = !0);
      }),
      (i.canAdvance = function (e, t) {
        var a = f ? i.pagingCount - 1 : i.last;
        return (
          !!t ||
          !(
            !f ||
            i.currentItem !== i.count - 1 ||
            0 !== e ||
            'prev' !== i.direction
          ) ||
          ((!f ||
            0 !== i.currentItem ||
            e !== i.pagingCount - 1 ||
            'next' === i.direction) &&
            !(e === i.currentSlide && !f) &&
            (!!i.vars.animationLoop ||
              ((!i.atEnd ||
                0 !== i.currentSlide ||
                e !== a ||
                'next' === i.direction) &&
                (!i.atEnd ||
                  i.currentSlide !== a ||
                  0 !== e ||
                  'next' !== i.direction))))
        );
      }),
      (i.getTarget = function (e) {
        return (
          (i.direction = e),
          'next' === e
            ? i.currentSlide === i.last
              ? 0
              : i.currentSlide + 1
            : 0 === i.currentSlide
            ? i.last
            : i.currentSlide - 1
        );
      }),
      (i.setProps = function (e, t, a) {
        var n = (function () {
          var a = e
              ? e
              : (i.itemW + i.vars.itemMargin) * i.move * i.animatingTo,
            n = (function () {
              if (p)
                return 'setTouch' === t
                  ? e
                  : v && i.animatingTo === i.last
                  ? 0
                  : v
                  ? i.limit -
                    (i.itemW + i.vars.itemMargin) * i.move * i.animatingTo
                  : i.animatingTo === i.last
                  ? i.limit
                  : a;
              switch (t) {
                case 'setTotal':
                  return v
                    ? (i.count - 1 - i.currentSlide + i.cloneOffset) * e
                    : (i.currentSlide + i.cloneOffset) * e;
                case 'setTouch':
                  return v ? e : e;
                case 'jumpEnd':
                  return v ? e : i.count * e;
                case 'jumpStart':
                  return v ? i.count * e : e;
                default:
                  return e;
              }
            })();
          return n * (i.vars.rtl ? 1 : -1) + 'px';
        })();
        i.transitions &&
          ((n = i.isFirefox
            ? u
              ? 'translate3d(0,' + n + ',0)'
              : 'translate3d(' + (parseInt(n) + 'px') + ',0,0)'
            : u
            ? 'translate3d(0,' + n + ',0)'
            : 'translate3d(' +
              ((i.vars.rtl ? -1 : 1) * parseInt(n) + 'px') +
              ',0,0)'),
          (a = void 0 !== a ? a / 1e3 + 's' : '0s'),
          i.container.css('-' + i.pfx + '-transition-duration', a),
          i.container.css('transition-duration', a)),
          (i.args[i.prop] = n),
          (i.transitions || void 0 === a) && i.container.css(i.args),
          i.container.css('transform', n);
      }),
      (i.setup = function (t) {
        if (m)
          i.vars.rtl
            ? i.slides.css({
                width: '100%',
                float: 'right',
                marginLeft: '-100%',
                position: 'relative',
              })
            : i.slides.css({
                width: '100%',
                float: 'left',
                marginRight: '-100%',
                position: 'relative',
              }),
            'init' === t &&
              (l
                ? i.slides
                    .css({
                      opacity: 0,
                      display: 'block',
                      webkitTransition:
                        'opacity ' + i.vars.animationSpeed / 1e3 + 's ease',
                      zIndex: 1,
                    })
                    .eq(i.currentSlide)
                    .css({ opacity: 1, zIndex: 2 })
                : 0 == i.vars.fadeFirstSlide
                ? i.slides
                    .css({ opacity: 0, display: 'block', zIndex: 1 })
                    .eq(i.currentSlide)
                    .css({ zIndex: 2 })
                    .css({ opacity: 1 })
                : i.slides
                    .css({ opacity: 0, display: 'block', zIndex: 1 })
                    .eq(i.currentSlide)
                    .css({ zIndex: 2 })
                    .animate(
                      { opacity: 1 },
                      i.vars.animationSpeed,
                      i.vars.easing
                    )),
            i.vars.smoothHeight && g.smoothHeight();
        else {
          var a, n;
          'init' === t &&
            ((i.viewport = e('<div class="' + s + 'viewport"></div>')
              .css({ overflow: 'hidden', position: 'relative' })
              .appendTo(i)
              .append(i.container)),
            (i.cloneCount = 0),
            (i.cloneOffset = 0),
            v &&
              ((n = e.makeArray(i.slides).reverse()),
              (i.slides = e(n)),
              i.container.empty().append(i.slides))),
            i.vars.animationLoop &&
              !p &&
              ((i.cloneCount = 2),
              (i.cloneOffset = 1),
              'init' !== t && i.container.find('.clone').remove(),
              i.container
                .append(
                  g
                    .uniqueID(i.slides.first().clone().addClass('clone'))
                    .attr('aria-hidden', 'true')
                )
                .prepend(
                  g
                    .uniqueID(i.slides.last().clone().addClass('clone'))
                    .attr('aria-hidden', 'true')
                )),
            (i.newSlides = e(i.vars.selector, i)),
            (a = v
              ? i.count - 1 - i.currentSlide + i.cloneOffset
              : i.currentSlide + i.cloneOffset),
            u && !p
              ? (i.container
                  .height(200 * (i.count + i.cloneCount) + '%')
                  .css('position', 'absolute')
                  .width('100%'),
                setTimeout(
                  function () {
                    i.newSlides.css({ display: 'block' }),
                      i.doMath(),
                      i.viewport.height(i.h),
                      i.setProps(a * i.h, 'init');
                  },
                  'init' === t ? 100 : 0
                ))
              : (i.container.width(200 * (i.count + i.cloneCount) + '%'),
                i.setProps(a * i.computedW, 'init'),
                setTimeout(
                  function () {
                    i.doMath(),
                      i.vars.rtl && i.isFirefox
                        ? i.newSlides.css({
                            width: i.computedW,
                            marginRight: i.computedM,
                            float: 'right',
                            display: 'block',
                          })
                        : i.newSlides.css({
                            width: i.computedW,
                            marginRight: i.computedM,
                            float: 'left',
                            display: 'block',
                          }),
                      i.vars.smoothHeight && g.smoothHeight();
                  },
                  'init' === t ? 100 : 0
                ));
        }
        p ||
          i.slides
            .removeClass(s + 'active-slide')
            .eq(i.currentSlide)
            .addClass(s + 'active-slide'),
          i.vars.init(i);
      }),
      (i.doMath = function () {
        var e = i.slides.first(),
          t = i.vars.itemMargin,
          a = i.vars.minItems,
          n = i.vars.maxItems;
        (i.w = void 0 === i.viewport ? i.width() : i.viewport.width()),
          i.isFirefox && (i.w = i.width()),
          (i.h = e.height()),
          (i.boxPadding = e.outerWidth() - e.width()),
          p
            ? ((i.itemT = i.vars.itemWidth + t),
              (i.itemM = t),
              (i.minW = a ? a * i.itemT : i.w),
              (i.maxW = n ? n * i.itemT - t : i.w),
              (i.itemW =
                i.minW > i.w
                  ? (i.w - t * (a - 1)) / a
                  : i.maxW < i.w
                  ? (i.w - t * (n - 1)) / n
                  : i.vars.itemWidth > i.w
                  ? i.w
                  : i.vars.itemWidth),
              (i.visible = Math.floor(i.w / i.itemW)),
              (i.move =
                i.vars.move > 0 && i.vars.move < i.visible
                  ? i.vars.move
                  : i.visible),
              (i.pagingCount = Math.ceil((i.count - i.visible) / i.move + 1)),
              (i.last = i.pagingCount - 1),
              (i.limit =
                1 === i.pagingCount
                  ? 0
                  : i.vars.itemWidth > i.w
                  ? i.itemW * (i.count - 1) + t * (i.count - 1)
                  : (i.itemW + t) * i.count - i.w - t))
            : ((i.itemW = i.w),
              (i.itemM = t),
              (i.pagingCount = i.count),
              (i.last = i.count - 1)),
          (i.computedW = i.itemW - i.boxPadding),
          (i.computedM = i.itemM);
      }),
      (i.update = function (e, t) {
        i.doMath(),
          p ||
            (e < i.currentSlide
              ? (i.currentSlide += 1)
              : e <= i.currentSlide && 0 !== e && (i.currentSlide -= 1),
            (i.animatingTo = i.currentSlide)),
          i.vars.controlNav &&
            !i.manualControls &&
            (('add' === t && !p) || i.pagingCount > i.controlNav.length
              ? g.controlNav.update('add')
              : (('remove' === t && !p) ||
                  i.pagingCount < i.controlNav.length) &&
                (p &&
                  i.currentSlide > i.last &&
                  ((i.currentSlide -= 1), (i.animatingTo -= 1)),
                g.controlNav.update('remove', i.last))),
          i.vars.directionNav && g.directionNav.update();
      }),
      (i.addSlide = function (t, a) {
        var n = e(t);
        (i.count += 1),
          (i.last = i.count - 1),
          u && v
            ? void 0 !== a
              ? i.slides.eq(i.count - a).after(n)
              : i.container.prepend(n)
            : void 0 !== a
            ? i.slides.eq(a).before(n)
            : i.container.append(n),
          i.update(a, 'add'),
          (i.slides = e(i.vars.selector + ':not(.clone)', i)),
          i.setup(),
          i.vars.added(i);
      }),
      (i.removeSlide = function (t) {
        var a = isNaN(t) ? i.slides.index(e(t)) : t;
        (i.count -= 1),
          (i.last = i.count - 1),
          isNaN(t)
            ? e(t, i.slides).remove()
            : u && v
            ? i.slides.eq(i.last).remove()
            : i.slides.eq(t).remove(),
          i.doMath(),
          i.update(a, 'remove'),
          (i.slides = e(i.vars.selector + ':not(.clone)', i)),
          i.setup(),
          i.vars.removed(i);
      }),
      g.init();
  }),
    e(window)
      .blur(function (e) {
        t = !1;
      })
      .focus(function (e) {
        t = !0;
      }),
    (e.flexslider.defaults = {
      namespace: 'flex-',
      selector: '.slides > li',
      animation: 'fade',
      easing: 'swing',
      direction: 'horizontal',
      reverse: !1,
      animationLoop: !0,
      smoothHeight: !1,
      startAt: 0,
      slideshow: !0,
      slideshowSpeed: 7e3,
      animationSpeed: 600,
      initDelay: 0,
      randomize: !1,
      fadeFirstSlide: !0,
      thumbCaptions: !1,
      pauseOnAction: !0,
      pauseOnHover: !1,
      pauseInvisible: !0,
      useCSS: !0,
      touch: !0,
      video: !1,
      controlNav: !0,
      directionNav: !0,
      prevText: 'Previous',
      nextText: 'Next',
      keyboard: !0,
      multipleKeyboard: !1,
      mousewheel: !1,
      pausePlay: !1,
      pauseText: 'Pause',
      playText: 'Play',
      controlsContainer: '',
      manualControls: '',
      customDirectionNav: '',
      sync: '',
      asNavFor: '',
      itemWidth: 0,
      itemMargin: 0,
      minItems: 1,
      maxItems: 0,
      move: 0,
      allowOneSlide: !0,
      isFirefox: !1,
      start: function () {},
      before: function () {},
      after: function () {},
      end: function () {},
      added: function () {},
      removed: function () {},
      init: function () {},
      rtl: !1,
    }),
    (e.fn.flexslider = function (t) {
      if ((void 0 === t && (t = {}), 'object' == typeof t))
        return this.each(function () {
          var a = e(this),
            n = t.selector ? t.selector : '.slides > li',
            i = a.find(n);
          (1 === i.length && t.allowOneSlide === !1) || 0 === i.length
            ? (i.fadeIn(400), t.start && t.start(a))
            : void 0 === a.data('flexslider') && new e.flexslider(this, t);
        });
      var a = e(this).data('flexslider');
      switch (t) {
        case 'play':
          a.play();
          break;
        case 'pause':
          a.pause();
          break;
        case 'stop':
          a.stop();
          break;
        case 'next':
          a.flexAnimate(a.getTarget('next'), !0);
          break;
        case 'prev':
        case 'previous':
          a.flexAnimate(a.getTarget('prev'), !0);
          break;
        default:
          'number' == typeof t && a.flexAnimate(t, !0);
      }
    });
})(jQuery);
!(function (e) {
  var n;
  if (
    ('function' == typeof define && define.amd && (define(e), (n = !0)),
    'object' == typeof exports && ((module.exports = e()), (n = !0)),
    !n)
  ) {
    var t = window.Cookies,
      o = (window.Cookies = e());
    o.noConflict = function () {
      return (window.Cookies = t), o;
    };
  }
})(function () {
  function e() {
    for (var e = 0, n = {}; e < arguments.length; e++) {
      var t = arguments[e];
      for (var o in t) n[o] = t[o];
    }
    return n;
  }
  function n(e) {
    return e.replace(/(%[0-9A-Z]{2})+/g, decodeURIComponent);
  }
  function t(o) {
    function r() {}
    function i(n, t, i) {
      if ('undefined' != typeof document) {
        (i = e({ path: '/' }, r.defaults, i)),
          'number' == typeof i.expires &&
            (i.expires = new Date(1 * new Date() + 864e5 * i.expires)),
          (i.expires = i.expires ? i.expires.toUTCString() : '');
        try {
          var c = JSON.stringify(t);
          /^[\{\[]/.test(c) && (t = c);
        } catch (f) {}
        (t = o.write
          ? o.write(t, n)
          : encodeURIComponent(String(t)).replace(
              /%(23|24|26|2B|3A|3C|3E|3D|2F|3F|40|5B|5D|5E|60|7B|7D|7C)/g,
              decodeURIComponent
            )),
          (n = encodeURIComponent(String(n))
            .replace(/%(23|24|26|2B|5E|60|7C)/g, decodeURIComponent)
            .replace(/[\(\)]/g, escape));
        var u = '';
        for (var a in i)
          i[a] &&
            ((u += '; ' + a), i[a] !== !0 && (u += '=' + i[a].split(';')[0]));
        return (document.cookie = n + '=' + t + u);
      }
    }
    function c(e, t) {
      if ('undefined' != typeof document) {
        for (
          var r = {},
            i = document.cookie ? document.cookie.split('; ') : [],
            c = 0;
          c < i.length;
          c++
        ) {
          var f = i[c].split('='),
            u = f.slice(1).join('=');
          t || '"' !== u.charAt(0) || (u = u.slice(1, -1));
          try {
            var a = n(f[0]);
            if (((u = (o.read || o)(u, a) || n(u)), t))
              try {
                u = JSON.parse(u);
              } catch (p) {}
            if (((r[a] = u), e === a)) break;
          } catch (p) {}
        }
        return e ? r[e] : r;
      }
    }
    return (
      (r.set = i),
      (r.get = function (e) {
        return c(e, !1);
      }),
      (r.getJSON = function (e) {
        return c(e, !0);
      }),
      (r.remove = function (n, t) {
        i(n, '', e(t, { expires: -1 }));
      }),
      (r.defaults = {}),
      (r.withConverter = t),
      r
    );
  }
  return t(function () {});
});
!(function (e) {
  function a() {
    (wh = e(window).height()),
      e('section.contactUs').css({ height: wh }),
      (bth = e('section.homeHero.room .textBlock .container .inner').height()),
      bth && (bth += 140);
    var a = e('.footerExtension').outerHeight(!0);
    e('footer').css('margin-bottom', a), e(this).css('min-height', a);
  }
  function t() {
    e('.rooms .column').matchHeight(),
      e('.block .column .text').matchHeight(),
      e('.blocksWrapper .contentBlock .textBlock').matchHeight(),
      e('div.room .textBlock').matchHeight(),
      e('div.item .textBlock').matchHeight(),
      e('.charity-item .text-wrapper').matchHeight(),
      e('.packages-block .item-title').matchHeight(),
      e('.packages-block .item-intro').matchHeight(),
      e(
        'body.template-packages section.packages-block.tiles .item-title'
      ).matchHeight(),
      e(
        'body.template-packages section.packages-block.tiles .item-intro'
      ).matchHeight(),
      e(
        'body.template-packages section.packages-block.tiles .price'
      ).matchHeight();
  }
  function i() {
    e('select').select2();
  }
  function n() {
    var a = e('.announcementBar').outerHeight(),
      t = e('header').outerHeight();
    e('.announcementBar').hasClass('active')
      ? (e('main').addClass('announce'),
        e('header').addClass('announce'),
        e('.phoneHeader').addClass('announce'),
        e('.bookBlock').addClass('announce'),
        e(this).css('height', a),
        e('.phoneHeader').css('top', a + t),
        e('.bookBlock').css('top', a + t))
      : (e(this).css('height', '0'),
        e('main').css('margin-top', '0'),
        e('header').css('top', '0'),
        e('.phoneHeader').css('top', t),
        e('.bookBlock').css('top', t));
  }
  function o() {
    Cookies.remove('popup'),
      'displayed' == Cookies.get('popup') ||
        setTimeout(function () {
          jQuery('.popupLink').trigger('click'),
            Cookies.set('popup', 'displayed', { expires: 1 });
        }, 5e3);
  }
  e(document).ready(function () {
    e('[data-toggle]').on('click', function (a) {
      a.preventDefault(),
        e(this).toggleClass('active'),
        e('#' + e(this).data('toggle')).toggleClass('active'),
        e('#' + e(this).data('toggle'))
          .find('input')
          .first()
          .focus(),
        e('body').toggleClass(e(this).data('toggle') + '-active');
    }),
      e('[data-close]').on('click', function (a) {
        a.preventDefault(),
          e(this).removeClass('active'),
          e('#' + e(this).data('toggle')).removeClass('active'),
          e('body').removeClass(e(this).data('toggle') + '-active');
      }),
      e('[data-scroll-element]').on('click', function (a) {
        a.preventDefault(),
          e('html, body').animate(
            {
              scrollTop:
                e('#' + e(this).data('scroll-element')).offset().top -
                e(this).data('scroll-offset'),
            },
            500
          );
      }),
      e('[data-scroll-to]').on('click', function (a) {
        a.preventDefault(),
          e('html, body').animate(
            {
              scrollTop:
                e(e(this).attr('href')).offset().top -
                e(this).data('scroll-offset'),
            },
            500
          );
      }),
      e('[data-top]').on('click', function (a) {
        a.preventDefault(), e('html, body').animate({ scrollTop: 0 }, 500);
      }),
      e('body').on(
        'click',
        'a[href*="' + window.location.hostname + '"], a[href^="/"]',
        function (a) {
          '' == e(this).attr('data-filter') &&
            '' == e(this).attr('data-load-more') &&
            (e('main, section.subscribe, footer').fadeOut(300),
            e('body').addClass('loading'));
        }
      ),
      e('[data-accordian]').on('click', 'div.title', function (a) {
        a.preventDefault();
        var t = e(this).parent().parent();
        e(this).parent().hasClass('active')
          ? e(this).parent().removeClass('active')
          : (t.data('collapse') && t.find('li').removeClass('active'),
            e(this).parent().toggleClass('active'));
      }),
      e('iframe[src*=youtube]').each(function () {
        e(this).attr(
          'src',
          e(this).attr('src') +
            '?modestbranding=1&autohide=1&showinfo=0&controls=0'
        ),
          e(this).attr('scrolling', 'no');
      }),
      e('iframe[src*=vimeo]').each(function () {
        e(this).attr('src', e(this).attr('src') + '?badge=0&byline=0&title=0'),
          e(this).attr('scrolling', 'no');
      });
    var o = e('[data-carousel]');
    o.owlCarousel({
      itemsScaleUp: !0,
      navText: ['', ''],
      smartSpeed: 500,
      animateOut: o.data('transitionStyle')
        ? o.data('transitionStyle')
        : 'fadeOut',
      items: 1,
      loop: !o.data('loop') || o.data('loop'),
      margin: o.data('margin') ? o.data('margin') : 0,
      nav: !!o.data('show-direction') && o.data('show-direction'),
      pagination: !0,
      autoplay: !!o.data('autoplay') && o.data('autoplay'),
      autoplaySpeed: o.data('speed') ? o.data('speed') : 1e3,
      mouseDrag: !!o.data('mousedrag') && o.data('mousedrag'),
      touchDrag: !o.data('touchdrag') || o.data('touchdrag'),
      pullDrag: !!o.data('pulldrag') && o.data('pulldrag'),
      slideBy: o.data('items') ? o.data('items') : 3,
      dots: !o.data('show-dots') || o.data('show-dots'),
      navSpeed: o.data('speed') ? o.data('speed') : 1e3,
      autoplayTimeout: o.data('timeout') ? o.data('timeout') : 1e4,
      autoplayHoverPause: !o.data('pausehover') || o.data('pausehover'),
      singleItem: !!o.data('single') && o.data('single'),
      autoHeight: !!o.data('autoheight') && o.data('autoheight'),
      autoWidth: !!o.data('autowidth') && o.data('autowidth'),
      startPosition: o.data('position') ? o.data('position') : 0,
      center: !!o.data('center') && o.data('center'),
      responsive: {
        0: { items: o.data('items-mobile') ? o.data('items-mobile') : 1 },
        768: { items: o.data('items-tablet') ? o.data('items-tablet') : 1 },
        1024: { items: o.data('items') ? o.data('items') : 1 },
      },
      onInitialized: function () {
        setTimeout(function () {
          o.addClass('active');
        }, 600),
          e('[data-carousel-next]').on('click', function (e) {
            o.trigger('next.owl.carousel', [
              o.data('speed') ? o.data('speed') : 1e3,
            ]);
          }),
          e('[data-carousel-prev]').on('click', function (e) {
            o.trigger('prev.owl.carousel', [
              o.data('speed') ? o.data('speed') : 1e3,
            ]);
          }),
          o.on('changed.owl.carousel', function (a) {
            var t = a.page.index + 1;
            0 == t && (t = 1),
              t < 10
                ? e('[data-slide]').text('0' + t)
                : e('[data-slide]').text(t);
          });
      },
    });
    var s = !1;
    if (
      (o.on('change.owl.carousel', function (a) {
        e('.owl-item.active').removeClass('show'),
          e('[data-slider-placeholder]').fadeOut(600),
          s ||
            setTimeout(function () {
              (s = !0),
                e('.owl-item.active').first().addClass('show'),
                e('[data-slider-placeholder]')
                  .html(
                    e('.owl-item.active')
                      .first()
                      .find('[data-slider-content]')
                      .clone()
                  )
                  .fadeIn(600);
            }, 600),
          setTimeout(function () {
            s = !1;
          }, 600);
      }),
      e(window).resize(function () {
        o.trigger('change.owl.carousel');
      }),
      ($pagination = !1),
      e('[data-pagination]').on('click', 'a', function (a) {
        a.preventDefault(),
          $pagination ||
            (($pagination = !0),
            e(this)
              .addClass('active')
              .text(e('[data-pagination]').data('loading')),
            e.ajax({
              type: 'GET',
              url:
                e(this).attr('href') +
                '#' +
                e('[data-pagination]').data('pagination'),
              dataType: 'html',
              success: function (a) {
                (result = e(a).find('[data-posts] [data-item]')),
                  (nextlink = e(a).find('[data-pagination] a').attr('href')),
                  e('[data-posts]').append(result).isotope('appended', result),
                  e('[data-pagination] a')
                    .removeClass('active')
                    .text(e('[data-pagination]').data('text')),
                  void 0 != nextlink
                    ? e('[data-pagination] a').attr('href', nextlink)
                    : e('[data-pagination]').remove(),
                  ($pagination = !1);
              },
            }));
      }),
      e('[data-pagination] a').length || e('[data-pagination]').remove(),
      (function (e) {
        function a(a) {
          var n = a.find('.marker'),
            o = {
              zoom: 11,
              center: new google.maps.LatLng(0, 0),
              mapTypeId: google.maps.MapTypeId.ROADMAP,
              disableDefaultUI: !0,
            },
            s = new google.maps.Map(a[0], o);
          return (
            (s.markers = []),
            n.each(function () {
              t(e(this), s);
            }),
            i(s),
            s
          );
        }
        function t(e, a) {
          var t = new google.maps.LatLng(
              e.attr('data-lat'),
              e.attr('data-lng')
            ),
            i = new google.maps.Marker({
              position: t,
              map: a,
              icon: e.attr('data-pin'),
            });
          if ((a.markers.push(i), e.html())) {
            var n = new google.maps.InfoWindow({ content: e.html() });
            google.maps.event.addListener(i, 'click', function () {
              n.open(a, i);
            });
          }
        }
        function i(a) {
          var t = [{}];
          a.setOptions({ styles: t });
          var i = new google.maps.LatLngBounds();
          e.each(a.markers, function (e, a) {
            var t = new google.maps.LatLng(a.position.lat(), a.position.lng());
            i.extend(t);
          }),
            1 == a.markers.length
              ? (a.setCenter(i.getCenter()), a.setZoom(2))
              : (a.setCenter(a.markers[0].getPosition()), a.setZoom(2));
        }
        !(function (e) {
          function a(a) {
            var n = a.find('.marker'),
              o = {
                zoom: 16,
                center: new google.maps.LatLng(0, 0),
                mapTypeId: google.maps.MapTypeId.ROADMAP,
              },
              s = new google.maps.Map(a[0], o);
            return (
              (s.markers = []),
              n.each(function () {
                t(e(this), s);
              }),
              i(s),
              s
            );
          }
          function t(e, a) {
            var t = new google.maps.LatLng(
                e.attr('data-lat'),
                e.attr('data-lng')
              ),
              i = new google.maps.Marker({ position: t, map: a });
            if ((a.markers.push(i), e.html())) {
              var n = new google.maps.InfoWindow({ content: e.html() });
              google.maps.event.addListener(i, 'click', function () {
                n.open(a, i);
              });
            }
          }
          function i(a) {
            var t = new google.maps.LatLngBounds();
            e.each(a.markers, function (e, a) {
              var i = new google.maps.LatLng(
                a.position.lat(),
                a.position.lng()
              );
              t.extend(i);
            }),
              1 == a.markers.length
                ? (a.setCenter(t.getCenter()), a.setZoom(2))
                : a.fitBounds(t);
          }
          var n = null;
          e(document).ready(function () {
            e('.acf-map').each(function () {
              n = a(e(this));
            });
          });
        })(jQuery);
        var n = null;
        e(document).ready(function () {
          e('.googleMap').each(function () {
            n = a(e(this));
          });
        });
      })(jQuery),
      e('[data-blocks]'))
    ) {
      var l = e('[data-blocks]'),
        c = l.data('cols-desktop');
      (c =
        e(window).width() < 768
          ? l.data('cols-mobile')
          : e(window).width() < 1024
          ? l.data('cols-tablet')
          : l.data('cols-desktop')),
        l.on('click', '[data-expand]', function (a) {
          a.preventDefault();
          var t = e('[data-blocks]'),
            i = t.find('[data-item]'),
            n = e(this).closest('[data-item]'),
            o = n.index(),
            s = t.find('[data-item]').length,
            l = Math.ceil((o + 1) / c),
            r = l * c - 1;
          r > s - 1 && (r = s - 1),
            '1' == n.attr('data-open')
              ? (t
                  .find('> [data-expanded]')
                  .slideUp(t.data('transition'), function () {
                    t.find('> [data-expanded]').remove();
                  }),
                n.attr('data-open', '0'),
                t.attr('data-open', '0'))
              : '1' != t.attr('data-open')
              ? ((block = t
                  .find('[data-item]')
                  .eq(o)
                  .find('[data-expanded]')
                  .clone()),
                block.insertAfter('[data-item]:eq(' + r + ')').hide(),
                t.find('> [data-expanded]').slideDown(t.data('transition')),
                n.attr('data-open', '1'),
                t.attr('data-open', '1'))
              : t
                  .find('> [data-expanded]')
                  .slideUp(t.data('transition'), function () {
                    t.find('> [data-expanded]').remove(),
                      i.attr('data-open', '0'),
                      n.attr('data-open', '1'),
                      (block = t
                        .find('[data-item]')
                        .eq(o - 1)
                        .find('[data-expanded]')
                        .clone()),
                      block.insertAfter('[data-item]:eq(' + r + ')').hide(),
                      t
                        .find('> [data-expanded]')
                        .slideDown(t.data('transition'));
                  });
        }),
        l.on('click', '[data-close]', function (e) {
          e.preventDefault(),
            l.find('[data-item][data-open="1"] [data-expand]').trigger('click');
        });
    }
    e(window).resize(function () {
      c =
        e(window).width() < 768
          ? l.data('cols-mobile')
          : e(window).width() < 1024
          ? l.data('cols-tablet')
          : l.data('cols-desktop');
    });
    var r = e('[data-isotope]');
    r.isotope({
      itemSelector: '.post',
      layoutMode: 'masonry',
      percentPosition: !0,
      masonry: { gutter: '[data-gutter]' },
    }),
      e('[data-filter] a').on('click', function (a) {
        a.preventDefault();
        var t = window.location.pathname.split('/');
        'projects' == t[1] || 'exchanges' == t[1]
          ? (e(this).parent().parent().find('a').removeClass('active'),
            e(this).addClass('active'),
            r.isotope({
              filter:
                '*' == e(this).data('filter')
                  ? '*'
                  : '.' + e(this).data('filter'),
            }),
            '*' == e(this).data('filter') &&
              (e('[data-filter]').removeClass('active'),
              e('[data-filter]').parent().removeClass('active')))
          : (window.location.href = '/' + t[1] + 's#' + e(this).attr('href'));
      }),
      window.location.hash &&
        setTimeout(function () {
          e(
            '[data-filter] a[href="' +
              window.location.hash.replace('#', '') +
              '"]'
          ).trigger('click');
        }, 800),
      setTimeout(function () {
        e('[data-isotope]').addClass('active');
      }, 1200),
      a(),
      i(),
      t(),
      n(),
      e('a.hideAnnouncement').click(function (a) {
        a.preventDefault(),
          e(this).parent('.announcementBar').hasClass('active') &&
            (e(this).parent('.announcementBar').removeClass('active'),
            e('main').removeClass('announce'),
            e('header').removeClass('announce'),
            e('.phoneHeader').removeClass('announce'),
            e('.bookBlock').removeClass('announce'),
            n());
      }),
      e('.popup').magnificPopup({
        type: 'image',
        closeOnContentClick: !0,
        mainClass: 'mfp-img-mobile',
        image: { verticalFit: !0 },
      }),
      e('.popupLink').magnificPopup({
        type: 'inline',
        preloader: !1,
        modal: !0,
      }),
      e(document).on('click', '.popup-modal-dismiss', function (a) {
        a.preventDefault(), e.magnificPopup.close();
      }),
      e('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        gallery: { enabled: !0, navigateByImgClick: !0, preload: [0, 1] },
      });
    skrollr.init({
      mobileCheck: function () {
        return !1;
      },
      render: function (e) {},
    });
    e('section.fullText .container .accordion .block .question').click(
      function (a) {
        a.preventDefault(),
          e(this).hasClass('active')
            ? (e(this).removeClass('active'),
              e(this).siblings('.answer').slideUp(300))
            : (e(this).addClass('active'),
              e(this).siblings('.answer').slideDown(300));
      }
    ),
      e('.charity-carousel').slick({
        autoplay: !1,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 3,
        slidesToScroll: 3,
        arrows: !1,
        dots: !0,
        responsive: [
          {
            breakpoint: 769,
            settings: { arrows: !1, slidesToShow: 2, slidesToScroll: 2 },
          },
          {
            breakpoint: 768,
            settings: { arrows: !1, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      }),
      e('.news-carousel').slick({
        autoplay: !1,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 4,
        slidesToScroll: 4,
        arrows: !1,
        dots: !0,
        responsive: [
          {
            breakpoint: 769,
            settings: { arrows: !1, slidesToShow: 2, slidesToScroll: 2 },
          },
          {
            breakpoint: 768,
            settings: { arrows: !1, slidesToShow: 1, slidesToScroll: 1 },
          },
        ],
      }),
      e('.carousel.logos').slick({
        autoplay: !0,
        autoplaySpeed: 3e3,
        speed: 1e3,
        infinite: !0,
        slidesToShow: 5,
        slidesToScroll: 1,
        arrows: !1,
        dots: !0,
        responsive: [
          { breakpoint: 769, settings: { slidesToShow: 3, slidesToScroll: 1 } },
          { breakpoint: 640, settings: { slidesToShow: 1, slidesToScroll: 1 } },
        ],
      });
    var d = e('.filter-tab'),
      p = e('.items-wrapper');
    d.click(function () {
      if (!e(this).hasClass('active')) {
        d.removeClass('active'), jQuery(this).addClass('active');
        var a = jQuery(this).data('key');
        p.removeClass('active').hide(),
          e('.items-wrapper.' + a)
            .addClass('active')
            .fadeIn('slow');
      }
    }),
      e('.baseBtn.map a').click(function (a) {
        a.preventDefault(),
          e('.mapBlock').hasClass('active')
            ? (e('.mapBlock').removeClass('active'), e(this).text('Show Map'))
            : (e('.mapBlock').addClass('active'), e(this).text('Hide Map'));
      }),
      e('.closeMap').click(function (a) {
        a.preventDefault(),
          e('.mapBlock').removeClass('active'),
          e('.baseBtn.map a').text('Show Map');
      }),
      e(window).scroll(function () {}),
      e('section.homeHero.room .downArrow').on('click', function (a) {
        e('html, body').animate(
          {
            scrollTop:
              e(this).parent().parent().next('section').offset().top - 90,
          },
          1e3
        );
      }),
      e('.downArrow').on('click', function (a) {
        e('html, body').animate(
          {
            scrollTop:
              e(this).parent().parent('section').next('section').offset().top -
              90,
          },
          1e3
        );
      }),
      e('.backTop').on('click', function (a) {
        e('html, body').animate({ scrollTop: e('body').offset().top - 0 }, 1e3);
      }),
      e('.popup').magnificPopup({
        type: 'image',
        closeOnContentClick: !0,
        mainClass: 'mfp-img-mobile',
        image: { verticalFit: !0 },
      }),
      e('body').on('click', '.mfp-bottom-bar .link', function (a) {
        a.preventDefault(),
          e(this).parent().parent().hasClass('active')
            ? (e(this).parent().parent().removeClass('active'),
              e(this).text('View Details +'),
              e(this).siblings('.details').slideUp(200),
              e('button.mfp-arrow').removeClass('hidden'))
            : (e(this).parent().parent().addClass('active'),
              e(this).text('Hide Details -'),
              e(this).siblings('.details').slideDown(200),
              e('button.mfp-arrow').addClass('hidden'));
      }),
      e('.popup-vimeo').magnificPopup({
        type: 'iframe',
        mainClass: 'mfp-fade',
        removalDelay: 160,
        preloader: !1,
        fixedContentPos: !1,
      }),
      e('.artList').magnificPopup({
        delegate: 'a.artPopup',
        type: 'image',
        tLoading: 'Loading image #%curr%...',
        mainClass: 'mfp-img-mobile',
        gallery: { enabled: !0, navigateByImgClick: !1, preload: [0, 1] },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (e) {
            return (
              '<span class="link">' +
              e.el.attr('title') +
              '</span><span class="details"><span class="artist">' +
              e.el.attr('data-artist') +
              '</span><span class="title">' +
              e.el.attr('data-title') +
              ', ' +
              e.el.attr('data-year') +
              '</span><span class="composition">' +
              e.el.attr('data-composition') +
              '<br />' +
              e.el.attr('data-size') +
              '</span><span class="location">' +
              e.el.attr('data-location') +
              '</span><span class="priceBlock ' +
              e.el.attr('data-sold') +
              '"><span class="price">' +
              e.el.attr('data-price') +
              '</span><span class="sold">SOLD</span></span><span class="desc ' +
              e.el.attr('data-showdesc') +
              '">' +
              e.el.attr('data-desc') +
              '</span><span class="buttonBlock"><span class="enquire"><a class="" href="mailto:' +
              e.el.attr('data-enquire') +
              '">Enquire</a></span><span class="artistCollect ' +
              e.el.attr('data-artcollect') +
              '"><a class="" href="https://www.collect-art.com.au/" target="_blank">Interest Free Loan</a></span></span></span>'
            );
          },
        },
      }),
      e('span.mediaNavMobile li').click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active')
            ? e('span.mediaNavMobile').hasClass('active')
              ? e('span.mediaNavMobile').removeClass('active')
              : e('span.mediaNavMobile').addClass('active')
            : (e('span.mediaNavMobile li').removeClass('active'),
              e(this).addClass('active'),
              e('span.mediaNavMobile').removeClass('active'),
              (thisIndex = e(this).index()),
              e('section.articles .container .block').fadeOut(300),
              setTimeout(function () {
                e('section.articles .container .block')
                  .eq(thisIndex)
                  .fadeIn(300);
              }, 300));
      }),
      e('section.articles .container span.mediaNavMobile li.filter-tab').click(
        function (a) {
          a.preventDefault(),
            e(this).hasClass('active') &&
              ((thisIndex = e(this).index()),
              e('section.articles .container span.mediaNav a').removeClass(
                'active'
              ),
              e(this).addClass('active'),
              e('section.articles .container .block').fadeOut(300),
              setTimeout(function () {
                e('section.articles .container .block')
                  .eq(thisIndex)
                  .fadeIn(300);
              }, 300));
        }
      ),
      e('.mobileDropdown').click(function () {
        e(this).toggleClass('show');
      }),
      e('section.articles .container span.mediaNav a.blockLink').click(
        function (a) {
          a.preventDefault(),
            e(this).hasClass('active') ||
              ((thisIndex = e(this).index()),
              e('section.articles .container span.mediaNav a').removeClass(
                'active'
              ),
              e(this).addClass('active'),
              e('section.articles .container .block').fadeOut(300),
              setTimeout(function () {
                e('section.articles .container .block')
                  .eq(thisIndex)
                  .fadeIn(300);
              }, 300));
        }
      ),
      e(
        '.experience.complimentary .experiences .container span.experienceNav a.blockLink'
      ).click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active') ||
            ((thisIndex = e(this).index()),
            e(
              '.experience.complimentary .experiences .container span.experienceNav a'
            ).removeClass('active'),
            e(this).addClass('active'),
            e(
              '.experience.complimentary .experiences .container .block'
            ).fadeOut(300),
            setTimeout(function () {
              e('.experience.complimentary .experiences .container .block')
                .eq(thisIndex)
                .fadeIn(300);
            }, 300));
      }),
      e(
        '.experience.complimentary .experiences .container ul.experienceNav li'
      ).click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active') ||
            ((thisIndex = e(this).index()),
            e(
              '.experience.complimentary .experiences .container ul.experienceNav li a'
            ).removeClass('active'),
            e(this).children().addClass('active'),
            e(
              '.experience.complimentary .experiences .container .block'
            ).fadeOut(300),
            setTimeout(function () {
              e('.experience.complimentary .experiences .container .block')
                .eq(thisIndex)
                .fadeIn(300);
            }, 300));
      }),
      e(
        '.experience.signature .experiences .container span.experienceNav a.blockLink'
      ).click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active') ||
            ((thisIndex = e(this).index()),
            e(
              '.experience.signature .experiences .container span.experienceNav a'
            ).removeClass('active'),
            e(this).addClass('active'),
            e('.experience.signature .experiences .container .block').fadeOut(
              300
            ),
            setTimeout(function () {
              e('.experience.signature .experiences .container .block')
                .eq(thisIndex)
                .fadeIn(300);
            }, 300));
      }),
      e(
        '.experience.signature .experiences .container ul.experienceNav li'
      ).click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active') ||
            ((thisIndex = e(this).index()),
            e(
              '.experience.signature .experiences .container ul.experienceNav li a'
            ).removeClass('active'),
            e(this).children().addClass('active'),
            e('.experience.signature .experiences .container .block').fadeOut(
              300
            ),
            setTimeout(function () {
              e('.experience.signature .experiences .container .block')
                .eq(thisIndex)
                .fadeIn(300);
            }, 300));
      }),
      e(
        '.experience.thirdParty .experiences .container span.experienceNav a.blockLink'
      ).click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active') ||
            ((thisIndex = e(this).index()),
            e(
              '.experience.thirdParty .experiences .container span.experienceNav a'
            ).removeClass('active'),
            e(this).addClass('active'),
            e('.experience.thirdParty .experiences .container .block').fadeOut(
              300
            ),
            setTimeout(function () {
              e('.experience.thirdParty .experiences .container .block')
                .eq(thisIndex)
                .fadeIn(300);
            }, 300));
      }),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.guide').click(function (
            a
          ) {
            a.preventDefault();
            var t = e(this).eq(0).attr('rel'),
              i =
                e('.experience.complimentary .expandedExperience').attr(
                  'rel'
                ) == t;
            e(i) &&
              (e('.experience .expandedExperience').removeClass('active'),
              e('.experience .expandedExperience')
                .eq(t - 1)
                .addClass('active'),
              e('body, html').animate(
                {
                  scrollTop:
                    e(
                      '.experience.complimentary .expandedExperience.active'
                    ).offset().top - 150,
                },
                1e3
              ));
          }),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.leisure').click(
            function (a) {
              a.preventDefault();
              var t = e(this).eq(0).attr('rel'),
                i = e('.expandedExperience.leisure').attr('rel') == t;
              e(i) &&
                (e('.experience .expandedExperience.leisure').removeClass(
                  'active'
                ),
                e('.experience .expandedExperience.leisure')
                  .eq(t - 1)
                  .addClass('active'),
                e('body, html').animate(
                  {
                    scrollTop:
                      e(
                        '.experience .expandedExperience.leisure.active'
                      ).offset().top - 100,
                  },
                  1e3
                ));
            }
          ),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.palate').click(function (
            a
          ) {
            a.preventDefault();
            var t = e(this).eq(0).attr('rel'),
              i = e('.expandedExperience.palate').attr('rel') == t;
            e(i) &&
              (e('.experience .expandedExperience.palate').removeClass(
                'active'
              ),
              e('.experience .expandedExperience.palate')
                .eq(t - 1)
                .addClass('active'),
              e('body, html').animate(
                {
                  scrollTop:
                    e('.experience .expandedExperience.palate').offset().top -
                    100,
                },
                1e3
              ));
          }),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.signature.half').click(
            function (a) {
              a.preventDefault();
              var t = e(this).eq(0).attr('rel'),
                i = e('.expandedExperience.signature.half').attr('rel') == t;
              e(i) &&
                (e(
                  '.experience .expandedExperience.signature.half'
                ).removeClass('active'),
                e('.experience .expandedExperience.signature.half')
                  .eq(t - 1)
                  .addClass('active'),
                e('body, html').animate(
                  {
                    scrollTop:
                      e(
                        '.experience .expandedExperience.signature.half.active'
                      ).offset().top - 100,
                  },
                  1e3
                ));
            }
          ),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.signature.full').click(
            function (a) {
              a.preventDefault();
              var t = e(this).eq(0).attr('rel'),
                i = e('.expandedExperience.signature.full').attr('rel') == t;
              e(i) &&
                (e(
                  '.experience .expandedExperience.signature.full'
                ).removeClass('active'),
                e('.experience .expandedExperience.signature.full')
                  .eq(t - 1)
                  .addClass('active'),
                e('body, html').animate(
                  {
                    scrollTop:
                      e(
                        '.experience .expandedExperience.signature.full.active'
                      ).offset().top - 100,
                  },
                  1e3
                ));
            }
          ),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.thirdParty.half').click(
            function (a) {
              a.preventDefault();
              var t = e(this).eq(0).attr('rel'),
                i = e('.expandedExperience.thirdParty.half').attr('rel') == t;
              e(i) &&
                (e(
                  '.experience .expandedExperience.thirdParty.half'
                ).removeClass('active'),
                e('.experience .expandedExperience.thirdParty.half')
                  .eq(t - 1)
                  .addClass('active'),
                e('body, html').animate(
                  {
                    scrollTop:
                      e(
                        '.experience .expandedExperience.thirdParty.half.active'
                      ).offset().top - 100,
                  },
                  1e3
                ));
            }
          ),
      (ww = e(window).width()),
      ww > 0
        ? (e('.popup-modal').magnificPopup({
            type: 'inline',
            preloader: !1,
            closeOnBgClick: !0,
          }),
          e(document).on('click', '.popup-modal-dismiss', function (a) {
            a.preventDefault(), e.magnificPopup.close();
          }))
        : e('.block .column.desktop a.experienceSingle.thirdParty.full').click(
            function (a) {
              a.preventDefault();
              var t = e(this).eq(0).attr('rel'),
                i = e('.expandedExperience.thirdParty.full').attr('rel') == t;
              e(i) &&
                (e(
                  '.experience .expandedExperience.thirdParty.full'
                ).removeClass('active'),
                e('.experience .expandedExperience.thirdParty.full')
                  .eq(t - 1)
                  .addClass('active'),
                e('body, html').animate(
                  {
                    scrollTop:
                      e(
                        '.experience .expandedExperience.thirdParty.full.active'
                      ).offset().top - 100,
                  },
                  1e3
                ));
            }
          ),
      e('a.closePerson').click(function (a) {
        a.preventDefault(),
          e('.experience .expandedExperience').removeClass('active'),
          e('body, html').animate(
            {
              scrollTop: e(this).parent().parent().parent('.block').offset()
                .top,
            },
            1e3
          );
      }),
      e('a.nav').click(function (a) {
        a.preventDefault(),
          e(this).hasClass('active')
            ? (e(this).removeClass('active'),
              e('nav.mobileMenu').removeClass('active'),
              e('html').removeClass('fixed'))
            : (e(this).addClass('active'),
              e('nav.mobileMenu').addClass('active'),
              e('html').addClass('fixed'));
      }),
      e('a.nav').click(function (a) {
        e('span.ham').toggleClass('active');
      }),
      e('.sliderBlockCarousel').owlCarousel({
        loop: !0,
        animateOut: 'fadeOut',
        items: 1,
        nav: !0,
        navText: !1,
        dots: !0,
        touchDrag: !0,
        mouseDrag: !0,
        autoplay: 5e3,
        center: !0,
        smartSpeed: 1e3,
      }),
      e('.roomsSlider').owlCarousel({
        loop: !0,
        animateOut: 'fadeOut',
        items: 1,
        nav: !0,
        navText: !0,
        dots: !1,
        touchDrag: !0,
        mouseDrag: !0,
        autoplay: 5e3,
        center: !0,
        smartSpeed: 1e3,
      }),
      e('.fullPageSliderCarousel').owlCarousel({
        loop: !0,
        animateOut: 'fadeOut',
        items: 1,
        nav: !0,
        navText: !1,
        dots: !0,
        touchDrag: !0,
        autoplay: 5e3,
        mouseDrag: !0,
        center: !0,
        stagePadding: 0,
        margin: 0,
        smartSpeed: 1e3,
      }),
      e('.experienceSlider').flexslider({
        animationLoop: !0,
        smoothHeigh: !0,
        slideshow: !0,
        slideshowSpeed: 6e3,
        animationSpeed: 900,
        controlNav: !1,
        directionNav: !1,
      }),
      e('.owl-carousel.sliderBlockCarousel').each(function () {
        (pl = e(this).children('.owl-dots').width()), (ml = pl / 2);
      }),
      e('.grid').isotope({
        itemSelector: '.grid-item',
        layoutMode: 'packery',
        percentPosition: !0,
        packery: { gutter: '.gutter-sizer', stagger: 30 },
      }),
      e('.grid').css({ visibility: 'visible', opacity: '1' }),
      e('.popup-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        mainClass: 'mfp-img-mobile',
        closeOnContentClick: !0,
        gallery: { verticalFit: !0, enabled: !0, navigateByImgClick: !0 },
        image: {
          tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
          titleSrc: function (e) {
            return e.el.attr('title');
          },
        },
      }),
      AOS.init(),
      e(document).on('nfFormReady', function (a, t) {
        e('input#nf-field-30').val('Test');
      });
  }),
    e(window).on('load', function () {
      a(),
        n(),
        o(),
        e('.grid').magnificPopup({
          delegate: 'a',
          type: 'image',
          tLoading: 'Loading image #%curr%...',
          mainClass: 'mfp-img-mobile',
          gallery: { enabled: !0, navigateByImgClick: !0, preload: [0, 1] },
          image: {
            tError:
              '<a href="%url%">The image #%curr%</a> could not be loaded.',
            titleSrc: function (e) {
              return e.el.attr('title');
            },
          },
        }),
        e('section.galleryListing .container .grid .grid-item a .overlay').each(
          function () {
            (oh = e(this).outerHeight()),
              e(this).css({ bottom: '-' + oh + 'px' });
          }
        ),
        e('section.roomPagination a').addClass('init');
      var t = e('section.homeHero video').attr('data-src'),
        i = e('section.homeHero video').attr('data-mobile-src');
      e(window).width() < 641
        ? e('section.homeHero video').append(
            '<source type="video/mp4" src="' + i + '">'
          )
        : e('section.homeHero video').append(
            '<source type="video/mp4" src="' + t + '">'
          ),
        e('section.homeHero video').attr('autoplay', ''),
        e('section.homeHero video').attr('playsinline', '');
    }),
    e(window).resize(function () {
      a(), n();
    });
})(window.jQuery);
